import {useContext} from 'react';
import {IntlContext} from '../../containers/IntlProvider';
import classNames from 'classnames';

type Props = {
  loaderMessage?: string | false;
  extraClasses?: string;
};

/**
 * Returns the spinning loader dots and text JSX element
 * @param loaderMessage is an option text that replace LOADING text.
 * @return {JSX.Element}
 * @constructor
 */
export default function Loader({loaderMessage, extraClasses = ''}: Props) {
  const localizedStringBundle: StringMap = useContext(IntlContext);
  const loaderClasses = classNames('m-auto flex flex-col items-center justify-center', extraClasses);
  return (
    <div data-testid="loader" className={loaderClasses}>
      <div className="h-12 w-12 animate-spin rounded-full border-3 border-accent border-action border-t-transparent" />
      <div className="text-md mt-4 font-medium">{loaderMessage || localizedStringBundle.LOADING}</div>
    </div>
  );
}
