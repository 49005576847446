import utils from '../helpers/utils';
import {getTrackingValues} from '../helpers/tracking';
import {api} from '../helpers/api';

export const UPDATE_ONBOARDING_FLAG_API_REQUEST = 'UPDATE_ONBOARDING_FLAG_API_REQUEST';
export const UPDATE_ONBOARDING_FLAG_API_SUCCESS = 'UPDATE_ONBOARDING_FLAG_API_SUCCESS';
export const UPDATE_ONBOARDING_FLAG_API_FAIL = 'UPDATE_ONBOARDING_FLAG_API_FAIL';

export const updateOnboardFlagRequest = () => {
  return {
    type: UPDATE_ONBOARDING_FLAG_API_REQUEST,
  };
};

export const updateOnboardFlagStatusSuccess = () => {
  return {
    type: UPDATE_ONBOARDING_FLAG_API_SUCCESS,
  };
};

export const updateOnboardFlagStatusFail = () => {
  return {
    type: UPDATE_ONBOARDING_FLAG_API_FAIL,
  };
};

export function updateOnboardingFlag() {
  const url = utils.updateOnboardingFlag();
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      client_id: 'dsp',
      'x-nlok-trace-id': getTrackingValues().NLokTraceId,
    },
    credentials: 'include',
    body: JSON.stringify({onboardingFlag: 'hide'}),
  };

  return api(url, config, updateOnboardFlagRequest(), updateOnboardFlagStatusSuccess, updateOnboardFlagStatusFail);
}
