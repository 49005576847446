import {useDispatch} from 'react-redux';
import {logout} from '../actions/logout';
import {useEffect, useContext} from 'react';
import {IntlContext} from '../containers/IntlProvider';

/**
 * Renders logged out message
 * @return {JSX.Element}
 */
export const LoggedOut = () => {
  const dispatch = useDispatch();
  const localizedStringBundle: StringMap = useContext(IntlContext);

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <div className="h-screen w-full bg-surface pt-8 text-center">
      <div className="pb-8 text-lg">{localizedStringBundle.LOGGED_OUT}</div>
    </div>
  );
};
