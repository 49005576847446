import {createContext} from 'react';
import {getLangCode, stringBundle} from '../stringBundle';

const IntlContext = createContext({});

type Props = {
  children?: $TSFixMe | $TSFixMe[];
};

const IntlProvider = ({children}: Props) => {
  const localizedStringBundle: StringMap = stringBundle(getLangCode(), () => {});
  return <IntlContext.Provider value={localizedStringBundle}>{children}</IntlContext.Provider>;
};
export {IntlContext, IntlProvider};
