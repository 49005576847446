import {
  API_RATE_LIMIT_HIT,
  IS_MOBILE_APP,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  WHITE_LABEL_CLIENT,
  UPDATE_USER,
} from '../actions/auth';
import _get from 'lodash/get';

export type AuthState = {
  isLoading: null | true | false;
  user: null | {
    primaryMember: Member;
    associatedMembers: Member[];
    expiry: number;
    isMobile: boolean;
    isAgent: boolean;
    isNortonSingleSignOn: boolean;
    hasUsernameConflict: boolean;
  };
  loginError: {
    error: boolean;
    result: string;
  };
  isMobileApp: boolean;
  refreshingTokens: boolean;
  expiry: null | string;
  whiteLabelClient: null | string;
};

const initialState = {
  isLoading: null,
  user: null,
  loginError: {error: false, result: 'pending'},
  isMobileApp: false, //the flag is solely for the case when SPA is loaded in a webview for the mobile apps, and nothing to do with viewing on a mobile browser
  refreshingTokens: false,
  expiry: null,
  whiteLabelClient: null,
};

export default function auth(state = initialState, action = {}): AuthState {
  switch ((action as $TSFixMe).type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        loginError: {error: false, result: 'pending'},
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: (action as $TSFixMe).user,
        loginError: {error: false, result: ''},
      });
    case UPDATE_USER:
      return Object.assign({}, state, {
        user: (action as $TSFixMe).user,
      });
    case API_RATE_LIMIT_HIT:
      return Object.assign({}, state, {
        isLoading: false,
        loginError: {error: true, result: (action as $TSFixMe).error},
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        loginError: {error: true, result: (action as $TSFixMe).error},
      });
    case IS_MOBILE_APP:
      return Object.assign({}, state, {
        isMobileApp: (action as $TSFixMe).bool,
      });
    case REFRESH_TOKEN_REQUEST:
      return Object.assign({}, state, {
        refreshingTokens: true,
      });
    case REFRESH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        refreshingTokens: false,
        expiry: _get(action, 'result.expiry', null) || null,
      });
    case REFRESH_TOKEN_FAILURE:
      return Object.assign({}, state, {
        refreshingTokens: false,
        expiry: null,
      });
    case WHITE_LABEL_CLIENT:
      return Object.assign({}, state, {
        whiteLabelClient: _get(action, 'whiteLabelClient', null),
      });
    default:
      return state;
  }
}
/* eslint-enable camelcase */
