import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import createReducer from './reducers/rootReducer';

const logger = createLogger({
  level: 'info',
  collapsed: true,
  duration: false,
});

const initialState = {};
const tempReducer = createReducer({});

function configureStore() {
  let store;
  if (module.hot) {
    store = createStore(
      tempReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware, logger),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
      )
    );
  } else {
    store = createStore(
      tempReducer,
      initialState,
      compose(applyMiddleware(thunkMiddleware), (f) => f)
    );
  }

  store.asyncReducers = {};
  return store;
}

export default configureStore();
