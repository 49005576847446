import utils from '../helpers/utils';
import {api} from '../helpers/api';

export const SET_FCRA_REQUEST = 'SET_FCRA_REQUEST';
export const SET_FCRA_SUCCESS = 'SET_FCRA_SUCCESS';
export const SET_FCRA_FAILURE = 'SET_FCRA_FAILURE';
export const SET_FCRA_SUCCESS_MODAL_STATE = 'SET_FCRA_SUCCESS_MODAL_STATE';

export const setFcraRequest = () => ({
  type: SET_FCRA_REQUEST,
});

export const setFcraSuccess = () => ({
  type: SET_FCRA_SUCCESS,
  accepted: true,
});

export const setFcraFailure = (error: $TSFixMe) => ({
  type: SET_FCRA_FAILURE,
  error,
});

export const setFcraSuccessModalState = () => ({
  type: SET_FCRA_SUCCESS_MODAL_STATE,
});

/**
 *	Method for accepting  FCRA.
 */
export function setFcraAccept() {
  const url = utils.getFcraConsentPath();
  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };

  return api(url, config, setFcraRequest(), setFcraSuccess, setFcraFailure);
}
