import _get from 'lodash/get';
import dateUtils from './dateUtils';
import {getScoreCssColor, getScoreRanking} from './creditScoreUtils';

function getScorePercentage(scoreRanges: $TSFixMe, score: $TSFixMe) {
  const percentage =
    ((score - scoreRanges[0].low) * 100) / (scoreRanges[scoreRanges.length - 1].high - scoreRanges[0].low);
  return percentage.toFixed(0);
}

export function buildCreditScoreData(
  scoreData: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  bureau: $TSFixMe = 'EFX'
) {
  let scoreName = null;
  let percentage = null;
  let providerViews = [];
  if (scoreData && Array.isArray(scoreData.providerViews)) {
    providerViews = scoreData.providerViews.filter((provider: $TSFixMe) => {
      return provider.provider === bureau;
    });
  }

  // if scoreData is empty, just return an empty array, no reason to proceed
  if (providerViews.length === 0) return [];

  let score = _get(providerViews, '[0].score', 'NA');

  const isScoreValid = (scoreCheck: $TSFixMe) => {
    return scoreCheck !== 0 && scoreCheck !== 1 && scoreCheck !== 4 && scoreCheck !== 'NA' && scoreCheck !== null;
  };

  const date = _get(scoreData, 'generatedDate', localizedStringBundle.CREDIT_SCORE_DATE_UNAVAILABLE);
  const formattedDate = dateUtils.convertDateToSlashFormat(date, langCode, '-');
  const scoreType = _get(scoreData, 'scoreType', localizedStringBundle.CREDIT_SCORE_REPORT_UNAVAILABLE);
  const scoreReasons = _get(providerViews, '[0].scoreReasons', []);
  if (bureau === 'CATU') {
    scoreReasons.forEach(
      (reason: $TSFixMe) => (reason.description = localizedStringBundle[`TU_SCORE_REASON_${reason.code}`])
    );
  }
  const scoreRanges = _get(providerViews, '[0].scoreRanges', []);
  if (isScoreValid(score)) {
    if (Array.isArray(scoreRanges)) {
      scoreRanges.forEach((range) => {
        if (score >= range.low && score <= range.high) {
          scoreName = range.name;
        }
      });
    }
  } else {
    scoreName = 'NA'; // don't localize this because it is used as a key in getScoreRanking and getScoreCssColor
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const scoreRangeIndex = getScoreRanking(scoreName);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const cssColor = getScoreCssColor(scoreName);

  if (isScoreValid(score)) {
    percentage = getScorePercentage(scoreRanges, score);
  } else {
    percentage = 0;
    if (score === 'NA') {
      score = localizedStringBundle.CREDIT_SCORE_DASH;
    }
  }

  return {
    score,
    percentage,
    date,
    formattedDate,
    scoreType,
    scoreReasons,
    scoreRanges,
    scoreName,
    cssColor,
    scoreRangeIndex,
  };
}
