export const INSTITUTION_INACTIVE_STATES = [
  'CREDENTIAL_ERROR',
  'UPDATE_CANCELLED',
  'SITE_CREDENTIAL_ERROR',
  'AUTH_SITE_CREDENTIAL_ERROR',
  'SITE_TECHNICAL_ERROR',
  'MAX_ATTEMPT_SITE_CREDENTIAL_ERROR',
  'SITE_UPDATED',
  'PERSONAL_DETAILS_SITE_UPDATED',
  'PROMOTIONAL_SITE_UPDATED',
  'UNSUPPORTED_LANG_SITE_UPDATED',
  'SITE_NOT_SUPPORTED',
  'AUTH_SITE_NOT_SUPPORTED',
  'REQUEST_TIMEOUT',
  'SITE_REQUEST_TIMEOUT',
  'AUTH_REQUEST_TIMEOUT',
  'LOGIN_REQUEST_TIMEOUT',
  'SITE_ERROR',
  'SITE_TECHNICAL_ERROR',
  'SITE_UNAVAILABLE',
  'BETA_SITE_WORK_IN_PROGRESS',
  'EXPIRED_UAR',
  'MULTIPLE_LOGIN_UAR',
  'NEW_INFORMATION_NEEDED',
  'OB_NO_CONSENT',
  'INVALID_OB_NO_TOKEN',
  'TECHNICAL_ERROR_OB_NO_TOKEN',
  'UNKNOWN_SITE_NOT_SUPPORTED',
  'UNKNOWN',
  'FL4_ACCOUNT_LOCKED',
  'FL4_ADDL_AUTHENTICATION_REQUIRED',
  'FL4_BETA_SITE_DEV_IN_PROGRESS',
  'FL4_CONSENT_ERROR',
  'FL4_CREDENTIALS_UPDATE_NEEDED',
  'FL4_DATA_NOT_AVAILABLE',
  'FL4_DATA_RETRIEVAL_FAILED',
  'FL4_INCORRECT_CREDENTIALS',
  'FL4_INCORRECT_OAUTH_TOKEN',
  'FL4_INVALID_ADDL_INFO_PROVIDED',
  'FL4_REQUEST_TIME_OUT',
  'FL4_SITE_ERROR',
  'FL4_SITE_NOT_SUPPORTED',
  'FL4_SITE_SESSION_INVALIDATED',
  'FL4_TECH_ERROR',
  'FL4_USER_ACTION_NEEDED_AT_SITE',
  'FL4_CONSENT_RENEWAL_REQUIRED',
  'MT_SUSPENDED_MISSING_ANSWER_AUTH_SECURITY',
  'MT_SUSPENDED_MISSING_ANSWER_AUTH_OTP',
  'MT_SUSPENDED_MISSING_ANSWER_AUTH_CAPTCHA',
  'MT_SUSPENDED_MISSING_ANSWER_AUTH_PUZZLE',
  'MT_AUTH_CREDS_CAPTCHA_INVALID',
  'MT_AUTH_CREDS_PUZZLE_INVALID',
  'MT_AUTH_CREDS_OTP_INVALID',
  'MT_AUTH_CREDS_INVALID',
  'MT_AUTH_CREDS_SECURITY_INVALID',
  'MT_AUTH_CREDS_LOCKED_TEMPORARY',
  'MT_AUTH_CREDS_LOCKED_PERMANENT',
  'MT_ERROR_PERMANENT',
  'MT_ERROR_TEMPORARY',
  'MT_ERROR_SESSION',
  'MT_ERROR_SERVICE_UNAVAILABLE',
  'MT_ERROR_NETWORK',
  'MT_ERROR_UNSUPPORTED',
  'MT_GUEST_INTERVENTION_REQUIRED',
  'MT_INACTIVE',
  'MT_RUNNING_AUTH',
];
