import {UPDATE_ONBOARDING_FLAG_API_SUCCESS} from '../actions/onboardingDashboard';
import {UPDATE_ONBOARDING_FLAG_API_FAIL} from '../actions/onboardingDashboard';

const initialState = {
  isOnboardingFlagUpdated: true,
};

export default function onboardingReducer(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case UPDATE_ONBOARDING_FLAG_API_SUCCESS:
      return Object.assign({}, state, {isOnboardingFlagUpdated: true});
    case UPDATE_ONBOARDING_FLAG_API_FAIL:
      return Object.assign({}, state, {isOnboardingFlagUpdated: false});
    default: {
      return state;
    }
  }
}
