export const currencyCodes: StringMap = {
  AR: 'ARS',
  AU: 'AUD',
  AW: 'AWG',
  BE: 'EUR',
  BO: 'BOB',
  BR: 'BRL',
  BZ: 'BZD',
  CA: 'CAD',
  CL: 'CLP',
  CO: 'COP',
  CR: 'CRC',
  CW: 'ANG',
  CN: 'CNY',
  DO: 'DOP',
  EC: 'USD',
  ES: 'EUR',
  FI: 'EUR',
  GB: 'GBP',
  GT: 'GTQ',
  HK: 'HKD',
  HN: 'HNL',
  IE: 'EUR',
  IT: 'EUR',
  IN: 'INR',
  JP: 'JPY',
  KY: 'KYD',
  MX: 'MXN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NZ: 'NZD',
  PA: 'PAB',
  PE: 'PEN',
  PL: 'PLN',
  PY: 'PYG',
  SE: 'SEK',
  SV: 'USD',
  SX: 'ANG',
  TT: 'TTD',
  TW: 'TWD',
  UY: 'UYU',
  VE: 'VES',
};
