/**
 * Used to trigger member-api cleanup
 * when NGP logout occurs.
 */

import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function logoutSuccess(result: $TSFixMe) {
  utils.clearSessionAndCookies();
  return {
    type: LOGOUT_SUCCESS,
    logout: result,
  };
}

export function logoutFailure(error: $TSFixMe) {
  utils.clearSessionAndCookies();
  return {
    type: LOGOUT_FAILURE,
    error,
  };
}

export function logout() {
  const url = utils.getLogoutPath();
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    timeout: 5000,
  };
  return api(url, config, logoutRequest(), logoutSuccess, logoutFailure);
}
