import {useEffect, useRef} from 'react';

/**
 * Custom hook that returns the previous value of a given value.
 * @param value - The value to track.
 * @returns The previous value of the given value.
 */
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
export default usePrevious;
