import {getTrackingValues} from './tracking';
import utils from './utils';
import {getLangCode} from '../stringBundle';

type Header = {
  Accept: string;
  account_id?: string;
  client_id: string;
  'Content-Type'?: string;
  lang_code: string;
  'X-CSRF-Token': string;
  'x-nlok-trace-id': string;
  access_token?: string;
};

// To be set by the SPA when operating in cookieless mode.  Stored here instead of on the window object to prevent GTM scripts from accessing it.
let headerAccessToken = '';

/**
 * Sets headerAccessToken, for subsequent use in methods that call Member API.
 * The SPA should call this method whenever it obtains a new access token from Member API to be used in future calls to Member API endpoints
 * that require authentication, while the SPA is in cookieless mode.
 * The SPA cannot use cookies when it is iframed on a partner site that does not have a Norton domain.
 * @param {string} token
 */
export function setHeaderAccessToken(token: string) {
  headerAccessToken = token;
}

/**
 * Returns headerAccessToken.
 * @returns {string}
 */
export function getHeaderAccessToken(): string {
  return headerAccessToken;
}

/**
 * Helper method to call an API
 * @param {string} url - api endpoint
 * @param {Object} options - object expected by fetch.
 * @return {Promise<>}
 */
export async function fetchRest(url: string, options?: FetchConfig): Promise<any> {
  try {
    const defaultOptions: FetchConfig = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
    };
    const mergedOptions: FetchConfig = {...defaultOptions, ...options};
    const defaultHeaders: Header = {
      Accept: 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      lang_code: getLangCode(),
      'x-nlok-trace-id': getTrackingValues().NLokTraceId,
      client_id: window.clientId || 'dsp', // window.clientId is set during partner auth with partner token, or NSL IDP.
    };

    // The SPA must use a token in the header when it is loaded in a 3rd party domain which prevents session cookies from being sent to Member API
    if (getHeaderAccessToken()) {
      defaultHeaders.access_token = getHeaderAccessToken();
    }
    const method = options?.method?.toUpperCase();
    if ((method === 'POST' || method === 'PUT') && !(options?.body?.constructor === FormData)) {
      defaultHeaders['Content-Type'] = 'application/json';
    }

    const headers = options?.headers || {};
    const mergedHeaders = headers ? {...defaultHeaders, ...headers} : defaultHeaders;
    const response = await fetch(url, {
      ...mergedOptions,
      headers: mergedHeaders,
      body: mergedOptions.body,
    });

    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (error) {
    return Promise.reject(error);
  }
}
