import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import _isNull from 'lodash/isNull';
import _sortBy from 'lodash/sortBy';
import dateUtils from './dateUtils';
import {getScoreCssColor, getScoreRanking} from './creditScoreUtils';
import _isEmpty from 'lodash/isEmpty';
import utils from './utils';
import {LogDataObj} from './creditHelper';

export function buildCreditReportData(
  data: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: string,
  bureau: string,
  accountId: string
) {
  const startTime = Date.now();
  const clientId = window.isDwm ? 'dwm' : 'dsp';
  const logObj: LogDataObj = {
    feature: 'Credit',
    actionType: 'Building Credit Report Data',
    startTime,
    clientId,
    statusCode: 204,
    accountId,
  };

  if (!data || _isEmpty(data) || typeof data === 'string') {
    logObj.message = `Data is either null|empty|string=>${typeof data}`;
    utils.log(logObj);
    return null;
  }

  const providerViews = _get(data, 'providerViews', []);
  if (_isEmpty(providerViews)) {
    logObj.message = `providerViews is either null|empty=>${typeof providerViews}`;
    utils.log(logObj);
    return null;
  }
  const providerViewFinal: $TSFixMe = [];

  // adding a defensive check incase there is different data type returned for providerViews as filter() and forEach() are array specific functions
  if (Array.isArray(providerViews)) {
    const generatedDate = data?.generatedDate || '';

    const latest = providerViews.filter((provider: $TSFixMe) => {
      return provider.provider === bureau;
    });

    // Preparing score parameters for the ArcChart
    // Don't translate the names because they are used as lookup values
    const defaultScoreRanges = [
      {low: 300, high: 599, name: 'Poor'},
      {low: 600, high: 660, name: 'Fair'},
      {low: 661, high: 715, name: 'Good'},
      {low: 716, high: 747, name: 'Very Good'},
      {low: 748, high: 850, name: 'Excellent'},
    ];

    providerViews.forEach((item: $TSFixMe, index: $TSFixMe) => {
      providerViewFinal[index] = item;

      const inquiries =
        !_isNull(item.inquiries) && !_isUndefined(item.inquiries)
          ? _sortBy(item.inquiries, (inquire) => {
              return 0 - inquire.reportedDate;
            })
          : [];
      const creditFreezeStatus = providerViews[index].creditFreezeStatus || {};
      let percentage = null;
      const date = _get(
        providerViews[index],
        'summary.reportGenerated',
        localizedStringBundle.CREDIT_SCORE_DATE_UNAVAILABLE
      );
      const formattedDate = dateUtils.convertDateToSlashFormat(date, langCode, '-');
      const providerSummaryCreditScore = _get(item, 'summary.creditScore', {});
      let score = _get(providerSummaryCreditScore, 'score', 'NA');
      const scoreType = '';
      const scoreRanges = defaultScoreRanges;
      const scoreReasons = _get(item, 'summary.creditScore.scoreReasons', {});
      let scoreName = null;

      const isScoreValid = !(score === 0 || score === 1 || score === 4 || score === 'NA');

      // Calculate scoreName
      if (isScoreValid) {
        const range =
          scoreRanges && scoreRanges.length > 0
            ? scoreRanges.filter((latestRange) => {
                if (score >= latestRange.low && score <= latestRange.high) {
                  return latestRange.name;
                }
                return '';
              })
            : '';
        scoreName = _get(range, '[0].name', '');
      } else {
        scoreName = localizedStringBundle.CREDIT_SCORE_NA;
      }

      const scoreRangeIndex = getScoreRanking(scoreName);
      const cssColor = getScoreCssColor(scoreName);

      // Calculate percentage
      if (isScoreValid) {
        const lowScore = _get(scoreRanges[scoreRangeIndex], 'low', 0);
        const highScore = _get(scoreRanges[scoreRangeIndex], 'high', 100);
        const perc = scoreRangeIndex * 20 + ((score - lowScore) / (highScore - lowScore)) * 20;
        percentage = perc.toFixed(0);
      } else {
        percentage = 0;
        if (score === 'NA') {
          score = localizedStringBundle.CREDIT_SCORE_DASH;
        }
      }

      providerViewFinal[index].inquiries = inquiries;
      providerViewFinal[index].creditFreezeStatus = creditFreezeStatus;
      providerViewFinal[index].summary.creditScore = {
        cssColor,
        date,
        formattedDate,
        percentage,
        score,
        scoreName,
        scoreRanges,
        scoreRangeIndex,
        scoreType,
        scoreReasons,
      };
    });

    const id = data && data.id ? data.id : null;

    // Summary
    const summary =
      latest && latest[0] && latest[0].summary ? latest[0].summary : localizedStringBundle.CREDIT_SCORE_UNAVAILABLE;

    // Summary - Revolving Accounts
    const revolvingAccounts = summary.revolvingAccounts ? summary.revolvingAccounts : {};

    // Revolving Accounts - Available
    const revolvingAvailable = revolvingAccounts.available ? revolvingAccounts.available : {};
    const revolvingAvailableAmt = revolvingAvailable.amount ? revolvingAvailable.amount : '$0';

    // Summary - Revolving Accounts - Balance
    const revolvingBalance = revolvingAccounts.balance ? revolvingAccounts.balance : {};
    const revolvingBalanceAmt = revolvingBalance.amount ? revolvingBalance.amount : '$0';
    const revolvingBalanceCurrency = revolvingBalance.currency ? revolvingBalance.currency : '$';

    // Summary - Revolving Accounts - Credit Limit
    const revolvingCreditLimit = revolvingAccounts.creditLimit ? revolvingAccounts.creditLimit : {};
    const revolvingCreditLimitAmt = revolvingCreditLimit.amount ? revolvingCreditLimit.amount : '$0';

    // Summary - Revolving Accounts - Debt To Credit Ratio
    const revolvingDtcRatio = revolvingAccounts.debtToCreditRatio
      ? revolvingAccounts.debtToCreditRatio
      : localizedStringBundle.CREDIT_SCORE_NOT_AVAILABLE;

    // Summary - Revolving Accounts - Debt To Credit Ratio
    const revolvingMonthlyPayment = revolvingAccounts.monthlyPaymentAmount
      ? revolvingAccounts.monthlyPaymentAmount
      : {};
    const revolvingMonthlyPaymentAmount = revolvingMonthlyPayment.amount ? revolvingMonthlyPayment.amount : '$0';

    // Summary - Revolving Accounts - Total Count
    const revolvingTotal = revolvingAccounts.totalAccounts ? revolvingAccounts.totalAccounts : '0';
    const revolvingTotalWithBalance = revolvingAccounts.totalAccountsWithBalance
      ? revolvingAccounts.totalAccountsWithBalance
      : '0';

    // Summary - Accounts
    const averageAccountAgeMonths = summary.averageAccountAgeMonths
      ? summary.averageAccountAgeMonths
      : localizedStringBundle.CREDIT_SCORE_NOT_AVAILABLE;
    const creditFileSecurityFreezeFlag = summary.creditFileSecurityFreezeFlag
      ? summary.creditFileSecurityFreezeFlag
      : localizedStringBundle.CREDIT_SCORE_NOT_AVAILABLE;

    // Score
    const creditScore =
      latest && latest[0] && latest[0].summary.creditScore
        ? latest[0].summary.creditScore
        : localizedStringBundle.CREDIT_SCORE_UNAVAILABLE;
    const score =
      latest && latest[0] && creditScore ? creditScore.score : localizedStringBundle.CREDIT_SCORE_UNAVAILABLE;
    const scorePercentage = (scorePerc: $TSFixMe) => {
      return (550 / scorePerc) * 100;
    };

    // Date
    let formattedDate = '';
    const date = _get(summary, 'reportGenerated', localizedStringBundle.CREDIT_SCORE_NA);
    if (date !== 'NA') {
      formattedDate = dateUtils.convertDateToSlashFormat(date, langCode, '-');
    } else {
      formattedDate = localizedStringBundle.CREDIT_SCORE_DATE_UNAVAILABLE;
    }

    const translateReporType = (type: $TSFixMe) => {
      let translatedType = null;
      switch (type) {
        case 'US_3B':
          translatedType = localizedStringBundle['3_BUREAU_CREDIT_REPORT'];
          break;
        case 'US_EFX':
          translatedType = localizedStringBundle.EQUIFAX_REPORT;
          break;
        default:
          translatedType = '';
      }
      return translatedType;
    };

    // Report Type
    const reportType =
      data && data.reportType ? data.reportType : localizedStringBundle.CREDIT_SCORE_REPORT_UNAVAILABLE;
    const translatedType = translateReporType(reportType);

    // Score Reasons
    const reasons =
      summary && creditScore && score ? creditScore.scoreReasons : localizedStringBundle.CREDIT_SCORE_NOT_AVAILABLE;
    // Loan Risk Ranges
    const loanRiskRanges =
      summary && creditScore && score ? creditScore.loanRiskRanges : localizedStringBundle.CREDIT_SCORE_NOT_AVAILABLE;

    // Score Ranges
    const percentage = scorePercentage(score);
    const ranges = summary && creditScore && score ? creditScore.scoreRanges : [];
    const ranking =
      ranges && ranges.length > 0
        ? ranges.map((latestRange: $TSFixMe) => {
            if (score > latestRange.low && score < latestRange.high) {
              return latestRange.name;
            }
            return null;
          })
        : '';

    const report = {
      generatedDate,
      activeReport: formattedDate.concat(' ').concat('-').concat(' ').concat(translatedType),
      latest: {
        id,
        reportType,
        score: {
          date,
          formattedDate,
          value: score,
          percentage,
          reasons,
          ranges,
          ranking,
          loan: {
            risk: {
              ranges: loanRiskRanges,
            },
          },
        },
        summary: {
          security: {
            freeze: creditFileSecurityFreezeFlag,
          },
          accounts: {
            average: {
              age: averageAccountAgeMonths,
            },
            revolving: {
              available: {
                amount: revolvingAvailableAmt,
              },
              balance: {
                amount: revolvingBalanceAmt,
                currency: revolvingBalanceCurrency,
              },
              limit: {
                amount: revolvingCreditLimitAmt,
              },
              ratio: revolvingDtcRatio,
              payment: {
                amount: revolvingMonthlyPaymentAmount,
              },
              total: {
                count: revolvingTotal,
                balanced: revolvingTotalWithBalance,
              },
            },
          },
        },
        providers: providerViewFinal,
      },
    };

    return report;
  } else {
    logObj.message = `providerViews is not an array=>${typeof providerViews}`;
    utils.log(logObj);
    return null;
  }
}
