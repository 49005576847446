import MenuItem from './MenuItem';
import utils from '../../helpers/utils';
import {useLocation} from 'react-router-dom';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux';
import React, {useContext, useEffect, useState} from 'react';
import {IntlContext} from '../../containers/IntlProvider';
import {RootState} from '../../reducers/rootReducer';
import {useRestorationCases} from '../../customHooks/useRestoration';
import {useScamValidationCases} from '../../customHooks/useScamValidation';

function Menu() {
  const pathname = useLocation().pathname;
  const auth = useSelector((store: RootState) => store.auth);
  const accountId = _get(auth, 'user.primaryMember.accountId');
  const alertListInbox = useSelector((store: RootState) => _get(store, 'alertList.alertListInbox', []));
  const institutionsList = useSelector((store: RootState) => _get(store, 'transactions.institutionsList', []));
  const {data: restorationCases} = useRestorationCases(accountId);
  const {data: scamValidationCases} = useScamValidationCases();
  const dynamicMenuData = {
    alertListInbox,
    institutionsList,
    restorationCases,
    scamValidationCases,
  };
  const localizedStringBundle: StringMap = useContext(IntlContext);
  const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', {});
  const marketingDetails = _get(auth, 'user.primaryMember.plan.marketingDetails', {});
  const whiteLabelClient = _get(auth, 'whiteLabelClient', '');
  const [dspMenuList, setDspMenuList] = useState(
    utils.dspMenuList(productFeatures, marketingDetails, dynamicMenuData, whiteLabelClient)
  );

  useEffect(() => {
    if (
      !_isEmpty(alertListInbox) ||
      !_isEmpty(institutionsList) ||
      !_isEmpty(restorationCases) ||
      !_isEmpty(scamValidationCases)
    ) {
      setDspMenuList(utils.dspMenuList(productFeatures, marketingDetails, dynamicMenuData, whiteLabelClient));
    }
  }, [alertListInbox, institutionsList, restorationCases, scamValidationCases, whiteLabelClient]);

  const filteredList = _filter(dspMenuList, (tab) => {
    if (_includes(pathname, tab.value)) {
      return tab;
    }
  });
  const currentlySelected = filteredList.length === 0 ? undefined : (filteredList[0] as $TSFixMe).value;
  const menuItems = dspMenuList.map((listItem) => {
    return (
      <MenuItem
        href={listItem.value}
        key={listItem.name}
        iconName={listItem.iconName}
        label={localizedStringBundle[listItem.name] ? localizedStringBundle[listItem.name] : ''}
        selected={currentlySelected}
        option={listItem.option}
        whiteLabelClient={whiteLabelClient}
        supportComponent={listItem.supportComponent}
      />
    );
  });

  return (
    <div id="menuSlidesContainer" data-testid="menuSlidesContainer" className="flex flex-col">
      {menuItems}
    </div>
  );
}

export default Menu;
