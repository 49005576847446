import {
  FETCH_STATUS_FAIL,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  LOCKS_FETCH_ONBOARD_STATUS_FAIL,
  LOCKS_FETCH_ONBOARD_STATUS_REQUEST,
  LOCKS_FETCH_ONBOARD_STATUS_SUCCESS,
  LOCKS_UPDATE_ONBOARD_STATUS_FAIL,
  LOCKS_UPDATE_ONBOARD_STATUS_REQUEST,
  LOCKS_UPDATE_ONBOARD_STATUS_SUCCESS,
  PERFORM_OPERATION_FAIL,
  PERFORM_OPERATION_REQUEST,
  PERFORM_OPERATION_SUCCESS,
  RESET_LOCKS_STATE,
  TOGGLE_TAX_FREEZE_BANNER,
} from '../actions/locks';

const initialState = {
  fetchStatusRequest: false,
  fetchStatusFail: false,
  fetchStatusSuccess: false,
  fetchStatusFailed: false,
  performOperationRequest: false,
  performOperationFail: false,
  performOperationSuccess: false,
  performOperationFailed: false,
  locksData: {
    credit: {
      locksBackendRequest: false,
      locksBackendSuccess: false,
      locksBackendFailed: false,
      errorStatusCode: '',
      apiErrorCode: '',
    },
    phone: {
      locksBackendRequest: false,
      locksBackendSuccess: false,
      locksBackendFailed: false,
      errorStatusCode: '',
      apiErrorCode: '',
    },
    payday: {
      locksBackendRequest: false,
      locksBackendSuccess: false,
      locksBackendFailed: false,
      errorStatusCode: '',
      apiErrorCode: '',
    },
  },
  locksFetchOnboardStatusRequest: false,
  locksFetchOnboardStatusSuccess: false,
  locksFetchOnboardStatusFail: false,
  locksUpdateOnboardStatusRequest: false,
  locksUpdateOnboardStatusSuccess: false,
  locksUpdateOnboardStatusFail: false,
  locksOnboardData: {},
  hasTaxFreezeBanner: false,
};

const locks = (state = initialState, action: $TSFixMe) => {
  switch (action.type) {
    case FETCH_STATUS_REQUEST:
    case PERFORM_OPERATION_REQUEST:
      return {
        ...state,
        fetchStatusRequest: true,
        locksData: {
          ...state.locksData,
          [action.lockType]: {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state.locksData[action.lockType],
            ...{locksBackendRequest: true, errorStatusCode: ''},
          },
        },
      };
    case FETCH_STATUS_SUCCESS:
    case PERFORM_OPERATION_SUCCESS:
      return {
        ...state,
        fetchStatusRequest: false,
        fetchStatusSuccess: true,
        locksData: {
          ...state.locksData,
          [action.lockType]: {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state.locksData[action.lockType],
            ...{locksBackendSuccess: true, locksBackendRequest: false, status: action.status},
          },
        },
      };
    case FETCH_STATUS_FAIL:
    case PERFORM_OPERATION_FAIL:
      return {
        ...state,
        fetchStatusRequest: false,
        fetchStatusFail: true,
        fetchStatusFailed: true,
        fetchStatusSuccess: false,
        locksData: {
          ...state.locksData,
          [action.lockType]: {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state.locksData[action.lockType],
            ...{
              locksBackendRequest: false,
              locksBackendFailed: true,
              locksBackendSuccess: false,
              errorStatusCode: action.errorStatusCode,
              apiErrorCode: action.apiErrorCode,
            },
          },
        },
      };
    case LOCKS_FETCH_ONBOARD_STATUS_REQUEST:
      return Object.assign({}, state, {
        locksFetchOnboardStatusRequest: true,
        locksFetchOnboardStatusSuccess: false,
        locksFetchOnboardStatusFail: false,
      });
    case LOCKS_FETCH_ONBOARD_STATUS_SUCCESS: {
      const locksOnboardData = action.locksOnboardData;
      locksOnboardData.phone.showNewLabel = !action.locksOnboardData.phone.onboarded;
      locksOnboardData.payday.showNewLabel = !action.locksOnboardData.payday.onboarded;
      return Object.assign({}, state, {
        locksFetchOnboardStatusRequest: false,
        locksFetchOnboardStatusSuccess: true,
        locksFetchOnboardStatusFail: false,
        locksOnboardData: {
          ...locksOnboardData,
        },
      });
    }
    case LOCKS_FETCH_ONBOARD_STATUS_FAIL:
      return Object.assign({}, state, {
        locksFetchOnboardStatusRequest: false,
        locksFetchOnboardStatusSuccess: false,
        locksFetchOnboardStatusFail: true,
        locksOnboardData: {
          onboarded: true,
          credit: {onboarded: true},
          phone: {onboarded: true, showNewLabel: false},
          payday: {onboarded: true, showNewLabel: false},
        },
      });
    case LOCKS_UPDATE_ONBOARD_STATUS_REQUEST:
      return Object.assign({}, state, {
        locksUpdateOnboardStatusRequest: true,
        locksUpdateOnboardStatusSuccess: false,
        locksUpdateOnboardStatusFail: false,
      });
    case LOCKS_UPDATE_ONBOARD_STATUS_SUCCESS:
      return Object.assign({}, state, {
        locksUpdateOnboardStatusRequest: false,
        locksUpdateOnboardStatusSuccess: true,
        locksUpdateOnboardStatusFail: false,
        locksOnboardData: {
          onboarded: true,
          credit: {onboarded: true},
          phone: {onboarded: true, showNewLabel: getShowNewLabelFlag(state.locksOnboardData, 'phone')},
          payday: {onboarded: true, showNewLabel: getShowNewLabelFlag(state.locksOnboardData, 'payday')},
        },
      });
    case LOCKS_UPDATE_ONBOARD_STATUS_FAIL:
      return Object.assign({}, state, {
        locksUpdateOnboardStatusRequest: false,
        locksUpdateOnboardStatusSuccess: false,
        locksUpdateOnboardStatusFail: true,
        locksOnboardData: {
          onboarded: true,
          credit: {onboarded: true},
          phone: {onboarded: true, showNewLabel: false},
          payday: {onboarded: true, showNewLabel: false},
        },
      });
    case TOGGLE_TAX_FREEZE_BANNER:
      return Object.assign({}, state, {
        hasTaxFreezeBanner: action.toShow,
      });
    case RESET_LOCKS_STATE:
      return initialState;
    default:
      return state;
  }
};

export function getShowNewLabelFlag(locksOnboardData: $TSFixMe, lockType: $TSFixMe) {
  const keys = Object.keys(locksOnboardData);
  return keys.some((lock) => locksOnboardData[lock].onboarded === true) &&
    keys.some((lock) => locksOnboardData[lock].onboarded === false)
    ? locksOnboardData[lockType].showNewLabel || false
    : false;
}

export default locks;
