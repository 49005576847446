import classNames from 'classnames';
import {REACT_EVENT_KEY_ENTER} from '../../constants/eventConstants';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'danger' | 'safe';
  id?: string;
  size?: 'small' | 'medium' | 'large';
  handleClick?: $TSFixMeFunction;
  trackEvent?: $TSFixMeFunction;
  handleKeyDown?: $TSFixMeFunction;
  leftIcon?: string;
  rightIcon?: string;
};

/**
 * Renders the button component
 * @param {string} size - size of the button
 * @param {string} variant - the color palette of the button.
 * @param {string} id - optional unique component level id
 * @param {HTMLElement} children - button child component.
 * @param {boolean} disabled - flag to disable the button.
 * @param {Function} handleClick - The callback that handles the button click.
 * @param {Function} trackEvent - The callback that handles the track event of the button.
 * @returns {JSX.Element} Returns markup for a button
 */
function Button({
  size = 'medium',
  variant = 'primary',
  children,
  disabled = false,
  handleClick,
  trackEvent,
  id,
  handleKeyDown,
  leftIcon = '',
  rightIcon = '',
}: Props) {
  /** handles the track event and the button click
   */
  const handler = (e: $TSFixMe) => {
    if (trackEvent) {
      trackEvent();
    }
    if (handleClick) {
      handleClick(e);
    }
  };

  const keyboardEventHandler = (e: $TSFixMe) => {
    if (e.key === REACT_EVENT_KEY_ENTER && handleKeyDown) {
      handleKeyDown(e);
    }
    return;
  };

  const buttonClasses = {
    base: 'rounded-oval self-center font-extrabold text-center border-2 w-full min-w-20 sm:w-auto',
    disabled: 'bg-button-disabled border-button-disabled-stroke cursor-not-allowed text-secondary-disabled',
    size: {
      small: 'px-3 py-1 text-sm',
      medium: 'px-4 py-2 text-base',
      large: 'px-6 py-3 text-lg',
    },
    variant: {
      primary: 'btn-focus-primary button-text bg-brand-primary hover:bg-brand-primary-hover border-button-stroke',
      secondary: 'btn-focus-secondary text-primary bg-surface hover:bg-primary hover:text-surface border-primary',
      danger: 'btn-focus-danger border-none text-surface bg-danger',
      safe: 'btn-focus-safe border-none text-surface bg-safe',
    },
  };
  const buttonGroupClass = classNames(buttonClasses.size[size], buttonClasses.base, {
    [`${buttonClasses.disabled}`]: disabled,
    [`${buttonClasses.variant[variant]}`]: !disabled,
  });

  const baseIconClasses = 'text-base font-bold border-b-2 border-solid material-icons group-hover:text-primary';
  const leftIconClassString = `${baseIconClasses} mr-2`;
  const rightIconClassString = `${baseIconClasses} ml-2`;

  return (
    <button
      disabled={disabled}
      className={buttonGroupClass}
      onClick={handler}
      {...{id}}
      data-testid={id}
      onKeyDown={keyboardEventHandler}
    >
      {leftIcon && <i className={leftIconClassString}>{leftIcon}</i>}
      {children}
      {rightIcon && <i className={rightIconClassString}>{rightIcon}</i>}
    </button>
  );
}
export default Button;
