import utils from '../helpers/utils';
import {Redirect, Route} from 'react-router-dom';

type RestrictedRouteProps = {
  component?: JSX.Element | null;
  productFeatures: ProductFeatures;
  marketingDetails?: $TSFixMe;
  path?: string;
  location?: $TSFixMe;
  whiteLabelClient: string; // Identifies white label client associated with the current user. Used to select partner-specific image.
};

export default function RestrictedRoute({
  component: Component,
  path,
  productFeatures,
  marketingDetails,
  whiteLabelClient,
  ...rest
}: RestrictedRouteProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const hasAccess = utils.hasAccessToRoute(path, productFeatures, marketingDetails, whiteLabelClient, rest);

  // redirect to /error in case user does not have access to a route
  let pathname = '/error';

  // redirect to /alerts in case user does not have access to /dashboard
  if (path === '/dashboard' && !hasAccess) {
    pathname = '/alerts';
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
        hasAccess ? <Component {...props} /> : <Redirect to={{pathname: pathname, state: {from: props.location}}} />
      }
    />
  );
}
