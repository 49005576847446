import {SET_FCRA_REQUEST, SET_FCRA_SUCCESS, SET_FCRA_FAILURE, SET_FCRA_SUCCESS_MODAL_STATE} from '../actions/fcra';

const initialState = {
  isFcraModalShown: false,
  isFcraAccepted: false,
  fcraAcceptErr: {},
  isFcraSuccessModalShown: false,
};

const fcra = (state = initialState, action: $TSFixMe) => {
  switch (action.type) {
    case SET_FCRA_REQUEST:
      return Object.assign({}, state, {});
    case SET_FCRA_SUCCESS:
      return Object.assign({}, state, {isFcraAccepted: action.accepted, isFcraModalShown: true});
    case SET_FCRA_FAILURE:
      return Object.assign({}, state, {fcraAcceptErr: action.error, isFcraModalShown: true});
    case SET_FCRA_SUCCESS_MODAL_STATE:
      return Object.assign({}, state, {isFcraSuccessModalShown: true});
    default:
      return state;
  }
};
export default fcra;
