import _get from 'lodash/get';
import {apiRateLimitHit} from '../actions/auth';
import {getHeaderAccessToken} from './fetchRest';
import {getTrackingValues} from './tracking';

// @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export function checkStatus(response) {
  if (!response.ok) {
    //(response.status < 200 || response.status > 300)
    const error = new Error(response);
    (error as $TSFixMe).response = response;
    throw error;
  }
  return response;
}

/**
 * A utility to call a restful service.
 *
 * @param {string} url - The restful service end point.
 * @param {Object} config - The config object expected by fetch.
 * @param {Object} requestAction - Action to dispatch immediately, specifying type of request initiated
 * @param {function} onRequestSuccess - The callback function to create request success action.
 *                 The function expects response json payload as its argument.
 * @param {function} onRequestFailure - The callback function to create request failure action.
 *                 The function expects error as its argument.
 * @param {Object} options - Additional options which may be passed through
 *
 */
export function api(
  url: string,
  config: FetchConfig,
  requestAction: $TSFixMe,
  onRequestSuccess: $TSFixMeFunction,
  onRequestFailure: $TSFixMeFunction,
  options: $TSFixMe = {}
): $TSFixMe {
  return (dispatch: $TSFixMe) => {
    dispatch(requestAction);

    if (!config.headers) {
      config.headers = {Accept: 'application/json', 'Content-Type': 'application/json'};
    }
    config.headers['x-nlok-trace-id'] = getTrackingValues().NLokTraceId; // pass along trace id from NGP
    // For member-api requests, provide a client_id value for logging purposes, distinct from MP WEB requests.
    const isMemberApiRequest = url.indexOf(window.REACT_APP_MEMBER_API_HOST) !== -1;
    if (isMemberApiRequest) {
      const defaultClientId = window.isDwm ? 'dwm' : 'dsp';
      // window.clientId is set when partner authenticates using partner token, or NSL IDP.
      // See App.jsx calls to loadSpaData with a whiteLabelClient parameter.
      config.headers.client_id = window.clientId || defaultClientId;
    }
    // The SPA must use a token in the header when it is loaded in a 3rd party domain which prevents session cookies from being sent to Member API
    if (getHeaderAccessToken()) {
      config.headers.access_token = getHeaderAccessToken();
    }

    return fetch(url, config)
      .then(checkStatus)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return dispatch(onRequestSuccess(json, options));
      })
      .catch((error) => {
        if (error.response === undefined) {
          return dispatch(onRequestFailure(error, options));
        }
        return error.response.json().then(
          // @ts-expect-error TS(7006) FIXME: Parameter 'json' implicitly has an 'any' type.
          (json) => {
            const response = json;

            if (response === undefined || Object.keys(response).length === 0) {
              return dispatch(onRequestFailure(error, options));
            }

            const errorReturn = {
              status: _get(error, 'response.status', {}),
              statusText: _get(error, 'response.statusText', {}),
              message: _get(response, 'message', {}),
              code: _get(response, 'errorCode', {}),
              statusCode: _get(response, 'statusCode', 0),
              memSiteAccountId: _get(response, 'memSiteAccountId', 0),
              efxStatusCode: _get(response, 'efxStatusCode', ''),
              errorStatusCode: _get(response, `${(options as $TSFixMe).lockType || ''}LocksStatusCode`, ''),
            };
            if (errorReturn.status === 429) {
              return dispatch(apiRateLimitHit(errorReturn));
            }

            return dispatch(onRequestFailure(errorReturn, options));
          },
          () => {
            // unable to parse error response
            const errorReturn = {
              status: _get(error, 'response.status', {}),
              statusText: _get(error, 'response.statusText', {}),
            };
            return dispatch(onRequestFailure(errorReturn, options));
          }
        );
      });
  };
}
