/**
 * Related to actions/logout.js
 */

import {LOGOUT_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS} from '../actions/logout';

const initialState = {
  logout: null,
};

function initializeState() {
  const logoutProfile = {
    logout: null,
  };
  return Object.assign({}, initialState, logoutProfile);
}

export default function auth(state = initializeState(), action = {}) {
  switch ((action as $TSFixMe).type) {
    case LOGOUT_REQUEST: {
      return Object.assign({}, state, {logout: null});
    }
    case LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        logout: true,
      });
    }
    case LOGOUT_FAILURE: {
      return Object.assign({}, state, {
        logout: false,
      });
    }
    default: {
      return state;
    }
  }
}
