import {
  EMAIL_OTP_GENERATE_FAILURE,
  EMAIL_OTP_GENERATE_REQUEST,
  EMAIL_OTP_GENERATE_SUCCESS,
  EMAIL_OTP_VERIFY_FAILURE,
  EMAIL_OTP_VERIFY_REQUEST,
  EMAIL_OTP_VERIFY_SUCCESS,
  RESET_EMAIL_OTP_STATE,
} from '../actions/otp';

export const initialState = {
  generateEmailOtpResponse: null,
  generateEmailOtpStatus: '',
  verifyEmailOtpResponse: null,
  verifyEmailOtpStatus: '',
};

type OtpResponse = {
  status: number;
  statusText: string;
  message: {success: boolean} | $TSFixMe;
  code: number;
  statusCode: number;
  memSiteAccountId: number;
  efxStatusCode: string;
  errorStatusCode: string;
};

export type OtpState = {
  generateEmailOtpStatus: null | string;
  generateEmailOtpResponse: null | '' | OtpResponse;
  verifyEmailOtpStatus: null | string;
  verifyEmailOtpResponse: null | '' | OtpResponse;
};

export default function otp(state = initialState, action = null): OtpState {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case EMAIL_OTP_GENERATE_REQUEST:
      return Object.assign({}, state, {generateEmailOtpStatus: EMAIL_OTP_GENERATE_REQUEST});
    case EMAIL_OTP_GENERATE_SUCCESS:
      return Object.assign({}, state, {
        generateEmailOtpStatus: EMAIL_OTP_GENERATE_SUCCESS,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        generateEmailOtpResponse: action.payload,
      });
    case EMAIL_OTP_GENERATE_FAILURE:
      return Object.assign({}, state, {
        generateEmailOtpStatus: EMAIL_OTP_GENERATE_FAILURE,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        generateEmailOtpResponse: action.error,
      });
    case EMAIL_OTP_VERIFY_REQUEST:
      return Object.assign({}, state, {verifyEmailOtpStatus: EMAIL_OTP_VERIFY_REQUEST});
    case EMAIL_OTP_VERIFY_SUCCESS:
      return Object.assign({}, state, {
        verifyEmailOtpStatus: EMAIL_OTP_VERIFY_SUCCESS,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        verifyEmailOtpResponse: action.payload,
      });
    case EMAIL_OTP_VERIFY_FAILURE:
      return Object.assign({}, state, {
        verifyEmailOtpStatus: EMAIL_OTP_VERIFY_FAILURE,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        verifyEmailOtpResponse: action.error,
      });
    case RESET_EMAIL_OTP_STATE:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
