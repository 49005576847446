import {
  SMM_ACCOUNTS_FAILURE,
  SMM_ACCOUNTS_REQUEST,
  SMM_ACCOUNTS_SUCCESS,
  SMM_ALERT_UPDATE_FAILURE,
  SMM_ALERT_UPDATE_REQUEST,
  SMM_ALERT_UPDATE_RESET,
  SMM_ALERT_UPDATE_SUCCESS,
  SMM_DELETE_ACCOUNT_FAILURE,
  SMM_DELETE_ACCOUNT_REQUEST,
  SMM_DELETE_ACCOUNT_RESET,
  SMM_DELETE_ACCOUNT_SUCCESS,
  SMM_ONBOARD_STATUS_FAILURE,
  SMM_ONBOARD_STATUS_REQUEST,
  SMM_ONBOARD_STATUS_SUCCESS,
  SMM_RECONNECT_REDIRECT_URL_FAILURE,
  SMM_RECONNECT_REDIRECT_URL_REQUEST,
  SMM_RECONNECT_REDIRECT_URL_SUCCESS,
  SMM_RECONNECT_REDIRECT_URL_RESET,
  SMM_REDIRECT_URL_FAILURE,
  SMM_REDIRECT_URL_REQUEST,
  SMM_REDIRECT_URL_RESET,
  SMM_REDIRECT_URL_SUCCESS,
  SMM_UPDATE_ONBOARD_STATUS_FAILURE,
  SMM_UPDATE_ONBOARD_STATUS_REQUEST,
  SMM_UPDATE_ONBOARD_STATUS_SUCCESS,
} from '../actions/socialMediaMonitoring';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
  accounts: {},
  redirectUrl: null,
  reconnectRedirectUrl: null,
  onboard: null,
  deleteAccount: false,
  status: {
    accounts: null,
    redirectUrl: null,
    onboard: null,
    reconnectRedirectUrl: null,
    deleteAccount: null,
    alertUpdate: null,
  },
};

export default function socialMediaMonitoring(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case SMM_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {accounts: SMM_ACCOUNTS_REQUEST}),
      });
    }
    case SMM_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        accounts: action.accounts,
        status: Object.assign({}, state.status, {accounts: SMM_ACCOUNTS_SUCCESS}),
      });
    case SMM_ACCOUNTS_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {accounts: SMM_ACCOUNTS_FAILURE}),
      });
    case SMM_REDIRECT_URL_REQUEST:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {redirectUrl: SMM_REDIRECT_URL_REQUEST}),
      });
    case SMM_REDIRECT_URL_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        redirectUrl: action.redirectUrl,
        status: Object.assign({}, state.status, {redirectUrl: SMM_REDIRECT_URL_SUCCESS}),
      });
    case SMM_REDIRECT_URL_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {redirectUrl: SMM_REDIRECT_URL_FAILURE}),
      });
    case SMM_REDIRECT_URL_RESET:
      return initialState;
    case SMM_RECONNECT_REDIRECT_URL_REQUEST:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {reconnectRedirectUrl: SMM_RECONNECT_REDIRECT_URL_REQUEST}),
      });
    case SMM_RECONNECT_REDIRECT_URL_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        reconnectRedirectUrl: action.redirectUrl,
        status: Object.assign({}, state.status, {reconnectRedirectUrl: SMM_RECONNECT_REDIRECT_URL_SUCCESS}),
      });
    case SMM_RECONNECT_REDIRECT_URL_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {reconnectRedirectUrl: SMM_RECONNECT_REDIRECT_URL_FAILURE}),
      });
    case SMM_RECONNECT_REDIRECT_URL_RESET:
      return initialState;
    case SMM_ONBOARD_STATUS_REQUEST:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {onboard: SMM_ONBOARD_STATUS_REQUEST}),
      });
    case SMM_ONBOARD_STATUS_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        onboard: action.onboard,
        status: Object.assign({}, state.status, {onboard: SMM_ONBOARD_STATUS_SUCCESS}),
      });
    case SMM_ONBOARD_STATUS_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {onboard: SMM_ONBOARD_STATUS_FAILURE}),
      });
    case SMM_UPDATE_ONBOARD_STATUS_REQUEST:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {onboard: SMM_UPDATE_ONBOARD_STATUS_REQUEST}),
      });
    case SMM_UPDATE_ONBOARD_STATUS_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        onboard: action.onboard,
        status: Object.assign({}, state.status, {onboard: SMM_UPDATE_ONBOARD_STATUS_SUCCESS}),
      });
    case SMM_UPDATE_ONBOARD_STATUS_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {onboard: SMM_UPDATE_ONBOARD_STATUS_FAILURE}),
      });
    case SMM_DELETE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {deleteAccount: SMM_DELETE_ACCOUNT_REQUEST}),
      });
    case SMM_DELETE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        accounts: updateConnectedAccount(action.onlineAccountId, action.userAccountId, state),
        status: Object.assign({}, state.status, {deleteAccount: SMM_DELETE_ACCOUNT_SUCCESS}),
      });
    case SMM_DELETE_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {deleteAccount: SMM_DELETE_ACCOUNT_FAILURE}),
      });
    case SMM_DELETE_ACCOUNT_RESET:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {deleteAccount: null}),
      });
    case SMM_ALERT_UPDATE_REQUEST: {
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {alertUpdate: SMM_ALERT_UPDATE_REQUEST}),
      });
    }
    case SMM_ALERT_UPDATE_FAILURE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {alertUpdate: SMM_ALERT_UPDATE_FAILURE}),
      });
    case SMM_ALERT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {alertUpdate: SMM_ALERT_UPDATE_SUCCESS}),
      });
    case SMM_ALERT_UPDATE_RESET:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {alertUpdate: null}),
      });
    default:
      return state;
  }
}

// Update the remove account
export function updateConnectedAccount(onlineAccountId: $TSFixMe, userAccountId: $TSFixMe, state: $TSFixMe) {
  const accounts = _cloneDeep(state.accounts);

  return accounts.primaryMember.accountId === userAccountId
    ? {
        primaryMember: updatePrimaryMember(onlineAccountId, accounts.primaryMember),
        juniorMembers: accounts.juniorMembers,
      }
    : {
        primaryMember: accounts.primaryMember,
        juniorMembers: updateJuniorMembers(userAccountId, onlineAccountId, accounts.juniorMembers),
      };
}

// Clear the remove account status,ID and username
export function updatePrimaryMember(onlineAccountId: $TSFixMe, updateAccount: $TSFixMe) {
  const primaryMember = updateAccount.socialMediaAccounts.map((member: $TSFixMe) => {
    if (member.id === onlineAccountId) {
      return {
        ...member,
        id: '',
        username: '',
        status: 'NOT_ADDED',
        profileImage: '',
      };
    } else {
      return member;
    }
  });
  return {
    accountId: updateAccount.accountId,
    socialMediaAccounts: primaryMember,
  };
}

// Clear the remove account status,ID and username
export function updateJuniorMembers(userAccountId: $TSFixMe, onlineAccountId: $TSFixMe, updateAccount: $TSFixMe) {
  return updateAccount.map((juniorMember: $TSFixMe) => {
    let updatedJuniorMembers;
    if (juniorMember.accountId === userAccountId) {
      updatedJuniorMembers = juniorMember.socialMediaAccounts.map((member: $TSFixMe) => {
        if (member.id === onlineAccountId) {
          return {
            ...member,
            id: '',
            username: '',
            status: 'NOT_ADDED',
            profileImage: '',
          };
        }
        return member;
      });
    } else {
      return juniorMember;
    }
    return {
      accountId: juniorMember.accountId,
      socialMediaAccounts: updatedJuniorMembers,
    };
  });
}
