import ReactDOM from 'react-dom';
import App from './containers/App';
import {HashRouter, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import store from './configureStore';
import './css/index.css';

// wiki - https://tanstack.com/query/v4/docs/react/guides/important-defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false, // Disable query refetch on component mount
      refetchOnWindowFocus: false, // Disable query refetch when window regains focus
      retry: false, // Disable retry attempts for failed queries
      staleTime: Infinity, // Set query results to never be considered stale
      retryOnMount: false, // Disable retrying failed queries on component mount
    },
    mutations: {
      retryOnFailure: false, // Disable retrying mutations on failure
    },
  },
});

// checks every 10ms to see if config is loaded.
// loads reactdom after config is loaded.
const interval = setInterval(function() {
  if (window.REACT_APP_MEMBER_API_HOST) {
    ReactDOM.render(
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <Route component={App} />
          </HashRouter>
        </QueryClientProvider>
      </Provider>,
      document.getElementById('root')
    );
    clearInterval(interval);
  }
}, 10);
