import {useEffect, useState, useRef, useContext} from 'react';
import {Route, Switch} from 'react-router-dom';
import Menu from '../menu/Menu';
import MenuMobile from '../menu/MenuMobile';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import utils from '../../helpers/utils';
import Image from '../common/VectorImage';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {IntlContext} from '../../containers/IntlProvider';
import {MEDIUM_SCREEN_MAX_WIDTH} from '../../constants/screenConstants';
import {RootState} from '../../reducers/rootReducer';

type HeaderDspProps = {
  productFeatures?: $TSFixMe;
  isMobileApp?: boolean;
  marketingDetails?: $TSFixMe;
};

function HeaderDsp({productFeatures, isMobileApp, marketingDetails}: HeaderDspProps) {
  const fcra = useSelector((store: RootState) => store.fcra);
  const auth = useSelector((store: RootState) => store.auth);
  const localizedStringBundle: StringMap = useContext(IntlContext);
  const [mobileNav, setMobileNav] = useState(false);
  const whiteLabelClient = _get(auth, 'whiteLabelClient', '');
  const {isFcraModalShown = false, fcraAcceptErr = {}} = fcra;
  const showFcraColp = _get(auth, 'user.primaryMember.plan.showFcraColp', false);
  const initialRef: any = null;
  const menuRef = useRef(initialRef);

  const navContainerClasses = classNames(
    'lg:flex-initial',
    mobileNav ? 'w-full' : 'lg:px-4 bg-nav min-w-fit border-r-1 border-accent',
    window.REACT_APP_USE_FEATURE_LAYOUT ? 'hidden' : ''
  );

  const headerTitle = utils.getHeaderTitleString(
    productFeatures,
    marketingDetails,
    localizedStringBundle,
    whiteLabelClient
  );

  /**
   * Adds styles to menu element based on mobileNav and REACT_APP_COLLAPSE_LEFT_NAV.
   * @param {boolean} mobileNav - true if mobile nav layout, false otherwise
   */
  const setPositionStyling = (mobileNav: boolean) => {
    if (!menuRef.current) return;
    const navContainer = document.getElementById('dsp-banner');
    if (navContainer) {
      if (mobileNav) {
        menuRef.current.style.setProperty('z-index', '10', 'important');
      } else {
        menuRef.current.style.removeProperty('z-index');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  function resize() {
    utils.resizeIframe();
    setPositionStyling(window.innerWidth < MEDIUM_SCREEN_MAX_WIDTH);
    // use mobile nav if screen width is below MEDIUM_SCREEN_MAX_WIDTH, or partner set the override to always use mobile nav
    return setMobileNav(window.innerWidth < MEDIUM_SCREEN_MAX_WIDTH || window.REACT_APP_COLLAPSE_LEFT_NAV);
  }

  function getMenu() {
    // don't show any menu if using feature layout
    if (window.REACT_APP_USE_FEATURE_LAYOUT) {
      return;
    }
    return mobileNav ? <MenuMobile /> : <Menu />;
  }

  /**
   * Returns title and logo section of header.
   * @param {string} titleStr - Title to display in the top left corner
   * @returns {JSX.Element}
   */
  function renderTitleLogo(titleStr: string): JSX.Element {
    // Partner images will be hosted in nortonLifelock CDN repo
    const partnerCdnPath = utils.getCdnImagesPath('/logo', whiteLabelClient);
    const nortonLifelockCdnPath = utils.getCdnImagesPath('/dsp-northstar/LifelockLogo', whiteLabelClient);

    return (
      <div className="ml-3 hidden w-full md:flex">
        <div className="mr-2 h-8 w-8 min-w-8">
          <Image alt="logo" url={whiteLabelClient ? partnerCdnPath : nortonLifelockCdnPath} dataTestId="logo" />
        </div>
        <h2 className="mb-2 mt-1 break-all text-xl font-bold tracking-normal text-primary">{titleStr}</h2>
      </div>
    );
  }

  /**
   * Returns "active monitoring" section of header.
   * @returns {JSX.Element}
   */
  function renderActiveMonitoring(): JSX.Element {
    return (
      <div className="my-2 flex self-center">
        <div className="mx-2 my-auto">
          <Image
            url={utils.getCdnImagesPath('/dsp-northstar/GreenCheck', whiteLabelClient)}
            classes={'w-4 h-4'}
            alt={localizedStringBundle.DARK_WEB_ACTIVE_MONITORING}
          />
        </div>
        <span className="ml-1 mt-0.5 align-middle text-xs font-extrabold text-safe">
          {localizedStringBundle.DARK_WEB_ACTIVE_MONITORING}
        </span>
      </div>
    );
  }

  if (isMobileApp) {
    return null;
  }

  if ((showFcraColp && !isFcraModalShown) || !_isEmpty(fcraAcceptErr)) {
    return null;
  }

  // hide title if using collapsed left nav or feature layout
  const titleWrapperClasses =
    window.REACT_APP_COLLAPSE_LEFT_NAV || window.REACT_APP_USE_FEATURE_LAYOUT
      ? 'hidden'
      : 'hidden justify-center max-w-fit mt-10 mb-4 lg:flex lg:flex-col';
  return (
    <Switch>
      <Route path="/alertPreferences" exact={true} render={() => ''} />
      <Route
        path="/"
        render={() => (
          <div id="dsp-banner" className={navContainerClasses} ref={menuRef}>
            <div className={titleWrapperClasses}>
              {renderTitleLogo(headerTitle)}
              {renderActiveMonitoring()}
            </div>
            {getMenu()}
          </div>
        )}
      />
    </Switch>
  );
}

export default HeaderDsp;
