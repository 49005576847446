import {useMutation, useQuery} from '@tanstack/react-query';
import {fetchRest} from '../helpers/fetchRest';
import utils from '../helpers/utils';

/**
 * Fetch user restoration cases through member-api.
 * @param {string} accountId sending to receive restoration cases.
 * @return {Object} restoration cases data, various response status(s),
 * and customize object for handling restoration cases and case details screen(s).
 */

export const useRestorationCases = (accountId: string) => {
  const url = utils.getRestorationCasesPath();
  return useQuery(['restoration'], () =>
    fetchRest(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        account_id: accountId,
      },
    })
  );
};

/**
 * Upload user restoration cases document.
 * @param {string} url receive relevant url where the document will send.
 * @param {string} mutateKey receive dynamic generated mutation key that will use to identify the document results.
 * @return {Object} uploadDocument object which has last document details.
 */
export const useUploadDocument = (url: string, mutateKey: string) => {
  return useMutation((options: FetchConfig) => fetchRest(url, options), {
    mutationKey: [mutateKey],
  });
};
