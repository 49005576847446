import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const API_RATE_LIMIT_HIT = 'API_RATE_LIMIT_HIT';
export const IS_MOBILE_APP = 'IS_MOBILE_APP';
export const WHITE_LABEL_CLIENT = 'WHITE_LABEL_CLIENT';
export const UPDATE_USER = 'UPDATE_USER';

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function loginSuccess(result: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    // Todo - have spa as dsp when window.mode config and dwm bundling are removed.
    window.parent && window.parent.postMessage({type: 'RECORDSPALOAD', spa: window.MODE}, window.REACT_APP_PARENT_HOST);
    return dispatch({
      type: LOGIN_SUCCESS,
      user: result,
    });
  };
}

function loginFailure(error: $TSFixMe) {
  // Todo - have spa as dsp when window.mode config and dwm bundling are removed.
  window.parent && window.parent.postMessage({type: 'RECORDSPALOAD', spa: window.MODE}, window.REACT_APP_PARENT_HOST);
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

export function loginRefresh() {
  const url = utils.getMembersPath();

  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, loginRequest(), loginSuccess, loginFailure);
}

export function refreshTokenRequest() {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
}

export function refreshTokenSuccess(result: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    return dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      result,
    });
  };
}

function refreshTokenFailure(error: $TSFixMe) {
  return {
    type: REFRESH_TOKEN_FAILURE,
    error,
  };
}

export function refreshToken() {
  const url = utils.getRefreshTokenPath();

  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({spaBundle: window.MODE}),
  };

  return api(url, config, refreshTokenRequest(), refreshTokenSuccess, refreshTokenFailure);
}

export function isMobileApp(bool: $TSFixMe) {
  return {
    type: IS_MOBILE_APP,
    bool,
  };
}

export function apiRateLimitHit(error: $TSFixMe) {
  return {
    type: API_RATE_LIMIT_HIT,
    error,
  };
}

/**
 * Returns object identifying the white label client associated with the current user.
 * The auth reducer should process this message and update the Redux store accordingly.
 * React components should check the white label client value in the Redux store, and adjust what they render if necessary.
 * One example would be displaying alternate logos and messages that are specific to the white label client.
 * @param {string} whiteLabelClient - Identifies the white label client associated with the current user.
 * @returns {{type:string, client:string}}
 */
export function whiteLabelClient(whiteLabelClient: string) {
  return {
    type: WHITE_LABEL_CLIENT,
    whiteLabelClient,
  };
}
