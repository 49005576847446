/**
 * Methods for managing non-personal tracking information including
 * NGP user hash, NGP session hash, x-nlok-trace-id, etc.
 *
 * The tracking information should be sent in calls to Member API to
 * assist in grouping various calls by user hash, session hash,
 * or page load.
 */

/**
 * Shared tracking object.  Should be initialized by the SPA when it receives
 * data from NGP.  Values should be read when setting up calls to
 * Member API that require passing along tracking values in request headers.
 */
const tracker: Tracker = {
  ngpUserHash: '',
  ngpSessionHash: '',
  NLokTraceId: '',
  tenantId: '',
  tenantName: '',
  pCode: '',
};

/**
 * Sets tracking values in tracker object.
 */
function initTrackingValues(
  ngpUserHash: string,
  ngpSessionHash: string,
  NLokTraceId: string,
  tenantId: string,
  tenantName: string,
  pCode: string
) {
  tracker.ngpUserHash = ngpUserHash;
  tracker.ngpSessionHash = ngpSessionHash;
  tracker.NLokTraceId = NLokTraceId;
  tracker.tenantId = tenantId;
  tracker.tenantName = tenantName;
  tracker.pCode = pCode;
}

/**
 * Returns a clone of the tracker object.
 * @return {Tracker}
 */
function getTrackingValues(): Tracker {
  return Object.assign({}, tracker); // prevent caller from accidentally modifying tracker values
}

export {getTrackingValues, initTrackingValues};
