import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const FETCH_STATUS_REQUEST = 'FETCH_STATUS_REQUEST';
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAIL = 'FETCH_STATUS_FAIL';
export const PERFORM_OPERATION_REQUEST = 'PERFORM_OPERATION_REQUEST';
export const PERFORM_OPERATION_SUCCESS = 'PERFORM_OPERATION_SUCCESS';
export const PERFORM_OPERATION_FAIL = 'PERFORM_OPERATION_FAIL';
export const LOCKS_FETCH_ONBOARD_STATUS_REQUEST = 'LOCKS_FETCH_ONBOARD_STATUS_REQUEST';
export const LOCKS_FETCH_ONBOARD_STATUS_SUCCESS = 'LOCKS_FETCH_ONBOARD_STATUS_SUCCESS';
export const LOCKS_FETCH_ONBOARD_STATUS_FAIL = 'LOCKS_FETCH_ONBOARD_STATUS_FAIL';
export const LOCKS_UPDATE_ONBOARD_STATUS_REQUEST = 'LOCKS_UPDATE_ONBOARD_STATUS_REQUEST';
export const LOCKS_UPDATE_ONBOARD_STATUS_SUCCESS = 'LOCKS_UPDATE_ONBOARD_STATUS_SUCCESS';
export const LOCKS_UPDATE_ONBOARD_STATUS_FAIL = 'LOCKS_UPDATE_ONBOARD_STATUS_FAIL';
export const RESET_LOCKS_STATE = 'RESET_LOCKS_STATE';
export const TOGGLE_TAX_FREEZE_BANNER = 'TOGGLE_TAX_FREEZE_BANNER';

export const resetLockState = (lockType: $TSFixMe) => ({
  type: RESET_LOCKS_STATE,
  lockType,
});

export const fetchStatusRequest = (lockType: $TSFixMe) => ({
  type: FETCH_STATUS_REQUEST,
  lockType: lockType,
});

// @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export const fetchStatusSuccess = (response, options) => ({
  type: FETCH_STATUS_SUCCESS,
  status: response[options.lockType].status,
  lockType: options.lockType,
});

// @ts-expect-error TS(7006) FIXME: Parameter 'errorReturn' implicitly has an 'any' ty... Remove this comment to see the full error message
export const fetchStatusFail = (errorReturn, options) => ({
  type: FETCH_STATUS_FAIL,
  apiErrorCode: errorReturn.code || '',
  errorStatusCode: errorReturn.errorStatusCode || '',
  lockType: options.lockType,
});

export const fetchStatus = (lockType = 'credit') => {
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };
  return api(
    window.REACT_APP_MEMBER_API_HOST + '/v1/locks/' + lockType,
    config,
    fetchStatusRequest(lockType),
    fetchStatusSuccess,
    fetchStatusFail,
    {lockType: lockType}
  );
};

export const performOperationRequest = (lockType: $TSFixMe) => ({
  type: PERFORM_OPERATION_REQUEST,
  lockType: lockType,
});

// @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export const performOperationSuccess = (response, options) => ({
  type: PERFORM_OPERATION_SUCCESS,
  status: response[options.lockType].status,
  lockType: options.lockType,
});

// @ts-expect-error TS(7006) FIXME: Parameter 'errorReturn' implicitly has an 'any' ty... Remove this comment to see the full error message
export const performOperationFail = (errorReturn, options) => ({
  type: PERFORM_OPERATION_FAIL,
  apiErrorCode: errorReturn.code || '',
  errorStatusCode: errorReturn.errorStatusCode || '',
  lockType: options.lockType,
});

export const performOperation = (lockType: $TSFixMe, operation: $TSFixMe, phoneNumberInfo: $TSFixMe) => {
  const phoneNumber = phoneNumberInfo ? phoneNumberInfo.phoneNumber : undefined;
  const countryCallingCode = phoneNumberInfo ? phoneNumberInfo.countryCallingCode : undefined;
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    body: JSON.stringify({operation, phoneNumber, countryCallingCode}),
    credentials: 'include',
  };
  return api(
    window.REACT_APP_MEMBER_API_HOST + `/v1/locks/${lockType}/${operation.toLowerCase()}`,
    config,
    performOperationRequest(lockType),
    performOperationSuccess,
    performOperationFail,
    {lockType: lockType}
  );
};

export const locksGetOnboardStatusRequest = () => ({
  type: LOCKS_FETCH_ONBOARD_STATUS_REQUEST,
});

export const locksGetOnboardStatusSuccess = (response: $TSFixMe) => ({
  type: LOCKS_FETCH_ONBOARD_STATUS_SUCCESS,
  locksOnboardData: response,
});

export const locksGetOnboardStatusFail = () => ({
  type: LOCKS_FETCH_ONBOARD_STATUS_FAIL,
});

export const fetchOnboardStatus = () => {
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };
  return api(
    utils.getLocksOnboardPath(),
    config,
    locksGetOnboardStatusRequest(),
    locksGetOnboardStatusSuccess,
    locksGetOnboardStatusFail
  );
};

export const locksUpdateOnboardStatusRequest = () => ({
  type: LOCKS_UPDATE_ONBOARD_STATUS_REQUEST,
});

export const locksUpdateOnboardStatusSuccess = () => ({
  type: LOCKS_UPDATE_ONBOARD_STATUS_SUCCESS,
});

export const locksUpdateOnboardStatusFail = () => ({
  type: LOCKS_UPDATE_ONBOARD_STATUS_FAIL,
});

export const updateOnboardStatus = () => {
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };
  return api(
    utils.getLocksOnboardPath(),
    config,
    locksUpdateOnboardStatusRequest(),
    locksUpdateOnboardStatusSuccess,
    locksUpdateOnboardStatusFail
  );
};

export function toggleTaxFreezeBanner(toShow: $TSFixMe) {
  return {
    type: TOGGLE_TAX_FREEZE_BANNER,
    toShow,
  };
}
