import {useMemo} from 'react';
import {v4 as uuidv4} from 'uuid';

type Props = {
  alt?: string;
  url?: string;
  classes?: string;
  height?: number;
  width?: number;
  imageWrapperClasses?: string;
  dataTestId?: string;
  useCache?: boolean; // If set to true it makes use of the useMemo method which reuses existing image instead of reloading
};

const Image = ({
  alt,
  url,
  classes,
  imageWrapperClasses,
  height,
  width,
  dataTestId,
  useCache = true,
}: Props): JSX.Element => {
  const key = uuidv4();

  const imgComponent = (
    <div key={key} className={imageWrapperClasses}>
      <picture>
        <source type="image/svg+xml" srcSet={`${url}.svg`} />
        <img
          height={height}
          width={width}
          alt={alt}
          className={classes}
          src={`${url}@1x.png`}
          srcSet={`${url}@1x.png 1x, ${url}@2x.png 2x, ${url}@3x.png 3x`}
          data-testid={dataTestId}
        />
      </picture>
    </div>
  );
  const memoizedComponent = useMemo(() => <>{imgComponent}</>, []);
  if (useCache) {
    return memoizedComponent;
  }
  return <>{imgComponent}</>;
};

export default Image;
