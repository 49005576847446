/* global EfxConsumerApiConstants EfxConsumerApiClient */
/*eslint-disable no-unused-vars*/

import _get from 'lodash/get';
import _has from 'lodash/has';
import {buildCreditScoreData} from '../helpers/buildCreditScoreData';
import {buildCreditReportData} from '../helpers/buildCreditReportData';
import utils from '../helpers/utils';
import {api} from '../helpers/api';
import {
  getAccountGuidAndMockEndpointStatus,
  getMockCreditScoreLatest,
  getMockCreditScoreHistory,
  getMockCreditReportLatest,
  getMockCreditReportLatestByType,
  getMockCreditReportById,
  getMockCreditReportHistory,
} from './creditMock';
import {Dispatch} from 'redux';

//Common
export const SET_PROVIDER_BUREAU_DATA = 'SET_PROVIDER_BUREAU_DATA';

//Equifax
export const CREDIT_SCORE = 'CREDIT_SCORE';
export const CREDIT_SCORE_STATUS = 'CREDIT_SCORE_STATUS';

export const CREDIT_SCORE_HISTORY = 'CREDIT_SCORE_HISTORY';
export const CREDIT_SCORE_HISTORY_STATUS = 'CREDIT_SCORE_HISTORY_STATUS';

export const CREDIT_REPORT = 'CREDIT_REPORT';
export const CREDIT_REPORT_STATUS = 'CREDIT_REPORT_STATUS';

export const LATEST_3B_CREDIT_REPORT = 'LATEST_3B_CREDIT_REPORT';
export const LATEST_3B_CREDIT_REPORT_STATUS = 'LATEST_3B_CREDIT_REPORT_STATUS';

export const PREVIOUS_EFX_CREDIT_REPORT = 'PREVIOUS_EFX_CREDIT_REPORT';
export const PREVIOUS_EFX_CREDIT_REPORT_STATUS = 'PREVIOUS_EFX_CREDIT_REPORT_STATUS';

export const PREVIOUS_3B_CREDIT_REPORT = 'PREVIOUS_3B_CREDIT_REPORT';
export const PREVIOUS_3B_CREDIT_REPORT_STATUS = 'PREVIOUS_3B_CREDIT_REPORT_STATUS';

export const CREDIT_REPORT_ABSTRACT = 'CREDIT_REPORT_ABSTRACT';
export const CREDIT_REPORT_ABSTRACT_STATUS = 'CREDIT_REPORT_ABSTRACT_STATUS';

export const EQUIFAX_STATUS = 'EQUIFAX_STATUS';
export const DOWNLOAD_REPORT_STATUS = 'DOWNLOAD_REPORT_STATUS';

export const CREDIT_FULFILLMENT_REQUEST = 'CREDIT_FULFILLMENT_REQUEST';
export const CREDIT_FULFILLMENT_SUCCESS = 'CREDIT_FULFILLMENT_SUCCESS';
export const CREDIT_FULFILLMENT_FAILURE = 'CREDIT_FULFILLMENT_FAILURE';
export const CREDIT_PULLINFO_REQUEST = 'CREDIT_PULLINFO_REQUEST';
export const CREDIT_PULLINFO_SUCCESS = 'CREDIT_PULLINFO_SUCCESS';
export const CREDIT_PULLINFO_FAILURE = 'CREDIT_PULLINFO_FAILURE';
export const CREDIT_ON_DEMAND = 'CREDIT_ON_DEMAND';
export const CLEAR_FULFILLMENT_INFO = 'CLEAR_FULFILLMENT_INFO';

//TransUnion
export const TU_REPORT_HISTORY_REQUEST = 'REPORT_HISTORY_REQUEST';
export const TU_REPORT_HISTORY_SUCCESS = 'REPORT_HISTORY_SUCCESS';
export const TU_REPORT_HISTORY_FAILURE = 'REPORT_HISTORY_FAILURE';
export const TU_REPORT_REQUEST = 'REPORT_REQUEST';
export const TU_REPORT_SUCCESS = 'REPORT_SUCCESS';
export const TU_REPORT_FAILURE = 'REPORT_FAILURE';

//Common
export function setProviderBureauData(bureau: string, creditMax: $TSFixMe) {
  return {
    type: SET_PROVIDER_BUREAU_DATA,
    bureau,
    creditMax,
  };
}

// Latest Credit Score

export async function getCreditScoreEquifax({
  dispatch,
  clientLibraryInstance,
  localizedStringBundle,
  langCode,
  accountId,
}: {
  dispatch: Dispatch<any>;
  clientLibraryInstance: $TSFixMe;
  localizedStringBundle: StringMap;
  langCode: $TSFixMe;
  accountId: string;
}) {
  const startTime = Date.now();
  try {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'loading'});

    const response = await clientLibraryInstance.getCreditScoreLatest();
    const creditScoreData = buildCreditScoreData(response, localizedStringBundle, langCode, 'EFX');
    dispatch({type: 'CREDIT_SCORE', credit_score: creditScoreData});
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'success'});
  } catch (error: $TSFixMe) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Credit Score Latest', accountId));
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'error', status_text: error.statusText || ''});
  }
}

export async function getCreditScoreMock({
  dispatch,
  accountGuid,
  localizedStringBundle,
  langCode,
}: {
  dispatch: Dispatch<any>;
  accountGuid: $TSFixMe;
  localizedStringBundle: StringMap;
  langCode: $TSFixMe;
}) {
  try {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'loading'});
    const response = await getMockCreditScoreLatest(accountGuid);
    const creditScoreData = buildCreditScoreData(response, localizedStringBundle, langCode, 'EFX');
    dispatch({type: 'CREDIT_SCORE', credit_score: creditScoreData});
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'success'});
  } catch (error: $TSFixMe) {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'error', status_text: error.statusText || ''});
    throw error;
  }
}

// Credit Score History

export async function getCreditScoreHistoryEquifax({
  dispatch,
  clientLibraryInstance,
  accountId,
}: {
  dispatch: Dispatch<any>;
  clientLibraryInstance: $TSFixMe;
  accountId: string;
}) {
  const startTime = Date.now();

  try {
    dispatch({type: 'CREDIT_SCORE_HISTORY_STATUS', credit_score_history_status: 'loading'});

    const response = await clientLibraryInstance.getCreditScoresHistory('US_EFX');
    dispatch({type: 'CREDIT_SCORE_HISTORY', credit_score_history: response});
    dispatch({
      type: 'CREDIT_SCORE_HISTORY_STATUS',
      credit_score_history_status: 'success',
    });
  } catch (error) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Credit Score History Equifax', accountId));
    dispatch({
      type: 'CREDIT_SCORE_HISTORY_STATUS',
      credit_score_history_status: 'error',
      credit_score_history_error: error,
    });
  }
}

// Helper function to get cached report or fetch if not in cache
const getCachedOrFetchReport = async(cache: {[key: string]: any}, id: string | null, fetchFn: () => Promise<any>) => {
  if (!id) return null;

  if (cache[id]) {
    return cache[id];
  }
  const report = await fetchFn();
  cache[id] = report;
  return report;
};

export async function getCreditScoreHistoryMock({
  dispatch,
  accountGuid,
}: {
  dispatch: Dispatch<any>;
  accountGuid: $TSFixMe;
}) {
  try {
    dispatch({type: 'CREDIT_SCORE_HISTORY_STATUS', credit_score_history_status: 'loading'});
    const response = await getMockCreditScoreHistory(accountGuid);
    dispatch({type: 'CREDIT_SCORE_HISTORY', credit_score_history: response});
    dispatch({
      type: 'CREDIT_SCORE_HISTORY_STATUS',
      credit_score_history_status: 'success',
    });
  } catch (error) {
    dispatch({
      type: 'CREDIT_SCORE_HISTORY_STATUS',
      credit_score_history_status: 'error',
      credit_score_history_error: error,
    });
    throw error;
  }
}

// Credit Report by Id

/**
 * Fetches and processes the credit report data.
 *
 * This function retrieves the latest credit report and its history, processes the data,
 * and dispatches the appropriate actions to update the state. It also implements caching
 * to avoid redundant network requests for the same report IDs.
 *
 * @param clientLibraryInstance - The instance of the client library used to make API calls.
 * @param localizedStringBundle - A bundle of localized strings for formatting the credit report data.
 * @param langCode - The language code for localization (default is 'en').
 * @param is3BUser - A flag indicating if the user is a 3B user (default is false).
 * @param creditPullInfo - Information about the credit pull.
 *
 * @returns A thunk function that performs the asynchronous operations and dispatches actions.
 */
export async function getCreditReportEquifax({
  dispatch,
  clientLibraryInstance,
  localizedStringBundle,
  langCode = 'en',
  is3BUser = false,
  creditPullInfo,
  accountId,
}: {
  dispatch: Dispatch<any>;
  clientLibraryInstance: $TSFixMe;
  localizedStringBundle: StringMap;
  langCode?: string;
  is3BUser?: boolean;
  creditPullInfo: $TSFixMe;
  accountId: string;
}) {
  const startTime = Date.now();
  const cache: {[key: string]: any} = {}; // Initialize cache

  let creditReportLatestResponse: any | null = null;
  let latestCreditReport;

  try {
    // Indicate that the credit report fetching process has started
    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});

    // Fetch the latest credit report from the client library
    creditReportLatestResponse = await clientLibraryInstance.getCreditReportLatest();

    // Extract the report ID from the latest credit report response
    const reportId = creditReportLatestResponse?.id;

    if (reportId) {
      // Add the latest credit report to the cache using the report ID as the key
      cache[reportId] = creditReportLatestResponse;
    }

    // Process the latest credit report data
    latestCreditReport = buildCreditReportData(
      creditReportLatestResponse,
      localizedStringBundle,
      langCode,
      'EFX',
      accountId
    );

    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'success'});
    dispatch({type: 'CREDIT_REPORT', credit_report: latestCreditReport});
  } catch (error) {
    // Log the error information and dispatch an error status
    utils.log(buildEfxLogInfo(error, startTime, 'Get Latest Credit Report', accountId));
    dispatch({
      type: 'CREDIT_REPORT_STATUS',
      credit_report_status: 'error',
      credit_report_error: error,
      report_type: 'latest',
    });
  }

  // Get the latest 3B credit report
  try {
    // Check if the latest credit report has exactly one provider view, the user is a 3B user,
    // and a fulfilled 3B report is available. This way the 3B credit report can be merged into the latest credit report.
    // where the credit scrores from it are used in the EXP and TU tabs

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'loading'});

    if (
      creditReportLatestResponse &&
      Array.isArray(creditReportLatestResponse?.providerViews || []) &&
      creditReportLatestResponse?.providerViews?.length === 1 &&
      is3BUser &&
      creditPullInfo.isFulfilled3BReportAvailable
    ) {
      // Fetch the latest 3B credit report from the client library
      const creditReportLatestByTypeResponse = await clientLibraryInstance.getCreditReportLatestByType('US_3B');

      // Build the credit report data for the fetched 3B credit report
      const creditReportLatestByTypeData = buildCreditReportData(
        creditReportLatestByTypeResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        accountId
      );

      // Get the list of providers from the fetched 3B credit report data, excluding 'EFX' provider
      const providersToMerge = _get(creditReportLatestByTypeData, 'latest.providers', []).filter(
        (bureau: $TSFixMe) => bureau.provider !== 'EFX'
      );

      // Merge the providers from the fetched 3B credit report data into the latest credit report response
      if (_has(latestCreditReport, 'latest.providers')) {
        creditReportLatestResponse.providerViews = [
          ..._get(latestCreditReport, 'latest.providers', []),
          ...providersToMerge,
        ];
      } else {
        creditReportLatestResponse = creditReportLatestByTypeData;
      }

      latestCreditReport = buildCreditReportData(
        creditReportLatestResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        accountId
      );

      // Extract the report ID from the fetched 3B credit report response
      const creditReportLatestByTypeId = creditReportLatestByTypeResponse?.id;

      if (creditReportLatestByTypeId) {
        // Add the fetched 3B credit report to the cache using the report ID as the key
        cache[creditReportLatestByTypeId] = creditReportLatestByTypeResponse;
      }
    } else {
      latestCreditReport = buildCreditReportData(
        creditReportLatestResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        accountId
      );
    }

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'success'});

    dispatch({
      type: 'CREDIT_REPORT',
      credit_report: latestCreditReport,
    });
    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT',
      latest_3B_credit_report: latestCreditReport,
    });
  } catch (error) {
    // Log the error information and dispatch an error status
    utils.log(buildEfxLogInfo(error, startTime, 'Get Latest 3B Credit Report', accountId));

    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT_STATUS',
      latest_3B_credit_report_status: 'error',
      latest_3B_credit_report_error: error,
      report_type: 'US_3B',
    });
  }

  let sortedCreditReports = [];

  // Fetch the credit report history
  const creditReportHistoryEquifaxResponse =
    (await getCreditReportAbstractEquifax({
      dispatch,
      clientLibraryInstance,
      accountId,
    })) || [];

  // Sort the credit reports by generated date
  sortedCreditReports =
    creditReportHistoryEquifaxResponse?.sort(
      (a: {generatedDate: string | number | Date}, b: {generatedDate: string | number | Date}) => {
        return new Date(b.generatedDate).getTime() - new Date(a.generatedDate).getTime();
      }
    ) || [];

  // Filter the credit reports by type
  const filteredEFXCreditReports =
    sortedCreditReports?.filter(
      (report: {reportType: string}) => report.reportType === 'US_3B' || report.reportType === 'US_EFX'
    ) || [];

  const filtered3BCreditReports =
    sortedCreditReports?.filter((report: {reportType: string}) => report.reportType === 'US_3B') || [];

  // Get the latest and previous credit report information
  const latest3BCreditReportInfo =
    filtered3BCreditReports && filtered3BCreditReports.length > 0 ? filtered3BCreditReports[0] : {};

  const previousEFXCreditReportInfo =
    filteredEFXCreditReports && filteredEFXCreditReports.length > 1 ? filteredEFXCreditReports[1] : {};

  const previous3BCreditReportInfo =
    filtered3BCreditReports && filtered3BCreditReports.length > 1 ? filtered3BCreditReports[1] : {};

  const latest3BCreditReportId = latest3BCreditReportInfo?.id || null;

  const previousEFXCreditReportId = previousEFXCreditReportInfo.id || null;
  const previous3BCreditReportId = previous3BCreditReportInfo.id || null;

  let latest3BCreditReport;
  try {
    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'loading'});

    // Fetch the latest 3B credit report
    latest3BCreditReport = await getCachedOrFetchReport(cache, latest3BCreditReportId, () => {
      return clientLibraryInstance.getCreditReport(latest3BCreditReportId);
    });

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'success'});

    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT',
      latest_3B_credit_report: latest3BCreditReport,
    });
  } catch (error) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Latest 3B Credit Report', accountId));
    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT_STATUS',
      latest_3B_credit_report_status: 'error',
      latest_3B_credit_report_error: error,
      report_type: 'US_3B',
    });
  }

  let previousEFXCreditReportResponse;
  let previousEFXCreditReport;

  try {
    dispatch({type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS', previous_EFX_credit_report_status: 'loading'});

    // Fetch the previous EFX credit report
    previousEFXCreditReportResponse = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
      ? await getCachedOrFetchReport(cache, previousEFXCreditReportId, () =>
          clientLibraryInstance.getCreditReport(previousEFXCreditReportId)
        )
      : {};

    previousEFXCreditReport = buildCreditReportData(
      previousEFXCreditReportResponse,
      localizedStringBundle,
      langCode,
      'EFX',
      accountId
    );

    dispatch({type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS', previous_EFX_credit_report_status: 'success'});
    dispatch({
      type: 'PREVIOUS_EFX_CREDIT_REPORT',
      previous_EFX_credit_report: previousEFXCreditReport,
    });
  } catch (error) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Previous EFX Credit Report', accountId));
    dispatch({
      type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS',
      previous_EFX_credit_report_status: 'error',
      previous_EFX_credit_report_error: error,
    });
  }

  let previous3BCreditReport;
  try {
    dispatch({type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS', previous_3B_credit_report_status: 'loading'});

    // Fetch the previous 3B credit report
    previous3BCreditReport = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
      ? await getCachedOrFetchReport(cache, previous3BCreditReportId, () =>
          clientLibraryInstance.getCreditReport(previous3BCreditReportId)
        )
      : {};

    dispatch({type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS', previous_3B_credit_report_status: 'success'});
    dispatch({
      type: 'PREVIOUS_3B_CREDIT_REPORT',
      previous_3B_credit_report: previous3BCreditReport,
    });
  } catch (error) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Previous 3B Credit Report', accountId));
    dispatch({
      type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS',
      previous_3B_credit_report_status: 'error',
      previous_3B_credit_report_error: error,
    });
  }
}

export async function getCreditReportMock({
  dispatch,
  accountGuid,
  localizedStringBundle,
  langCode = 'en',
  is3BUser = false,
  creditPullInfo,
}: {
  dispatch: Dispatch<any>;
  accountGuid: $TSFixMe;
  localizedStringBundle: StringMap;
  langCode: string;
  is3BUser: boolean;
  creditPullInfo: $TSFixMe;
}) {
  const cache: {[key: string]: any} = {}; // Initialize cache

  let creditReportLatestResponse: any | null = null;
  let latestCreditReport;

  try {
    // Indicate that the credit report fetching process has started
    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});

    // Fetch the latest credit report from the client library
    creditReportLatestResponse = await getMockCreditReportLatest(accountGuid);

    // Extract the report ID from the latest credit report response
    const reportId = creditReportLatestResponse?.id;

    if (reportId) {
      // Add the latest credit report to the cache using the report ID as the key
      cache[reportId] = creditReportLatestResponse;
    }
    // Process the latest credit report data
    latestCreditReport = buildCreditReportData(
      creditReportLatestResponse,
      localizedStringBundle,
      langCode,
      'EFX',
      'mockAccountId'
    );

    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'success'});

    dispatch({
      type: 'CREDIT_REPORT',
      credit_report: latestCreditReport,
    });
  } catch (error) {
    // Log the error information and dispatch an error status
    dispatch({
      type: 'CREDIT_REPORT_STATUS',
      credit_report_status: 'error',
      credit_report_error: error,
      report_type: 'latest',
    });
  }

  // Get the latest 3B credit report
  try {
    // Check if the latest credit report has exactly one provider view, the user is a 3B user,
    // and a fulfilled 3B report is available. This way the 3B credit report can be merged into the latest credit report.
    // where the credit scrores from it are used in the EXP and TU tabs

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'loading'});

    if (
      creditReportLatestResponse &&
      Array.isArray(creditReportLatestResponse?.providerViews || []) &&
      creditReportLatestResponse?.providerViews?.length === 1 &&
      is3BUser &&
      creditPullInfo.isFulfilled3BReportAvailable
    ) {
      // Fetch the latest 3B credit report from the client library
      const creditReportLatestByTypeResponse = await getMockCreditReportLatestByType(accountGuid, 'US_3B');

      // Build the credit report data for the fetched 3B credit report
      const creditReportLatestByTypeData = buildCreditReportData(
        creditReportLatestByTypeResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        'mockAccountId'
      );

      // Get the list of providers from the fetched 3B credit report data, excluding 'EFX' provider
      const providersToMerge = _get(creditReportLatestByTypeData, 'latest.providers', []).filter(
        (bureau: $TSFixMe) => bureau.provider !== 'EFX'
      );

      // Merge the providers from the fetched 3B credit report data into the latest credit report response
      if (_has(latestCreditReport, 'latest.providers')) {
        creditReportLatestResponse.providerViews = [
          ..._get(latestCreditReport, 'latest.providers', []),
          ...providersToMerge,
        ];
      } else {
        creditReportLatestResponse = creditReportLatestByTypeData;
      }

      latestCreditReport = buildCreditReportData(
        creditReportLatestResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        'mockAccountId'
      );

      // Extract the report ID from the fetched 3B credit report response
      const creditReportLatestByTypeId = creditReportLatestByTypeResponse?.id;

      if (creditReportLatestByTypeId) {
        // Add the fetched 3B credit report to the cache using the report ID as the key
        cache[creditReportLatestByTypeId] = creditReportLatestByTypeResponse;
      }
    } else {
      latestCreditReport = buildCreditReportData(
        creditReportLatestResponse,
        localizedStringBundle,
        langCode,
        'EFX',
        'mockAccountId'
      );
    }

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'success'});

    dispatch({
      type: 'CREDIT_REPORT',
      credit_report: latestCreditReport,
    });
    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT',
      latest_3B_credit_report: latestCreditReport,
    });
  } catch (error) {
    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT_STATUS',
      latest_3B_credit_report_status: 'error',
      latest_3B_credit_report_error: error,
      report_type: 'US_3B',
    });
  }

  let sortedCreditReports = [];
  try {
    // Fetch the credit report history
    const getCreditReportAbstractMockFunc = getCreditReportAbstractMock(accountGuid);
    const creditReportHistoryMockResponse = await getCreditReportAbstractMockFunc(dispatch);

    // Sort the credit reports by generated date
    sortedCreditReports = creditReportHistoryMockResponse?.sort(
      (a: {generatedDate: string | number | Date}, b: {generatedDate: string | number | Date}) => {
        return new Date(b.generatedDate).getTime() - new Date(a.generatedDate).getTime();
      }
    );
  } catch (error) {
    dispatch({
      type: 'CREDIT_REPORT_ABSTRACT_STATUS',
      credit_report_abstract_status: 'error',
      credit_report_abstract_status_error: error,
    });
  }

  // Filter the credit reports by type
  const filteredEFXCreditReports = sortedCreditReports.filter(
    (report: {reportType: string}) => report.reportType === 'US_3B' || report.reportType === 'US_EFX'
  );

  const filtered3BCreditReports = sortedCreditReports.filter(
    (report: {reportType: string}) => report.reportType === 'US_3B'
  );

  // Get the latest and previous credit report information
  const latest3BCreditReportInfo =
    filtered3BCreditReports && filtered3BCreditReports.length > 0 ? filtered3BCreditReports[0] : null;

  const previousEFXCreditReportInfo =
    filteredEFXCreditReports && filteredEFXCreditReports.length > 1 ? filteredEFXCreditReports[1] : null;

  const previous3BCreditReportInfo =
    filtered3BCreditReports && filtered3BCreditReports.length > 1 ? filtered3BCreditReports[1] : null;

  const latest3BCreditReportId = latest3BCreditReportInfo?.id;
  const previousEFXCreditReportId = previousEFXCreditReportInfo ? previousEFXCreditReportInfo.id : null;
  const previous3BCreditReportId = previous3BCreditReportInfo ? previous3BCreditReportInfo.id : null;

  let latest3BCreditReport;
  try {
    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'loading'});

    // Fetch the latest 3B credit report
    latest3BCreditReport = await getCachedOrFetchReport(cache, latest3BCreditReportId, () =>
      getMockCreditReportById(accountGuid, latest3BCreditReportId)
    );

    dispatch({type: 'LATEST_3B_CREDIT_REPORT_STATUS', latest_3B_credit_report_status: 'success'});

    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT',
      latest_3B_credit_report: latest3BCreditReport,
    });
  } catch (error) {
    dispatch({
      type: 'LATEST_3B_CREDIT_REPORT_STATUS',
      latest_3B_credit_report_status: 'error',
      latest_3B_credit_report_error: error,
      report_type: 'US_3B',
    });
  }

  let previousEFXCreditReportResponse;
  let previousEFXCreditReport;

  try {
    dispatch({type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS', previous_EFX_credit_report_status: 'loading'});

    // Fetch the previous EFX credit report
    previousEFXCreditReportResponse = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
      ? await getCachedOrFetchReport(cache, previousEFXCreditReportId, () =>
          getMockCreditReportById(accountGuid, previousEFXCreditReportId)
        )
      : {};

    previousEFXCreditReport = buildCreditReportData(
      previousEFXCreditReportResponse,
      localizedStringBundle,
      langCode,
      'EFX',
      'mockAccountId'
    );

    dispatch({type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS', previous_EFX_credit_report_status: 'success'});
    dispatch({
      type: 'PREVIOUS_EFX_CREDIT_REPORT',
      previous_EFX_credit_report: previousEFXCreditReport,
    });
  } catch (error) {
    dispatch({
      type: 'PREVIOUS_EFX_CREDIT_REPORT_STATUS',
      previous_EFX_credit_report_status: 'error',
      previous_EFX_credit_report_error: error,
    });
  }

  let previous3BCreditReport;
  try {
    dispatch({type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS', previous_3B_credit_report_status: 'loading'});
    // Fetch the previous 3B credit report
    previous3BCreditReport = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
      ? await getCachedOrFetchReport(cache, previous3BCreditReportId, () =>
          getMockCreditReportById(accountGuid, previous3BCreditReportId)
        )
      : {};

    dispatch({type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS', previous_3B_credit_report_status: 'success'});
    dispatch({
      type: 'PREVIOUS_3B_CREDIT_REPORT',
      previous_3B_credit_report: previous3BCreditReport,
    });
  } catch (error) {
    dispatch({
      type: 'PREVIOUS_3B_CREDIT_REPORT_STATUS',
      previous_3B_credit_report_status: 'error',
      previous_3B_credit_report_error: error,
    });
  }
}

// Download Credit Report

export function downloadCreditReport(reportid: $TSFixMe, jwt: JWT, accountId: string) {
  return (dispatch: $TSFixMe) => {
    const startTime = Date.now();
    dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'loading'});
    const equifaxApiKey = _get(jwt, 'vendorInfo.equifaxApiKey');
    const equifaxEndPoint = _get(jwt, 'vendorInfo.equifaxEndPoint');
    const jwtToken = _get(jwt, 'jwtToken');

    if (!equifaxApiKey || !equifaxEndPoint || !jwtToken) {
      //TODO: We need error case specific for printing credit report
      return null;
    }
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiClient'.
    return EfxConsumerApiClient({
      apiKey: equifaxApiKey,
      server: equifaxEndPoint,
      clientAssertion: jwtToken,
    }).then(
      (clientLibraryInstance: $TSFixMe) => {
        dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'success'});
        return clientLibraryInstance.getDownloadCreditReportPDFLink(reportid);
      },
      (error: $TSFixMe) => {
        utils.log(buildEfxLogInfo(error, startTime, 'Download Credit Report', accountId));
        dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'error', download_report_error: error});
      }
    );
  };
}

// Credit Report History

export async function getCreditReportAbstractEquifax({
  dispatch,
  clientLibraryInstance,
  accountId,
}: {
  dispatch: Dispatch<any>;
  clientLibraryInstance: $TSFixMe;
  accountId: string;
}) {
  const startTime = Date.now();

  try {
    dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});

    const response = await clientLibraryInstance.getCreditReportAbstract();

    dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: response});
    dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});

    return response;
  } catch (error) {
    utils.log(buildEfxLogInfo(error, startTime, 'Get Credit Report Abstract', accountId));
    dispatch({
      type: 'CREDIT_REPORT_ABSTRACT_STATUS',
      credit_report_abstract_status: 'error',
      credit_report_abstract_status_error: error,
    });

    return [null];
  }
}

export function getCreditReportAbstractMock(accountGuid: $TSFixMe) {
  return async(dispatch: $TSFixMe) => {
    try {
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});

      const response = await getMockCreditReportHistory(accountGuid);

      dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: response});
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});

      return response;
    } catch (error) {
      dispatch({
        type: 'CREDIT_REPORT_ABSTRACT_STATUS',
        credit_report_abstract_status: 'error',
        credit_report_abstract_status_error: error,
      });

      return null;
    }
  };
}

// Define the new method
async function handleEquifaxClient(
  tokenObject: any,
  dispatch: any,
  localizedStringBundle: any,
  langCode: string,
  is3BUser: boolean,
  creditPullInfo: any,
  accountId: string
) {
  const startTime = Date.now();

  const venderInfoScope = _get(tokenObject, 'vendorInfo.scope');
  const equifaxApiKey = _get(tokenObject, 'vendorInfo.equifaxApiKey');
  const equifaxEndPoint = _get(tokenObject, 'vendorInfo.equifaxEndPoint');
  const jwtToken = _get(tokenObject, 'jwtToken');
  const scope =
    venderInfoScope === 'delivery'
      ? // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
        EfxConsumerApiConstants.ApiChannel.DELIVERY
      : // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
        EfxConsumerApiConstants.ApiChannel.PROXY_DELIVERY;
  if ((!equifaxApiKey || !equifaxEndPoint || !jwtToken) && !window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
    dispatch({type: 'EQUIFAX_STATUS', equifax_status: 'error'});
    return null;
  }

  try {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiClient'.
    const clientLibraryInstance = await EfxConsumerApiClient({
      scope,
      apiKey: equifaxApiKey,
      server: equifaxEndPoint,
      clientAssertion: jwtToken,
    });

    await getCreditScoreEquifax({dispatch, clientLibraryInstance, localizedStringBundle, langCode, accountId});
    await getCreditScoreHistoryEquifax({dispatch, clientLibraryInstance, accountId});
    await getCreditReportEquifax({
      dispatch,
      clientLibraryInstance,
      localizedStringBundle,
      langCode,
      is3BUser,
      creditPullInfo,
      accountId,
    });
  } catch (error) {
    utils.log({
      feature: 'Credit',
      actionType: `EFX client call - ${equifaxEndPoint}`,
      message: error,
      statusCode: 401,
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      elapsedTime: new Date() - startTime,
      clientId: window.isDwm ? 'dwm' : 'dsp',
    });
    dispatch({type: 'EQUIFAX_STATUS', equifax_status: 'error', equifax_error: error});
  }
}

export function equifaxClient(
  tokenObject: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  is3BUser: $TSFixMe,
  creditPullInfo: $TSFixMe,
  accountId: string
) {
  if (window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
    return async(dispatch: $TSFixMe, getState: $TSFixMe) => {
      const {accountGuid, useCreditMockEndpoints = false} = await getAccountGuidAndMockEndpointStatus(getState());

      if (useCreditMockEndpoints) {
        await getCreditScoreMock({dispatch, accountGuid, localizedStringBundle, langCode});
        await getCreditScoreHistoryMock({dispatch, accountGuid});
        await getCreditReportMock({dispatch, accountGuid, localizedStringBundle, langCode, is3BUser, creditPullInfo});
      } else {
        handleEquifaxClient(
          tokenObject,
          dispatch,
          localizedStringBundle,
          langCode,
          is3BUser,
          creditPullInfo,
          accountId
        );
      }
    };
  }
  return async(dispatch: $TSFixMe) => {
    handleEquifaxClient(tokenObject, dispatch, localizedStringBundle, langCode, is3BUser, creditPullInfo, accountId);
  };
}

// Exported wrapper used solely by credit.test.js to mock equifaxClient when testing
// callEfx.
export const creditLib = {
  equifaxClient,
};

export const callEfx = (
  accountId: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  is3BUser: $TSFixMe,
  creditPullInfo: $TSFixMe,
  jwt: JWT
) => {
  return (dispatch: $TSFixMe) => {
    return dispatch(creditLib.equifaxClient(jwt, localizedStringBundle, langCode, is3BUser, creditPullInfo, accountId));
  };
};

// Credit On Demand
export function setCreditOnDemand() {
  return {
    type: CREDIT_ON_DEMAND,
  };
}

// Credit Pull Info

function getCreditPullInfoRequest() {
  return {
    type: CREDIT_PULLINFO_REQUEST,
  };
}

function getCreditPullInfoFailure(error: $TSFixMe, options: $TSFixMe) {
  utils.log(buildEfxLogInfo(error, options.startTime, 'Get Credit Pull Info', options?.accountId));
  return {
    type: CREDIT_PULLINFO_FAILURE,
    error,
  };
}

function getCreditPullInfoSuccess(pullInfo: $TSFixMe, options: $TSFixMe) {
  // TODO: We wish to mock the following from the memx-tool credit report tool
  /*pullInfo = {
    isFulfilled1BReportAvailable: true,
    isFulfilled3BReportAvailable: true,
    isPull1BReportAllowed: false,
    isPull1BScoreAllowed: false,
    isPull3BReportAllowed: false,
    nextAvailable1BReportPullDate: 1724230800000,
    nextAvailable1BScorePullDate: 1724490000000,
    nextAvailable3BReportPullDate: 1716581351000,
  };*/

  return {
    type: CREDIT_PULLINFO_SUCCESS,
    pullInfo,
  };
}

export function getCreditPullInfo(accountId: string) {
  const url = utils.getCreditPullDatePath();
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, getCreditPullInfoRequest(), getCreditPullInfoSuccess, getCreditPullInfoFailure, {
    startTime: Date.now(),
    accountId,
  });
}

// Credit Fulfillment

function getFulfillmentRequest(topTabId: string) {
  const reportType = topTabId === 'EFX' ? '1B' : '3B';
  return {
    type: CREDIT_FULFILLMENT_REQUEST,
    reportType,
  };
}

function getFulfillmentSuccess(fulfillmentInfo: $TSFixMe, options: $TSFixMe) {
  return {
    type: CREDIT_FULFILLMENT_SUCCESS,
    fulfillmentInfo,
  };
}

function getFulfillmentFailure(error: $TSFixMe, options: $TSFixMe) {
  utils.log(buildEfxLogInfo(error, options.startTime, 'Get Credit Fulfillment', options?.accountId));
  return {
    type: CREDIT_FULFILLMENT_FAILURE,
    error,
  };
}

export function getCreditFulfillment(
  bureau: string,
  features: $TSFixMe,
  topTabId: string,
  method: HttpMethod = 'POST',
  accountId: string
) {
  const url = utils.getCreditReportPath(bureau);

  const config: FetchConfig = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
    body: JSON.stringify({
      features,
    }),
  };

  return api(url, config, getFulfillmentRequest(topTabId), getFulfillmentSuccess, getFulfillmentFailure, {
    startTime: Date.now(),
    accountId,
  });
}

// Clear fullfillment info
export function clearFulfillmentInfo() {
  return {
    type: CLEAR_FULFILLMENT_INFO,
  };
}

// TransUnion Report History
function getReportHistoryRequest() {
  return {
    type: TU_REPORT_HISTORY_REQUEST,
  };
}

function getReportHistorySuccess(reportHistory: $TSFixMe, localizedStringBundle: StringMap, langCode: $TSFixMe) {
  return {
    type: TU_REPORT_HISTORY_SUCCESS,
    reportHistoryScoreTrend: reportHistory.scoreHistory,
    reportHistory: reportHistory.history,
    newReportAllowed: reportHistory.newReportAllowed,
    nextAvailablePullDate: reportHistory.nextAvailablePullDate,
    nextAvailablePullDateAfterToday: reportHistory.nextAvailablePullDateAfterToday,
    previousScore: reportHistory.previousScore,
    previousScoreDate: reportHistory.previousScoreDate,
    localizedStringBundle,
    langCode,
  };
}

function getReportHistoryFailure(error: $TSFixMe, options: $TSFixMe) {
  utils.log(buildEfxLogInfo(error, options.startTime, 'Get Credit Report History', options?.accountId));
  return {
    type: TU_REPORT_HISTORY_FAILURE,
    error,
  };
}

export function getTuCreditReportHistory(
  bureau: string,
  localizedStringBundle: StringMap,
  langCode: string,
  accountId: string
) {
  const url = utils.getCreditReportHistoryPath(bureau);
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return api(
    url,
    config,
    getReportHistoryRequest(),
    (reportHistory: $TSFixMe) => getReportHistorySuccess(reportHistory, localizedStringBundle, langCode),
    getReportHistoryFailure,
    {
      startTime: Date.now(),
      accountId,
    }
  );
}

// TransUnion Credit Report

function getReportRequest() {
  return {
    type: TU_REPORT_REQUEST,
  };
}

function getReportSuccess(report: $TSFixMe, localizedStringBundle: StringMap, langCode: $TSFixMe, accountId: string) {
  return {
    type: TU_REPORT_SUCCESS,
    report,
    localizedStringBundle,
    langCode,
    accountId,
  };
}

function getReportFailure(error: $TSFixMe, options: $TSFixMe) {
  utils.log(buildEfxLogInfo(error, options.startTime, 'Get Credit Report (TU)', options?.accountId));
  const errorCode = _get(error, 'code', '');
  return {
    type: TU_REPORT_FAILURE,
    errorCode,
  };
}

export function getTuCreditReport(
  bureau: string,
  localizedStringBundle: StringMap,
  langCode: string,
  method: HttpMethod = 'GET',
  accountId: string
) {
  const url = utils.getCreditReportPath(bureau);

  const config: FetchConfig = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };

  return api(
    url,
    config,
    getReportRequest(),
    (report: $TSFixMe) => getReportSuccess(report, localizedStringBundle, langCode, accountId),
    getReportFailure,
    {
      startTime: Date.now(),
      accountId,
    }
  );
}

/**
 * Helper function to build up EFX error logging info
 *
 * @param error
 * @param startTime
 * @returns {Object}
 */
function buildEfxLogInfo(error: $TSFixMe, startTime: $TSFixMe, endpoint: string, accountId: string) {
  const elapsedTime = Date.now() - startTime;
  const errorMessage = utils.tryParseJson(_get(error, 'responseText', _get(error, 'statusText', '')));
  const statusCode = _get(error, 'status', '');
  const efxEndpoint = _get(error, 'responseURL', endpoint);

  return {
    feature: 'Credit',
    actionType: `EFX client call - ${efxEndpoint}`,
    message:
      typeof errorMessage === 'string' && errorMessage ? errorMessage : `Error: ${endpoint} : ${JSON.stringify(error)}`,
    statusCode,
    elapsedTime,
    error,
    clientId: window.isDwm ? 'dwm' : 'dsp',
    accountId,
  };
}

/*eslint-enable no-unused-vars*/
