/* eslint-disable no-console */

// TODO: Refractor
const debug = false;

function logMsg(...args: $TSFixMe) {
  if (debug) {
    console.log(args);
  }
}

export default {
  error: logMsg,
  info: logMsg,
  debug: logMsg,
};

/* eslint-enable no-console */
