import {ALERT_CATEGORY_FILTER} from '../actions/alertCategoryFilter';

const initialState = {
  alertCategory: null,
  alertBucket: null,
};

export default function alertCategoryFilter(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case ALERT_CATEGORY_FILTER:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertBucket: action.alertCategoryFilter.alertBucket,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertCategory: action.alertCategoryFilter.alertCategory,
      });
    default:
      return state;
  }
}
