import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {getLangCode, stringBundle} from '../stringBundle';
import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const ALERT_LIST_INBOX_REQUEST = 'ALERT_LIST_INBOX_REQUEST';
export const ALERT_LIST_INBOX_SUCCESS = 'ALERT_LIST_INBOX_SUCCESS';
export const ALERT_LIST_INBOX_FAILURE = 'ALERT_LIST_INBOX_FAILURE';

export const ALERT_LIST_DISPUTED_REQUEST = 'ALERT_LIST_DISPUTED_REQUEST';
export const ALERT_LIST_DISPUTED_SUCCESS = 'ALERT_LIST_DISPUTED_SUCCESS';
export const ALERT_LIST_DISPUTED_FAILURE = 'ALERT_LIST_DISPUTED_FAILURE';

export const ALERT_LIST_ARCHIVED_REQUEST = 'ALERT_LIST_ARCHIVED_REQUEST';
export const ALERT_LIST_ARCHIVED_SUCCESS = 'ALERT_LIST_ARCHIVED_SUCCESS';
export const ALERT_LIST_ARCHIVED_FAILURE = 'ALERT_LIST_ARCHIVED_FAILURE';

export const ALERT_UPDATED_ALERT_LIST_SUCCESS = 'ALERT_UPDATED_ALERT_LIST_SUCCESS';

export const ALERTS_CASE_OPENED_FILTER_SUCCESS = 'ALERTS_CASE_OPENED_FILTER_SUCCESS';
export const ALERTS_INBOX_FILTER_SUCCESS = 'ALERTS_INBOX_FILTER_SUCCESS';
export const ALERTS_ARCHIVED_FILTER_SUCCESS = 'ALERTS_ARCHIVED_FILTER_SUCCESS';

export const ALERTS_DISPOSITION_REQUEST = 'ALERTS_DISPOSITION_REQUEST';
export const ALERTS_DISPOSITION_SUCCESS = 'ALERTS_DISPOSITION_SUCCESS';
export const ALERTS_DISPOSITION_FAILURE = 'ALERTS_DISPOSITION_FAILURE';

export const ALERTS_ARCHIVED_SET_PAGINATION_SUCCESS = 'ALERTS_ARCHIVED_SET_PAGINATION_SUCCESS';
export const ALERTS_INBOX_SET_PAGINATION_SUCCESS = 'ALERTS_INBOX_SET_PAGINATION_SUCCESS';
export const ALERTS_CASE_OPENED_SET_PAGINATION_SUCCESS = 'ALERTS_CASE_OPENED_SET_PAGINATION_SUCCESS';

/**
 * Get alert filter by category drop down value from level2AlertCategory
 *
 *
 * @param alerts
 * @returns {*}
 */
function getAlertFilterDropdowns(alerts: $TSFixMe) {
  const localizedStringBundle = stringBundle(getLangCode());
  const categoryNames = {};

  const defaultDropdownValue = {
    name: localizedStringBundle.ALL_CATEGORIES,
    value: 'All',
  };

  alerts.forEach((alert: $TSFixMe) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    categoryNames[alert.level2AlertCategory] = 1;
  });
  const categoryNameKeys = Object.keys(categoryNames);

  const categoryNameKeysArray = categoryNameKeys
    .map((category) => {
      return {
        name: category,
        value: category,
      };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name, getLangCode());
    });

  if (categoryNameKeys.length > 1) {
    categoryNameKeysArray.unshift(defaultDropdownValue);
  }
  return categoryNameKeysArray;
}

/**
 * Gets member filter drop down values
 *
 * @param {object} primaryMember - primary member info
 * @param {Array<object>} associatedMembers - All sub member data
 * @returns {Array<object>}  subMembersMapped - list of members
 */
function getAlertMemberFilterDropdowns(primaryMember: $TSFixMe, associatedMembers: $TSFixMe[]) {
  const membersList = [];
  associatedMembers.forEach(({relationship, accountId, firstName, lastName}) => {
    if (relationship === 'Guardian') {
      membersList.push({
        groupId: accountId,
        ownerFirstName: firstName,
        ownerLastName: lastName,
        isMinor: true,
        name: `${firstName} ${lastName} (Minor)`,
        value: accountId,
      });
    }
  });
  membersList.push({
    groupId: (primaryMember as $TSFixMe).accountId,
    ownerFirstName: (primaryMember as $TSFixMe).firstName,
    ownerLastName: (primaryMember as $TSFixMe).lastName,
    isMinor: (primaryMember as $TSFixMe).relationship === 'Guardian',
    name: `${(primaryMember as $TSFixMe).firstName} ${(primaryMember as $TSFixMe).lastName}`,
    value: (primaryMember as $TSFixMe).accountId,
  });

  const subMembersMapped = membersList.filter((line) => line && line.groupId);

  if (subMembersMapped.length > 1) {
    const localizedStringBundle = stringBundle(getLangCode());
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ name: any; value: string; }' i... Remove this comment to see the full error message
    subMembersMapped.unshift({
      name: localizedStringBundle.ALL_MEMBERS,
      value: 'All',
    });
  }
  return subMembersMapped;
}

//-------- REQUEST ----------
function alertListInboxRequest() {
  return {
    type: ALERT_LIST_INBOX_REQUEST,
  };
}

function alertListDisputedRequest() {
  return {
    type: ALERT_LIST_DISPUTED_REQUEST,
  };
}

function alertListArchivedRequest(dateFilter: $TSFixMe) {
  return {
    type: ALERT_LIST_ARCHIVED_REQUEST,
    dateFilter,
  };
}

//-------- SUCCESS ----------
function alertListInboxSuccess(payload: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const alerts = _get(payload, 'alertList');
    const unreadCount = _get(payload, 'unreadCount');
    const summarySize = _get(payload, 'summarySize');
    const primaryMember = _get(getState(), 'auth.user.primaryMember', {});
    const associatedMembers = _get(getState(), 'auth.user.associatedMembers', []);

    if (alerts) {
      const alertFilterDropdowns = getAlertFilterDropdowns(alerts);
      const inboxMembersListKeysObject = getAlertMemberFilterDropdowns(primaryMember, associatedMembers);

      dispatch({
        type: ALERT_LIST_INBOX_SUCCESS,
        alertListInbox: alerts,
        alertListInboxFiltered: alerts,
        inboxAlertTypeNameKeysObject: alertFilterDropdowns,
        inboxMembersListKeysObject,
        unreadCount,
        summarySize,
      });
    } else {
      dispatch({
        type: ALERT_LIST_INBOX_FAILURE,
        error: 'Error with loading alertList Inbox',
      });
    }
  };
}

function alertListDisputedSuccess(payload: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const alerts = _get(payload, 'alertList');
    const primaryMember = _get(getState(), 'auth.user.primaryMember', {});
    const associatedMembers = _get(getState(), 'auth.user.associatedMembers', []);

    if (alerts) {
      const alertFilterDropdowns = getAlertFilterDropdowns(alerts);
      const caseOpenedMembersListKeysObject = getAlertMemberFilterDropdowns(primaryMember, associatedMembers);
      return dispatch({
        type: ALERT_LIST_DISPUTED_SUCCESS,
        alertListCaseOpened: alerts,
        alertListCaseOpenedFiltered: alerts,
        caseOpenedAlertTypeNameKeysObject: alertFilterDropdowns,
        caseOpenedMembersListKeysObject,
      });
    } else {
      return dispatch({
        type: ALERT_LIST_DISPUTED_FAILURE,
        error: 'Error with loading alertList Disputed',
      });
    }
  };
}

/**
 * Dispatch an action indicating archived-alerts fetch succeeded, with the newly-fetched alerts combined with the ones
 * already in state (ones fetched previously).
 *
 * @param {object} payload - The response payload with the alerts that were fetched.
 * @returns {function} - a function that dispatches the ALERT_LIST_ARCHIVED_SUCCESS action, with updated alert state
 */
function alertListArchivedSuccess(payload: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    let alerts = [];
    const justFetchedAlerts = _get(payload, 'alertList');
    const previouslyFetchedAlerts = _get(getState(), 'alertList.alertListArchived');
    if (Array.isArray(previouslyFetchedAlerts)) {
      alerts = previouslyFetchedAlerts.concat(justFetchedAlerts);
    } else {
      alerts = justFetchedAlerts;
    }
    const primaryMember = _get(getState(), 'auth.user.primaryMember', {});
    const associatedMembers = _get(getState(), 'auth.user.associatedMembers', []);

    if (alerts) {
      const alertFilterDropdowns = getAlertFilterDropdowns(alerts);
      const archivedMembersListKeysObject = getAlertMemberFilterDropdowns(primaryMember, associatedMembers);

      dispatch({
        type: ALERT_LIST_ARCHIVED_SUCCESS,
        alertListArchived: alerts,
        alertListArchivedFiltered: alerts,
        archivedAlertTypeNameKeysObject: alertFilterDropdowns,
        archivedMembersListKeysObject,
      });
    } else {
      dispatch({
        type: ALERT_LIST_ARCHIVED_FAILURE,
        error: 'Error with loading alertList Archived',
      });
    }
  };
}

//-------- FAILURE ----------
function alertListInboxFailure(error: $TSFixMe) {
  return {
    type: ALERT_LIST_INBOX_FAILURE,
    error,
  };
}

function alertListDisputedFailure(error: $TSFixMe) {
  return {
    type: ALERT_LIST_DISPUTED_FAILURE,
    error,
  };
}

function alertListArchivedFailure(error: $TSFixMe) {
  return {
    type: ALERT_LIST_ARCHIVED_FAILURE,
    error,
  };
}

//-------- GET ALERTS ----------
function getAlertListConfig(): FetchConfig {
  return {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      lang_code: getLangCode(),
    },
    credentials: 'include',
  };
}

export function getAlertListInbox() {
  const url = utils.getAlertListInboxPath();

  return api(url, getAlertListConfig(), alertListInboxRequest(), alertListInboxSuccess, alertListInboxFailure);
}

export function getAlertListDarkWebInbox(countryCode: $TSFixMe) {
  const url = utils.getAlertListDarkWebPath();
  return api(
    url + '&countryCode=' + countryCode,
    getAlertListConfig(),
    alertListInboxRequest(),
    alertListInboxSuccess,
    alertListInboxFailure
  );
}

export function getAlertListDisputed() {
  const url = utils.getAlertListDisputedPath();

  return api(url, getAlertListConfig(), alertListDisputedRequest(), alertListDisputedSuccess, alertListDisputedFailure);
}

/**
 * Initiates a fetch of archived alerts for the specified date range, and dispatches appropriate actions
 *
 * @param {object} dateFilter - date filter that specifies date range desired ("value" specifies how many days back)
 * @param {object} dateFilterFetched - date filter with "value" that specifies how many days back we've already fetched
 * @returns {function(*, *): *} a function that dispatches actions including the results of fetch success
 */
export function getAlertListArchived(dateFilter: DateFilterItem, dateFilterFetched: DateFilterItem) {
  const daysBackToStartDate = dateFilter ? dateFilter.value : 0;
  const daysBackToEndDate = dateFilterFetched ? dateFilterFetched.value : 0;

  const url = utils.getAlertListArchivedPath(daysBackToStartDate, daysBackToEndDate);

  return api(
    url,
    getAlertListConfig(),
    alertListArchivedRequest(dateFilter),
    alertListArchivedSuccess,
    alertListArchivedFailure
  );
}

/**
 * Given a list of alerts return an array of alerts not matching alertId
 *
 *
 * @returns {object[]}
 * @param {object[]}inputList
 * @param {string} alertId
 */
function getNonMatchingAlerts(inputList: $TSFixMe[], alertId: string) {
  return inputList.filter((alert) => {
    return (alert as $TSFixMe).id !== alertId;
  });
}

/**
 * Add (or replace) the given alert to the alert list, returning a flag to indicate whether it was added
 *
 * @param {object} newAlert - new alert to be added
 * @param {!object[]} inputList - list of alerts (mutated by adding/replacing the given alert)
 * @param {boolean} shouldReplaceExisting - Flag indicates whether to replace existing alert (if any)
 * @returns {boolean} - true if the alert was added, false if the alert already existed
 */
function addAlertToList(newAlert: $TSFixMe, inputList: $TSFixMe[], shouldReplaceExisting = true) {
  // see if the alert is already in the list, and get its index so we can replace it
  const existingIndex = inputList.findIndex((alert: $TSFixMe) => alert.id === newAlert.id);
  if (existingIndex === -1) {
    // add the new alert to the list, and return true indicating that a new alert was added
    inputList.unshift(newAlert);
    return true;
  }

  // replace the pre-existing copy of the alert with the new one (no re-sort required)
  if (shouldReplaceExisting) {
    inputList[existingIndex] = newAlert;
  }
  // return false to indicate that the alert was not added "brand new" (i.e. no need to re-sort the list)
  return false;
}

/**
 * Given a list of alerts, sorts by the createdAt date field (currently the only sort order supported)
 *
 * @param {object[]} inputList - list of alerts
 * @returns {object[]} - the (mutated) original alert list, sorted by createdAt date
 */
function sortAlertList(inputList: $TSFixMe[]) {
  return inputList.sort((alert1: $TSFixMe, alert2: $TSFixMe) => {
    const alert1CreatedAt = new Date((alert1 as $TSFixMe).createdAt);
    const alert2CreatedAt = new Date((alert2 as $TSFixMe).createdAt);
    return alert1CreatedAt > alert2CreatedAt ? -1 : alert1CreatedAt < alert2CreatedAt ? 1 : 0;
  });
}

/**
 * Given an input alertListArray , alertFilterObject and either level2AlertCategory or groupId
 * return matching alerts
 *
 * @returns {object[]}
 * @param {object[]} alertListArray
 * @param {object} alertFilterObject
 * @param {string} filterKey
 */
function getAlertsByKey(alertListArray: $TSFixMe[], alertFilterObject: $TSFixMe, filterKey: string) {
  if (alertFilterObject && (alertFilterObject as $TSFixMe).value) {
    return alertListArray.filter((x) => {
      return x[filterKey] === (alertFilterObject as $TSFixMe).value;
    });
  }
  return [];
}

/**
 * Given an input alertList and objects representing inbox, case opened and archived, return the filtered version
 *
 * @returns {object}
 * @param {object[]} alertListInbox
 * @param {object[]} alertListCaseOpened
 * @param {object[]} alertListArchived
 * @param {object} alertList
 */
function getFilteredBuckets(
  alertListInbox: $TSFixMe[],
  alertListCaseOpened: $TSFixMe[],
  alertListArchived: $TSFixMe[],
  alertList: $TSFixMe
) {
  const alertBuckets = {alertListInbox, alertListCaseOpened, alertListArchived};
  const alertListCaseOpenedAlertFilter = _get(alertList, 'alertListCaseOpenedAlertFilter', null);
  const alertListCaseOpenedMemberFilter = _get(alertList, 'alertListCaseOpenedMemberFilter', null);
  const alertListInboxAlertFilter = _get(alertList, 'alertListInboxAlertFilter', null);
  const alertListInboxMemberFilter = _get(alertList, 'alertListInboxMemberFilter', null);
  const alertListArchivedAlertFilter = _get(alertList, 'alertListArchivedAlertFilter', null);
  const alertListArchivedMemberFilter = _get(alertList, 'alertListArchivedMemberFilter', null);
  const alertListArchivedDateFilter = _get(alertList, 'alertListArchivedDateFilter');

  if (alertListInboxAlertFilter && alertListInboxAlertFilter.value !== 'All') {
    alertBuckets.alertListInbox = getAlertsByKey(
      alertBuckets.alertListInbox,
      alertListInboxAlertFilter,
      'level2AlertCategory'
    );
  }

  if (alertListInboxMemberFilter && alertListInboxMemberFilter.value !== 'All') {
    alertBuckets.alertListInbox = getAlertsByKey(alertBuckets.alertListInbox, alertListInboxMemberFilter, 'groupId');
  }

  if (alertListCaseOpenedAlertFilter && alertListCaseOpenedAlertFilter.value !== 'All') {
    alertBuckets.alertListCaseOpened = getAlertsByKey(
      alertBuckets.alertListCaseOpened,
      alertListCaseOpenedAlertFilter,
      'level2AlertCategory'
    );
  }

  if (alertListCaseOpenedMemberFilter && alertListCaseOpenedMemberFilter.value !== 'All') {
    alertBuckets.alertListCaseOpened = getAlertsByKey(
      alertBuckets.alertListCaseOpened,
      alertListCaseOpenedMemberFilter,
      'groupId'
    );
  }

  // Filter archived alerts, first by date, then by category and member
  if (alertListArchivedDateFilter) {
    alertBuckets.alertListArchived = performFilteringByDate(
      alertBuckets.alertListArchived,
      alertListArchivedDateFilter
    );
  }

  if (alertListArchivedAlertFilter && alertListArchivedAlertFilter.value !== 'All') {
    alertBuckets.alertListArchived = getAlertsByKey(
      alertBuckets.alertListArchived,
      alertListArchivedAlertFilter,
      'level2AlertCategory'
    );
  }

  if (alertListArchivedMemberFilter && alertListArchivedMemberFilter.value !== 'All') {
    alertBuckets.alertListArchived = getAlertsByKey(
      alertBuckets.alertListArchived,
      alertListArchivedMemberFilter,
      'groupId'
    );
  }
  return alertBuckets;
}

/**
 * Given an alert's current details data (possibly with a new status and/or bucket value), update its list entry,
 * and move it to a different bucket-list, if appropriate.
 *
 * @param {string} alertId - the unique alert identifier
 * @param {object} updatedFields - the alert details, including bucket and status
 */
export function updateAlertInAlertList(alertId: $TSFixMe, updatedFields: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const alertList = getState().alertList;
    let alertListInbox = _get(alertList, 'alertListInbox', []) || [];
    let alertListCaseOpened = _get(alertList, 'alertListCaseOpened', []) || [];
    let alertListArchived = _get(alertList, 'alertListArchived', []) || [];

    if (alertListInbox) {
      const inboxAlertItem = alertListInbox.find((alert: $TSFixMe) => {
        return alert.id === alertId;
      });
      const alertIcon = _get(inboxAlertItem, 'alertIcon', 'default.png');

      const inboxAlertTempDispositionTimer = _get(inboxAlertItem, 'tempDispositionTimer', 0);
      if (inboxAlertTempDispositionTimer > 0 && updatedFields.tempDispositionTimer === null) {
        clearTimeout(inboxAlertTempDispositionTimer);
      }

      const status = _get(updatedFields, 'status', '');
      const existingIsRead = status ? status !== 'generated' : _get(inboxAlertItem, 'isRead', false);
      const isRead = _get(updatedFields, 'isRead', existingIsRead);
      const matchedAlert = Object.assign({}, inboxAlertItem, updatedFields, {isRead}, {alertIcon});

      let bucket = 'inbox';
      if (window.isDwm !== true) {
        bucket = _get(matchedAlert, 'bucket', 'inbox');
      }

      // if the alert originated in the inbox bucket, update its details and move it to its new bucket if needed
      // (only alerts originating in Inbox will have status/bucket value changes that require updates to our lists)
      if (inboxAlertItem) {
        switch (bucket) {
          case 'archived': {
            if (addAlertToList(matchedAlert, alertListArchived, false)) {
              alertListArchived = sortAlertList(alertListArchived);
            }
            alertListInbox = getNonMatchingAlerts(alertListInbox, alertId);
            break;
          }

          case 'disputed': {
            if (addAlertToList(matchedAlert, alertListCaseOpened, false)) {
              alertListCaseOpened = sortAlertList(alertListCaseOpened);
            }
            alertListInbox = getNonMatchingAlerts(alertListInbox, alertId);
            break;
          }

          default: {
            alertListInbox = alertListInbox.map((alert: $TSFixMe) => {
              if (alert.id === alertId) {
                alert = matchedAlert;
              }
              return alert;
            });
            break;
          }
        }
      }

      const alertBuckets = getFilteredBuckets(alertListInbox, alertListCaseOpened, alertListArchived, alertList);

      const finalResponse = {
        type: ALERT_UPDATED_ALERT_LIST_SUCCESS,
        alertListInbox,
        alertListCaseOpened,
        alertListArchived,
        claimCaseId: updatedFields.claimCaseId,
        alertListInboxFiltered: alertBuckets.alertListInbox,
        alertListCaseOpenedFiltered: alertBuckets.alertListCaseOpened,
        alertListArchivedFiltered: alertBuckets.alertListArchived,
      };

      return dispatch(finalResponse);
    }
  };
}

//-------- Pagination ----------
export function alertsSetArchivedPageNumber(archivedCurrentPage: $TSFixMe) {
  return {
    type: ALERTS_ARCHIVED_SET_PAGINATION_SUCCESS,
    archivedCurrentPage,
  };
}

export function alertsSetInboxPageNumber(inboxCurrentPage: $TSFixMe) {
  return {
    type: ALERTS_INBOX_SET_PAGINATION_SUCCESS,
    inboxCurrentPage,
  };
}

export function alertsSetCaseOpenedPageNumber(caseOpenedCurrentPage: $TSFixMe) {
  return {
    type: ALERTS_CASE_OPENED_SET_PAGINATION_SUCCESS,
    caseOpenedCurrentPage,
  };
}

/**
 * Given an input alertList with definition of bucketString, typeNameKeysObjectString as well as other helper
 * variables, returns a filtered alertListInputBucketFiltered and inputBucketAlertTypeNameKeysObject
 *
 * @returns {object}
 * @param {object[]}  alertList
 * @param {string} bucketString
 * @param {string} typeNameKeysObjectString
 * @param {object} alertFilter
 * @param {object} memberFilter
 * @param {string} dropdownType
 * @param {object} localizedStringBundle
 * @param {object} dateFilter
 */
function getFilteredInputBucketAndAlertTypeNameKeysObject(
  alertList: $TSFixMe[],
  bucketString: string,
  typeNameKeysObjectString: string,
  alertFilter: $TSFixMe,
  memberFilter: $TSFixMe,
  dropdownType: string,
  localizedStringBundle: StringMap,
  dateFilter: DateFilterItem
) {
  let alertListInputBucketFiltered = _get(alertList, bucketString, []) || [];
  let inputBucketAlertTypeNameKeysObject = _get(alertList, typeNameKeysObjectString);

  if (dateFilter) {
    alertListInputBucketFiltered = performFilteringByDate(alertListInputBucketFiltered, dateFilter);
  }

  if (alertFilter && (alertFilter as $TSFixMe).value !== 'All') {
    alertListInputBucketFiltered = getAlertsByKey(alertListInputBucketFiltered, alertFilter, 'level2AlertCategory');
  }

  if (memberFilter && (memberFilter as $TSFixMe).value !== 'All') {
    alertListInputBucketFiltered = getAlertsByKey(alertListInputBucketFiltered, memberFilter, 'groupId');
  }

  inputBucketAlertTypeNameKeysObject = getBucketAlertTypeNameKeysObject(
    dropdownType,
    alertListInputBucketFiltered,
    inputBucketAlertTypeNameKeysObject,
    localizedStringBundle
  );

  return {alertListInputBucketFiltered, inputBucketAlertTypeNameKeysObject};
}

/**
 * Given a date-filter specifying how many days back to go, return only alerts within that recent date range.
 *
 * @param {object[]} unfilteredAlerts - a list of alerts that needs to be filtered
 * @param {DateFilterItem} dateFilter - a date filter object with a "value" property specifying how many days back to include
 * @returns {object[]} - The filtered list of alerts
 */
function performFilteringByDate(unfilteredAlerts: $TSFixMe[], dateFilter: DateFilterItem) {
  // simply return the original, unfiltered alerts, if no dateFilter is supplied,
  if (!dateFilter) return unfilteredAlerts;

  const daysBackToStartDate = dateFilter.value;
  const startDateIsoString = utils.getIsoDateStringFromDaysBack(daysBackToStartDate);

  // simply return the original, unfiltered alerts, if we get back an empty ISO-format date string
  // It means the date filter value is beyond max (indicating "all" desired), or invalid
  if (!startDateIsoString) return unfilteredAlerts;

  // filter alerts to include only the ones more recent than the start date
  return unfilteredAlerts.filter(
    (alert) => (alert as $TSFixMe).createdAt && (alert as $TSFixMe).createdAt > startDateIsoString
  );
}

/**
 * Apply the given filters to the unfiltered alert list for the given bucket, returning a filtered list of alerts
 *
 * @param {object[]} unfilteredAlerts - a list of alerts that needs to be filtered
 * @param {object} dateFilter - a date filter object w/ "value" property specifying how many days back to include
 * @param {object} alertFilter - alert-category filter object w/ "value" property specifying the category desired
 * @param {object} memberFilter - a member filter object w/ "value" property specifying the member name (groupId).
 *
 * @returns {object[]} The filtered list of alerts
 */
function performAlertFiltering(
  unfilteredAlerts: $TSFixMe[],
  dateFilter: DateFilterItem,
  alertFilter: $TSFixMe,
  memberFilter: $TSFixMe
) {
  let filteredAlerts: $TSFixMe = [];

  // apply filters to unfiltered alerts (if any), first by alert-type (category), then by member (groupId)
  if (unfilteredAlerts && !_isEmpty(unfilteredAlerts)) {
    filteredAlerts = unfilteredAlerts;

    if (dateFilter) {
      filteredAlerts = performFilteringByDate(unfilteredAlerts, dateFilter);
    }
    if (alertFilter && (alertFilter as $TSFixMe).value !== 'All') {
      filteredAlerts = getAlertsByKey(filteredAlerts, alertFilter, 'level2AlertCategory');
    }
    if (memberFilter && (memberFilter as $TSFixMe).value !== 'All') {
      filteredAlerts = getAlertsByKey(filteredAlerts, memberFilter, 'groupId');
    }
  }
  return filteredAlerts;
}

/**
 * Adjust the contents of our alert categories (alert-type-name) dropdown when a new member or date range is selected.
 *
 * @param {object[]} filteredAlerts - The list of alerts, after filtering performed
 * @param {string} dropdownType - One of these values: "alerttypeDropdown", "memberDropdown", or "dateDropdown"
 * @param localizedStringBundle - An object map with localized (translated) strings
 * @returns {object[]} The adjusted array of alert-type key names, including only those that appear in the filteredAlerts
 */
function getAdjustedAlertTypeNameKeysObject(
  filteredAlerts: $TSFixMe[],
  dropdownType: string,
  localizedStringBundle: StringMap
) {
  let adjustedAlertTypeNameKeysObject;
  let alertTypeNameKeys = [];
  const alertTypeNames: $TSFixMe = [];

  if (dropdownType === 'memberDropdown' || dropdownType === 'dateDropdown') {
    if (filteredAlerts.length > 0) {
      filteredAlerts.forEach((x) => {
        alertTypeNames[(x as $TSFixMe).level2AlertCategory] = 1;
      });

      alertTypeNameKeys = Object.keys(alertTypeNames);

      adjustedAlertTypeNameKeysObject = alertTypeNameKeys.map((x) => {
        return {
          name: x,
          value: x,
        };
      });

      if (alertTypeNameKeys.length > 1) {
        const defaultDropdownValue = {
          name: localizedStringBundle.ALL_CATEGORIES,
          value: 'All',
        };
        adjustedAlertTypeNameKeysObject.unshift(defaultDropdownValue);
      }
    } else {
      adjustedAlertTypeNameKeysObject = [];
    }
  }
  return adjustedAlertTypeNameKeysObject;
}

/**
 * Given an input alertListInputArray, alertTypeNameKeysObject and dropdownType return the
 * alertListInputBucketFiltered and inputBucketAlertTypeNameKeysObject
 *
 * @returns {{alertListInputBucketFiltered, inputBucketAlertTypeNameKeysObject}}
 * @param {string} dropdownType
 * @param {object[]} alertListInputArray
 * @param {object} alertTypeNameKeysObject
 * @param {object} localizedStringBundle
 */
function getBucketAlertTypeNameKeysObject(
  dropdownType: string,
  alertListInputArray: $TSFixMe[],
  alertTypeNameKeysObject: $TSFixMe,
  localizedStringBundle: StringMap
) {
  let alertTypeNameKeys = [];
  const alertTypeNames: $TSFixMe = [];

  if (dropdownType === 'memberDropdown') {
    if (alertListInputArray.length > 0) {
      alertListInputArray.forEach((x) => {
        alertTypeNames[(x as $TSFixMe).level2AlertCategory] = 1;
      });

      alertTypeNameKeys = Object.keys(alertTypeNames);

      alertTypeNameKeysObject = alertTypeNameKeys.map((x) => {
        return {
          name: x,
          value: x,
        };
      });

      if (alertTypeNameKeys.length > 1) {
        const defaultDropdownValue = {
          name: localizedStringBundle.ALL_CATEGORIES,
          value: 'All',
        };
        (alertTypeNameKeysObject as $TSFixMe).unshift(defaultDropdownValue);
      }
    } else {
      alertTypeNameKeysObject = [];
    }
  }
  return alertTypeNameKeysObject;
}

//-------- Filtration ----------
/**
 * Performs filtering of the alerts in the inbox bucket, and dispatches an action with the results
 *
 * @param {object} alertFilter - The alert-category filter to be applied
 * @param {object} memberFilter - The member filter to be applied
 * @param {string} dropdownType - One of these values: "alerttypeDropdown", "memberDropdown", or "dateDropdown"
 * @param {object} dateFilter - The date filter to be applied (all alerts going back X days into the past), if any
 *
 * @returns {function(*, *): *} A function to dispatch filtered results as action type ALERTS_INBOX_FILTER_SUCCESS
 */
export function alertsSetInboxFilter(
  alertFilter: $TSFixMe,
  memberFilter: $TSFixMe,
  dropdownType: string,
  dateFilter: DateFilterItem
) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const localizedStringBundle = stringBundle(getLangCode());
    const alertList = getState().alertList;

    const {alertListInputBucketFiltered, inputBucketAlertTypeNameKeysObject} =
      getFilteredInputBucketAndAlertTypeNameKeysObject(
        alertList,
        'alertListInbox',
        'inboxAlertTypeNameKeysObject',
        alertFilter,
        memberFilter,
        dropdownType,
        localizedStringBundle,
        dateFilter
      );

    return dispatch({
      type: ALERTS_INBOX_FILTER_SUCCESS,
      alertFilter,
      memberFilter,
      dateFilter,
      alertListInboxFiltered: alertListInputBucketFiltered,
      inboxAlertTypeNameKeysObject: inputBucketAlertTypeNameKeysObject,
      currentPage: 1,
    });
  };
}

/**
 * Performs filtering of the alerts in the disputed bucket, and dispatches an action with the results
 *
 * @param {object} alertFilter - The alert-category filter to be applied
 * @param {object} memberFilter - The member filter to be applied
 * @param {string} dropdownType - One of these values: "alerttypeDropdown", "memberDropdown", or "dateDropdown"
 * @param {object} dateFilter - The date filter to be applied (all alerts going back X days into the past), if any
 *
 * @returns {function(*, *): *} A function to dispatch filtered results as action type ALERTS_CASE_OPENED_FILTER_SUCCESS
 */
export function alertsSetCaseOpenedFilter(
  alertFilter: $TSFixMe,
  memberFilter: $TSFixMe,
  dropdownType: string,
  dateFilter: DateFilterItem
) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const localizedStringBundle = stringBundle(getLangCode());
    const alertList = getState().alertList;

    const {alertListInputBucketFiltered, inputBucketAlertTypeNameKeysObject} =
      getFilteredInputBucketAndAlertTypeNameKeysObject(
        alertList,
        'alertListCaseOpened',
        'caseOpenedAlertTypeNameKeysObject',
        alertFilter,
        memberFilter,
        dropdownType,
        localizedStringBundle,
        dateFilter
      );

    dispatch({
      type: ALERTS_CASE_OPENED_FILTER_SUCCESS,
      alertFilter,
      memberFilter,
      dateFilter,
      alertListCaseOpenedFiltered: alertListInputBucketFiltered,
      caseOpenedAlertTypeNameKeysObject: inputBucketAlertTypeNameKeysObject,
      currentPage: 1,
    });
  };
}

/**
 * Performs filtering of the alerts in the archived bucket, and dispatches an action with the results
 *
 * @param {object} dateFilter - The date filter to be applied (all alerts going back X days into the past), if any
 * @param {object} alertFilter - The alert-category filter to be applied
 * @param {object} memberFilter - The member filter to be applied
 * @param {string} dropdownType - One of these values: "alerttypeDropdown", "memberDropdown", or "dateDropdown"
 *
 * @returns {function(*, *): *} A function to dispatch filtered results as action type ALERTS_ARCHIVED_FILTER_SUCCESS
 */
export function alertsSetArchivedFilter(
  dateFilter: DateFilterItem,
  alertFilter: $TSFixMe,
  memberFilter: $TSFixMe,
  dropdownType: string
) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const localizedStringBundle = stringBundle(getLangCode());
    const alertListState = _get(getState(), 'alertList', {});
    const unfilteredAlerts = _get(alertListState, 'alertListArchived', []);
    const alertTypeNameKeysObject = _get(alertListState, 'archivedAlertTypeNameKeysObject', []);
    const filteredAlerts = performAlertFiltering(unfilteredAlerts, dateFilter, alertFilter, memberFilter);

    const filterSuccessObject = {
      type: ALERTS_ARCHIVED_FILTER_SUCCESS,
      dateFilter,
      alertFilter,
      memberFilter,
      alertListArchivedFiltered: filteredAlerts,
      archivedAlertTypeNameKeysObject: alertTypeNameKeysObject,
      archivedCurrentPage: 1,
    };

    // After filtering, we might need to adjust the list of categories in our alert-type dropdown
    const adjustedKeysObject = getAdjustedAlertTypeNameKeysObject(filteredAlerts, dropdownType, localizedStringBundle);
    if (adjustedKeysObject) {
      Object.assign(filterSuccessObject, {archivedAlertTypeNameKeysObject: adjustedKeysObject});
    }
    dispatch(filterSuccessObject);
  };
}

//-------- Disposition ----------
function alertsDispositionRequest() {
  return {
    type: ALERTS_DISPOSITION_REQUEST,
  };
}

function alertsDispositionSuccess(payload: $TSFixMe, options: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const alertId = _get(options, 'id');
    const updatedDetailAlert = _get(payload, 'alertDetail');
    if (updatedDetailAlert) {
      const alertList = getState().alertList;
      let alertListInbox = _get(alertList, 'alertListInbox', []) || [];
      const alertListCaseOpened = _get(alertList, 'alertListCaseOpened', []) || [];
      const alertListArchived = _get(alertList, 'alertListArchived', []) || [];
      if (alertListInbox) {
        const bucket = _get(updatedDetailAlert, 'bucket');

        const matchedAlertFilterArray = alertListInbox.filter((alert: $TSFixMe) => {
          return alert.id === alertId;
        });

        const matchedAlertFilter = matchedAlertFilterArray[0];
        const matchedAlertTempDispositionTimer = _get(matchedAlertFilter, 'tempDispositionTimer', 0);
        const alertIcon = _get(matchedAlertFilter, 'alertIcon', 'default.png');

        if (matchedAlertTempDispositionTimer > 0) {
          clearTimeout(matchedAlertTempDispositionTimer);
          matchedAlertFilter.tempDispositionTimer = null;
        }

        matchedAlertFilter.tempDisposition = 'finished';
        const status = _get(updatedDetailAlert, 'status', '');
        const existingIsRead = status ? status !== 'generated' : _get(matchedAlertFilter, 'isRead', false);
        const isRead = _get(updatedDetailAlert, 'isRead', existingIsRead);
        const matchedAlert = Object.assign({}, matchedAlertFilter, updatedDetailAlert, {isRead}, {alertIcon});

        switch (bucket) {
          case 'archived': {
            addAlertToList(matchedAlert, alertListArchived);
            alertListInbox = alertListInbox.filter((alert: $TSFixMe) => {
              return alert.id !== alertId;
            });
            break;
          }
          case 'disputed': {
            addAlertToList(matchedAlert, alertListCaseOpened);
            alertListInbox = alertListInbox.filter((alert: $TSFixMe) => {
              return alert.id !== alertId;
            });
            break;
          }
          default: {
            alertListInbox = alertListInbox.map((alert: $TSFixMe) => {
              if (alert.id === alertId) {
                alert = matchedAlert;
              }
              return alert;
            });
            break;
          }
        }

        const alertBuckets = getFilteredBuckets(alertListInbox, alertListCaseOpened, alertListArchived, alertList);
        return dispatch({
          type: ALERTS_DISPOSITION_SUCCESS,
          alertListInbox,
          alertListCaseOpened,
          alertListArchived,
          claimCaseId: updatedDetailAlert.claimCaseId,
          alertListCaseOpenedFiltered: alertBuckets.alertListCaseOpened,
          alertListInboxFiltered: alertBuckets.alertListInbox,
          alertListArchivedFiltered: alertBuckets.alertListArchived,
        });
      }

      if (
        updatedDetailAlert &&
        updatedDetailAlert.hasAdvancedDispositionQuestions &&
        updatedDetailAlert.disposition === 'NOT_LEGIT'
      ) {
        //browserHistory.push(`/alerts/${updatedDetailAlert.id}`) // FIXME react-router v4 has hashRouter creating a hash history
      }
    } else {
      return dispatch(alertsDispositionFailure('Disposition Error', options));
    }
  };
}

function alertsDispositionFailure(error: $TSFixMe, options: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const alertList = getState().alertList;
    const alertListInbox = _get(alertList, 'alertListInbox', []) || [];

    if (alertListInbox) {
      alertListInbox.forEach((alert: $TSFixMe, index: $TSFixMe) => {
        if (alert.id === options.id) {
          alertListInbox[index].dispositionFailed = true;
          alertListInbox[index].tempDisposition = null;
          alertListInbox[index].tempDispositionTimer = null;
        }

        return alert;
      });

      let alertListInboxFiltered = alertListInbox;
      const alertListInboxAlertFilter = _get(alertList, 'alertListInboxAlertFilter');
      const alertListInboxMemberFilter = _get(alertList, 'alertListInboxMemberFilter');

      if (alertListInboxAlertFilter) {
        if (alertListInboxAlertFilter.value !== 'All') {
          alertListInboxFiltered = alertListInboxFiltered.filter((x: $TSFixMe) => {
            return x.level2AlertCategory === alertListInboxAlertFilter.value;
          });
        }
      }

      if (alertListInboxMemberFilter) {
        if (alertListInboxMemberFilter.value !== 'All') {
          alertListInboxFiltered = alertListInboxFiltered.filter((x: $TSFixMe) => {
            return x.groupId === alertListInboxMemberFilter.value;
          });
        }
      }

      dispatch({
        type: ALERTS_DISPOSITION_FAILURE,
        alertListInbox,
        alertListInboxFiltered,
      });
    }
  };
}

export function setAlertDisposition(options: $TSFixMe, accountId: $TSFixMe, isMobileLogin: $TSFixMe) {
  let dispositionBoolean = null;

  switch (options.disposition) {
    case 'LEGIT':
      dispositionBoolean = true;
      break;
    case 'NOT_LEGIT':
      dispositionBoolean = false;
      break;
    default:
      dispositionBoolean = null;
      break;
  }

  const url = utils.getAlertDetailPath(options.id);

  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      account_id: accountId,
      mobileLogin: isMobileLogin,
    },
    credentials: 'include',
    body: JSON.stringify({
      id: options.id,
      disposition: dispositionBoolean,
    }),
  };

  return api(url, config, alertsDispositionRequest(), alertsDispositionSuccess, alertsDispositionFailure, options);
}
