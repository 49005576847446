//error codes and description for analytics purposes
const adobeErrorCodeMap = {
  120011: 'api rate limit hit',
  20008: 'auth no account found',
  32000: 'login inactive',
  32010: 'login blocked status',
  32020: 'login expired status',
  32030: 'login cancelled status',
  32040: 'login pending migration status',
  32050: 'login profile pending setup',
  80005: 'oidc error',
  80011: 'missing state nonce in session',
  24000: 'data api query error',
  19000: 'colp invalid input',
  80010: 'jwt key error',
};

export default {adobeErrorCodeMap};
export const privacyMonitorUserStatusCode = 60022;
