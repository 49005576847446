import {HIDE_MODAL, SET_MODAL_QUERY, SET_MODAL_SIZE_SPEC, SHOW_MODAL} from '../actions/modal';

const initialState = {
  modalType: null,
  modalProps: {},
  modalSizeSpec: {
    content: {},
  },
  modalQuery: {},
};

export default function modal(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case SHOW_MODAL:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {modalType: action.modalType, modalProps: action.modalProps});
    case HIDE_MODAL:
      return initialState;
    case SET_MODAL_SIZE_SPEC:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {modalSizeSpec: action.modalSizeSpec});
    case SET_MODAL_QUERY:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {modalQuery: action.modalQuery});
    default:
      return state;
  }
}
