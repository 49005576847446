import utils from '../helpers/utils';
import _get from 'lodash/get';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_MODAL_SIZE_SPEC = 'SET_MODAL_SIZE_SPEC';
export const SET_MODAL_QUERY = 'SET_MODAL_QUERY';

export function showModalCredit(modalName: $TSFixMe, data: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    let option;
    const category = _get(data, 'sectionName.eventCategory', '');
    const label = _get(data, 'sectionName.eventLabel', '');
    const action = _get(data, 'sectionName.eventAction', '');
    const value = _get(data, 'sectionName.value', '1');

    switch (modalName) {
      case 'CREDIT_HISTORY_MODAL':
      case 'CREDIT_INQUIRIES_MODAL':
      case 'CREDIT_REPORT_DOWNLOAD_MODAL':
      case 'CREDIT_REPORT_INACCURACY_MODAL':
        option = {
          value,
          action,
          label,
          category,
        };
        break;
      default:
        option = {
          value: '',
          label: '',
          action: '',
          category: '',
        };
        break;
    }

    utils.trackEvent(option);

    dispatch({
      type: 'SHOW_MODAL',
      modalType: modalName,
      modalProps: {data},
    });
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export function showModal() {
  return {
    type: SHOW_MODAL,
  };
}

export function setModalSizeSpec(modalSizeSpec: $TSFixMe) {
  return {
    type: SET_MODAL_SIZE_SPEC,
    modalSizeSpec,
  };
}
export function setModalQuery(modalQuery: $TSFixMe) {
  return {
    type: SET_MODAL_QUERY,
    modalQuery,
  };
}
