const alertListReducer = (state = {}, action: $TSFixMe) => {
  switch (action.type) {
    case 'SET_ALERT_LIST':
      return Object.assign({}, state, {alertList: action.alertList});
    case 'SET_FETCH_ALERTS_FLAG':
      return Object.assign({}, state, {fetchAlertsFlag: action.fetchAlertsFlag});
    default:
      return state;
  }
};

export default alertListReducer;
