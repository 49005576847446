import _get from 'lodash/get';
import 'core-js/es6/number'; //For the Number.isNaN() method is not supported in IE-11.
import formatDate from 'date-fns/format';
import utils from './utils';

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export default class dateUtils {
  static getDateReportGeneratedFormatted(provider: ReportProvider, langCode: string) {
    const reportGenerated = _get(provider, 'summary.reportGenerated', '');
    return dateUtils.convertDateToSlashFormat(reportGenerated, langCode, '');
  }

  static getFormattedDates(date: string | number, langCode: string) {
    const date1 = new Date(date || '');
    if (!isNaN(date1.getTime())) {
      return formatDate(date1, this.getDateFormat(langCode));
    }
    return undefined;
  }

  /**
   * returns an action banner date format by langCode (in the pattern of DD/MM/YYYY, MM/DD/YYYY or YYYY/MM/DD)
   * @param {string} langCode - a browser language code
   * @return {string} - returns a date format
   */
  static getDateFormat(langCode: string) {
    switch (langCode) {
      case 'de-DE':
      case 'de-DK':
      case 'en-AU':
      case 'en-CA':
      case 'en-GB':
      case 'en-IN':
      case 'en-NZ':
      case 'es-ES':
      case 'es-MX':
      case 'fi-FI':
      case 'fr-CA':
      case 'fr-FR':
      case 'it-IT':
      case 'nb-NO':
      case 'nl-NL':
      case 'pl-PL':
      case 'pt-BR':
        return 'DD/MM/YYYY';
      case 'ja':
      case 'sv-SE':
      case 'zh-TW':
        return 'YYYY/MM/DD';
      case 'en-US':
      default:
        return 'MM/DD/YYYY';
    }
  }

  /**
   * Given two date strings, converts them to dates and returns the result of subtracting
   * the second date from the first date.
   * If either date string is not a valid date, returns null.
   * @param {string} dateStr1
   * @param {string} dateStr2
   * @return {number|null}
   */
  static compareDateStrings(dateStr1: string, dateStr2: string) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    if (date1.toString() === 'Invalid Date' || date2.toString() === 'Invalid Date') {
      return null;
    }
    return date1.getTime() - date2.getTime();
  }

  /**
   * Returns true if the date represented by dateStr is in the future. Returns false otherwise.
   * @param {string} dateStr Date string in 'YYYY-MM-DD' or 'MM/DD/YYYY' format
   * @return {boolean}
   */
  static isFutureDate(dateStr: string) {
    const date = new Date(dateStr);
    if (date.toDateString() === 'Invalid Date') {
      return false;
    }
    const now = new Date();
    return now.getTime() - date.getTime() < 0;
  }

  static isValidDateRange(date: string) {
    const dateFormat1 = date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
    const dateFormat2 = date.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
    let year;
    if (dateFormat1) {
      year = date.substring(0, 4);
    }
    if (dateFormat2) {
      year = date.substring(6);
    }
    if (!year) {
      return false;
    }
    return parseInt(year, 0) >= 1000 && parseInt(year, 0) <= 9999;
  }

  // DEPRECATED in favor of using Date object and checking for invalid date
  /**
   * Returns true if dateStr is in YYYY-MM-DD or MM/DD/YYYY format and has
   * valid digits for YYYY, MM and DD.  Returns false otherwise.
   * @param {string} strDate
   * @return {boolean}
   */
  /*
  static isValidDateDigit(dateStr) {
    const dateFormat1 = dateStr.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
    const dateFormat2 = dateStr.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
    if (dateFormat1 || dateFormat2) {
      const dateDigits = parseInt(dateStr.substring(3, 5), 10);
      const monthDigits = parseInt(dateStr.substring(0, 2), 10);
      const yearDigits = parseInt(dateStr.substring(6), 10);
      switch (monthDigits) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return dateDigits <= 31 && dateDigits > 0;
        case 4:
        case 6:
        case 9:
        case 11:
          return dateDigits <= 30 && dateDigits > 0;
        case 2:
          return this.isLeapYear(yearDigits) ? dateDigits <= 29 && dateDigits > 0 : dateDigits <= 28 && dateDigits > 0;
        default:
          return false;
      }
    }
    return false;
  }
*/

  // DEPRECATED
  /**
   * Returns true if the specified year is a leap year.  Returns false otherwise.
   * @param {number} year
   * @return {boolean}
   */
  /*
  static isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
  */

  /**
   * Returns a string representing localized date, based on langCode and options.
   *
   * @param {string} dateStr String representation of a date
   * @param {string} langCode Language code used to determine how to format the date
   * @param {string} defaultVal Default value to return if the dateStr is an invalid date
   * @param {object} options Object literal that specifies how to represent month, day, year, etc.
   * @returns {string} formatted string representing a date
   */
  static localizeStrDate(
    dateStr: string,
    langCode = 'en-US',
    defaultVal = '',
    options: Intl.DateTimeFormatOptions = {}
  ) {
    if (!dateStr) {
      return defaultVal;
    }

    const date = new Date(dateStr);
    if (Number.isNaN(date.getUTCDate())) {
      return defaultVal;
    }

    if (options.timeZone === undefined) {
      options.timeZone = 'UTC';
    }

    const dateTimeFormat = Intl.DateTimeFormat(langCode, options);
    let formattedDate: string;
    try {
      formattedDate = dateTimeFormat.format(date);
    } catch (e) {
      const error = e as Error;
      formattedDate = defaultVal;
      utils.log({
        feature: 'localizeStrDate',
        actionType: 'dateTimeFormat',
        error: error.toString(),
        startTime: Date.now(),
        clientId: window.isDwm ? 'dwm' : 'dsp',
        message: `dateTimeFormat method is failing to convert date: ${dateStr}`,
      });
    }
    return formattedDate;
    // MEMX-5160 known discrepancy between ie11 and chrome for jp langCode
    // ie11 uses characters to separate month, day, year.  Chrome uses / separator.
  }

  /**
   * Formats full date using dashes.  Used by transactions date filter.
   * @param {string} dateStr String representation of a date
   * @returns {string} String representation of date using dashes.
   */
  static formatFullDate(dateStr: string) {
    let result = null;
    const dateFormat1 = dateStr.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
    const dateFormat2 = dateStr.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
    if (dateFormat1) {
      const dateDigits = dateStr.split('-');
      result = `${dateDigits[1]}/${dateDigits[2]}/${dateDigits[0]}`;
    }
    if (dateFormat2) {
      const dateDigits = dateStr.split('/');
      result = `${dateDigits[2]}-${dateDigits[0]}-${dateDigits[1]}`;
    }
    return result;
  }

  /**
   * Converts any recognizable date string to localized date string with
   * short month, numeric day, numeric year.  For en-US, it would be MMM D, YYYY
   *
   * @param {string} dateStr String representation of a date
   * @param {string} langCode Language code used to determine how to format the date
   * @param {string} defaultVal Default value to return in case date is invalid
   * @return {string}
   */
  static formatDateShortMonth(dateStr: string, langCode = 'en-US', defaultVal = '') {
    const options: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric', year: 'numeric'};
    return dateUtils.localizeStrDate(dateStr, langCode, defaultVal, options);
  }

  /**
   * Converts any recognizable date string to localized date string with numeric
   * month, numeric day, and numeric year.  For en-US, it would be MM/DD/YYYY.
   * For example, January 2, 2019 would be 1/2/2019.
   *
   * @param {string} dateStr String representation of a date
   * @param {string} langCode Language code used to determine how to format the date
   * @param {string} defaultVal Default value to return in case date is invalid
   * @return {string} localized representation of date with numeric month, numeric day, and numeric year
   */
  static convertDateToSlashFormat(dateStr: string, langCode = 'en-US', defaultVal = '') {
    const options: Intl.DateTimeFormatOptions = {month: '2-digit', day: '2-digit', year: 'numeric'};
    return dateUtils.localizeStrDate(dateStr, langCode, defaultVal, options);
  }

  /**
   * Returns the timestamp converted into a localized date string.
   * @param {number} timestamp Timestamp representation of a date
   * @param {string} langCode Language code used to determine how to format the time
   * @return {string} endDate Format is 8:00 AM.
   */
  static convertDateToTimeStamp(timestamp: number, langCode = 'en-US') {
    return new Date(timestamp).toLocaleString(langCode, {hour: 'numeric', minute: 'numeric', hour12: true});
  }

  /**
   * Returns startDate in required format based on the past number of days passed
   *
   * @param {number} pastNumberOfDays past number of days.
   * @param {Date} dateOverride
   * @return {string} Date string in YYYY-MM-DD format. if current date is 2023-07-10, getStartDate(7) will return 2023-07-04
   */
  static getStartDate(pastNumberOfDays = 1, dateOverride?: Date) {
    const initialDate = dateOverride ? new Date(dateOverride) : new Date();
    initialDate.setDate(initialDate.getDate() - pastNumberOfDays + 1);
    const startDay = initialDate.getDate();
    const startDayPadded = startDay > 9 ? startDay : `0${startDay}`;
    const startMonth = initialDate.getMonth() + 1;
    const startMonthPadded = startMonth > 9 ? startMonth : `0${startMonth}`;
    const startYear = initialDate.getFullYear();
    return `${startYear}-${startMonthPadded}-${startDayPadded}`;
  }

  /**
   * Returns the endDate, essentially today's date, in YYYY-MM-DD format.
   *
   * @param {Date} dateOverride Optional initial date
   * @return {string} Date string in YYYY-MM-DD format
   */
  static getEndDate(dateOverride?: Date) {
    const initialDate = dateOverride ? new Date(dateOverride) : new Date();
    const endDate = initialDate.getDate();
    const endDayPadded = endDate > 9 ? endDate : `0${endDate}`;
    const endMonth = initialDate.getMonth() + 1;
    const endMonthPadded = endMonth > 9 ? endMonth : `0${endMonth}`;
    const endYear = initialDate.getFullYear();
    return `${endYear}-${endMonthPadded}-${endDayPadded}`;
  }

  /**
   * Determine whether the two inputs are Date objects that fall on the same day.
   *
   * @param {Date} date1
   * @param {Date} date2
   * @return {boolean} true if the dates are on the same day
   */
  static isSameDay(date1: Date, date2: Date) {
    // check if we're dealing with date objects...if not, just return false;
    if (!date1 || !date2 || typeof date1.getUTCDate !== 'function' || typeof date2.getUTCDate !== 'function') {
      return false;
    }

    return (
      date1.getUTCDate() === date2.getUTCDate() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCFullYear() === date2.getUTCFullYear()
    );
  }

  /**
   * Determine whether the input is a Date object that falls on today's date.
   *
   * Link to one solution:
   *    https://flaviocopes.com/how-to-determine-date-is-today-javascript/
   *
   * @param {Date} someDate
   * @return {boolean}
   */
  static isToday(someDate: Date) {
    const todayEpochtimestamp = new Date().getTime();
    const today = new Date(todayEpochtimestamp);

    return dateUtils.isSameDay(someDate, today);
  }

  /**
   * Determine whether the input is a Date object that falls on yesterday's date.
   *
   * @param {Date} someDate
   * @return {boolean}
   */
  static isYesterday(someDate: Date) {
    const todayEpochTimestamp = new Date().getTime();
    const yesterdayEpochTimestamp = todayEpochTimestamp - 24 * 60 * 60 * 1000;
    const yesterday = new Date(yesterdayEpochTimestamp);

    return dateUtils.isSameDay(someDate, yesterday);
  }

  /**
   * Returns an array of month names: Jan, Feb, Mar, ...
   *
   * @param localizedStringBundle {localizedStringBundle}
   * @return {string[]}
   */
  static getMonthArr(localizedStringBundle: StringMap) {
    const monthArr = [
      localizedStringBundle.JAN,
      localizedStringBundle.FEB,
      localizedStringBundle.MAR,
      localizedStringBundle.APR,
      localizedStringBundle.MAY,
      localizedStringBundle.JUN,
      localizedStringBundle.JUL,
      localizedStringBundle.AUG,
      localizedStringBundle.SEP,
      localizedStringBundle.OCT,
      localizedStringBundle.NOV,
      localizedStringBundle.DEC,
    ];
    return monthArr.map((month) => (month ? month.charAt(0).toUpperCase() + month.slice(1).toLowerCase() : ''));
  }

  static dateEasyFormatted(timestamp: string | number | Date, localizedStringBundle: StringMap) {
    const months = this.getMonthArr(localizedStringBundle);
    let pastDate;

    if (typeof timestamp === 'string' || typeof timestamp === 'number') {
      const timestampEpochtime = new Date(timestamp).getTime();
      pastDate = new Date(timestampEpochtime);
    } else if (typeof timestamp === 'object') {
      pastDate = timestamp;
    } else {
      return '';
    }
    const displayDay = pastDate ? pastDate.getUTCDate() : '';
    const displayMonth = pastDate ? pastDate.getUTCMonth() : 0;
    // const displayMonth = pastDate ? parseInt(pastDate.getUTCMonth(), 10) + 1 : 0;
    if (displayDay === 0) {
      return '';
    }

    const displayMonthText = pastDate ? months[displayMonth - 1] : '';
    const displayYear = pastDate ? pastDate.getUTCFullYear() : '';

    const currentYear = new Date().getUTCFullYear();

    if (dateUtils.isToday(pastDate)) {
      return localizedStringBundle.TODAY;
    } else if (dateUtils.isYesterday(pastDate)) {
      return localizedStringBundle.YESTERDAY;
    } else if (currentYear === displayYear) {
      return `${displayMonthText} ${displayDay}`;
    }
    return `${displayMonthText} ${displayDay}, ${displayYear}`;
  }

  /**
   * Returns a localized masked representation of a date using &#9679 as the placeholder character.
   * @param {string} langCode
   * @returns {string}
   */
  static maskedDate(langCode: string) {
    return dateUtils.localizeStrDate('10/15/2019', langCode, '').replace(/[0-9]/g, '&#9679;');
  }

  /**
   * Given a date string, returns a date string in MM/DD/YYYY with leading 0's
   * such that 1/1/2021 becomes 01/01/2021
   * @param {string} dateStr
   * @returns {string}
   */
  static formatDateToTwoNumber(dateStr: string) {
    return dateStr.replace(/\b(\d\/)/g, '0$1');
  }

  /**
   * Returns the Date that is n months prior to the input date, taking into account that months do not all have the same number of days
   * @param {Date} date
   * @param {number} n - number of months prior to get the date for
   * @returns {Date}
   */
  static getDateNMonthsPrior(date: Date, n: number) {
    const d = new Date(date);
    const m = d.getMonth();
    d.setMonth(d.getMonth() - n);
    // If the new month number is less than n months prior, set to last day of previous month
    const diff = (m + 12 - d.getMonth()) % 12;
    if (diff < n) d.setDate(0);

    return d;
  }

  /**
   * Returns a new date object with time values set to 0
   * @param {Date} date - Date to strip of time values
   * @returns {Date} - Date with time values set to 0
   */
  static getDateWithoutTime(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  /**
   * Calculates the number of days from the date to the current date.
   *
   * @param {string} date - The start date.
   * @returns {number} The number of days from the date to the current date.
   */
  static calculateDaysFromStart(date: string): number {
    const currentDate = new Date();
    const startDate = new Date(date);
    const differenceInMilliseconds = currentDate.getTime() - startDate.getTime();
    return Math.floor(differenceInMilliseconds / MILLISECONDS_PER_DAY);
  }

  /**
   * Returns the number of months between two dates
   * @param {Date} date1
   * @param {Date} date2
   * @returns {number}
   */
  static getDifferenceInMonths(date1: Date, date2: Date): number {
    return Math.abs(date2.getMonth() - date1.getMonth() + 12 * (date2.getFullYear() - date1.getFullYear()));
  }

  /**
   * Returns the difference between two dates as an object
   * It converts dates to a 'YYYY-MM-DD' format, ignoring HH:MM:SS, and takes an optional endDate or uses the current date
   * @param {Date} startingDate
   * @param {Date} endingDate
   * @returns {DateDiff}
   */
  static dateDiff(startingDate: Date, endingDate: Date = new Date()) {
    // Convert dates to YYYY-MM-DD format
    let startDate = new Date(startingDate.toISOString().substr(0, 10));
    let endDate = new Date(endingDate.toISOString().substr(0, 10));

    // Swap dates if startDate is later than endDate
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }

    // Calculate the difference in years, months, and days
    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    let dayDiff = endDate.getDate() - startDate.getDate();

    // Adjust months and years if necessary
    if (dayDiff < 0) {
      monthDiff--;
      const previousMonth = endDate.getMonth() === 0 ? 11 : endDate.getMonth() - 1;
      const previousYear = previousMonth === 11 ? endDate.getFullYear() - 1 : endDate.getFullYear();
      const daysInPreviousMonth = new Date(previousYear, previousMonth + 1, 0).getDate();
      dayDiff += daysInPreviousMonth;
    }

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    return {
      years: yearDiff,
      months: monthDiff,
      days: dayDiff,
    };
  }

  /**
   * Returns a string in the format of "X years, Y months".
   * Used with the `dateDiff` method to format the string, does not use days.
   * @param {DateDiff} dateDiff
   * @param {StringMap} stringBundle
   * @returns {string}
   */
  static createDateDiffStr = (dateDiff: DateDiff, stringBundle: StringMap) => {
    const years = dateDiff.years;
    const months = dateDiff.months;
    let diffStr = '';

    if (years > 0) {
      if (years > 1) diffStr += `${years} ${stringBundle.YEARS}`;
      else diffStr += `${years} ${stringBundle.YEAR}`;

      if (dateDiff.months > 0) diffStr += ', ';
    }
    if (months > 0) {
      if (months > 1) diffStr += `${months} ${stringBundle.MONTHS}`;
      else diffStr += `${months} ${stringBundle.MONTH}`;
    }
    return diffStr;
  };

  /**
   * Converts a given number of days into years, months, and days.
   *
   * @param {number} days - The total number of days to convert.
   * @returns {Object} An object containing the equivalent years, months, and remaining days.
   * @returns {number} return.years - The number of full years.
   * @returns {number} return.months - The number of full months after accounting for years.
   * @returns {number} return.days - The remaining number of days after accounting for years and months.
   */
  static convertDaysToMonthsYears(days: number) {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const daysLeft = days % 30;
    return {years, months, days: daysLeft};
  }
}
