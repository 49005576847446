import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const SMM_ACCOUNTS_REQUEST = 'SMM_ACCOUNTS_REQUEST';
export const SMM_ACCOUNTS_SUCCESS = 'SMM_ACCOUNTS_SUCCESS';
export const SMM_ACCOUNTS_FAILURE = 'SMM_ACCOUNTS_FAILURE';
export const SMM_REDIRECT_URL_FAILURE = 'SMM_REDIRECT_URL_FAILURE';
export const SMM_REDIRECT_URL_REQUEST = 'SMM_REDIRECT_URL_REQUEST';
export const SMM_REDIRECT_URL_SUCCESS = 'SMM_REDIRECT_URL_SUCCESS';
export const SMM_REDIRECT_URL_RESET = 'SMM_REDIRECT_URL_RESET';
export const SMM_RECONNECT_REDIRECT_URL_FAILURE = 'SMM_RECONNECT_REDIRECT_URL_FAILURE';
export const SMM_RECONNECT_REDIRECT_URL_REQUEST = 'SMM_RECONNECT_REDIRECT_URL_REQUEST';
export const SMM_RECONNECT_REDIRECT_URL_SUCCESS = 'SMM_RECONNECT_REDIRECT_URL_SUCCESS';
export const SMM_RECONNECT_REDIRECT_URL_RESET = 'SMM_RECONNECT_REDIRECT_URL_RESET';
export const SMM_DELETE_ACCOUNT_FAILURE = 'SMM_DELETE_ACCOUNT_FAILURE';
export const SMM_DELETE_ACCOUNT_REQUEST = 'SMM_DELETE_ACCOUNT_REQUEST';
export const SMM_DELETE_ACCOUNT_SUCCESS = 'SMM_DELETE_ACCOUNT_SUCCESS';
export const SMM_DELETE_ACCOUNT_RESET = 'SMM_DELETE_ACCOUNT_RESET';
export const SMM_ONBOARD_STATUS_FAILURE = 'SMM_ONBOARD_STATUS_FAILURE';
export const SMM_ONBOARD_STATUS_REQUEST = 'SMM_ONBOARD_STATUS_REQUEST';
export const SMM_ONBOARD_STATUS_SUCCESS = 'SMM_ONBOARD_STATUS_SUCCESS';
export const SMM_UPDATE_ONBOARD_STATUS_FAILURE = 'SMM_UPDATE_ONBOARD_STATUS_FAILURE';
export const SMM_UPDATE_ONBOARD_STATUS_REQUEST = 'SMM_UPDATE_ONBOARD_STATUS_REQUEST';
export const SMM_UPDATE_ONBOARD_STATUS_SUCCESS = 'SMM_UPDATE_ONBOARD_STATUS_SUCCESS';
export const SMM_ALERT_UPDATE_FAILURE = 'SMM_ALERT_UPDATE_FAILURE';
export const SMM_ALERT_UPDATE_REQUEST = 'SMM_ALERT_UPDATE_REQUEST';
export const SMM_ALERT_UPDATE_SUCCESS = 'SMM_ALERT_UPDATE_SUCCESS';
export const SMM_ALERT_UPDATE_RESET = 'SMM_ALERT_UPDATE_RESET';

type SocialMediaAccount = {
  id: string;
  network: string;
  partner: string;
  providerDisplayName: string;
  username: string;
  status: string;
  facebookWarningShown: boolean;
};

type AccountsSuccessPayload = {
  primaryMember: {
    accountId: string;
    socialMediaAccounts: [SocialMediaAccount];
  };
  juniorMembers: [
    {
      accountId: string;
      socialMediaAccounts: [SocialMediaAccount];
    },
  ];
};

type RedirectUrlSuccessPayload = {
  redirect_url: string;
};

type AccountDeletedPayload = {
  accountDeleted: boolean;
};

type AccountDeletedOptions = {
  onlineAccountId: string;
  userAccountId: string;
  providerDisplayName: string;
};

type OnboardPathSuccessPayload = {
  onboarded: boolean;
};

// Get Accounts
export function getSmmAccounts(userId: string) {
  const url = utils.getSmmAccounts(userId);
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, getAccountsRequest(), getAccountsSuccess, getAccountsFailure);
}

function getAccountsRequest() {
  return {
    type: SMM_ACCOUNTS_REQUEST,
  };
}

function getAccountsSuccess(payload: AccountsSuccessPayload) {
  const payloadData = {
    primaryMember: _get(payload, 'primaryMember', {}),
    juniorMembers: _get(payload, 'juniorMembers', []),
    config: _get(payload, 'config', {}),
  };
  return {
    type: SMM_ACCOUNTS_SUCCESS,
    accounts: !_isEmpty(payload.primaryMember) ? payloadData : {},
  };
}

function getAccountsFailure(error: ObjectMap) {
  return {
    type: SMM_ACCOUNTS_FAILURE,
    error,
  };
}

// Get Redirect Url for Add Account
export function getRedirectUrl(userAccountId: string | object, network: string) {
  const url = utils.addSmmAccount(userAccountId, network);
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, redirectUrlRequest(), redirectUrlSuccess, redirectUrlFailure);
}

function redirectUrlRequest() {
  return {
    type: SMM_REDIRECT_URL_REQUEST,
  };
}

function redirectUrlSuccess(payload: RedirectUrlSuccessPayload) {
  return {
    type: SMM_REDIRECT_URL_SUCCESS,
    redirectUrl: payload.redirect_url,
  };
}

function redirectUrlFailure(error: ObjectMap) {
  return {
    type: SMM_REDIRECT_URL_FAILURE,
    error,
  };
}

export function resetRedirectUrlStatus() {
  return {
    type: SMM_REDIRECT_URL_RESET,
  };
}

// Get Redirect Url for Reconnect Account
export function getReconnectRedirectUrl(userAccountId: string, onlineAccountId: string, accountNetwork: string) {
  const url = utils.reconnectSmmAccount(userAccountId, onlineAccountId, accountNetwork);
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, reconnectRedirectUrlRequest(), reconnectRedirectUrlSuccess, reconnectRedirectUrlFailure);
}

function reconnectRedirectUrlRequest() {
  return {
    type: SMM_RECONNECT_REDIRECT_URL_REQUEST,
  };
}

function reconnectRedirectUrlSuccess(payload: RedirectUrlSuccessPayload) {
  return {
    type: SMM_RECONNECT_REDIRECT_URL_SUCCESS,
    redirectUrl: payload.redirect_url,
  };
}

function reconnectRedirectUrlFailure(error: ObjectMap) {
  return {
    type: SMM_RECONNECT_REDIRECT_URL_FAILURE,
    error,
  };
}

export function resetReconnectRedirectUrlStatus() {
  return {
    type: SMM_RECONNECT_REDIRECT_URL_RESET,
  };
}

// Delete Account
export function deleteAccount(
  userAccountId: string | undefined,
  onlineAccountId: string | undefined,
  providerDisplayName: string | undefined
) {
  const url = utils.deleteSmmAccount(userAccountId, onlineAccountId, providerDisplayName);
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };
  return api(url, config, deleteRequest(), deleteSuccess, deleteFailure, {
    onlineAccountId,
    userAccountId,
    providerDisplayName,
  });
}

function deleteRequest() {
  return {
    type: SMM_DELETE_ACCOUNT_REQUEST,
  };
}

function deleteSuccess(payload: AccountDeletedPayload, options: AccountDeletedOptions) {
  return {
    type: SMM_DELETE_ACCOUNT_SUCCESS,
    deleteAccount: payload.accountDeleted,
    onlineAccountId: options.onlineAccountId,
    userAccountId: options.userAccountId,
  };
}

function deleteFailure(error: ObjectMap) {
  return {
    type: SMM_DELETE_ACCOUNT_FAILURE,
    error,
  };
}

// Get SMM onBoard Status
export function getSmmOnboardStatus() {
  const url = utils.getSmmOnboardPath();
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, smmOnboardPathRequest(), smmOnboardPathSuccess, smmOnboardPathFailure);
}

function smmOnboardPathRequest() {
  return {
    type: SMM_ONBOARD_STATUS_REQUEST,
  };
}

function smmOnboardPathSuccess(payload: OnboardPathSuccessPayload) {
  return {
    type: SMM_ONBOARD_STATUS_SUCCESS,
    onboard: payload.onboarded,
  };
}

function smmOnboardPathFailure(error: ObjectMap) {
  return {
    type: SMM_ONBOARD_STATUS_FAILURE,
    error,
  };
}

// Update SMM onBoard Status
export function updateSmmOnboardStatus(
  fbWarnBanner: {
    facebookWarningShown: boolean;
    onlineAccountId: string;
  } | null = null
) {
  const url = utils.getSmmOnboardPath();
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };
  if (fbWarnBanner) {
    config['body'] = JSON.stringify({
      facebookWarningShown: fbWarnBanner.facebookWarningShown,
      onlineAccountId: fbWarnBanner.onlineAccountId,
    });
  }
  return api(url, config, updateOnboardPathRequest(), updateOnboardPathSuccess, updateOnboardPathFailure);
}

function updateOnboardPathRequest() {
  return {
    type: SMM_UPDATE_ONBOARD_STATUS_REQUEST,
  };
}

function updateOnboardPathSuccess(payload: OnboardPathSuccessPayload) {
  return {
    type: SMM_UPDATE_ONBOARD_STATUS_SUCCESS,
    onboard: payload,
  };
}

function updateOnboardPathFailure(error: ObjectMap) {
  return {
    type: SMM_UPDATE_ONBOARD_STATUS_FAILURE,
    error,
  };
}

export function resetDeleteStatus() {
  return {
    type: SMM_DELETE_ACCOUNT_RESET,
  };
}

// Get SMM Alert Update
export function smmAlertUpdate(accountId: string, alertId: string, actionType: string) {
  const url = utils.smmAlertUpdate();

  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      account_id: accountId,
    },
    credentials: 'include',
    body: JSON.stringify({
      alertId,
      actionType,
    }),
  };
  return api(url, config, smmAlertUpdateRequest(), smmAlertUpdateSuccess, smmAlertUpdateFailure);
}

function smmAlertUpdateRequest() {
  return {
    type: SMM_ALERT_UPDATE_REQUEST,
  };
}

function smmAlertUpdateFailure(error: ObjectMap) {
  return {
    type: SMM_ALERT_UPDATE_FAILURE,
    error,
  };
}

function smmAlertUpdateSuccess() {
  return {
    type: SMM_ALERT_UPDATE_SUCCESS,
  };
}

export function resetSmmAlertUpdateStatus() {
  return {
    type: SMM_ALERT_UPDATE_RESET,
  };
}
