// These error codes are in-sync with member-api error codes
// Exception for IS_LOGGED_IN_ERROR
export default {
  IS_LOGGED_IN_ERROR: 100,
  ONLINE_ACCOUNT_MONITORING_NOT_FULFILLED: 10009,
  API_RATE_LIMIT_HIT: 120011,
  NGP_HASH_MISMATCH: 80007,
  INVALID_NGP_ACCESS_TOKEN: 80013,
  OIDC_EXCHANGE_TOKEN_ERROR: 80014,
  MISSING_MAY_ACT_CLAIM_ERROR: 80015,
  AUTH_NO_ACCOUNT_FOUND: 20008,
  AUTH_NO_ACCOUNT_FOUND_APPLE_RELAY_EMAIL: 20009,
  AUTH_LSA_AND_FCRA_BOTH_NOT_ACCEPTED: 20010,
  AUTH_LSA_ACCEPTED_AND_FCRA_NOT_ACCEPTED: 20012,
  AUTH_LSA_REQUIRED_NOT_ACCEPTED: 20014,
  LOGIN_INACTIVE: 32000,
  LOGIN_BLOCKED_STATUS: 32010,
  LOGIN_EXPIRED_STATUS: 32020,
  LOGIN_CANCELLED_STATUS: 32030,
  LOGIN_PENDING_MIGRATION_STATUS: 32040,
  LOGIN_PROFILE_PENDING_SETUP: 32050,
  FULFILLMENT_NONRETRYABBLE_ERROR_CODE: 550150,
  FULFILLMENT_TRYABBLE_ERROR_CODE: 550152,
  PAYDAY_DEFAULT_ERROR_CODE: 160020,
};
