import {SET_QUEBEC_OPT_IN_REQUEST, SET_QUEBEC_OPT_IN_SUCCESS, SET_QUEBEC_OPT_IN_FAILURE} from '../actions/quebecOptIn';

type Action = {
  type: string;
  consent: boolean;
  error: object;
};

const initialState = {
  isQuebecModalShown: false,
  consent: false,
  quebecOptInErr: {},
};

const quebecOptIn = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_QUEBEC_OPT_IN_REQUEST:
      return Object.assign({}, state, {});
    case SET_QUEBEC_OPT_IN_SUCCESS:
      return Object.assign({}, state, {consent: action.consent, isQuebecModalShown: true});
    case SET_QUEBEC_OPT_IN_FAILURE:
      return Object.assign({}, state, {quebecOptInErr: action.error, isQuebecModalShown: true});
    default:
      return state;
  }
};
export default quebecOptIn;
