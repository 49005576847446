import utils from '../helpers/utils';
import {api} from '../helpers/api';

export const SET_QUEBEC_OPT_IN_REQUEST = 'SET_QUEBEC_OPT_IN_REQUEST';
export const SET_QUEBEC_OPT_IN_SUCCESS = 'SET_QUEBEC_OPT_IN_SUCCESS';
export const SET_QUEBEC_OPT_IN_FAILURE = 'SET_QUEBEC_OPT_IN_FAILURE';

/**
 * Set quebec opt in request
 *
 * @return {Object} - Returns a redux action to handle request
 */
export const setQuebecOptInRequest = () => ({
  type: SET_QUEBEC_OPT_IN_REQUEST,
});

/**
 * Set quebec opt in success
 *
 * @return {Object} -  Returns a redux action to handle success
 */
export const setQuebecOptInSuccess = () => ({
  type: SET_QUEBEC_OPT_IN_SUCCESS,
  consent: true,
});

/**
 * Set quebec opt in error
 *
 * @return {Object} -  Returns a redux action to handle error
 */
export const setQuebecOptInFailure = (error: $TSFixMe) => ({
  type: SET_QUEBEC_OPT_IN_FAILURE,
  error,
});

/**
 *	Method for setting Quebec reimbursement consent
 * @param {boolean} consent - boolean consent for reimbursement opt in
 * @return {function} - Thunk to be dispatched
 */
export function setQuebecOptInConsent(consent: boolean) {
  const url: string = utils.getQuebecOptInPath();
  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    body: JSON.stringify({
      consent: consent,
    }),
    credentials: 'include',
  };

  return api(url, config, setQuebecOptInRequest(), setQuebecOptInSuccess, setQuebecOptInFailure);
}
