import log from '../helpers/log';
//import errorCodes from './errorCodes';
import _cloneDeep from 'lodash/cloneDeep';
import {getTrackingValues} from './tracking';
import errorCodes from './errorCodes';

const POST_CONFIG: FetchConfig = Object.freeze({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Todo - have client_id as dsp when window.mode config and dwm bundling are removed.
    client_id: window.MODE === 'DWM' ? 'dwm' : 'dsp',
  },
  credentials: 'include',
});

// commenting out unused methods, to be removed after regression testing
/*
const GET_CONFIG = Object.freeze({
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    client_id: window.MODE === 'DWM' ? 'dwm' : 'dsp',
  },
  credentials: 'include',
});
*/
/**
 * Calls member-api to determine if user is already logged in.
 */
/*
export function isLoggedIn() {
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_IS_LOGGED_IN_PATH;

  return fetch(url, POST_CONFIG)
    .then(function(fetchObj) {
      if (!fetchObj.ok) {
        let err = new Error('is logged in fetch failed');
        err.code = errorCodes.IS_LOGGED_IN_ERROR;
        return Promise.reject(err);
      }
      return fetchObj.json();
    })
    .then(function(response) {
      return response.loggedIn;
    });
}
*/

/**
 * Calls member-api endpoint to retrieve member info.
 * A valid member-api session must have been established beforehand.
 * See "authWithAccessCode"
 */
/*
export function getMemberInfo() {
  // necessary to load member data before getting alert data
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_MEMBERS_PATH;

  return fetch(url, GET_CONFIG).then(function(fetchObj) {
    log.info('getMemberInfo response=', fetchObj);
    return fetchObj.json();
  });
}
*/

/**
 * Calls member-api endpoint to retrieve alert data.
 * "getMemberInfo" must have been called beforehand.
 */
/*
export function getAlertList() {
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_ALERTS_PATH + '?bucket=inbox';

  // not passing account id defaults to primary member's alerts

  return fetch(url, GET_CONFIG)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      log.info('alertList response=', data);

      return data;
    });
}
*/

/**
 * Calls member-api endpoint to retrieve details for a single alert.
 * @param alertId
 * @returns {Promise} - the alert details
 */
/*
export function getAlertDetail(alertId) {
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_ALERT_DETAIL_PATH + '/' + alertId;
  return fetch(url, GET_CONFIG)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      log.info('alert detail=', data);
      return data;
    });
}
*/
/*
export function logToServer(data) {
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_LOG_PATH;
  let postConfig = {body: JSON.stringify([data])};
  Object.assign(postConfig, POST_CONFIG);
  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      return data;
    });
}
*/

/**
 * Checks if the client has an active session on member-api that has
 * data matching hashes in ngpData.
 * Returns true if both conditions are met, false otherwise.
 *
 * @return {Promise<boolean>}
 */
export function checkUser() {
  const url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_CHECK_USER_PATH;
  const ngpData = getTrackingValues();

  // Todo - have spaBundle as DSP when window.mode config and dwm bundling are removed.
  const postConfig: FetchConfig = _cloneDeep(POST_CONFIG);
  postConfig.body = JSON.stringify({spaBundle: window.MODE});
  postConfig.headers.ngp_user_hash = ngpData.ngpUserHash; // header names are lowercase
  postConfig.headers.ngp_session_hash = ngpData.ngpSessionHash;
  postConfig.headers['x-nlok-trace-id'] = ngpData.NLokTraceId;
  postConfig.headers['x-nlok-tenant-id'] = ngpData.tenantId;
  postConfig.headers['x-nlok-tenant-name'] = ngpData.tenantName;
  postConfig.headers['x-nlok-p-code'] = ngpData.pCode;
  // TODO dry up fetch-parse-throw logic
  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      if (data.isValidSession === false || data.errorCode || data.errorMessage) {
        const error = new Error();
        (error as $TSFixMe).errorCode = data.errorCode || errorCodes.NGP_HASH_MISMATCH;
        (error as $TSFixMe).errorMessage = data.errorMessage;

        throw error;
      }
    });
}

/**
 * Initiates call to member-api to obtain lifelock access token from ngp access token and set session
 * @param {string} ngpAccessToken
 * @return {Promise<Response>}
 */
export function startTokenExchange(ngpAccessToken: string) {
  const url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_OIDC_TOKEN_EXCHANGE;
  const ngpData = getTrackingValues();
  const postConfig: FetchConfig = _cloneDeep(POST_CONFIG);
  postConfig.body = JSON.stringify({access_token: ngpAccessToken, spaBundle: window.MODE});
  postConfig.headers.ngp_user_hash = ngpData.ngpUserHash; // header names are lowercase
  postConfig.headers.ngp_session_hash = ngpData.ngpSessionHash;
  postConfig.headers['x-nlok-trace-id'] = ngpData.NLokTraceId;
  postConfig.headers['x-nlok-tenant-id'] = ngpData.tenantId;
  postConfig.headers['x-nlok-tenant-name'] = ngpData.tenantName;
  postConfig.headers['x-nlok-p-code'] = ngpData.pCode;

  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      if (data.status === 'ok') {
        let whiteLabelClient;
        if (typeof data.whiteLabelClient === 'string') {
          whiteLabelClient = data.whiteLabelClient.toLowerCase();
        }
        sessionStorage.setItem('whiteLabelClient', whiteLabelClient);

        return {
          type: 'LOGGED_IN',
          payload: {
            hasUserNameConflict: data.hasUsernameConflict,
            showLifeLockWelcome: data.showLifeLockWelcome,
            needLifeLockTermsConditions: data.needLifeLockTermsConditions,
            whiteLabelClient,
          },
        };
      } else {
        return {type: 'LOGIN_ERROR', payload: data.error};
      }
    })
    .catch((error) => {
      log.debug('nsl token exchange error=', error);
      return {type: 'LOGIN_ERROR', payload: {}};
    });
}

/**
 * Initiates call to member-api to obtain NSL url that the SPA
 * should call to confirm the Norton user is logged in.
 * @return {Promise<Response>}
 */
export function startNslHandshake() {
  // calls member-api to discover NSL url for requesting access code
  const url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_DISCOVER_NSL_PATH;
  const ngpData = getTrackingValues();

  // Todo - have spaBundle as DSP when window.mode config and dwm bundling are removed.
  const postConfig = {body: JSON.stringify({spaBundle: window.MODE})};
  Object.assign(postConfig, _cloneDeep(POST_CONFIG));
  (postConfig as $TSFixMe).headers.ngp_user_hash = ngpData.ngpUserHash; // header names are lowercase
  (postConfig as $TSFixMe).headers.ngp_session_hash = ngpData.ngpSessionHash;
  (postConfig as $TSFixMe).headers['x-nlok-trace-id'] = ngpData.NLokTraceId;

  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      log.debug('startnslhandshake data=', data);

      //getNslAccessCode(data.redirectUri); // no ajax allowed with google oidc
      (document.getElementById('authIframe') as $TSFixMe).src = data.authUrlWithRedirect; // will keep getting error when prompt=none in auth url and interaction required by IDP
      // will keep getting error when prompt=none in auth url and interaction required by IDP
      //window.open(data.authUrlWithRedirect);
    })
    .catch(function(error) {
      // TODO test url with //https://httpstat.us/500
      log.debug('nsl handshake error=', error);
    });
}

/**
 * Establishes session with member-api using ssoToken
 * @param {string} ssoToken
 */
export function startSso(ssoToken: string) {
  const url = window.REACT_APP_MEMBER_API_HOST + '/v1/auth/tokensBySsoToken';
  const postConfig = {body: JSON.stringify({include_member_data: true})}; // side effect of include_member_data=true is member-api session established
  Object.assign(postConfig, _cloneDeep(POST_CONFIG));
  (postConfig as $TSFixMe).headers.sso_token = ssoToken;
  (postConfig as $TSFixMe).headers['x-nlok-trace-id'] = getTrackingValues().NLokTraceId;

  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      if (data.errorCode) {
        const error = new Error();
        (error as $TSFixMe).errorCode = data.errorCode;
        throw error;
      }
    });
}

/**
 * Initiates call to Member API to establish session for partner user using NSL token.
 * @property {string} nslToken - NSL token used to establish a session for partner's user
 * @property {string} clientId - client identifier, used to identify the client calling Member API
 * @return {Promise<Response>}
 */
export function authWithNslToken(nslToken: string, clientId: string) {
  const url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_TOKENS_BY_NSL_TOKENS_PATH;
  const postConfig = {body: JSON.stringify({nsl_access_token: nslToken})};
  Object.assign(postConfig, _cloneDeep(POST_CONFIG));
  (postConfig as $TSFixMe).headers['client_id'] = clientId;
  return fetch(url, postConfig)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      if (data.primaryMember && typeof data.primaryMember === 'object') {
        return {
          type: 'PARTNER_LOGIN_SUCCESS',
          payload: {accessToken: data.access_token},
        };
      } else {
        return {type: 'PARTNER_LOGIN_ERROR', payload: data.error};
      }
    })
    .catch((error) => {
      log.debug('authWithNslToken error=', error);
      return {type: 'PARTNER_LOGIN_ERROR', payload: {}};
    });
}

/*
export function logout() {
  let url = window.REACT_APP_MEMBER_API_HOST + window.REACT_APP_LOGOUT_PATH;
  return fetch(url, GET_CONFIG)
    .then((fetchObj) => {
      return fetchObj.json();
    })
    .then((data) => {
      return data;
    });
}
*/

/*
export default {
  //getMemberInfo: getMemberInfo,
  //getAlertDetail: getAlertDetail,
  //getAlertList: getAlertList,
  //isLoggedIn: isLoggedIn,
  //logout: logout,
  //logToServer: logToServer,
  //startNslHandshake: startNslHandshake,
};
*/
