/**
 * Contains actions for transaction async calls.
 */
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _has from 'lodash/has';
import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';
export const TRANSACTION_OVERVIEW_SUCCESS = 'TRANSACTION_OVERVIEW_SUCCESS';
export const TRANSACTION_LIST_FAILURE = 'TRANSACTION_LIST_FAILURE';

export const MEMBER_ACCOUNTS_REQUEST = 'MEMBER_ACCOUNTS_REQUEST';
export const MEMBER_ACCOUNTS_SUCCESS = 'MEMBER_ACCOUNTS_SUCCESS';
export const MEMBER_ACCOUNTS_FAILURE = 'MEMBER_ACCOUNTS_FAILURE';

export const PAGINATE_TRANSACTIONS = 'PAGINATE_TRANSACTIONS';
export const FILTERED_TRANSACTIONS = 'FILTERED_TRANSACTIONS';
export const CURRENT_DATE_SELECTION = 'CURRENT_DATE_SELECTION';
export const CURRENT_ACCOUNT_SELECTION = 'CURRENT_ACCOUNT_SELECTION';

export const TXM_SET_ALERT_PREF_REQUEST = 'TXM_SET_ALERT_PREF_REQUEST';
export const TXM_SET_ALERT_PREF_SUCCESS = 'TXM_SET_ALERT_PREF_SUCCESS';
export const TXM_SET_ALERT_PREF_FAILURE = 'TXM_SET_ALERT_PREF_FAILURE';

export const TXM_SET_RECURRING_ALERT_OPT_REQUEST = 'TXM_SET_RECURRING_ALERT_OPT_REQUEST';
export const TXM_SET_RECURRING_ALERT_OPT_SUCCESS = 'TXM_SET_RECURRING_ALERT_OPT_SUCCESS';
export const TXM_SET_RECURRING_ALERT_OPT_FAILURE = 'TXM_SET_RECURRING_ALERT_OPT_FAILURE';

export const DELETE_INSTITUTION_LOGIN_REQUEST = 'DELETE_INSTITUTION_LOGIN_REQUEST';
export const DELETE_INSTITUTION_LOGIN_SUCCESS = 'DELETE_INSTITUTION_LOGIN_SUCCESS';
export const DELETE_INSTITUTION_LOGIN_FAILURE = 'DELETE_INSTITUTION_LOGIN_FAILURE';

export const GET_ACCESS_TOKEN_REQUEST = 'GET_ACCESS_TOKEN_REQUEST';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const GET_ACCESS_TOKEN_ERROR = 'GET_ACCESS_TOKEN_ERROR';

export type RecurringAlertOptPayLoad = {
  success: boolean;
};

export function deleteInstitutionLoginRequest() {
  return {
    type: DELETE_INSTITUTION_LOGIN_REQUEST,
  };
}

export function deleteInstitutionLoginSuccess(payload: $TSFixMe) {
  return {
    type: DELETE_INSTITUTION_LOGIN_SUCCESS,
    payload,
  };
}

export function deleteInstitutionLoginFailure(error: $TSFixMe) {
  return {
    type: DELETE_INSTITUTION_LOGIN_FAILURE,
    error,
  };
}

export function transactionListRequest() {
  return {
    type: TRANSACTION_LIST_REQUEST,
  };
}

export function transactionListSuccess(payload: $TSFixMe) {
  let transactions = {};
  let transactionsCount = 0;
  let lastUpdated = '';
  let last30DaysTransactionsCount = 0;
  let last30DaysRecurringTransactionsCount = 0;
  let last30DaysRecurringTransactionsTotal = 0;
  let recurringTransactions = [];
  let recurringTransactionsStatus = '';

  if (!_isEmpty(payload)) {
    transactions = payload.transactions;
    transactionsCount = _get(payload, 'totalRowCount', 0);
    last30DaysTransactionsCount = payload.last30DaysTransactionsCount;
    last30DaysRecurringTransactionsCount = payload.last30DaysRecurringTransactionsCount;
    last30DaysRecurringTransactionsTotal = payload.last30DaysRecurringTransactionsTotal;
    recurringTransactions = payload.recurringTransactions;
    recurringTransactionsStatus = payload.recurringTransactionsStatus;
    if (!_isEmpty(transactions)) {
      if (Array.isArray(transactions) && transactions[0].postedDate) {
        lastUpdated = transactions[0].postedDate;
      } else if ((transactions as $TSFixMe).postedDate) {
        lastUpdated = (transactions as $TSFixMe).postedDate;
      }
    }
  }

  if (!transactions) {
    transactions = payload.message;
  }

  if (_isEmpty(transactions)) {
    transactions = {noResults: 'No transactions found'};
  }

  return {
    type: TRANSACTION_LIST_SUCCESS,
    transactionList: transactions,
    transactionsCount,
    lastUpdated,
    last30DaysTransactionsCount,
    last30DaysRecurringTransactionsCount,
    last30DaysRecurringTransactionsTotal,
    recurringTransactions,
    recurringTransactionsStatus,
  };
}

export function transactionListSuccess4Overview(payload: $TSFixMe) {
  let transactions = {};
  if (!_isEmpty(payload)) {
    transactions = payload.transactions;
    if (!transactions) {
      transactions = payload.message;
    }

    if (_isEmpty(transactions)) {
      transactions = {noResults: 'No transactions found'};
    }
  }

  return {
    type: TRANSACTION_OVERVIEW_SUCCESS,
    transactionList4Overview: transactions,
  };
}

export function transactionListFailure(error: $TSFixMe) {
  return {
    type: TRANSACTION_LIST_FAILURE,
    error,
  };
}

export function memberAccountsRequest() {
  return {
    type: MEMBER_ACCOUNTS_REQUEST,
  };
}

export function memberAccountsSuccess(payload: $TSFixMe) {
  let institutionsList = [];
  let alertPreferences = [];
  let accounts = [];

  if (!_isEmpty(payload)) {
    institutionsList = _get(payload, 'institutions', []);
    alertPreferences = _get(payload, 'alertPreferences', []);
    accounts = _get(payload, 'accounts', []);
  }

  if (!Array.isArray(institutionsList)) institutionsList = [institutionsList];

  if (_isEmpty(institutionsList)) {
    // @ts-expect-error TS(2322) FIXME: Type '{ noResults: string; }' is not assignable to... Remove this comment to see the full error message
    institutionsList = {noResults: 'No institutions available for this user'};
  }

  return {
    type: MEMBER_ACCOUNTS_SUCCESS,
    institutionsList,
    alertPreferences,
    accounts,
  };
}

export function memberAccountsFailure(error: $TSFixMe) {
  return {
    type: MEMBER_ACCOUNTS_FAILURE,
    error,
  };
}

export function setRecurringBillsAlertOptRequest() {
  return {
    type: TXM_SET_RECURRING_ALERT_OPT_REQUEST,
  };
}

export function setRecurringBillsAlertOptSuccess(payload: RecurringAlertOptPayLoad) {
  if (!_isEmpty(payload)) {
    return {
      type: TXM_SET_RECURRING_ALERT_OPT_SUCCESS,
    };
  }
  return {
    type: TXM_SET_RECURRING_ALERT_OPT_FAILURE,
    error: 'error',
  };
}

export function setRecurringBillsAlertOptFailure() {
  return {
    type: TXM_SET_RECURRING_ALERT_OPT_FAILURE,
  };
}

export function setTransactionAlertPrefRequest() {
  return {
    type: TXM_SET_ALERT_PREF_REQUEST,
  };
}

export function setTransactionAlertPrefSuccess(payload: $TSFixMe) {
  if (!_isEmpty(payload)) {
    const alertPreference = [];
    alertPreference.push(
      {
        symbol: 'GREATER_THAN',
        type: 'PURCHASE',
        value: _get(payload, 'preferences.transaction'),
      },
      {
        symbol: 'GREATER_THAN',
        type: 'TRANSFER',
        value: _get(payload, 'preferences.transfer'),
      },
      {
        symbol: 'GREATER_THAN',
        type: 'WITHDRAWAL',
        value: _get(payload, 'preferences.withdrawal'),
      },
      {
        symbol: 'GREATER_THAN',
        type: 'TRANSACTION',
        value: _get(payload, 'preferences.transaction'),
      }
    );
    return {
      type: TXM_SET_ALERT_PREF_SUCCESS,
      alertPreference,
    };
  }
  return {
    type: TXM_SET_ALERT_PREF_FAILURE,
    error: _get(payload, 'txm.message', 'error'),
  };
}

export function setTransactionAlertPrefFailure() {
  return {
    type: TXM_SET_ALERT_PREF_FAILURE,
  };
}

// Utility action creators
export function paginateTransactions(transactionList: $TSFixMe, startVal = 0, endVal: $TSFixMe) {
  let start = startVal;
  let end = endVal;
  const filteredTransactions = [];

  if (!_isEmpty(transactionList) && !transactionList.noResults) {
    if (transactionList.length <= end) {
      end = transactionList.length;
    }

    if (Array.isArray(transactionList)) {
      transactionList.forEach(() => {
        while (start < end) {
          filteredTransactions.push(transactionList[start++]);
        }
      });
    } else {
      filteredTransactions.push(transactionList);
    }
  }

  return {
    type: PAGINATE_TRANSACTIONS,
    paginatedTransactions: filteredTransactions,
  };
}

export function getTransactions(
  transactionList: $TSFixMe,
  accountId?: string,
  transactionType?: string,
  startDate?: string,
  endDate?: string
) {
  let filteredTransactions = [];
  if (!_isEmpty(transactionList) && !transactionList.noResults) {
    if (Array.isArray(transactionList)) {
      if (!_isEmpty(accountId) && accountId !== 'Accounts') {
        filteredTransactions = transactionList.filter((t) => {
          return t.accountId === accountId;
        });
      } else {
        filteredTransactions = transactionList;
      }
    } else if (transactionList.accountId === accountId || _isEmpty(accountId) || accountId === 'Accounts') {
      filteredTransactions.push(transactionList);
    }
    if (transactionType) {
      filteredTransactions = filteredTransactions.filter((t) => {
        if (transactionType === 'RECURRING') {
          return t.isRecurring === true;
        }
        return transactionType === t.transactionType;
      });
    }
    if (startDate && endDate) {
      const startingDate = new Date(startDate);
      const endingDate = new Date(endDate);
      filteredTransactions = filteredTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.transactionDate);
        return transactionDate >= startingDate && transactionDate <= endingDate;
      });
    }
  }

  return {
    type: FILTERED_TRANSACTIONS,
    filteredTransactionList: filteredTransactions,
  };
}

// Yodlee Action Creators
export function getAccessTokenRequest() {
  return {
    type: GET_ACCESS_TOKEN_REQUEST,
  };
}

export function getAccessTokenSuccess(payload: $TSFixMe) {
  return {
    type: GET_ACCESS_TOKEN_SUCCESS,
    getAccessTokenResult: payload,
  };
}

export function getAccessTokenFailure(error: $TSFixMe) {
  return {
    type: GET_ACCESS_TOKEN_ERROR,
    error,
  };
}

/**
 * Calls member-api institutionAccounts endpoint with delete verb
 *
 * @param args
 * @param accountId
 * @return {*}
 */
export function deleteInstitutionLogin(args: $TSFixMe, accountId: string) {
  const url = `${window.REACT_APP_MEMBER_API_HOST}${
    window.REACT_APP_TRANSACTIONS_PATH
  }/institutionAccounts?institutionId=${encodeURIComponent(args.institutionId)}&memSiteAccountId=${encodeURIComponent(
    args.memSiteAccountId
  )}`;
  const config: FetchConfig = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      account_id: accountId,
    },
    credentials: 'include',
  };
  return api(
    url,
    config,
    deleteInstitutionLoginRequest(),
    deleteInstitutionLoginSuccess,
    deleteInstitutionLoginFailure
  );
}

/**
 * Calls GET /accountTransactions in the member-api REST API
 *
 * @param {Object} args
 * @param {string} args.memberId
 * @param {string} args.startDate
 * @param {string} args.endDate
 * @param {string} args.rowsToRetrieve
 * @param {string} args.institutionId
 * @param {string} args.accountId
 * @param {string} args.offset
 * @param {string} args.sortField
 * @param {string} args.sortOrder
 * @param accountId
 *
 * @return {*} Returns the dispatch and state objects to the relevant reducer.
 */
export function getAccountTransactions(
  args: {
    memberId: string;
    startDate: string;
    endDate: string;
    rowsToRetrieve: string;
    institutionId: string;
    accountId: string;
    offset: string;
    sortField: string;
    sortOrder: string;
  },
  accountId: string
) {
  const queryParam = new URLSearchParams(args).toString();
  const url = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSACTIONS_PATH}?${queryParam}`;
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      account_id: accountId,
    },
    credentials: 'include',
  };
  if ((args as $TSFixMe).isForOverview) {
    return api(url, config, transactionListRequest(), transactionListSuccess4Overview, transactionListFailure);
  }
  return api(url, config, transactionListRequest(), transactionListSuccess, transactionListFailure);
}

export function getInstitutionAccounts(args: Record<string, string>, accountId: string) {
  const queryParam = new URLSearchParams(args).toString();
  const url = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSACTIONS_PATH}/institutionAccounts?${queryParam}`;
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      account_id: accountId,
    },
    credentials: 'include',
  };
  return api(url, config, memberAccountsRequest(), memberAccountsSuccess, memberAccountsFailure);
}

export function setTransactionAlertPreference(args: $TSFixMe, accountId: string) {
  const url = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSACTIONS_PATH}/txmAlertPreference`;
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      account_id: accountId,
    },
    credentials: 'include',
    body: JSON.stringify(args),
  };
  return api(
    url,
    config,
    setTransactionAlertPrefRequest(),
    setTransactionAlertPrefSuccess,
    setTransactionAlertPrefFailure
  );
}

export function setRecurringTransactionAlertOpt(args: $TSFixMe, transactionId: string) {
  const url = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSACTIONS_PATH}/recurring/${transactionId}`;
  const config: FetchConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
    body: JSON.stringify(args),
  };
  return api(
    url,
    config,
    setRecurringBillsAlertOptRequest(),
    setRecurringBillsAlertOptSuccess,
    setRecurringBillsAlertOptFailure
  );
}

export function paginateTransactionList(transactionList: $TSFixMe, start: $TSFixMe, end: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    dispatch(paginateTransactions(transactionList, start, end));
  };
}

export function filterTransactions(
  transactionList: $TSFixMe,
  accountId?: string,
  transactionType?: string,
  startDate?: string,
  endDate?: string
) {
  return (dispatch: $TSFixMe) => {
    dispatch(getTransactions(transactionList, accountId, transactionType, startDate, endDate));
  };
}

export function getAccessToken(options: $TSFixMe) {
  let url = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSACTIONS_PATH}/fastLinkToken`;

  if (!_isEmpty(options) && _has(options, 'useCache')) {
    url += `?useCache=${options.useCache}`;
  }

  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, getAccessTokenRequest(), getAccessTokenSuccess, getAccessTokenFailure);
}
