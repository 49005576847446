import {useEffect, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import utils from '../../helpers/utils';
import Dropdown from '../../components/common/Dropdown';
import {IntlContext} from '../../containers/IntlProvider';
import * as menuUtils from '../../helpers/menuUtils';
import {RootState} from '../../reducers/rootReducer';
import {useRestorationCases} from '../../customHooks/useRestoration';
import {useScamValidationCases} from '../../customHooks/useScamValidation';

const MenuMobile = () => {
  const localizedStringBundle: StringMap = useContext(IntlContext);
  const history = useHistory();
  const pathname = useLocation().pathname;
  const store = useSelector((store) => store);
  const whiteLabelClient = _get(store, 'auth.whiteLabelClient', '');
  const productFeatures = _get(store, 'auth.user.primaryMember.plan.productFeatures', {});
  const marketingDetails = _get(store, 'auth.user.primaryMember.plan.marketingDetails', {});
  const alertListInbox = useSelector((store: RootState) => _get(store, 'alertList.alertListInbox', []));
  const institutionsList = useSelector((store: RootState) => _get(store, 'transactions.institutionsList', []));
  const accountId = _get(store, 'auth.user.primaryMember.accountId');
  const {data: restorationCases} = useRestorationCases(accountId);
  const {data: scamValidationCases} = useScamValidationCases();
  const dynamicMenuData = {
    alertListInbox,
    institutionsList,
    restorationCases,
    scamValidationCases,
  };
  const list = utils.dspMenuList(productFeatures, marketingDetails, dynamicMenuData, whiteLabelClient);
  const filteredList = _filter(list, (v) => _includes(pathname, v.value));

  const defaultOption = () => {
    return {
      value: '/dashboard',
      name: localizedStringBundle.LEFT_NAV_DASHBOARD,
      option: {category: 'Navigation', action: 'click', label: 'Dashboard Tab'},
      iconName: 'Dashboard',
      supportComponent: '',
    };
  };

  const [selected, setSelected] = useState(filteredList[0] || defaultOption());
  const localizedList = _isEmpty(localizedStringBundle)
    ? []
    : list.map((item) => {
        return {
          value: item.value,
          name: localizedStringBundle[_get(item, 'name', '')],
          logo: menuUtils.getPathForNavIcon(item.iconName, whiteLabelClient),
          supportComponent: item.supportComponent || '',
          iconName: item.iconName,
        };
      });

  let listFiltered = localizedList;
  const selectedLabel = localizedStringBundle[_get(selected, 'name', '')] || _get(selected, 'name', '');
  const selectedObj = {value: _get(selected, 'value', ''), name: selectedLabel};

  useEffect(() => {
    const filteredList = _filter(list, (v) => _includes(pathname, v.value));

    if (_isEmpty(filteredList)) {
      filteredList[0] = defaultOption();
    }

    if (pathname.indexOf('online-account-monitoring') === 1) {
      setSelected({
        name: 'MONITORING',
        value: '/online-account-monitoring',
        option: {category: 'Navigation', action: 'click', label: 'Monitored Info Tab'},
        iconName: 'MonitoredInfo',
        supportComponent: '',
      });
      listFiltered = localizedList;
    } else if (pathname.indexOf('privacyMonitor') === 1) {
      setSelected({
        name: 'PRIVACY',
        value: '/privacyMonitor',
        option: {category: 'Navigation', action: 'click', label: 'Privacy Monitoring Tab'},
        iconName: 'MonitoredInfo',
        supportComponent: '',
      });
      listFiltered = localizedList;
    } else if (!_isEqual(filteredList[0], selected)) {
      setSelected(filteredList[0]);
    }
  }, [pathname]);

  const headerTitle = utils.getHeaderTitleString(
    productFeatures,
    marketingDetails,
    localizedStringBundle,
    whiteLabelClient
  );

  return (
    <div className="flex flex-grow flex-nowrap justify-between border-1 border-accent bg-nav">
      <Dropdown
        menuMobile={true}
        list={listFiltered}
        key="menu-dropdown"
        selectedName={selectedObj}
        setSelected={(item) => {
          history.push(item.value);
          setSelected(item);
        }}
        headerTitle={headerTitle}
        whiteLabelClient={whiteLabelClient}
      />
    </div>
  );
};

export default MenuMobile;
