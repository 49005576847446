import {
  ALERTS_ARCHIVED_FILTER_SUCCESS,
  ALERTS_ARCHIVED_SET_PAGINATION_SUCCESS,
  ALERTS_CASE_OPENED_FILTER_SUCCESS,
  ALERTS_CASE_OPENED_SET_PAGINATION_SUCCESS,
  ALERTS_DISPOSITION_FAILURE,
  ALERTS_DISPOSITION_SUCCESS,
  ALERTS_INBOX_FILTER_SUCCESS,
  ALERTS_INBOX_SET_PAGINATION_SUCCESS,
  ALERT_LIST_ARCHIVED_FAILURE,
  ALERT_LIST_ARCHIVED_REQUEST,
  ALERT_LIST_ARCHIVED_SUCCESS,
  ALERT_LIST_DISPUTED_FAILURE,
  ALERT_LIST_DISPUTED_REQUEST,
  ALERT_LIST_DISPUTED_SUCCESS,
  ALERT_LIST_INBOX_FAILURE,
  ALERT_LIST_INBOX_REQUEST,
  ALERT_LIST_INBOX_SUCCESS,
  ALERT_UPDATED_ALERT_LIST_SUCCESS,
} from '../actions/alertList';
import {getLangCode, stringBundle} from '../stringBundle';
import {ALL_ALERTS_LOOK_BACK} from '../constants/durations';

const defaultMemberFilter = {name: 'All Members', value: 'All'};

let localizedStringBundle;

const initialState = {
  alertListCaseOpened: null,
  alertListInbox: null,
  alertListArchived: null,

  alertListCaseOpenedFiltered: null,
  alertListInboxFiltered: null,
  alertListArchivedFiltered: null,

  alertListCaseOpenedAlertFilter: {},
  alertListInboxAlertFilter: {},
  alertListArchivedAlertFilter: {},

  alertListCaseOpenedMemberFilter: defaultMemberFilter,
  alertListInboxMemberFilter: defaultMemberFilter,
  alertListArchivedMemberFilter: defaultMemberFilter,

  alertListArchivedDateFilter: {},
  alertListArchivedDateFilterFetched: null,
  alertListArchivedFetchInProgress: false,

  inboxDateFilter: {},
  caseOpenedDateFilter: {},
  claimCaseId: '',
  inboxCurrentPage: 1,
  archivedCurrentPage: 1,
  caseOpenedCurrentPage: 1,
};

export default function alertList(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case ALERT_LIST_INBOX_REQUEST:
      localizedStringBundle = stringBundle(getLangCode());
      return Object.assign({}, state, {
        alertListInboxMemberFilter: {name: localizedStringBundle.ALL_MEMBERS, value: 'All'},
        alertListInboxAlertFilter: {name: localizedStringBundle.ALL_CATEGORIES, value: 'All'},
        inboxDateFilter: {name: localizedStringBundle.PAST_ALL, value: ALL_ALERTS_LOOK_BACK},
      });
    case ALERT_LIST_INBOX_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInbox: action.alertListInbox,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxFiltered: action.alertListInboxFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxAlertTypeNameKeysObject: action.inboxAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxMembersListKeysObject: action.inboxMembersListKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxUnreadCount: action.unreadCount,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxSummarySize: action.summarySize,
      });
    case ALERT_LIST_INBOX_FAILURE:
      return Object.assign({}, state, {
        inboxHasError: true,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxError: action.error,
      });
    case ALERT_LIST_DISPUTED_REQUEST:
      localizedStringBundle = stringBundle(getLangCode());
      return Object.assign({}, state, {
        alertListCaseOpenedAlertFilter: {name: localizedStringBundle.ALL_CATEGORIES, value: 'All'},
        caseOpenedDateFilter: {name: localizedStringBundle.PAST_ALL, value: ALL_ALERTS_LOOK_BACK},
      });
    case ALERT_LIST_DISPUTED_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpened: action.alertListCaseOpened,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedFiltered: action.alertListCaseOpenedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        caseOpenedAlertTypeNameKeysObject: action.caseOpenedAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        caseOpenedMembersListKeysObject: action.caseOpenedMembersListKeysObject,
      });
    case ALERT_LIST_DISPUTED_FAILURE:
      return Object.assign({}, state, {
        disputedHasError: true,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        disputedError: action.error,
      });
    case ALERT_LIST_ARCHIVED_REQUEST:
      localizedStringBundle = stringBundle(getLangCode());
      return Object.assign({}, state, {
        alertListArchivedMemberFilter: {name: localizedStringBundle.ALL_MEMBERS, value: 'All'},
        alertListArchivedAlertFilter: {name: localizedStringBundle.ALL_CATEGORIES, value: 'All'},
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedDateFilter: action.dateFilter,
        alertListArchivedFetchInProgress: true,
      });
    case ALERT_LIST_ARCHIVED_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchived: action.alertListArchived,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedFiltered: action.alertListArchivedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        archivedAlertTypeNameKeysObject: action.archivedAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        archivedMembersListKeysObject: action.archivedMembersListKeysObject,
        alertListArchivedDateFilterFetched: state.alertListArchivedDateFilter,
        alertListArchivedDateFilter: state.alertListArchivedDateFilter,
        alertListArchivedFetchInProgress: false,
      });
    case ALERT_LIST_ARCHIVED_FAILURE:
      return Object.assign({}, state, {
        archivedHasError: true,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        archivedError: action.error,
        alertListArchivedFetchInProgress: false,
      });
    case ALERT_UPDATED_ALERT_LIST_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInbox: action.alertListInbox,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        claimCaseId: action.claimCaseId,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpened: action.alertListCaseOpened,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchived: action.alertListArchived,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedFiltered: action.alertListCaseOpenedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxFiltered: action.alertListInboxFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedFiltered: action.alertListArchivedFiltered,
      });
    case ALERTS_CASE_OPENED_FILTER_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedAlertFilter: action.alertFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedMemberFilter: action.memberFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedFiltered: action.alertListCaseOpenedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        caseOpenedAlertTypeNameKeysObject: action.caseOpenedAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        caseOpenedCurrentPage: action.currentPage,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        caseOpenedDateFilter: action.dateFilter,
      });
    case ALERTS_INBOX_FILTER_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxAlertFilter: action.alertFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxMemberFilter: action.memberFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxFiltered: action.alertListInboxFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxAlertTypeNameKeysObject: action.inboxAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxDateFilter: action.dateFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        inboxCurrentPage: action.currentPage,
      });
    case ALERTS_ARCHIVED_FILTER_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedDateFilter: action.dateFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedAlertFilter: action.alertFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedMemberFilter: action.memberFilter,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedFiltered: action.alertListArchivedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        archivedAlertTypeNameKeysObject: action.archivedAlertTypeNameKeysObject,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        archivedCurrentPage: action.archivedCurrentPage,
      });
    case ALERTS_ARCHIVED_SET_PAGINATION_SUCCESS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {archivedCurrentPage: action.archivedCurrentPage});
    case ALERTS_INBOX_SET_PAGINATION_SUCCESS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {inboxCurrentPage: action.inboxCurrentPage});
    case ALERTS_CASE_OPENED_SET_PAGINATION_SUCCESS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {caseOpenedCurrentPage: action.caseOpenedCurrentPage});
    case ALERTS_DISPOSITION_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInbox: action.alertListInbox,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        claimCaseId: action.claimCaseId,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpened: action.alertListCaseOpened,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchived: action.alertListArchived,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListCaseOpenedFiltered: action.alertListCaseOpenedFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxFiltered: action.alertListInboxFiltered,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListArchivedFiltered: action.alertListArchivedFiltered,
      });
    case ALERTS_DISPOSITION_FAILURE:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInbox: action.alertListInbox,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertListInboxFiltered: action.alertListInboxFiltered,
      });
    default:
      return state;
  }
}
