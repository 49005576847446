import utils from './utils';
import Image from '../components/common/VectorImage';

/**
 * Renders nav icon from CDN
 * @param {string} imageName - Image Name to be fetched from CDN
 * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
 * @returns {JSX.Element}
 */
export const renderNavIcon = (imageName: string, whiteLabelClient: string) => {
  const imagePath = getPathForNavIcon(imageName, whiteLabelClient);
  return (
    <Image
      alt={imageName}
      url={imagePath}
      classes="h-8 w-8 align-middle"
      key={imagePath}
      data-testid={`nav-bar-${imageName}`}
    />
  );
};

/**
 * Returns CDN path for menu icon which matches it's path in the CDN repo
 * @param {string} imageName image name to be fetched from CDN
 * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
 * @return {string} CDN path for icon
 */
export const getPathForNavIcon = (imageName: string, whiteLabelClient: string) => {
  return utils.getCdnImagesPath(`/dsp-northstar/feature-icons/${imageName}`, whiteLabelClient);
};
