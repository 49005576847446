/* global gtag:true, analytics:true */
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
import _isArray from 'lodash/isArray';
import cookie from 'react-cookies';
import store from 'store';
import storageEngine from 'store/src/store-engine';
import sessionstore from 'store/storages/sessionStorage';
import {setModalSizeSpec} from '../actions/modal';
import Image from '../components/common/Image';
import errorCodeMapForAnalytics from '../constants/errorCodeMap';
import pageNameMapForAnalytics from '../constants/pageNameMapForAnalytics';
import {LL_SITE_DIRECORY_PARTNER_ID, LL_SSDCAT} from '../constants/supportUrl';
import {AuthState} from '../reducers/auth';
import {Institution} from '../reducers/transactionReducers';
import {getLangCode, interpolate, getBrowserLanguage} from '../stringBundle';
import {getHeaderAccessToken} from './fetchRest';
import log from './log';
import {getTrackingValues} from './tracking';
import {currencyCodes} from '../constants/currencyCodeMap';
import AlertToggle from '../containers/alerts/AlertToggle';
import parse from 'html-react-parser';
import dateUtils from './dateUtils';

const sessionStorage = storageEngine.createStore([sessionstore], []);
const SHOWN = 'shown';
const PRIMARY_PHONE_MODAL_PREFIX = 'PrimaryPhoneModal-';

export const EFX_BUREAU_CODE = 'EFX';
const TU_BUREAU_CODE = 'TU';
const EXP_BUREAU_CODE = 'EXP';

const STANDARD_PLAN_CUTOFF_MONTHS_FOR_UPSELL_MSG = 6;
const DWM_UPGRADE_URL = 'https://lifelock.norton.com/products/lifelock-advantage';

export default class utils {
  static clearSessionAndCookies() {
    store.clearAll();
    sessionStorage.clearAll();
    const cookies = document.cookie;
    for (let i = 0; i < cookies.split(';').length; ++i) {
      const myCookie = cookies[i];
      const pos = myCookie.indexOf('=');
      const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'cookie' implicitly has an 'any' type.
  static getCookieValue(cookie, name) {
    const nameStr = name + '=';
    const decodedCookie = decodeURIComponent(cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(nameStr) === 0) {
        return c.substring(nameStr.length, c.length);
      }
    }
    return '';
  }

  static parseHtml(text: string) {
    return parse(text);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'parameters' implicitly has an 'any' typ... Remove this comment to see the full error message
  static log(parameters) {
    const url = this.getLogPath();

    const selectedParameters = {
      source: _get(parameters, 'source', 'portal'),
      version: _get(parameters, 'version', ''),
      feature: _get(parameters, 'feature', ''),
      actionType: _get(parameters, 'actionType', ''),
      message: _get(parameters, 'message', ''),
      accountId: _get(parameters, 'accountId', ''),
      token: _get(parameters, 'token', ''),
      startTime: _get(parameters, 'startTime', ''),
      endTime: _get(parameters, 'endTime', ''),
      elapsedTime: _get(parameters, 'elapsedTime', ''),
      error: _get(parameters, 'error', ''),
      statusCode: _get(parameters, 'statusCode', ''),
    };

    const config: FetchConfig = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        client_id: _get(parameters, 'clientId', 'dsp'),
        'X-CSRF-Token': this.getCookieValue(document.cookie, 'XSRF-TOKEN'),
        'x-nlok-trace-id': getTrackingValues().NLokTraceId,
      },
      credentials: 'include',
      body: JSON.stringify(selectedParameters),
    };

    // The SPA must use a token in the header when it is loaded in a 3rd party domain which prevents session cookies from being sent to Member API
    if (getHeaderAccessToken()) {
      config.headers.access_token = getHeaderAccessToken();
    }

    return fetch(url, config);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  static mapComparedObjectKeys(a, b) {
    // @ts-expect-error TS(7034) FIXME: Variable 'objectKeyList' implicitly has type 'any[... Remove this comment to see the full error message
    const objectKeyList = [];
    const comparedObjectsList = this.compareAlertDetailObjects(a, b);

    comparedObjectsList.forEach((t) => {
      objectKeyList[t.key] = t;
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'objectKeyList' implicitly has an 'any[]'... Remove this comment to see the full error message
    return objectKeyList;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  static compareAlertDetailObjects(a, b) {
    // This method helps to compare object A and B.
    // The final comparison result will be placed in an array of objects called final

    // @ts-expect-error TS(7034) FIXME: Variable 'final' implicitly has type 'any[]' in so... Remove this comment to see the full error message
    const final = [];

    // @ts-expect-error TS(7034) FIXME: Variable 'finalKeys' implicitly has type 'any[]' i... Remove this comment to see the full error message
    const finalKeys = [];

    // Pickup all the unique keys from object A
    Object.keys(a).forEach((key) => {
      // @ts-expect-error TS(7005) FIXME: Variable 'finalKeys' implicitly has an 'any[]' typ... Remove this comment to see the full error message
      if (finalKeys.indexOf(key) === -1) {
        finalKeys.push(key);
      }
    });

    // Pickup all the unique keys from object B
    Object.keys(b).forEach((key) => {
      // @ts-expect-error TS(7005) FIXME: Variable 'finalKeys' implicitly has an 'any[]' typ... Remove this comment to see the full error message
      if (finalKeys.indexOf(key) === -1) {
        finalKeys.push(key);
      }
    });

    // Loop through the complete list of finalKeys

    // @ts-expect-error TS(7005) FIXME: Variable 'finalKeys' implicitly has an 'any[]' typ... Remove this comment to see the full error message
    finalKeys.forEach((key) => {
      // We ignore the alertEntry and Narratives

      if (key !== 'alertEntry' && key !== 'narratives') {
        if (a.hasOwnProperty(key)) {
          if (b.hasOwnProperty(key)) {
            // The property exists in both A and B

            if (typeof a[key] === 'object') {
              // Case when the specific property value exists in both A & B and is an object
              // Quick comparison of the stringified elements can let us know if the children objects are different

              const status = JSON.stringify(a[key]) === JSON.stringify(b[key]) ? 'same' : 'changed';

              if (status === 'changed') {
                // Value of A object differs from B object

                final.push({
                  key,
                  type: 'object',
                  status,
                  value: b[key].description
                    ? b[key].description
                    : b[key].value
                    ? b[key].value
                    : b[key].amount
                    ? b[key].amount
                    : '',
                  before: a[key].description
                    ? a[key].description
                    : a[key].value
                    ? a[key].value
                    : a[key].amount
                    ? a[key].amount
                    : '',
                });
              } else {
                // Value of A object is the same as B object
                final.push({
                  key,
                  type: 'object',
                  status,
                  value: b[key].description
                    ? b[key].description
                    : b[key].value
                    ? b[key].value
                    : b[key].amount
                    ? b[key].amount
                    : '',
                });
              }
            } else if (b[key] !== a[key]) {
              // Value of A string differs from B string
              final.push({
                key,
                type: 'string',
                status: 'changed',
                value: b[key],
                before: a[key],
              });
            } else {
              // A value is the same as B string
              final.push({key, type: 'string', status: 'same', value: a[key]});
            }
          } else {
            // Value of A string no longer exists in B
            final.push({key, type: 'string', status: 'removed', value: null, before: a[key]});
          }
        } // Only B have this property
        else if (b.hasOwnProperty(key) && b[key]) {
          if (typeof b[key] === 'object') {
            if (b[key].description) {
              // B value is new description object
              final.push({
                key,
                type: 'object',
                status: 'added',
                value: b[key].description,
                before: null,
              });
            } else if (b[key].amount) {
              // B value is new dollar object
              final.push({
                key,
                type: 'object',
                status: 'added',
                value: b[key].amount,
                before: null,
                currency: b[key].currency,
              });
            }
          } else {
            // B value is new string
            final.push({key, type: 'string', status: 'added', value: b[key], before: null});
          }
        } else {
          // Both values are null
          final.push({key, type: 'string', status: 'same', value: null, before: null});
        }
      }
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'final' implicitly has an 'any[]' type.
    return final;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
  static someObjectParametersHaveValue(obj) {
    let notEmpty = false;

    if (obj) {
      const allKeys = Object.keys(obj);

      if (allKeys) {
        allKeys.forEach((key) => {
          if (obj[key].value) {
            notEmpty = true;
          }
        });
      }
    }

    return notEmpty;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'dates' implicitly has an 'any' type.
  static historyToObject(dates, scores) {
    // Check length
    if (Array.isArray(dates) && Array.isArray(scores)) {
      const datesArr = dates;
      const scoresArr = scores;
      const historyObject = {
        dates: datesArr,
        scores: scoresArr,
      };
      return historyObject;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'str' implicitly has an 'any' type.
  static convertUpperCase(str) {
    let description = str.toLowerCase();
    description = description.split(' ');
    for (let i = 0; i < description.length; i++) {
      if (i === 0) {
        description[i] = description[i].charAt(0).toUpperCase() + description[i].slice(1);
      }
    }
    return description.join(' ');
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  static trackEventForMonitoredInfo(type, action) {
    const option = {
      category: 'Member info',
      action: 'click',
      label: `${type} ${action}`,
    };
    this.trackEvent(option);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  static trackEvent(option) {
    if (typeof option.value === 'number') {
      option.value = option.value.toString();
    }
    if (window.gtag) {
      const eventData = {
        event_category: _get(option, 'category', ''),
        event_label: _get(option, 'label', ''),
        event_client_id: _get(window, 'clientId', 'dsp'), // pass the white label client value store in window or default to dsp
      };

      if (option.value) (eventData as $TSFixMe).value = option.value;

      if (window.analytics) {
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.sku) (eventData as $TSFixMe).SKU = analytics.sku;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.promoCode) eventData['Promo Code'] = analytics.promoCode;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.isPrimary) eventData['Is Primary Member'] = analytics.isPrimary.toString();
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.partnerGuid) eventData['Member Partner GUID'] = analytics.partnerGuid;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.archivedAlertCount) eventData['Archived Alert Count'] = analytics.archivedAlertCount;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.disputedAlertCount) eventData['Disputed Alert Count'] = analytics.disputedAlertCount;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.inboxAlertCount) eventData['Inbox Alert Count'] = analytics.inboxAlertCount;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.lpmUsertype) eventData['LPM Usertype'] = analytics.lpmUsertype;
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'analytics'.
        if (analytics.hashedAccountId) eventData['Hashed Norton Guid'] = analytics.hashedNortonGuid;
      }
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'gtag'.
      gtag('event', _get(option, 'action', ''), eventData);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'inputType' implicitly has an 'any' type... Remove this comment to see the full error message
  static trackEventForManageAccount(inputType, action, hasValue) {
    const labelMap = {
      ADDRESS: 'Address',
      PHONE_NUMBER: 'Phone',
      PHONE: 'Phone',
      E_MAIL_ADDR: 'Email',
      EMAIL: 'Email',
      DRIVERS_LICENSE: 'Driver License',
      INSURANCE_CARD: 'Insurance',
      transaction: 'TXM_Purchase',
      transfer: 'TXM_Transfer',
      withdrawal: 'TXM_Cash',
      USERNAME: 'Username',
      PASSWORD: 'Password',
      VERBAL_PASSCODE: 'Verbal_Passcode',
      BANK_ACCOUNT: 'Internet_Monitor',
      CREDIT_DEBIT: 'Internet_Monitor',
      HOME_PROPERTY: 'Home_Property',
    };
    const option = {
      category: 'MAccount',
      action,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: labelMap[inputType] ? labelMap[inputType] : inputType,
      event: 'trackEvent',
    };
    if (hasValue) {
      (option as $TSFixMe).value = 1;
    }
    this.trackEvent(option);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  static getMaxRecordCount(data, inputType, defaultVal) {
    let maxCount = defaultVal;
    if (data !== undefined && data.zone !== undefined) {
      const zones = data.zone;
      const recordtype = _filter(zones, {recordType: inputType});
      maxCount = _get(recordtype[0], 'maxRecordCount', defaultVal);
    }
    return Number(maxCount);
  }

  /**
   * Returns the amount in localized currency format with 2 decimal places and currency symbol.
   * Example: 12345.12 with 'en-US' langCode and 'USD' currency turns into $12,345.12
   *
   * @param {number} amount Number to be formatted
   * @param {string} langCode Language code for formatting amount
   * @param {string} currency Currency code for formatting amount
   * @return {string}
   */
  static convertAmountToString(amount: number, langCode: string, currency: string) {
    const amt = Number(amount).toFixed(2);
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    return new Intl.NumberFormat(langCode, {style: 'currency', currency: currency}).format(amt);
  }

  /**
   * returns formatted currency value based on lang code, currency code and fraction digits
   */
  static formatCurrency(
    value: number,
    langCode: string,
    currency: string,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0
  ) {
    const currencyFormatter = Intl.NumberFormat(langCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits,
      maximumFractionDigits,
    });

    return currencyFormatter.format(value);
  }

  /**
   * returns the localized amount with negative sign if the transactionType is DEBIT
   */
  static getConvertedTransactionAmount(amount: number, currencyCode: string, transactionType: string) {
    let convertedAmount = utils.convertAmountToString(amount, getLangCode(), currencyCode);
    if (transactionType === 'DEBIT') {
      convertedAmount = `-${convertedAmount}`;
    }
    return convertedAmount;
  }

  /**
   * Returns an object with count of transactions and total amount for given startDate and list of transactions.
   *
   * @param {string} startDate String representation of date in YYYY-MM-DD format
   * @param {object} data Array of transaction objects, or a single transaction object
   * @param {string} langCode Language code for formatting total
   * @param {string} membershipCountryCode Two-character country-code of the member's membership
   * @return {{count: number, total: (*|amt)}}
   */
  static numberOfTransactions(startDate: string, data: $TSFixMe, langCode = 'en-US', membershipCountryCode = 'US') {
    const transactions = data;
    let count = 0;
    let total = 0;

    let currency = currencyCodes[membershipCountryCode.toUpperCase()] || 'USD'; // Defaults to USD
    if (Array.isArray(transactions) && !_isEmpty(transactions)) {
      transactions.forEach((arrayItem) => {
        currency = arrayItem.currencyCode || 'USD';
        if (arrayItem.postedDate >= startDate) {
          count += 1;
          if (!_isEmpty(arrayItem.absoluteAmount)) {
            total += parseFloat(arrayItem.absoluteAmount);
          }
        }
      });
    } else if ((transactions as $TSFixMe).postedDate >= startDate) {
      count = 1;
      total = parseFloat((transactions as $TSFixMe).amount);
      currency = (transactions as $TSFixMe).currencyCode || 'USD';
    }
    return {count, total: utils.convertAmountToString(total, langCode, currency)};
  }

  /**
   * Returns a boolean after checking the partnerId from member partnerDetails
   *
   * @param {object} partnerDetails Object containing the partnerId and partnerUnitId
   * @return {boolean}
   */
  static isDirectToConsumer(partnerDetails: PartnerDetails | {}) {
    const dtcPartnerList = [1000];
    const partnerId = _get(partnerDetails, 'partnerId', 0);
    return dtcPartnerList.indexOf(partnerId) > -1;
  }

  /**
   * Returns an object with count of transactions and total amount for given startDate and list of transactions.
   *
   * @param {Object} auth Auth object containing all the member related data
   * @param {object} transactions Object of complete txm state
   * @param {string} modalType String val depicting the type of modal, for which content is needed
   * @param {StringMap} stringBundle Localized stringBundle
   * @param {boolean} isZeroStateForModal Boolean val indicating if there are records added for given modalType
   * @return {Object} aside content for the given modalType
   */
  static getAsideContentForDWMModals(
    auth: AuthState,
    modalType: string,
    stringBundle: StringMap,
    transactions: Object,
    isZeroStateForModal: boolean
  ): object {
    const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', {});
    const partnerDetails = _get(auth, 'user.primaryMember.plan.partnerDetails', {});
    const primarySubscriptionStart = _get(auth, 'user.primaryMember.primarySubscriptionStart', '');
    const whiteLabelClient = _get(auth, 'whiteLabelClient', '');
    const isDirectToConsumer = utils.isDirectToConsumer(partnerDetails);
    const isTxmUpgradeScenario = utils.isTxmUpgradeScenario(productFeatures, whiteLabelClient);
    const institutionsList = _get(transactions, 'institutionsList', {});
    const isAccountAddedForTxm = !institutionsList.noResults;

    let title = '',
      description = '',
      dataTestId = '';
    switch (modalType) {
      case 'creditCard':
        {
          title = stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TITLE_CREDIT_CARD;
          description = stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TEXT1_CREDIT_CARD;
          dataTestId = 'test-credit-card-modal-aside-content';
        }
        break;
      case 'bankAccount': {
        title = stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TITLE;
        description = stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TEXT1;
        dataTestId = 'test-bank-modal-aside-content';
      }
    }
    const imageTag = (
      <img
        src={utils.getCdnImagesPath(
          '/dsp-northstar/monitored-info/monitored-info-credit-card@1x.png',
          whiteLabelClient
        )}
        alt="credit-card"
        className="m-auto flex h-32 w-56"
      />
    );

    const defaultAsideContent = {
      title: title,
      description: [description],
      testIdentifier: dataTestId,
    };
    let asideContent = {};

    // if there are records for monitorInfo, if DTC partnerCode, if txm feature is available and no accounts added for txm
    if (
      !isZeroStateForModal &&
      isDirectToConsumer &&
      utils.isFeaturePresent(productFeatures, 'transactionMonitoring') &&
      !isAccountAddedForTxm
    ) {
      asideContent = {
        title: stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TXM_TITLE,
        description: [stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TXM_MSG],
        testIdentifier: dataTestId,
        icon: imageTag,
        ctaText: stringBundle.GET_STARTED,
        ctaUrl: '/transactions',
        hasImageAndCta: true,
        showTooltip: true,
        tooltipTitle: title,
        tooltipParagraphArray: [description],
        isCtaUrlInApp: true,
      };
    } else if (!isZeroStateForModal && isDirectToConsumer && isTxmUpgradeScenario) {
      // if standard plan, calculate the subscription age, if the subscriptionAge > 6mon, then only show upsell msg
      const standardPlanAge = dateUtils.getDifferenceInMonths(new Date(primarySubscriptionStart), new Date());
      if (standardPlanAge >= STANDARD_PLAN_CUTOFF_MONTHS_FOR_UPSELL_MSG) {
        asideContent = {
          title: stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TXM_UPGRADE_TITLE,
          description: [stringBundle.MANAGE_MODAL_INTERNET_MONITORING_INFO_TXM_UPGRADE_MSG],
          testIdentifier: dataTestId,
          icon: imageTag,
          ctaText: stringBundle.BUTTON_UPGRADE,
          ctaUrl: DWM_UPGRADE_URL,
          hasImageAndCta: true,
          showTooltip: true,
          tooltipTitle: title,
          tooltipParagraphArray: [description],
          isCtaUrlInApp: false,
        };
      } else {
        asideContent = defaultAsideContent;
      }
    } else {
      asideContent = defaultAsideContent;
    }
    return asideContent;
  }

  /**
   * Checks if the current user has Transactions available for him.
   *
   * @param currentUser
   * @returns {boolean}
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'currentUser' implicitly has an 'any' ty... Remove this comment to see the full error message
  static showTransactions(currentUser) {
    const productFeatures = _get(currentUser, 'plan.productFeatures', '');
    const featureStatus = _get(productFeatures, 'transactionMonitoring.featureStatus', '');
    const fulfillmentStatus = _get(productFeatures, 'transactionMonitoring.fulfillmentStatus', '');
    const featureStatusJp = _get(productFeatures, 'transactionMonitoringJp.featureStatus', '');
    const fulfillmentStatusJp = _get(productFeatures, 'transactionMonitoringJp.fulfillmentStatus', '');
    const featureStatusUk = _get(productFeatures, 'financialMonitoringUk.featureStatus', '');
    const fulfillmentStatusUk = _get(productFeatures, 'financialMonitoringUk.fulfillmentStatus', '');

    return (
      (featureStatus === 'AVAILABLE' && fulfillmentStatus === 'ENROLLED') ||
      (featureStatusJp === 'AVAILABLE' && fulfillmentStatusJp === 'ENROLLED') ||
      (featureStatusUk === 'AVAILABLE' && fulfillmentStatusUk === 'ENROLLED')
    );
  }

  /**
   * Check if the language code is french dialect.
   *
   * @returns {boolean}
   */
  static isFrenchLangCode() {
    const langCode = getLangCode().toLowerCase();
    return langCode.includes('fr');
  }

  /**
   * Checks if the user has Transactions fulfillmentStatus is pending.
   *
   * @param productFeatures - user's plan specific product features
   * @returns {boolean}
   */
  static isTransactionsPending(productFeatures: ProductFeatures) {
    const featureStatus = _get(productFeatures, 'transactionMonitoring.featureStatus', '');
    const fulfillmentStatus = _get(productFeatures, 'transactionMonitoring.fulfillmentStatus', '');
    const featureStatusJp = _get(productFeatures, 'transactionMonitoringJp.featureStatus', '');
    const fulfillmentStatusJp = _get(productFeatures, 'transactionMonitoringJp.fulfillmentStatus', '');
    const featureStatusUK = _get(productFeatures, 'financialMonitoringUk.featureStatus', '');
    const fulfillmentStatusUK = _get(productFeatures, 'financialMonitoringUk.fulfillmentStatus', '');

    return (
      (featureStatus === 'AVAILABLE' && fulfillmentStatus === 'PENDING') ||
      (featureStatusJp === 'AVAILABLE' && fulfillmentStatusJp === 'PENDING') ||
      (featureStatusUK === 'AVAILABLE' && fulfillmentStatusUK === 'PENDING')
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'institutionId' implicitly has an 'any' ... Remove this comment to see the full error message
  static getBankInfoByInstitutionId(institutionId) {
    const InstitutionIdMap = {
      '39': {
        id: 0,
        label: 'Chase',
        altText: 'Chase Bank',
      },
      '2852': {
        id: 1,
        label: 'BOA',
        altText: 'Bank of America',
      },
      '1503': {
        id: 2,
        label: 'Citi',
        altText: 'Citibank',
      },
      '524': {
        id: 3,
        label: 'US',
        altText: 'U.S. Bank',
      },
      '1649': {
        id: 4,
        label: 'MS',
        altText: 'Morgan Stanley',
      },
      '2162': {
        id: 5,
        label: 'PNC',
        altText: 'PNC',
      },
      '2856': {
        id: 6,
        label: 'CapOne',
        altText: 'Capital One',
      },
      '3612': {
        id: 7,
        label: 'HSBC',
        altText: 'HSBC Bank',
      },
      '4132': {
        id: 9,
        label: 'TD',
        altText: 'TD Bank',
      },
      '3010': {
        id: 11,
        label: 'BB&T',
        altText: 'BB&T',
      },
      '2383': {
        id: 12,
        label: 'SunTrust',
        altText: 'SunTrust Bank',
      },
      '9565': {
        id: 13,
        label: 'Ally',
        altText: 'Ally Bank',
      },
      '16441': {
        id: 14,
        altText: 'RBC',
      },
      '16486': {
        id: 15,
        altText: 'Citizens Bank',
      },
      '3278': {
        id: 16,
        altText: 'USAA',
      },
      '5': {
        id: 17,
        label: 'Wells',
        altText: 'Wells Fargo',
      },
      '21': {
        id: 18.5,
        label: 'Charles',
        altText: 'Charles Schwab',
      },
      '12': {
        id: 20,
        label: 'Amex',
        altText: 'American Express',
      },
    };

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (InstitutionIdMap[institutionId] !== (null || undefined)) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const institution = InstitutionIdMap[institutionId];
      return {
        linearLogo: `bank-linear-logo-${institution.id + 1}`,
        label: institution.label,
        altText: institution.altText,
        id: institution.id,
      };
    }

    return {
      linearLogo: null,
      label: '',
      altText: '',
      id: 0,
    };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'stringIncludingASCII' implicitly has an... Remove this comment to see the full error message
  static asc2Str(stringIncludingASCII) {
    if (_isEmpty(stringIncludingASCII)) return '';

    // @ts-expect-error TS(7006) FIXME: Parameter 'ascii' implicitly has an 'any' type.
    return stringIncludingASCII.replace(/&#([0-9]{2,3});/g, (ascii) => {
      return String.fromCharCode(parseInt(ascii.substring(2, ascii.length - 1), 10));
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  static getParameterByName(name, url) {
    /* eslint-disable no-useless-escape */
    const updatedName = name.replace(/[\[\]]/g, '\\$&');
    /* eslint-disable no-useless-escape */
    const regex = new RegExp(`[?&]${updatedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url || window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  static numericTextInputOnly(evt) {
    const event = evt || window.event;
    event.persist();
    let key = event.keyCode || event.which;
    key = String.fromCharCode(key);
    const regex = /[0-9\uFF10-\uFF19]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  }

  /**
   * A helper function to convert full width numbers to half width
   * Example: half width - 3568, full width - ３５６８
   * @param {string}
   * @return {string} returns a number in string format
   **/
  // @ts-expect-error TS(7006) FIXME: Parameter 'cardNumber' implicitly has an 'any' typ... Remove this comment to see the full error message
  static getHalfWidthNumber(cardNumber) {
    let convertedNumber = cardNumber;
    //checks if number is full width and converts to half width
    const fullWidthRegex = /[\uFF10-\uFF19]/g;
    // @ts-expect-error TS(7006) FIXME: Parameter 'ch' implicitly has an 'any' type.
    convertedNumber = convertedNumber.replace(fullWidthRegex, function(ch) {
      return String.fromCharCode(ch.charCodeAt(0) - 65248);
    });
    return convertedNumber;
  }

  /*eslint-disable no-bitwise*/
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  static isValidLuhn(value) {
    const cardNumber = this.getHalfWidthNumber(value);
    let len = cardNumber.length;
    let mul = 0;
    const prodArr = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
    ];
    let sum = 0;
    while (len--) {
      sum += prodArr[mul][parseInt(cardNumber.charAt(len), 10)];
      mul ^= 1;
    }
    return sum % 10 === 0 && sum > 0;
  }

  /**
   * Returns URL of image corresponding to path that is relative to memberportal img folder.
   * @param {string} path - path of the image file, somewhere in memberportal img folder
   * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
   * @returns
   */
  static getCdnPath(path: string, whiteLabelClient?: string) {
    if (whiteLabelClient) {
      // CDN paths "avast" and "avg" should be used for avast_intl and avg_intl
      const client =
        whiteLabelClient === 'avast_intl' ? 'avast' : whiteLabelClient === 'avg_intl' ? 'avg' : whiteLabelClient;
      return `${window.REACT_APP_CDN_HOST}${window.REACT_APP_WHITE_LABEL_CDN_IMAGES_PATH}/${client}/memberportal/img${path}`;
    }
    return `${window.REACT_APP_CDN_HOST}${window.REACT_APP_ASSETS_CDN_PATH}${path}`;
  }

  /**
   * Returns URL of image corresponding to path that is relative to either whitelabel images folder or secure images folder.
   * White label images are in their own directory structure, separate from NortonLifeLock images.
   *
   * @param {string} path - path of the image file.  Must have a leading '/' character.
   * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
   * @returns {string} - CDN URL of the image file
   */
  static getCdnImagesPath(path: string, whiteLabelClient: string | null) {
    if (whiteLabelClient) {
      // CDN paths "avast" and "avg" should be used for avast_intl and avg_intl
      const client =
        whiteLabelClient === 'avast_intl' ? 'avast' : whiteLabelClient === 'avg_intl' ? 'avg' : whiteLabelClient;
      return window.REACT_APP_CDN_HOST + window.REACT_APP_WHITE_LABEL_CDN_IMAGES_PATH + '/' + client + path;
    } else {
      return `${window.REACT_APP_CDN_HOST}${window.REACT_APP_ASSETS_CDN_IMAGES_PATH}${path}`;
    }
  }

  /**
   * Gets country specific values of plan details by auth countryCode
   *
   * @param {string} countryCode - auth countryCode
   * @param {StringMap} localizedStringBundle - string bundle for messaging
   * @param {string} displayLang - value for displayLang in site director support url
   * @param {string} partnerId - value for partnerId in site director support url
   * @param {string} puId - value for puid in site director support url
   * @returns {object} - Returns an object of country specific values
   */
  static getCountrySpecificPlanDetailsValues(
    countryCode: string,
    localizedStringBundle: StringMap,
    displayLang: string,
    partnerId: string,
    puId: string
  ) {
    let supportPhone,
      countryName,
      disclaimer2,
      langCode,
      supportLinkRestoration,
      supportPhoneRestoration,
      timingsRestoration,
      lmsSupportTimings,
      lmsSupportPhone,
      legalUrl = '',
      restorationMessageSuffix = '';
    supportLinkRestoration = utils.getSupportUrl('Restoration_Support', displayLang, countryCode, partnerId, puId);
    switch (countryCode) {
      case 'NZ':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_NZ;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_NZ || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_NZ;
        supportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_NZ;
        lmsSupportTimings = '';
        countryName = localizedStringBundle.COUNTRY_ADJECTIVE_NZ;
        legalUrl = window.PLAN_DETAILS_LEGAL_URL_NZ;
        disclaimer2 = interpolate(localizedStringBundle.PLAN_DETAILS_DISCLAIMER2_NZ, {legalUrl});
        langCode = 'en-NZ';
        restorationMessageSuffix = interpolate(localizedStringBundle.TO_OPEN_ONE, {supportPhone});
        break;
      case 'FR':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_FR;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_FR || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_FR;
        lmsSupportTimings =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_FR || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_FR;
        lmsSupportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_FR;
        break;
      case 'US':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_US;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_US || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_US;
        lmsSupportTimings =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_US || localizedStringBundle.CSPB_LMS_SUPPORT_TIMINGS_US;
        lmsSupportPhone = window.REACT_APP_LMS_SUPPORT_NUMBER_US;
        restorationMessageSuffix = '';
        break;
      case 'IN':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_IN;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_IN || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_IN;
        lmsSupportTimings =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_IN || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_IN;
        restorationMessageSuffix = '';
        supportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_IN;
        break;
      case 'DE':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_DE;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_DE || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_DE;
        lmsSupportTimings =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_DE || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_DE;
        lmsSupportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_DE;
        break;
      case 'GB':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_GB;
        lmsSupportTimings =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_GB || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_GB;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_GB || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_GB;
        break;
      case 'BR':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_BR;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_BR || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_BR;
        supportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_BR;
        lmsSupportTimings = '';
        legalUrl = '';
        disclaimer2 = '';
        langCode = 'pt-BR';
        restorationMessageSuffix = interpolate(localizedStringBundle.TO_OPEN_ONE, {supportPhone});
        break;
      case 'MX':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_MX;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_MX || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_MX;
        supportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_MX;
        lmsSupportTimings = '';
        legalUrl = '';
        disclaimer2 = interpolate(localizedStringBundle.PLAN_DETAILS_DISCLAIMER2_NZ, {legalUrl});
        langCode = 'es-MX';
        restorationMessageSuffix = interpolate(localizedStringBundle.TO_OPEN_ONE, {supportPhone});
        break;
      case 'JP':
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_JP;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_JP || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_JP;
        break;
      case 'AU':
      default:
        supportPhoneRestoration = window.REACT_APP_RESTORATION_SUPPORT_PHONE_AU;
        timingsRestoration =
          window.REACT_APP_RESTORATION_SUPPORT_TIMINGS_AU || localizedStringBundle.RESTORATION_SUPPORT_TIMINGS_AU;
        supportPhone = window.REACT_APP_RESTORATION_SUPPORT_PHONE_AU;
        lmsSupportTimings = '';
        countryName = localizedStringBundle.COUNTRY_ADJECTIVE_AU;
        legalUrl = window.PLAN_DETAILS_LEGAL_URL_AU;
        disclaimer2 = interpolate(localizedStringBundle.PLAN_DETAILS_DISCLAIMER2_AU, {legalUrl});
        langCode = 'en-AU';
        restorationMessageSuffix = interpolate(localizedStringBundle.TO_OPEN_ONE, {supportPhone});
        break;
    }

    return {
      supportPhone,
      countryName,
      disclaimer2,
      langCode,
      supportPhoneRestoration,
      supportLinkRestoration,
      timingsRestoration,
      lmsSupportTimings,
      lmsSupportPhone,
      restorationMessageSuffix,
    };
  }

  /**
   * Gets country specific values of restoration support number and timings by auth countryCode
   *
   * @param {string} countryCode - user's countryCode
   * @param {StringMap} stringBundle - lang string bundle
   *
   * @returns {JSX.Element} - Returns JSX element with country specific support timings and phone number
   */
  static getRestorationSupportInfo(countryCode: string, stringBundle: StringMap): JSX.Element {
    let stringBundleKey = 'RESTORATION_SUPPORT_TIMINGS_US';

    if (['IT', 'NL', 'PL', 'CH', 'BE', 'SE', 'IE'].includes(countryCode)) {
      stringBundleKey = 'RESTORATION_SUPPORT_TIMINGS_EU';
    } else if (countryCode && stringBundle[`RESTORATION_SUPPORT_TIMINGS_${countryCode}`]) {
      stringBundleKey = `RESTORATION_SUPPORT_TIMINGS_${countryCode}`;
    }
    const phoneNumber = window[`REACT_APP_RESTORATION_SUPPORT_PHONE_${countryCode}`]
      ? window[`REACT_APP_RESTORATION_SUPPORT_PHONE_${countryCode}`]
      : window.REACT_APP_RESTORATION_SUPPORT_PHONE_US;

    return (
      <>
        <a className="text-action underline underline-offset-4 hover:no-underline" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </a>
        ,&nbsp;<span>{stringBundle[stringBundleKey]}.</span>
      </>
    );
  }

  /**
   * Gets country specific values by countryCode
   *
   * @param {string} countryCode - user countryCode
   * @param {string} param - default variable name from config file(Ex: if variable is window.REACT_APP_TXM_ERROR_PHONE(this will be US related variable), param will be 'REACT_APP_TXM_ERROR_PHONE')
   * @returns {object} - Returns country specific value(phone number, url etc.)
   */
  static getCountrySpecificValue(countryCode: string, param: string) {
    if (!countryCode || countryCode === 'US') {
      return window[param];
    }

    return window[`${param}_${countryCode}`];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'bureau' implicitly has an 'any' type.
  static getCreditReportHistoryPath(bureau) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_CREDIT_REPORT_HISTORY_PATH}${bureau}`;
  }
  static getCreditPullDatePath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_CREDIT_NEXT_PULL_DATE}`;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'bureau' implicitly has an 'any' type.
  static getCreditReportPath(bureau) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_CREDIT_REPORT_PATH}${bureau}`;
  }

  static getRedirectToPrivacyResults() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_PRIVACY_RESULTS_PATH}`;
  }

  static getModifyAccountPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_MODIFY_ACCOUNT_PATH}`;
  }

  static getMergeAccountPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_MERGE_ACCOUNT_PATH}`;
  }

  static getContactPreferencesPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_CONTACT_PREFERENCES_PATH}`;
  }

  static getStatesPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_STATES_PATH}`;
  }

  static getLogPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_LOG_PATH}`;
  }

  static getMembersPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_MEMBERS_PATH}`;
  }

  static getRefreshTokenPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_NSL_REFRESH_TOKEN_PATH}`;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
  static getSmmAccounts(userId) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_ACCOUNTS}/${userId}/accounts`;
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
  static addSmmAccount(userId, network) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_INIT_ACCOUNT}/?network=${network}&id=${userId}`;
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'userAccountId' implicitly has an 'any' ... Remove this comment to see the full error message
  static reconnectSmmAccount(userAccountId, onlineAccountId, accountNetwork) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_INIT_ACCOUNT}/?network=${accountNetwork}&accountId=${onlineAccountId}&id=${userAccountId}`;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
  static deleteSmmAccount(userId, onlineAccountId, providerDisplayName) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_ACCOUNTS}/${userId}/account/${onlineAccountId}?network=${providerDisplayName}`;
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'accountId' implicitly has an 'any' type... Remove this comment to see the full error message
  static connectSmmAccount(accountId) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_ACCOUNTS}/${accountId}`;
  }

  static getSmmOnboardPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_ONBOARD_STATUS_PATH}`;
  }

  static smmAlertUpdate() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SMM_ALERT_UPDATE}`;
  }

  static getPlanDetailsPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/planDetails`;
  }

  static getLogoutPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/logout`;
  }

  static getJWTPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_CREDIT_JWT_PATH}`;
  }

  static getAlertSummaryPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERTS_SUMMARY_PATH}`;
  }

  static getAlertListInboxPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERTS_INBOX_PATH}`;
  }

  static getAlertListDarkWebPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERTS_DARK_WEB_INBOX_PATH}`;
  }

  static getAlertListDisputedPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERTS_DISPUTED_PATH}`;
  }

  static getTxmJpInitPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TXM_JP_INIT_PATH}`;
  }

  static getTxmJpAccountPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TXM_JP_ACCOUNT_PATH}`;
  }

  static getAllRecurringTransactionsPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALL_RECURRING_TRANSACTIONS_PATH}`;
  }

  static getLLOnboardingPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/members/onboardingFlag`;
  }

  static getBreachSummaryPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/breach/summary`;
  }

  /**
   * Get ISO-format date string for the given number of days backwards in time, or an empty string when
   * input is invalid or exceeds maxDaysBack (default 25 years)
   *
   * @param {number} daysBack - The number of days prior to the current moment in time
   * @param {number} maxDaysBack - The maximum number of days accepted, default of 25 years
   * @returns {string} An ISO-format date string, or empty string when input is invalid or beyond maximum value
   */
  static getIsoDateStringFromDaysBack(daysBack: number, maxDaysBack: number = 25 * 365) {
    const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

    // ensure the input is of proper type, and makes sense.  Otherwise, return empty string (no filtering)
    if (typeof daysBack !== 'number' || daysBack > maxDaysBack) {
      return '';
    }

    // generate the startDate query param string, which should be returned in all cases
    const startDateMilliseconds = Date.now() - daysBack * MILLISECONDS_PER_DAY;
    return new Date(startDateMilliseconds).toISOString();
  }

  /**
   * Generates a query-param string with "startDate" and "endDate" (if needed) parameters, used with alerts queries
   *
   * @param {number} daysBackToStartDate - Specifies the start date as number of days backwards in time from today
   * @param {number} daysBackToEndDate - Specifies the end date as number of days backwards in time from today
   * @returns {string} Query parameter string containing startDate and endDate (if applicable) for a data-api fiql-query
   */
  static getDateFilterQueryParams(daysBackToStartDate: number, daysBackToEndDate: number) {
    const startDate = daysBackToStartDate ? this.getIsoDateStringFromDaysBack(daysBackToStartDate) : '';
    const endDate = daysBackToEndDate ? this.getIsoDateStringFromDaysBack(daysBackToEndDate) : '';
    const startDateQueryParam = startDate ? `&startDate=${startDate}` : '';
    const endDateQueryParam = endDate ? `&endDate=${endDate}` : '';

    return startDateQueryParam + endDateQueryParam;
  }

  /**
   * Get the path to backend api for fetching archived alerts, using the date filter start/end date if provided
   *
   * @param {number} daysBackToStartDate - number of days backwards in time to use as start date in date filter
   * @param {number} daysBackToEndDate - - number of days backwards in time to use as end date in date filter
   * @returns {string} The host name, url and query params for fetching our archived alerts, with date filtering
   */
  static getAlertListArchivedPath(daysBackToStartDate: number, daysBackToEndDate: number) {
    let path = `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERTS_ARCHIVED_PATH}`;
    if (daysBackToStartDate > 0) {
      path += this.getDateFilterQueryParams(daysBackToStartDate, daysBackToEndDate);
    }
    return path;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'alertId' implicitly has an 'any' type.
  static getAlertDetailPath(alertId) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERT_DETAIL_PATH}/${alertId}`;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'alertId' implicitly has an 'any' type.
  static getAlertDetailViewPath(alertId) {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_ALERT_DETAIL_VIEW_PATH}/${alertId}?useFallback=true`;
  }

  static getRestorationCasesPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_RESTORATION_CASES_PATH}`;
  }
  static getScamValidationCasesPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SCAM_PROTECT_CASES_PATH}`;
  }

  static getUploadScamValidationDocumentPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_SCAM_PROTECT_UPLOAD_PATH}`;
  }

  static getLPMPrivacyResultsSummaryPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_PRIVACY_RESULTS_SUMMARY_PATH}`;
  }

  /**
   * Returns member-api url path for the license api to validate the user entitlement.
   *
   * @returns {string} - Returns the url path for license api call
   */
  static getValidateLicensePath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_PRIVACY_MONITOR_VALIDATE_LICENSE}`;
  }

  static getLocksOnboardPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_LOCKS_ONBOARD_STATUS_PATH}`;
  }

  static getFcraConsentPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_FCRA_CONTENT_PATH}`;
  }

  static getQuebecOptInPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_QUEBEC_OPT_IN_PATH}`;
  }

  static getAppDownloadUiPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_APP_DOWNLOAD_PATH}`;
  }

  static getBillingNotificationsPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/notifications/billing`;
  }

  static uploadRestorationDocumentPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_RESTORATION_UPLOAD_PATH}`;
  }

  static getEmailOtpGeneratePath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_EMAIL_OTP_GENERATE_PATH}`;
  }

  static getEmailOtpVerifyPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_EMAIL_OTP_VERIFY_PATH}`;
  }

  static getHomePropertiesPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_HOMEPROPERTIES_PATH}`;
  }

  static getTxmSearchProvidersPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}/v1/transactions/search-providers`;
  }

  /**
   * returns credit uk token endpoint path(token register)
   */
  static getTransunionUkRegisterPath() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_TRANSUNION_UK_REGISTER_PATH}`;
  }

  static resizeIframe(windowSpec?: GetWindowSpecPayload, setHeight?: number) {
    //use this function in componentDidMount and componentDidUpdate
    let minHeight = 0;
    if (windowSpec || (this as $TSFixMe).windowSpecTracker) {
      (this as $TSFixMe).windowSpecTracker = windowSpec || (this as $TSFixMe).windowSpecTracker;
      minHeight =
        (this as $TSFixMe).windowSpecTracker.windowHeight -
        (this as $TSFixMe).windowSpecTracker.footerHeight -
        (this as $TSFixMe).windowSpecTracker.headerHeight;
    }
    //the logic above is to handle an edge case where a modal opens up and the height of the iframe is not large enough to display.
    //assigning windowSpec to utils class as a form of a cache for another edge case where on refresh of page, multiple calls to this method are made and windowSpec is no longer available

    let payload = minHeight > document.body.clientHeight ? minHeight : document.body.clientHeight;

    if (setHeight) {
      payload = setHeight;
    }
    window.parent.postMessage({type: 'RESIZED', payload: payload}, window.REACT_APP_PARENT_HOST);
  }

  static scrollTo(scrollPosition = 0) {
    window.parent.postMessage({type: 'SCROLL_PARENT', payload: scrollPosition}, window.REACT_APP_PARENT_HOST);
  }

  static updateOnboardingFlag() {
    return `${window.REACT_APP_MEMBER_API_HOST}${window.REACT_APP_UPDATE_ONBOARDING_FLAG_PATH}`;
  }

  /**
   * @typedef {"AVAILABLE"|"UPGRADE"|"NOT_AVAILABLE"|"ACTIVATE"} FeatureStatus
   */

  /**
   * @typedef {"ENROLLED"|"PENDING"|"CANCELLED"|"ERROR"|"NOT_AVAILABLE"|"DEFERRED"} FulfillmentStatus
   */

  /**
   * A number, or a string containing a number.
   * @typedef {Object} ProductStatus
   * @property {FeatureStatus} featureStatus
   * @property {FulfillmentStatus} fulfillmentStatus
   */

  /**
   * Given the full set of product features, returns true if the given feature is present.
   * @param {ProductFeatures} productFeatures
   * @param {string} feature - feature that is available for the current user.
   * @return {boolean} - returns true if given feature is present.
   */
  static isFeaturePresent(productFeatures: ProductFeatures | {}, feature: string) {
    return _get(productFeatures, `${feature}.featureStatus`, 'NOT_AVAILABLE') !== 'NOT_AVAILABLE';
  }

  /**
   * Returns true if credit score content should be visible, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static creditScoreVisible(productFeatures: ProductFeatures) {
    return (
      !utils.isPendingPIICollectionState(productFeatures) &&
      (utils.isFeaturePresent(productFeatures, 'creditScore_1B') ||
        utils.isFeaturePresent(productFeatures, 'creditScore_3B') ||
        utils.isFeaturePresent(productFeatures, 'creditScore1bCa') ||
        utils.isCreditUk(productFeatures))
    );
  }

  /**
   * Returns true if transaction monitoring content should be visible, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static txmVisible(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'transactionMonitoring') ||
      utils.isFeaturePresent(productFeatures, 'transactionMonitoringJp') ||
      utils.isFeaturePresent(productFeatures, 'financialMonitoringUk')
    );
  }

  /**
   * Returns true if the product features indicate a TXM upgrade scenario
   * @param {ProductFeatures} productFeatures
   * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
   * @return {boolean}
   */
  static isTxmUpgradeScenario(productFeatures: ProductFeatures | {}, whiteLabelClient: string | null) {
    if (whiteLabelClient) {
      return false;
    }
    const transactionFeatureStatus = _get(productFeatures, 'transactionMonitoring.featureStatus', null);
    const transactionFulfillmentStatus = _get(productFeatures, 'transactionMonitoring.fulfillmentStatus', null);
    return transactionFeatureStatus === 'UPGRADE' && transactionFulfillmentStatus === 'NOT_AVAILABLE';
  }

  /**
   * Returns true if restorationInternational available, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static isRestorationIntl(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'restorationInternational') &&
      _get(productFeatures, 'restorationInternational.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns true if restorationSummary available, based on productFeatures and serviceCode
   * @param {ProductFeatures} productFeatures feature status and fulfillment status of various product features
   * @return {boolean}
   */

  static isRestorationAvailable(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'restorationSummary') &&
      _get(productFeatures, 'restorationSummary.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns true if scamProtect feature is available in the product features.
   * @param {ProductFeatures} productFeatures
   * @returns {boolean}
   */
  static hasScamProtectFeature(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'scamProtect') &&
      _get(productFeatures, 'scamProtect.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns true if restorationInternational available, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static isReimbursement(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'reimbursement') &&
      _get(productFeatures, 'reimbursement.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns true if planDetails feature is available, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static planDetailsVisible(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'planDetails') &&
      _get(productFeatures, 'planDetails.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns true if txm jp available, based on productFeatures
   * @param {ProductFeatures} productFeatures feature status and fulfillment status of various product features
   * @return {boolean}
   */
  static isTxmJpAvailable(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'transactionMonitoringJp') &&
      _get(productFeatures, 'transactionMonitoringJp.featureStatus') === 'AVAILABLE'
    );
  }

  /**
   * Returns transaction threshold drop down list associated with countryCode
   * @param {string} countryCode - country code of user, to determine which drop down list to utilize
   * @param {StringMap} localizedStringBundle - string bundle for messaging
   * @return {object[]}
   */
  static getTxmPrefDropDownList(
    countryCode: string,
    localizedStringBundle: StringMap
  ): {name: string; value: string}[] {
    let dropdownList: number[] = [];
    switch (countryCode) {
      case 'AU':
      case 'GB':
      case 'CA':
      case 'NZ':
      case 'US': {
        dropdownList = [100, 200, 500, 1000, 2000, 5000, 10000, 25000];
        break;
      }
      case 'JP': {
        dropdownList = [
          300, 500, 1000, 3000, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000,
        ];
        break;
      }
    }
    return dropdownList.map((amount) => utils.getThresholdDropListEntry(amount, countryCode, localizedStringBundle));
  }

  /**
   * Returns properly formatted threshold values for associated country
   * @param {number} amount - drop down list amount, to be formatted to specific currency and returned in object.
   * @param {string} countryCode - country code of user, to determine which drop down list to utilize
   * @param {StringMap} localizedStringBundle - string bundle for messaging
   * @return {Object}
   */
  static getThresholdDropListEntry(amount: number, countryCode: string, localizedStringBundle: StringMap) {
    // Default currency is USD
    let memberCurrency = 'USD';

    if (countryCode) {
      memberCurrency = this.getCurrencyCode(countryCode.toUpperCase());
    }

    const formattedAmount = new Intl.NumberFormat(getLangCode(), {
      currency: memberCurrency,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...(((memberCurrency === 'AUD' && getLangCode() !== 'en-AU') ||
        (memberCurrency === 'NZD' && getLangCode() !== 'en-NZ')) && {currencyDisplay: 'code'}),
    }).format(amount);

    return {
      name: interpolate(localizedStringBundle.MANAGE_AP_TXM_OVER_AMOUNT, {formattedAmount}),
      value: amount.toString(),
    };
  }

  /**
   * Returns true if creditScore and txm are visible, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static isOnboardingFlowOne(productFeatures: ProductFeatures) {
    return utils.creditScoreVisible(productFeatures) && utils.txmVisible(productFeatures);
  }

  /**
   * Returns true if restorationInternational.featureStatus is AVAILABLE based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static isOnboardingFlowTwo(productFeatures: ProductFeatures) {
    return utils.isRestorationIntl(productFeatures) || utils.isReimbursement(productFeatures);
  }

  /**
   * Returns product features for the primary member's plan.
   * @param {Object} auth
   * @return {ProductFeatures}
   */
  static getProductFeatures(auth: $TSFixMe) {
    return _get(auth, 'user.primaryMember.plan.productFeatures', {});
  }

  /**
   * Returns true if locks feature content should be visible, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static locksVisible(productFeatures: ProductFeatures) {
    return (
      utils.isFeaturePresent(productFeatures, 'creditLocks') || utils.isFeaturePresent(productFeatures, 'phoneLocks')
    );
  }

  /**
   * Returns true if locks feature content should be visible, based on productFeatures and feature flag
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static creditLocksAvailable(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'creditLocks') && window.REACT_APP_ENABLE_LOCK_FREEZE === true;
  }

  /**
   * Returns true if locks feature content should be visible, based on productFeatures and feature flag
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static phoneLocksAvailable(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'phoneLocks') && window.REACT_APP_ENABLE_PHONE_LOCK === true;
  }

  /**
   * Returns true if monitored info feature content should be visible, based on productFeatures
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static monitoredInfoVisible(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'monitoredInfo');
  }

  /**
   * Returns true/false based on user's access to a page
   * @param {string} path to a particular page
   * @param {ProductFeatures} productFeatures feature status and fulfillment status of various product features
   * @param {Object} marketingDetails of the product
   * @param {string} whiteLabelClient - Identifies the white label client associated with the current user.
   * @param {Object} rest additional arguments
   * @return {boolean}
   */
  static hasAccessToRoute(
    path: string,
    productFeatures: ProductFeatures,
    marketingDetails: MarketingDetails,
    whiteLabelClient: string,
    rest: $TSFixMe
  ) {
    switch (path) {
      case '/transactions':
      case '/transactions/all/search':
      case '/transactions/all':
      case '/txm-transactions/txmJp-add-account-error':
      case '/txm-monitoring/txmJp-add-account-error':
        return utils.txmVisible(productFeatures) && !utils.isTxmUpgradeScenario(productFeatures, whiteLabelClient);
      case '/monitoring':
      case '/monitoring/:section?':
        return utils.monitoredInfoVisible(productFeatures);
      case '/credit':
      case '/credit/:tab':
        return utils.creditScoreVisible(productFeatures);
      case '/dashboard':
        return utils.dashboardVisible(productFeatures, marketingDetails);
      case '/locks':
      case '/locks/freeze':
        return (
          utils.creditLocksAvailable(productFeatures) ||
          utils.phoneLocksAvailable(productFeatures) ||
          utils.isFeaturePresent(productFeatures, 'creditFreeze')
        );
      case '/hometitle':
        return utils.hasHomeTitleMonitoring(productFeatures);
      case '/privacyMonitor':
        return utils.hasPrivacyMonitoring(productFeatures);
      case '/privacyadvisor':
        return utils.hasPrivacyAdvisor(productFeatures);
      case '/social-media-monitoring/add-account-error':
      case '/social-media-monitoring/add-account-success': {
        //if SMM is not available for primary member,check SMM feature is enabled for juniors,
        const isPrimarySmmEnabled = utils.hasSocialMediaMonitoring(productFeatures);
        return isPrimarySmmEnabled
          ? isPrimarySmmEnabled
          : utils.associatedMemberSmmFeatureAvailable((rest as $TSFixMe).associatedMembers);
      }
      case '/plandetails':
        return utils.planDetailsVisible(productFeatures);
      case '/transactions/recurring/all':
        return utils.isFeaturePresent(productFeatures, 'financialMonitoringUk') || this.showFmUiUsCa(productFeatures);
      case '/scam-validation':
        return utils.hasScamProtectFeature(productFeatures);
      default:
        return false;
    }
  }

  /**
   * Returns the title string for the App header
   * @param {ProductFeatures} productFeatures - object specifying product features for current user's plan
   * @param {object} marketingDetails - object specifying marketing details for current user's plan
   * @param {StringMap} localizedStringBundle - An object map with localized (translated) strings
   * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
   * @returns {string}
   */
  static getHeaderTitleString(
    productFeatures: ProductFeatures,
    marketingDetails: MarketingDetails,
    localizedStringBundle: StringMap,
    whiteLabelClient: string
  ) {
    const restorationServiceCode = _get(productFeatures, 'restorationSummary.serviceCode', '');
    if (whiteLabelClient) {
      if (window.REACT_APP_PRODUCT_NAME === 'NortonLifelock') {
        return localizedStringBundle.ID_THEFT_PROTECTION;
      } else {
        return window.REACT_APP_PRODUCT_NAME;
      }
    }
    switch (restorationServiceCode) {
      case 'LLS_RESTORATIONLITE':
        return localizedStringBundle.LIFELOCK_IDENTITY_ADVISOR;
      case 'LLS_RESTORATIONLITE_NOCASES':
        return utils.hasScamProtectFeature(productFeatures)
          ? localizedStringBundle.ID_THEFT_PROTECTION
          : localizedStringBundle.ID_ADVISOR_JAPAN_TITLE;
      case 'LLS_RESTORATIONASST':
        return localizedStringBundle.ID_NAVIGATOR;
      case 'LLS_RESTORATION_HOMETITLE':
        return utils.isHomeTitleStandalone(marketingDetails)
          ? localizedStringBundle.HOMETITLE_PROTECT
          : localizedStringBundle.ID_THEFT_PROTECTION;
      case 'LLS_RESTORATION_ANZ':
        if (utils.isReimbursement(productFeatures)) {
          return utils.isIdAdvisorStandalone(marketingDetails)
            ? localizedStringBundle.ID_ADVISOR_TITLE
            : localizedStringBundle.IDENTITY_THEFT;
        }
        return utils.isIdAdvisorStandalone(marketingDetails)
          ? localizedStringBundle.ID_ADVISOR_TITLE
          : localizedStringBundle.ID_THEFT_PROTECTION;
      case 'LLS_RESTORATION_IND':
        return utils.isIdAdvisorStandalone(marketingDetails)
          ? localizedStringBundle.IDENTITY_THEFT
          : localizedStringBundle.ID_ADVISOR_IDENTITY_TITLE;
      default: {
        if (window.REACT_APP_PRODUCT_NAME === 'NortonLifelock') {
          return localizedStringBundle.ID_THEFT_PROTECTION;
        } else {
          return window.REACT_APP_PRODUCT_NAME;
        }
      }
    }
  }

  /**
   * Returns true when user has freeze access only
   * @param {ProductFeatures} productFeatures - object specifying product features for current user's plan
   * @returns {boolean}
   */
  static showFreeze(productFeatures: ProductFeatures) {
    const isCreditLocksNotAvailable = _get(productFeatures, 'creditLocks.featureStatus', '') !== 'AVAILABLE';

    const isPaydayLoanLocksNotAvailable = _get(productFeatures, 'paydayLoanLocks.featureStatus', '') !== 'AVAILABLE';
    const isPhoneLocksNotAvailable = _get(productFeatures, 'phoneLocks.featureStatus', '') !== 'AVAILABLE';

    const isCreditFreezeAvailable = _get(productFeatures, 'creditFreeze.featureStatus', '') === 'AVAILABLE';
    const isCreditActivated = !(_get(productFeatures, 'creditScore_3B.featureStatus', '') === 'ACTIVATE');
    return (
      isCreditActivated &&
      isCreditLocksNotAvailable &&
      isPaydayLoanLocksNotAvailable &&
      isPhoneLocksNotAvailable &&
      isCreditFreezeAvailable
    );
  }

  /**
   * Returns whether the user has US credit features.
   * Used to add Credit Offers tile to the appropriate parent page.
   * @param {ProductFeatures} productFeatures
   */
  static hasUSCredit(productFeatures: ProductFeatures) {
    return (
      !utils.isPendingPIICollectionState(productFeatures) &&
      (utils.isFeaturePresent(productFeatures, 'creditScore_1B') ||
        utils.isFeaturePresent(productFeatures, 'creditScore_3B')) &&
      !utils.isCreditUpgradeFeatures(productFeatures)
    );
  }

  /**
   * Returns whether the user is enrolled and not in upgrade scenario for credit features
   * @param {ProductFeatures} productFeatures
   * @returns {boolean}
   */
  static isCreditUser(productFeatures: ProductFeatures): boolean {
    return utils.creditScoreVisible(productFeatures) && !utils.isCreditUpgradeFeatures(productFeatures);
  }

  /**
   * Returns boolean indicating if the user has financial offers available in their product features
   * @param {ProductFeatures} productFeatures
   * @returns
   */
  static hasFinancialOffers(productFeatures: ProductFeatures): boolean {
    return utils.isFeatureAvailable(productFeatures, 'financialOffers');
  }

  /**
   * @typedef {Object} MenuItem
   * @property {string} value The path to a particular page
   * @property {string} name The name of the menu item
   */

  /**
   * Returns an array of menu items.  Typically used for the left nav with North Star design.
   * @param {ProductFeatures} productFeatures feature status and fulfillment status of various product features
   * @param {Object} marketingDetails of the product
   * @param {Object} dynamicData containing information about the user's alerts and other dynamic data to be added
   * @param {string} whiteLabelClient - Identifies white label client associated with the current user. Used to select partner-specific image.
   * @param {string} countryCode - countryCode of the user
   * @returns {!Array<MenuItem>}
   */
  static dspMenuList(
    productFeatures: ProductFeatures,
    marketingDetails: MarketingDetails,
    dynamicData: $TSFixMe,
    whiteLabelClient: string
  ) {
    const result: {
      value: string;
      name: string;
      option: {category: string; action: string; label: string};
      iconName: string;
      clickTracking?: boolean;
      supportComponent: string | JSX.Element;
    }[] = [];
    const showFreeze = this.showFreeze(productFeatures);
    const option = {category: 'Left-Nav', action: 'click', label: ''};
    if (utils.dashboardVisible(productFeatures, marketingDetails)) {
      result.push({
        value: '/dashboard',
        name: 'LEFT_NAV_DASHBOARD',
        option: {...option, ...{label: 'Dashboard'}},
        iconName: 'dashboard',
        supportComponent: '',
      });
    }

    /* Alerts */

    const alertListInbox = _get(dynamicData, 'alertListInbox', []);
    const unreadAlerts =
      !_isEmpty(alertListInbox) && alertListInbox?.length > 0
        ? alertListInbox?.filter((alert: {isRead: boolean}) => {
            return !alert.isRead;
          })
        : [];
    const alertsSupportText = unreadAlerts.length === 0 ? '' : unreadAlerts.length;
    const alertIconName = unreadAlerts.length === 0 ? 'alerts' : 'alerts-notification';
    result.push({
      value: '/alerts',
      name: 'LEFT_NAV_ALERTS',
      option: {
        ...option,
        ...{label: 'Alerts'},
      },
      iconName: alertIconName,
      supportComponent: alertsSupportText,
    });

    const showTransactionUpgrade = utils.isTxmUpgradeScenario(productFeatures, whiteLabelClient);

    // Add credit if visible and check that user isn't in a credit upgrade scenario before rendering
    if (utils.isCreditUser(productFeatures)) {
      result.push({
        value: '/credit',
        name: 'LEFT_NAV_CREDIT',
        option: {...option, ...{label: 'Credit'}},
        iconName: 'credit',
        supportComponent: '',
      });
    }

    /* Transactions */

    if (
      !utils.isPendingPIICollectionState(productFeatures) &&
      (utils.creditLocksAvailable(productFeatures) ||
        utils.phoneLocksAvailable(productFeatures) ||
        utils.isFeaturePresent(productFeatures, 'creditFreeze'))
    ) {
      result.push({
        value: '/locks',
        name: showFreeze ? 'FREEZES' : 'LEFT_NAV_IDENTITY_LOCK',
        clickTracking: true,
        option: {...option, ...{label: 'Identity Lock'}},
        iconName: 'identity-lock',
        supportComponent: '',
      });
    }
    if (utils.txmVisible(productFeatures) && !showTransactionUpgrade) {
      const institutionsList = _get(dynamicData, 'institutionsList', []);
      const {connectedInstitutionsCount} = utils.getConnectedInstitutionsCount(institutionsList);
      const triangleWarningUrl = utils.getCdnImagesPath('/dsp-northstar/TriangleWarning', whiteLabelClient);
      const triangleWarningIcon =
        !_isEmpty(institutionsList) && connectedInstitutionsCount < institutionsList?.length ? (
          <Image url={triangleWarningUrl} alt="Error" imageWrapperClasses="w-5" dataTestId={'error-image-icon'} />
        ) : (
          ''
        );
      result.push({
        value: '/transactions',
        name: utils.getTxmNameKey(productFeatures),
        option: {
          ...option,
          ...{label: 'Financial Monitoring'},
        },
        iconName: 'transactions',
        supportComponent: triangleWarningIcon,
      });
    }

    if (utils.hasPrivacyAdvisor(productFeatures)) {
      result.push({
        value: '/privacyadvisor',
        name: 'LEFT_NAV_PRIVACY_ADVISOR',
        option: {...option, ...{label: 'Privacy Advisor'}},
        iconName: 'privacy-advisor',
        supportComponent: '',
      });
    }

    if (utils.hasHomeTitleMonitoring(productFeatures)) {
      const homeTitleMenu = {
        value: '/hometitle',
        name: 'LEFT_NAV_HOMETITLE',
        option: {...option, ...{label: 'Home Title'}},
        iconName: 'home-title',
        supportComponent: '',
      };
      utils.isHomeTitleStandalone(marketingDetails) ? result.unshift(homeTitleMenu) : result.push(homeTitleMenu);
    }

    /* Scam Protection */

    const scamProtectData: ReturnedCase[] =
      _get(dynamicData, 'scamValidationCases.responseData.parentCaseDetails', []) || [];
    const filteredScamProtectCase: ReturnedCase[] = scamProtectData.filter(
      (value: ReturnedCase) => value.parentCaseEndDateTime === null
    );
    const scamProtectCaseCount: string =
      filteredScamProtectCase.length === 0 ? '' : filteredScamProtectCase.length.toString();

    if (utils.hasScamProtectFeature(productFeatures)) {
      result.push({
        value: '/scam-validation',
        name: 'LEFT_NAV_SCAM_PROTECTION',
        option: {...option, ...{label: 'Scam Protection'}},
        iconName: 'scam-protection',
        supportComponent: scamProtectCaseCount,
      });
    }

    /* Restoration */

    const restorationData: ReturnedCase[] =
      _get(dynamicData, 'restorationCases.responseData.parentCaseDetails', []) || [];

    const filteredRestorationCases: ReturnedCase[] = restorationData.filter(
      (value: ReturnedCase) => value.parentCaseEndDateTime === null
    );
    const restorationFilterResults =
      filteredRestorationCases.length === 0 ? '' : filteredRestorationCases.length.toString();

    const restorationIconName =
      filteredRestorationCases.length === 0 ? 'id-restoration' : 'id-restoration-notification';

    if (utils.isRestorationAvailable(productFeatures)) {
      result.push({
        value: '/restoration',
        name: 'LEFT_NAV_ID_RESTORATION',
        option: {...option, ...{label: 'Restoration'}},
        iconName: restorationIconName,
        supportComponent: restorationFilterResults,
      });
    }

    if (utils.monitoredInfoVisible(productFeatures)) {
      result.push({
        value: '/monitoring',
        name: 'LEFT_NAV_MONITORED_INFO',
        option: {
          ...option,
          ...{label: 'Monitored Info'},
        },
        iconName: 'monitored-info',
        supportComponent: '',
      });
    }

    if (utils.planDetailsVisible(productFeatures)) {
      result.push({
        value: '/plandetails',
        name: 'LEFT_NAV_PLAN_DETAILS',
        option: {...option, ...{label: 'Plan Details'}},
        iconName: 'plan-details',
        supportComponent: '',
      });
    }

    return result;
  }

  /**
   * Returns true if user has credit score, txm, or credit monitoring
   * @param {ProductFeatures} productFeatures
   * @param marketingDetails
   * @return {boolean}
   */
  static dashboardVisible(productFeatures: ProductFeatures, marketingDetails = {}) {
    // Show dashboard tab only if plan has credit feature and/or txm feature or has credit monitoring
    return (
      utils.isIdAdvisorStandalone(marketingDetails) ||
      utils.creditScoreVisible(productFeatures) ||
      utils.txmVisible(productFeatures) ||
      utils.hasCreditMonitoring(productFeatures) ||
      utils.isRestorationIntl(productFeatures) ||
      utils.isReimbursement(productFeatures) ||
      utils.hasSocialMediaMonitoring(productFeatures) ||
      (utils.hasHomeTitleMonitoring(productFeatures) &&
        utils.isRestorationAvailable(productFeatures) &&
        utils.monitoredInfoVisible(productFeatures))
    );
  }

  /**
   * Returns true if user has credit monitoring
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasCreditMonitoring(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'creditMonitoring_1B');
  }

  /**
   * Returns true if user has home title monitoring
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasHomeTitleMonitoring(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'homeTitle');
  }

  /**
   * Returns true if user has social media monitoring
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasSocialMediaMonitoring(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'socialMediaMonitoring');
  }

  /**
   * Returns true if user has privacy monitoring
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasPrivacyMonitoring(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'privacyMonitor');
  }

  /**
   * Returns true if user has privacy advisor
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasPrivacyAdvisor(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'privacyAdvisor');
  }

  /**
   * Returns true if user has standalone home title monitoring
   * @param {Object} marketingDetails
   * @returns {boolean}
   */
  static isHomeTitleStandalone(marketingDetails: MarketingDetails) {
    return _get(marketingDetails, 'homeTitle.isStandalone', false) === true;
  }

  /**
   * Returns true if user has standalone id advisor
   * @param {Object} marketingDetails
   * @return {boolean}
   */
  static isIdAdvisorStandalone(marketingDetails: MarketingDetails | {}) {
    return _get(marketingDetails, 'idAdvisor.isStandalone', false) === true;
  }

  /**
   * Returns boolean val depending on service codes of home title and restoration to display home title widget 1/2 vs full on dashboard
   * When plan is premium like ultimatePlus or CSP_6 half width is displayed, when plan is DWM with homeTitle full width is displayed
   * @param {Object} productFeatures
   * @return {boolean}
   * */
  static isHomeTitleWidgetFullWidth(productFeatures: ProductFeatures) {
    const isHomeTitleAvailable = utils.hasHomeTitleMonitoring(productFeatures);
    const isRestorationHomeTitle =
      _get(productFeatures, 'restorationSummary.serviceCode', '') === 'LLS_RESTORATION_HOMETITLE';
    return isHomeTitleAvailable && isRestorationHomeTitle;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'Modal' implicitly has an 'any' type.
  static initializeModal(Modal) {
    Modal.setAppElement('body');
    window.parent.postMessage(
      {
        type: 'GET_PARENT_WINDOW_SPEC',
        payload: {actionToTake: 'updateModalSpec'},
      },
      window.REACT_APP_PARENT_HOST
    );
    window.parent.postMessage({type: 'MODAL_OPENED', payload: true}, window.REACT_APP_PARENT_HOST);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  static closeModal(props) {
    window.parent.postMessage({type: 'MODAL_OPENED', payload: false}, window.REACT_APP_PARENT_HOST);
    props.dispatch({type: 'HIDE_MODAL'});
    props.dispatch(setModalSizeSpec({content: {}}));
  }

  static polyfillsForIe() {
    //IE11 polyfill for startsWith() method//
    if (!String.prototype.startsWith) {
      // eslint-disable-next-line
      String.prototype.startsWith = function (search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
      };
    }

    //IE11 polyfill for find() method//
    if (!Array.prototype.find) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
      Array.prototype.find = function(func) {
        let selectedItem = null;
        if (func && typeof func === 'function') {
          for (let i = 0; i < this.length; i++) {
            if (func(this[i])) {
              selectedItem = this[i];
              break;
            }
          }
        }
        return selectedItem;
      };
    }

    //IE11 polyfill for parseInt//
    if (Number.parseInt === undefined) Number.parseInt = window.parseInt;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'modalType' implicitly has an 'any' type... Remove this comment to see the full error message
  static checkModalState(modalType) {
    return modalType !== null;
  }

  static isIeOrEdge() {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf('MSIE') > 0 || userAgent.indexOf('Trident') > 0 || userAgent.indexOf('Edge') > 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns a boolean of whether the user agent is from a mobile device
   * Considers tablets mobile as well
   * @returns {boolean}
   */
  static isMobileBrowser() {
    const ua = window.navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      // tablet (considered mobile)
      return true;
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      // mobile
      return true;
    }
    return false;
  }

  /**
   * Returns a boolean of whether the user agent is from a standard mobile device
   * @returns {boolean}
   */
  static isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  /**
   * Returns a boolean of whether the user agent is from a standard mobile device that isn't an iPad
   * @returns {boolean}
   */
  static isMobileNonIpadDevice() {
    return /iPhone|iPod|Android/i.test(navigator.userAgent);
  }

  /**
   * Returns a boolean of whether the user agent is an android device
   * @returns {boolean}
   */
  static isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  static sendMessage(payload, host) {
    if (typeof payload !== 'object') {
      throw new Error('expected payload to be a JSON object');
    }
    try {
      window.parent.postMessage(payload, host);
    } catch (error) {
      log.error(error);
    }
  }

  // To find account which are in Priority Polling--Active--Unauthorized/needs_reconnect status
  // @ts-expect-error TS(7006) FIXME: Parameter 'connectedAccounts' implicitly has an 'a... Remove this comment to see the full error message
  static getConnectedAccountsPollingStatus(connectedAccounts) {
    if (!connectedAccounts) {
      return {};
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'accounts' implicitly has an 'any' type.
    return connectedAccounts.reduce((accounts, account) => {
      if (!accounts[account.provider] || account.status === 'polling') {
        accounts[account.provider] = account.status;
      }
      if (accounts[account.provider] !== 'polling' && account.status === 'active') {
        accounts[account.provider] = account.status;
      }
      return accounts;
    }, {});
  }

  /**
   * Ensures that the input value is an array, or else it returns a new array containing the value
   *
   * Note: This is required when parsing some data-api responses, where a field can be either an array
   * of objects, or sometimes comes as a single object.
   *
   * @param {*} value - either an array (to be returned as is) or any non-array variable to be placed inside an array
   * @returns {[]} - the input array, or a new array that contains any input that is non-array and non-null
   */
  static ensureArray(value: $TSFixMe) {
    if (Array.isArray(value)) {
      return value;
    } else if (value === undefined || value === null) {
      return [];
    } else {
      return [value];
    }
  }

  /**
   * Iterates through the institutions in specified list, counting connected and disabled accounts.
   *
   * @param {object[]} institutionsList - institutions with transactions-monitoring accounts data
   * @returns {{connectedAccountsCount: number, disabledAccountsCount: number}}
   */
  static getConnectedAccountsCount(institutionsList: Institution[]) {
    let connectedAccountsCount = 0,
      disabledAccountsCount = 0;

    // bumpCounts is an internal function to increment our counts (connected/disabled) for a single accounts list
    // it gets called below, six times for each institution (once for each of the six types of accounts)
    // @ts-expect-error TS(7006) FIXME: Parameter 'accounts' implicitly has an 'any' type.
    const bumpCounts = (accounts, useStateTest = false) => {
      if (!_isEmpty(accounts)) {
        // make sure we have an array of accounts (sometimes it's a single account object, sometimes an array of them)
        const accountsArray = utils.ensureArray(accounts);
        if (!useStateTest) {
          // count all accounts in list (with no check for disabled ones) and then exit bumpCounts function
          connectedAccountsCount += accountsArray.length;
          return;
        }
        accountsArray.forEach((account) => {
          if (typeof account === 'object' && typeof account.state === 'string') {
            if (account.state === 'DISABLED') disabledAccountsCount++;
            else connectedAccountsCount++;
          }
        });
      }
    };

    // inspect each institution, bumping our counters for each of the six different types of accounts
    if (!_isEmpty(institutionsList) && !(institutionsList as $TSFixMe).noResults) {
      if (Array.isArray(institutionsList)) {
        institutionsList.forEach((institution) => {
          bumpCounts((institution as $TSFixMe).bankingAccounts);
          bumpCounts((institution as $TSFixMe).cardAccounts);
          bumpCounts((institution as $TSFixMe).insuranceAccounts);
          bumpCounts((institution as $TSFixMe).rewardsAccounts);
          bumpCounts((institution as $TSFixMe).investmentAccounts, true);
          bumpCounts((institution as $TSFixMe).loanAccounts, true);
        });
      }
    }
    return {connectedAccountsCount, disabledAccountsCount};
  }

  /**
   * Iterates through the institutions in specified list, counting connected and disabled accounts.
   *
   * @param {object[]} institutionsList - institutions with transactions-monitoring accounts data
   * @returns {{connectedInstitutionsCount: number}}
   */
  static getConnectedInstitutionsCount(institutionsList: Institution[]): {connectedInstitutionsCount: number} {
    const connectedInstitutionsCount =
      _isArray(institutionsList) && !_isEmpty(institutionsList)
        ? institutionsList?.reduce(
            (acc: number, obj: {institutionState: string | string[]; autoRefreshState: string | string[]}) => {
              return (
                acc +
                ((obj.institutionState === 'ACTIVE' &&
                  (obj.autoRefreshState === 'ENABLED_SCHEDULED' ||
                    obj.autoRefreshState === 'UNKNOWN_AUTO_REFRESH_STATE')) ||
                obj.institutionState === 'MT_SUCCESS'
                  ? 1
                  : 0)
              );
            },
            0
          )
        : 0;

    return {connectedInstitutionsCount};
  }

  /**
   * Returns a state of monitored accounts in the format of a fraction if 1 or more monitered accounts are not in connected state; otherwise a number string (eg. 2/5, 99+)
   *
   * @param {Institution[] | Institution | string} institutionsList - institutions array, object, or a string containing financial-monitoring accounts data
   * @param {number} connectedInstitutionsCount - count of connected institutions
   * @param {number} maxCountToShow - max count to show (ie. 99)
   * @returns {string} state of monitored accounts in the format of a fraction if 1 or more monitered accounts are not in connected state; otherwise a number string
   */
  static getMonitoredAccountState(
    institutionsList: Institution | Institution[] | string,
    connectedInstitutionsCount: number,
    maxCountToShow: number
  ) {
    if (
      typeof institutionsList === 'string' ||
      _isEmpty(institutionsList) ||
      !_isEmpty(_get(institutionsList, 'noResults', ''))
    ) {
      return '0';
    }

    if (Array.isArray(institutionsList)) {
      const connectedInstitutionsCountString =
        connectedInstitutionsCount > 99 ? `${maxCountToShow}+` : connectedInstitutionsCount.toString();
      const institutionsListString =
        institutionsList.length > 99 ? `${maxCountToShow}+` : institutionsList.length.toString();

      if (connectedInstitutionsCount === institutionsList.length) {
        return connectedInstitutionsCountString;
      }
      return `${connectedInstitutionsCountString}/${institutionsListString}`;
    }

    if (typeof institutionsList === 'object') {
      if (connectedInstitutionsCount === 1) {
        return '1';
      }
      return '0/1';
    }
    return '0';
  }

  /**
   * Iterates through the institutions in specified list, returning only those that are not connected
   *
   * @param {object[]} institutionsList - institutions with transactions-monitoring accounts data
   * @returns {object[]} disconnectedAccounts - Array of disconnected accounts
   */
  static getNonConnectedInstitutions(institutionsList: Institution[]): Institution[] {
    return !_isEmpty(institutionsList) && !_get(institutionsList, 'noResults')
      ? institutionsList.filter((institution) => {
          const {institutionState, autoRefreshState} = institution;
          const isYodleeActive = institutionState === 'ACTIVE';
          const isYodleeAutoRefreshEnabled =
            autoRefreshState === 'ENABLED_SCHEDULED' || autoRefreshState === 'UNKNOWN_AUTO_REFRESH_STATE';
          const isMtSuccess = institutionState === 'MT_SUCCESS';
          return !((isYodleeActive && isYodleeAutoRefreshEnabled) || isMtSuccess);
        })
      : [];
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
  static getMaskedThreatFromEnd(text) {
    if (_isEmpty(text)) return text;
    // It will catch all the last X&XX-XXX-XXX@X.XXX-X.XX scenario in the threats.
    return text.replace(new RegExp(/[^a-vyz0-9{, ?:}]{2}[ X.{},_@&?!^:\[\]\-\(\)]*$/gim), '');
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
  static getMaskedThreatFromStart(text) {
    if (_isEmpty(text)) return text;
    // It will catch all the start X&XX-XXX-XXX@X.XXX-X.XX scenario in the threats.
    return text.replace(new RegExp(/^[^a-vyz0-9{, ?:}][ X.{},_@&?!^:\[\]\-\(\)]*/gim), '');
  }

  /**
   * Derive alert counts from alertList prop and sets it to the analytics object
   *
   * @param alertListData
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'alertListData' implicitly has an 'any' ... Remove this comment to see the full error message
  static setAlertCountToAnalytics(alertListData) {
    const alertCounts = {
      inboxAlertCount: (_get(alertListData, 'alertListInbox') || []).length,
      disputedAlertCount: (_get(alertListData, 'alertListCaseOpened') || []).length,
      archivedAlertCount: (_get(alertListData, 'alertListArchived') || []).length,
    };
    window.analytics = window.analytics || {};
    Object.assign(window.analytics, alertCounts);
  }

  /**
   * Returns the last matching paramName from params found in input textString
   * @param {object[]} params - array of parameter objects
   * @param {string} textString - text to search within for parameters
   * @param {object} localizedStringBundle - provided for replacing texts within the template strings
   * @param {string} whiteLabelClient - string indicating partner client app
   * @return {string} - matching parameter OR empty string if none found
   */
  static getParamNameForTextString(
    params: $TSFixMe[] = [],
    textString: string,
    localizedStringBundle: StringMap,
    whiteLabelClient: string
  ) {
    let paramName = '';
    if (textString) {
      const paramsDictionary = this.getParamsDictionary(params, localizedStringBundle, whiteLabelClient);
      Object.keys(paramsDictionary).forEach((key) => {
        if (textString.includes('${' + key + '}')) {
          paramName = key;
        }
      });
    }
    return paramName;
  }

  /**
   * Replaces ${key0} ${key1} ${key2} ... strings in textArray with templatized params.
   * @param {array} params - template string such as "${support}}"
   * @param {array} textArray - lines where the template strings will be retrieved from
   * @param {object} localizedStringBundle - provided for replacing texts within the template strings
   * @param {string} whiteLabelClient - string indicating partner client app
   * @return {array} - interpolated array of string with placeholders replaced by corresponding values in replacements
   */
  static parseParamsInTextArray(
    params: Array<any> = [],
    textArray: Array<any>,
    localizedStringBundle: StringMap,
    whiteLabelClient: string
  ) {
    /* eslint-disable no-template-curly-in-string */
    const paramsDictionary = this.getParamsDictionary(params, localizedStringBundle, whiteLabelClient);
    return textArray.map((text) => {
      Object.keys(paramsDictionary).forEach((key) => {
        text = text.replace('${' + key + '}', paramsDictionary[key]);
      });
      return text;
    });
    /* eslint-enable no-template-curly-in-string */
  }

  /**
   * Replaces ${key0} ${key1} ${key2} ... string in textString with templatized params.
   * @param {array} params - template string such as "${support}}"
   * @param {string} textString - lines where the template strings will be retrieved from
   * @param {object} localizedStringBundle - provided for replacing texts within the template strings
   * @param {string} whiteLabelClient - string indicating partner client app
   * @return {string} - interpolated string with placeholders replaced by corresponding values in replacements
   */
  static parseParamsInTextString(
    params: Array<any> = [],
    textString: string,
    localizedStringBundle: StringMap,
    whiteLabelClient: string
  ) {
    if (_isEmpty(textString)) return '';
    const paramsDictionary = this.getParamsDictionary(params, localizedStringBundle, whiteLabelClient);
    Object.keys(paramsDictionary).forEach((key) => {
      textString = textString.replace('${' + key + '}', paramsDictionary[key]);
    });
    return textString;
  }

  /**
   * generates an object of param names and the link to be replaced.
   * @param {string} hrefUrl - url string href attr
   * @param {string} displayText - display text for <a> tag
   * @param {string} dataTestId - data-testid string
   * @returns {string} - string JSX for the anchor element
   */

  static getAnchorTag(hrefUrl: string, displayText: string, dataTestId: string) {
    return `<a class="text-action underline underline-offset-4 hover:no-underline" data-testid=${dataTestId} target='_blank' rel="noopener noreferrer" href='${hrefUrl}'>${displayText}</a>`;
  }

  /**
   * generates an object of param names and the link to be replaced.
   * @param {array} params - template string such as "${support}}"
   * @param {object} localizedStringBundle - string bundle containing localized messages
   * @param {string} whiteLabelClient - string indicating the partner client app
   * @return {object} - an object of param names and the link to be replaced
   */
  static getParamsDictionary(params: Array<any> = [], localizedStringBundle: StringMap, whiteLabelClient: string) {
    const paramsDictionary: StringMap = {};
    params.forEach(function(param) {
      const paramName = _get(param, 'paramName', '');

      switch (param.action) {
        case 'verifyEmail':
          {
            const displayText = _get(param, 'displayText');
            const actionValue = _get(param, 'actionValue');
            paramsDictionary[
              paramName
            ] = `<div class="text-action cursor-pointer hover:text-action" data-cta="verifyEmail" data-email=${actionValue}>${displayText}</div>`;
          }
          break;
        case 'emailVerified':
          {
            const displayText = _get(param, 'displayText');
            paramsDictionary[paramName] = `<img alt=${localizedStringBundle.EMAIL_VERIFIED} src=${utils.getCdnPath(
              '/verified.png',
              whiteLabelClient
            )} /> <span class="font-normal ml-2">${displayText}</span>`;
          }
          break;
        case 'support':
          {
            let supportUrl = _get(param, 'articleUrl', window.REACT_APP_ALERT_DETAIL_FOOTER_SUPPORT_INFO);

            // for partners, ignore URL from member-api (NortonLifeLock support article), use partner override instead
            if (whiteLabelClient) {
              supportUrl = window.REACT_APP_ALERT_DETAIL_FOOTER_SUPPORT_INFO;
            }
            const supportDisplayText = _get(
              param,
              'displayText',
              localizedStringBundle.ALERT_FOOTER_DEFAULT_SUPPORT_TEXT
            );
            paramsDictionary[
              paramName
            ] = `<a class="font-bold text-action support" id="dw-support-link" target='_blank' rel="noopener noreferrer" href='${supportUrl}'>${supportDisplayText}</a>`;
          }
          break;
        case 'review':
          {
            const reviewSiteUrl = _get(param, 'reviewSiteUrl', '');
            const reviewDisplayText = _get(param, 'displayText', '');
            if (reviewSiteUrl) {
              const gaTrackingValues = _get(param, 'gaTrackingValues', {});
              const category = _get(gaTrackingValues, 'category', ''),
                label = _get(gaTrackingValues, 'label', '');
              const option = {
                category: category,
                action: 'View',
                label: label,
              };
              if (category && category !== '' && label && label !== '') {
                utils.trackEvent(option);
              }
              paramsDictionary[
                paramName
              ] = `<a class="font-bold text-action review" id="review-link" data-gaCategory='${category}' data-gaLabel='${label}' target='_blank' rel="noopener noreferrer" href='${reviewSiteUrl}'>${reviewDisplayText}</a>`;
            } else {
              // should-not-happen, but if we don't receive a link URL, then we just show the simple display-text, not a link
              paramsDictionary[paramName] = reviewDisplayText;
            }
          }
          break;
        case 'upgrade':
          {
            const upgradeDisplayText = _get(
              param,
              'displayText',
              localizedStringBundle.ALERT_FOOTER_DEFAULT_UPGRADE_TEXT
            );
            paramsDictionary[
              paramName
            ] = `<span class="cursor-pointer text-action"><span class="upgradeButton">${upgradeDisplayText}</span></span>`;
          }
          break;
        case 'call':
          {
            paramsDictionary[paramName] = _get(param, 'displayText', '');
          }
          break;
        case 'sendEmail':
          {
            paramsDictionary[paramName] = _get(param, 'displayText', '');
          }
          break;
        case 'disposition_yes':
          {
            const dispositionYesText = _get(param, 'displayText', 'Yes');
            paramsDictionary[
              paramName
            ] = `<div class="p-4 font-bold text-center rounded-full cursor-pointer w-25 text-surface disposition_yes bg-safe md:w-50" data-cta=${param.action}>${dispositionYesText}</div>`;
          }
          break;
        case 'disposition_no':
          {
            const dispositionNoText = _get(param, 'displayText', 'No');
            paramsDictionary[
              paramName
            ] = `<div class="p-4 font-bold text-center rounded-full cursor-pointer w-25 text-surface disposition_no bg-danger red md:w-50" data-cta=${param.action}>${dispositionNoText}</div>`;
          }
          break;
        case 'navigate':
          {
            const navigateText = _get(param, 'displayText', 'No');
            paramsDictionary[
              paramName
            ] = `<div class="p-4 font-bold text-center rounded-full cursor-pointer w-25 text-surface disposition_navigate bg-danger red md:w-50" data-cta=${param.action}>${navigateText}</div>`;
          }
          break;
        case 'show-disposition-yes':
        case 'show-disposition-no':
          {
            paramsDictionary[paramName] = _get(param, 'displayText', '');
          }
          break;
        case 'manage_freezes':
        case 'manage_identity_lock':
          {
            const manageLocksFreezeText = _get(param, 'displayText', '');
            paramsDictionary[
              paramName
            ] = `<div id="manage-locks-freeze" class="p-4 text-sm font-bold text-center uppercase cursor-pointer text-surface locks-freeze bg-action"> ${manageLocksFreezeText}</div>`;
          }
          break;
        case 'button':
          {
            const buttonText = _get(param, 'displayText', '');
            const ctaAction = _get(param, 'ctaAction', '');
            paramsDictionary[
              paramName
            ] = `<button type="submit" data-cta= ${ctaAction} class="p-2 w-full text-sm font-bold text-center uppercase rounded border-2 border-solid cursor-pointer text-action border-action md:w-50">${buttonText}</button>`;
          }
          break;
        case 'manage_credit':
        case 'manage_freezes_link':
        case 'manage_identity_locks_link':
        case 'manage_pii':
        case 'manage_restoration':
        case 'manage_txm':
        case 'view_recurring_txm_details':
        case 'restoration_insurance':
        case 'identity_theft':
        case 'password_manager':
          {
            const appUrl = _get(param, 'articleUrl', window.REACT_APP_ALERT_DETAIL_FOOTER_SUPPORT_INFO);
            const internalTarget = _get(param, 'internalTarget', '') || '';
            const targetId = _get(param, 'targetId', '') || '';
            const targetType = _get(param, 'targetType', '') || '';
            const appNavText = _get(param, 'displayText');
            if (internalTarget) {
              paramsDictionary[
                paramName
              ] = `<a class="font-bold text-action internal-nav-${internalTarget}" data-target-id=${targetId} data-target-type=${targetType} id="internal-nav-${internalTarget}" href='${internalTarget}'>${appNavText}</a>`;
            } else {
              paramsDictionary[
                paramName
              ] = `<a class="font-bold text-action support" id="app_nav_${param.action}" target='_blank' rel="noopener noreferrer" href='${appUrl}'>${appNavText}</a>`;
            }
          }
          break;
        case 'legal':
          {
            let legalUrl: string = _get(param, 'articleUrl', window.REACT_APP_LEGAL_URL);
            if (whiteLabelClient) {
              legalUrl = window.REACT_APP_LEGAL_URL;
            }
            const legalDisplayText: string = _get(param, 'displayText', window.REACT_APP_LEGAL_LINK_TEXT);
            paramsDictionary[paramName] = utils.getAnchorTag(legalUrl, legalDisplayText, 'gen-legal-link');
          }
          break;
        case 'smmAlerts':
          {
            let smmUrl: string = _get(param, 'articleUrl', window.REACT_APP_SMM_LINK);
            if (whiteLabelClient) {
              smmUrl = window.REACT_APP_SMM_LINK;
            }
            let smmLinkDisplayText: string = _get(param, 'displayText', window.REACT_APP_SMM_LINK_TEXT);
            if (whiteLabelClient) {
              smmLinkDisplayText = window.REACT_APP_SMM_LINK_TEXT;
            }
            paramsDictionary[paramName] = utils.getAnchorTag(smmUrl, smmLinkDisplayText, 'gen-smm-link');
          }
          break;
        default:
          break;
      }
    });
    return paramsDictionary;
  }

  /**
   * generates original or toggled value with toggle icon
   * @param {ToggleProps} toggle - toggle data for alert
   * @param {string} value - original value
   * @param {Record<string, unknown>} option - ga event
   * @return {JSX.Element} - returns jsx element with original or toggled value including toggle
   */
  static parseAlertValue = (toggle: ToggleProps, value: string, option: Record<string, unknown>) => {
    return _isEmpty(toggle) ? <>{value}</> : <AlertToggle toggleData={toggle} value={value} option={option} />;
  };

  /**
   * @param {array} array containing credit feature statuses
   * @param {array} array containing api call statuses
   * @return {boolean} - returns true/false if credit component is loading
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'creditFeatureStatuses' implicitly has a... Remove this comment to see the full error message
  static isCreditLoading(creditFeatureStatuses, creditApiStatuses) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'status' implicitly has an 'any' type.
    if (creditFeatureStatuses.some((status) => status === 'LOADING')) return true;
    //if 3b is not available then we remove the last api status since it is not applicable
    if (creditFeatureStatuses[2] !== 'AVAILABLE') {
      creditApiStatuses.pop();
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'status' implicitly has an 'any' type.
    if (creditFeatureStatuses.some((status) => status === 'AVAILABLE')) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'status' implicitly has an 'any' type.
      return creditApiStatuses.some((status) => status === 'loading');
    }
    return false;
  }

  /**
   * @param {string} queryString containing the query params string from browser url
   * @return {object} - an object of param names and values
   */
  static parseQueryString(queryString: string) {
    let params = {},
      i,
      l;

    const queries = queryString.split('&');
    for (i = 0, l = queries.length; i < l; i++) {
      const temp = queries[i].split('=');
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      params[temp[0]] = temp[1];
    }
    return params;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'bureau' implicitly has an 'any' type.
  static getBureauInfo(bureau) {
    let bureauDetails = {};
    switch (bureau) {
      case TU_BUREAU_CODE:
        bureauDetails = {
          dispute: 'https://www.transunion.com/credit-disputes/dispute-your-credit',
        };
        break;
      case EXP_BUREAU_CODE:
        bureauDetails = {
          dispute: 'https://www.experian.com/disputes/main.html',
        };
        break;
      case EFX_BUREAU_CODE:
        bureauDetails = {
          dispute: 'https://www.equifax.com/personal/credit-report-services/credit-dispute/',
        };
        break;
      default:
        break;
    }
    return bureauDetails;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
  static hasHtml(text) {
    return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(text);
  }

  static isCreditLocksFeatureAvailable(productFeatures: ProductFeatures) {
    return utils.locksVisible(productFeatures) && window.REACT_APP_ENABLE_LOCK_FREEZE;
  }

  static isCreditLockPending(authProps: AuthState) {
    return _get(authProps, 'user.primaryMember.plan.productFeatures.creditLocks.fulfillmentStatus', null) === 'PENDING';
  }

  static isCreditLockDeferred(authProps: AuthState) {
    return (
      _get(authProps, 'user.primaryMember.plan.productFeatures.creditLocks.fulfillmentStatus', null) === 'DEFERRED'
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'jsonString' implicitly has an 'any' typ... Remove this comment to see the full error message
  static tryParseJson(jsonString) {
    try {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    }

    return false;
  }

  /**
   * @param {string} pageName
   * @param {string} spaExperience Spa Header experience > home title standalone|id advisor standalone|ipts|dwm (for analytics purposes)
   * @param {number} errorCode
   */
  static setPageNameForAnalytics(pageName: string, spaExperience: string, errorCode = 0) {
    let currentPageName;
    currentPageName = this.getPageNameForAnalytics(pageName);
    if (!currentPageName) {
      currentPageName = pageName;
    }
    //for dwm, there is custom logic to always redirect to /alerts in containers/alerts/DarkWebLanding.js
    if (currentPageName === '/') {
      currentPageName = spaExperience === 'dark web' ? 'alerts inbox' : 'dashboard';
    }
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    let errorDesc = errorCodeMapForAnalytics.adobeErrorCodeMap[errorCode];
    if (!errorDesc) {
      errorDesc = 'unknown error';
    }
    const finalPageNameForAnalytics =
      spaExperience !== 'error'
        ? `${spaExperience} ${currentPageName}`
        : `${currentPageName} ${errorCode} ${errorDesc}`;
    if (!window.nortonAnalytics) {
      window.nortonAnalytics = {};
    }
    if (!_isEmpty(window.adobeAnalytics)) {
      Object.assign(window.nortonAnalytics, window.adobeAnalytics);
    }
    if (!window.nortonAnalytics.site_country) {
      window.nortonAnalytics.site_country = 'us';
    }
    window.nortonAnalytics.content_title = window.document.title;
    if (finalPageNameForAnalytics) {
      window.nortonAnalytics.page_name = finalPageNameForAnalytics;
      if (window.s) {
        //This clear call ensures that all vars, props and events are cleared prior to page tracking.
        window.s.clearVars();
        if (window.s.trackPageView) {
          window.s.trackPageView(window.nortonAnalytics);
        }
      }
    }
  }

  /**
   * @param  {string} currentPagePath page pathname
   * @returns {string}
   */
  static getPageNameForAnalytics(currentPagePath: string) {
    let strArr: string[] = [];
    if (currentPagePath && typeof currentPagePath === 'string') {
      strArr = currentPagePath.split('/');
    }
    //the path returned from props could be in form /alerts/inbox/page/1 or /transactions/all/page/1 etc.
    if (strArr.length > 3 && (strArr[1] === 'alerts' || strArr[1] === 'transactions')) {
      currentPagePath = `/${strArr[1]}/${strArr[2]}`;
    } else if (strArr.length > 2 && strArr[1] === 'restoration') {
      //for restoration cases, the url path is in format /restoration/{caseNumber}
      currentPagePath = `/${strArr[1]}`;
    } else if (strArr.length > 2 && strArr[1] === 'monitoring') {
      currentPagePath = `/${strArr[1]}`;
    } else if (strArr.length > 2 && strArr[1] === 'alerts' && strArr[2].match(/^\d+$/)) {
      currentPagePath = `/${strArr[1]}/detail`;
    }
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return pageNameMapForAnalytics.adobePageNameMap[currentPagePath];
  }

  /**
   * sets cookie to expire in 20 minutes, the duration of a web session &
   * member will see given prompt/modal every login
   */
  static saveCookieForModalShown(modalTypePrefix: string, accountId: string) {
    const expires = new Date();
    expires.setTime(new Date().getTime() + 20 * 60 * 1000);
    cookie.save(modalTypePrefix + accountId, SHOWN, {
      path: '/',
      expires,
      domain: '.norton.com',
    });
  }

  /**
   * checks cookie whether the modal has been shown to the member in the current login session
   */
  static checkCookieForModalShown(modalTypePrefix: string, accountId: string) {
    return cookie.load(modalTypePrefix + accountId) === SHOWN;
  }

  // show phone prompt modal only to non-DWM members without a primary phone, and only once every 20 minute session
  // @ts-expect-error TS(7006) FIXME: Parameter 'auth' implicitly has an 'any' type.
  static needsPrimaryPhoneModal(auth) {
    const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', {});
    const isDwm = _get(auth, 'user.primaryMember.plan.isDwm', true);
    const isDashboardVisible = utils.dashboardVisible(productFeatures);
    const isPrimaryPhoneAvailable = !_isEmpty(_get(auth, 'user.primaryMember.primaryPhone', null));
    let primaryPhoneNumber;
    if (isPrimaryPhoneAvailable) {
      primaryPhoneNumber = _get(auth, 'user.primaryMember.primaryPhone.phoneNumber', '');
    }
    const accountId = _get(auth, 'user.primaryMember.accountId', '');
    const hasAlreadyBeenShown = utils.checkCookieForModalShown(PRIMARY_PHONE_MODAL_PREFIX, accountId);
    const hasFullItps = _get(productFeatures, 'restorationSummary.serviceCode', '') === 'LLS_RESTORATION';

    return isDashboardVisible && !isDwm && !primaryPhoneNumber && !hasAlreadyBeenShown && hasFullItps;
  }

  /**
   * Returns if needsDSPOnboarding is true or false
   * Returns false if the isAgent flag is true
   * @param {Object} auth
   * @returns {boolean}
   */
  static needsDspOnBoarding(auth: $TSFixMe) {
    if (_get(auth, 'user.isAgent') === true) {
      return false;
    }
    return _get(auth, 'user.primaryMember.needsDSPOnboarding', false);
  }

  /**
   * Returns if showQuebecReimbursementOptIn is true or false
   * Returns false if the isAgent flag is true
   * @param {Object} auth
   * @returns {boolean}
   */
  static showQuebecReimbursementOptIn(auth: AuthState) {
    if (_get(auth, 'user.isAgent', false)) {
      return false;
    }
    return _get(auth, 'user.primaryMember.plan.showQuebecReimbursementOptIn', false);
  }

  /**
   * SMM : check if we need to get image form social media or from CDN
   * @param {string} url path
   * @param {string} defaultImage path
   * @param {string} whiteLabelClient indicates partner client app
   * @returns {string} Returns path to the image
   */
  static getImagePath(url: string, defaultImage: string, whiteLabelClient: string) {
    const path = url.split('/')[0];
    if (path === 'http:' || _isEmpty(path)) {
      return utils.getCdnPath(defaultImage, whiteLabelClient);
    }
    return path === 'https:' ? url : utils.getCdnPath(`/social_media_monitoring/${url}@3x.png`, whiteLabelClient);
  }

  /**
   *
   * @param productFeatures, user's plan specific product features
   * @returns {boolean}, true for IDNAVIGATOR user, false for rest of the them
   */
  static isIdNavigator(productFeatures: ProductFeatures) {
    return _get(productFeatures, 'restorationSummary.serviceCode', '') === 'LLS_RESTORATIONASST';
  }

  /**
   * Returns true if credit score 1b or 3b is AVAILABLE based on productFeatures
   * @param {Object<string,ProductStatus>} productFeatures
   * @return {boolean}
   */
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'ProductStatus'.
  static creditScoreAvailable(productFeatures: {[s: string]: ProductStatus}) {
    return (
      utils.isFeatureAvailable(productFeatures, 'creditScore_1B') ||
      utils.isFeatureAvailable(productFeatures, 'creditScore_3B')
    );
  }

  /**
   * Returns true if credit score 1b or 3b is AVAILABLE and ENROLLED
   * @param {ProductFeatures} productFeatures
   * @return {boolean}
   */
  static hasEquifaxCredit(productFeatures: ProductFeatures) {
    const creditScore1BFeatureStatus = _get(productFeatures, 'creditScore_1B.featureStatus', '');
    const creditScore1BFulfillmentStatus = _get(productFeatures, 'creditScore_1B.fulfillmentStatus', '');
    const creditScore3BFeatureStatus = _get(productFeatures, 'creditScore_3B.featureStatus', '');
    const creditScore3BFulfillmentStatus = _get(productFeatures, 'creditScore_3B.fulfillmentStatus', '');
    const creditScore1bCaFeatureStatus = _get(productFeatures, 'creditScore1bCa.featureStatus', '');

    return (
      creditScore1bCaFeatureStatus !== 'AVAILABLE' &&
      ((creditScore1BFeatureStatus === 'AVAILABLE' && creditScore1BFulfillmentStatus === 'ENROLLED') ||
        (creditScore3BFeatureStatus === 'AVAILABLE' && creditScore3BFulfillmentStatus === 'ENROLLED'))
    );
  }

  /**
   * Given the full set of product features, checks whether the given feature is AVAILABLE
   * @param productFeatures
   * @param feature
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'productFeatures' implicitly has an 'any... Remove this comment to see the full error message
  static isFeatureAvailable(productFeatures, feature) {
    return _get(productFeatures, `${feature}.featureStatus`, 'NOT_AVAILABLE') === 'AVAILABLE';
  }

  /**
   * Returns true if user is allowed to upgrade to a credit product. False otherwise.
   * @param {object} productFeatures - object with status information on various products
   * @param {string} primaryBureau - identifies primary credit bureau associated with current user's existing credit product
   * @param {string} creditPartnerId - identifies credit bureau associated with product that is an upgrade
   * @param {string} whiteLabelClient - identifies the white label client associated with the current user
   * @returns {boolean}
   */
  static isCreditUpgradeScenario(
    productFeatures: object,
    primaryBureau: string,
    creditPartnerId: string,
    whiteLabelClient: string
  ) {
    if (whiteLabelClient) {
      return false;
    }

    const scoreFeatureStatus1B = _get(productFeatures, 'creditScore_1B.featureStatus', '');
    const scoreFeatureStatus3B = _get(productFeatures, 'creditScore_3B.featureStatus', '');
    const scoreFeatureStatus1bCa = _get(productFeatures, 'creditScore1bCa.featureStatus', '');

    const is1BUpgradable =
      creditPartnerId === EFX_BUREAU_CODE && scoreFeatureStatus1B === 'UPGRADE' && primaryBureau === EFX_BUREAU_CODE;
    const is3BUpgradable =
      (creditPartnerId === TU_BUREAU_CODE || creditPartnerId === EXP_BUREAU_CODE) &&
      scoreFeatureStatus3B === 'UPGRADE' &&
      primaryBureau === EFX_BUREAU_CODE;
    const is1BCaUpgradable = scoreFeatureStatus1bCa === 'UPGRADE' && primaryBureau === TU_BUREAU_CODE;

    return is1BUpgradable || is3BUpgradable || is1BCaUpgradable;
  }

  /**
   * Returns a boolean indicating whether the credit score feature is available but fulfillment is not
   * @param {ProductFeatures} productFeatures
   * @returns {boolean}
   */
  static isCreditScoreUnavailable(productFeatures: ProductFeatures) {
    const scoreFeatureStatus1B = _get(productFeatures, 'creditScore_1B.featureStatus', '');
    const scoreFeatureStatus3B = _get(productFeatures, 'creditScore_3B.featureStatus', '');
    const scoreFeatureStatus1bCa = _get(productFeatures, 'creditScore1bCa.featureStatus', '');
    const fulfillmentStatus1B = _get(productFeatures, 'creditScore_1B.fulfillmentStatus', '');
    const fulfillmentStatus3B = _get(productFeatures, 'creditScore_3B.fulfillmentStatus', '');
    const fulfillmentStatus1bCa = _get(productFeatures, 'creditScore1bCa.fulfillmentStatus', '');

    return (
      (scoreFeatureStatus3B === 'AVAILABLE' && fulfillmentStatus3B === 'NOT_AVAILABLE') ||
      (scoreFeatureStatus1B === 'AVAILABLE' && fulfillmentStatus1B === 'NOT_AVAILABLE') ||
      (scoreFeatureStatus1bCa === 'AVAILABLE' && fulfillmentStatus1bCa === 'NOT_AVAILABLE')
    );
  }

  /**
   * Returns if the user has credit score features in UPGRADE feature status and without any ACTIVATE or AVAILABLE feature statuses
   * @param productFeatures - user's plan product features
   * @returns {boolean}
   */
  static isCreditUpgradeFeatures(productFeatures: object) {
    const scoreFeatureStatus1B = _get(productFeatures, 'creditScore_1B.featureStatus', '');
    const scoreFeatureStatus3B = _get(productFeatures, 'creditScore_3B.featureStatus', '');
    const scoreFeatureStatus1bCa = _get(productFeatures, 'creditScore1bCa.featureStatus', '');

    const hasUpgradeStatus =
      scoreFeatureStatus1B === 'UPGRADE' || scoreFeatureStatus3B === 'UPGRADE' || scoreFeatureStatus1bCa === 'UPGRADE';
    const hasNoAvailableStatus = !(
      scoreFeatureStatus1B === 'AVAILABLE' ||
      scoreFeatureStatus3B === 'AVAILABLE' ||
      scoreFeatureStatus1bCa === 'AVAILABLE'
    );
    const hasNoActiveStatus = !(
      scoreFeatureStatus1B === 'ACTIVE' ||
      scoreFeatureStatus3B === 'ACTIVE' ||
      scoreFeatureStatus1bCa === 'ACTIVE'
    );

    return hasUpgradeStatus && hasNoActiveStatus && hasNoAvailableStatus;
  }

  static isCreditActivateScenario(
    productFeatures: object,
    primaryBureau: string,
    creditPartnerId: string = EFX_BUREAU_CODE
  ) {
    const scoreFeatureStatus1B = _get(productFeatures, 'creditScore_1B.featureStatus', '');
    const scoreFeatureStatus3B = _get(productFeatures, 'creditScore_3B.featureStatus', '');
    const scoreFeatureStatus1bCa = _get(productFeatures, 'creditScore1bCa.featureStatus', '');
    const creditMonitoring1B = _get(productFeatures, 'creditMonitoring_1B.featureStatus', '');
    const creditMonitoring3B = _get(productFeatures, 'creditMonitoring_3B.featureStatus', '');
    const isEfxActivateRequired = creditPartnerId === EFX_BUREAU_CODE && scoreFeatureStatus1B === 'ACTIVATE';
    const isTuExpActivateRequired =
      (creditPartnerId === TU_BUREAU_CODE || creditPartnerId === EXP_BUREAU_CODE) &&
      scoreFeatureStatus3B === 'ACTIVATE';
    const isTuCaActivateRequired = scoreFeatureStatus1bCa === 'ACTIVATE';

    return (
      ((isEfxActivateRequired ||
        isTuExpActivateRequired ||
        creditMonitoring1B === 'ACTIVATE' ||
        creditMonitoring3B === 'ACTIVATE') &&
        primaryBureau === EFX_BUREAU_CODE) ||
      (isTuCaActivateRequired && primaryBureau === TU_BUREAU_CODE)
    );
  }

  /**
   * Returns the transaction preference values
   * @param {Object}  inputData
   * @returns {Object}
   */
  static getTransactionPrefs(inputData: $TSFixMe) {
    const pIndex = _findIndex(inputData, {type: 'PURCHASE'});
    const tIndex = _findIndex(inputData, {type: 'TRANSFER'});
    const wIndex = _findIndex(inputData, {type: 'WITHDRAWAL'});
    const jpTransactionIndex = _findIndex(inputData, {type: 'TRANSACTION'});
    return {
      transaction: _get(inputData[pIndex], 'value', '') || _get(inputData[jpTransactionIndex], 'value', ''),
      transfer: _get(inputData[tIndex], 'value', ''),
      withdrawal: _get(inputData[wIndex], 'value', ''),
    };
  }

  /**
   * compare function that defines the sort order. its an input to Array.prototype.sort
   * @param {string} field - to sort on
   * @param {boolean} reverse
   * @param {function} primer
   */
  static sortBy(field: string, reverse: boolean, primer: $TSFixMeFunction) {
    let reverseVal = reverse;
    const key = primer
      ? // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        function(x) {
          return primer(x[field]);
        }
      : // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
        function(x) {
          return x[field];
        };
    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'boolean'.
    reverseVal = !reverseVal ? 1 : -1;
    // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
    return function(a, b) {
      const val1 = key(a);
      const val2 = key(b);
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return reverseVal * ((val1 > val2) - (val2 > val1));
    };
  }

  /** General function for PhoneModal and PrimaryPhonePromptModal
   * for evaluating success msg and dispatch PersonalInfoStatusModal
   * @param {function} localizedStringBundle
   * @param {function} dispatch
   * **/
  static dispatchPersonalInfoStatusModal(localizedStringBundle: StringMap, dispatch: $TSFixMeFunction) {
    const statusInfo = {
      header: localizedStringBundle.MANAGE_MODAL_PHONE_HEADER,
      icon: localizedStringBundle.MANAGE_MODAL_PHONE_ICON,
      message: '',
      origin: 'PHONE',
    };
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'MA_PERSONAL_INFO_STATUS_MODAL',
      modalProps: {
        data: statusInfo,
      },
    });
    utils.trackEventForMonitoredInfo('Phone', 'save');
  }

  /** General function to check if smm needs to be displayed
   * @param {object} auth
   * @return {boolean}
   * **/
  static smmFeatureAvailable(auth: $TSFixMe) {
    let smmFulfillmentStatus = _get(
      auth,
      'user.primaryMember.plan.productFeatures.socialMediaMonitoring.fulfillmentStatus'
    );
    //SMM feature is not enabled for primary, check SMM is enabled for junior
    if (smmFulfillmentStatus === 'NOT_AVAILABLE') {
      // check if any associated member has SMM feature enabled, in case SMM is not enabled for primary member
      const associatedMembers = _get(auth, 'user.associatedMembers', []);
      if (Array.isArray(associatedMembers) && associatedMembers.length > 0) {
        const associatedMemberSmmEnabled = utils.getSmmEnabledAssociatedMember(associatedMembers);
        const productFeatures =
          Array.isArray(associatedMemberSmmEnabled) && associatedMemberSmmEnabled.length > 0
            ? _get(associatedMemberSmmEnabled[0], 'plan.productFeatures')
            : null;
        if (productFeatures) {
          smmFulfillmentStatus = _get(productFeatures, 'socialMediaMonitoring.fulfillmentStatus');
        }
      }
    }

    return (
      window.REACT_APP_ENABLE_SOCIAL_MEDIA_MONITORING === true &&
      (smmFulfillmentStatus === 'ENROLLED' || smmFulfillmentStatus === 'PENDING')
    );
  }

  /** General function to check if smm needs to be enabled for primary member
   * @param {object} auth
   * @return {boolean}
   * **/
  static smmFeatureAvailableForPrimary(auth: $TSFixMe) {
    const smmFulfillmentStatus = _get(
      auth,
      'user.primaryMember.plan.productFeatures.socialMediaMonitoring.fulfillmentStatus'
    );

    return (
      window.REACT_APP_ENABLE_SOCIAL_MEDIA_MONITORING === true &&
      (smmFulfillmentStatus === 'ENROLLED' || smmFulfillmentStatus === 'PENDING')
    );
  }

  /**
   * Check SMM feature avaiable for associated members
   * @param {Array} associatedMembers - Array of household members object
   * @return {boolean}
   */
  static associatedMemberSmmFeatureAvailable(associatedMembers: Array<any>) {
    const associatedMemberSmmEnabled = utils.getSmmEnabledAssociatedMember(associatedMembers);
    return !!(Array.isArray(associatedMemberSmmEnabled) && associatedMemberSmmEnabled.length > 0);
  }

  /**
   * Function to get SMM enabled associated member product features
   * @param {Array} associatedMembers - Array of household members object
   * @return {Array} - SMM enabled associated members object
   */
  static getSmmEnabledAssociatedMember(associatedMembers: Array<any>) {
    return associatedMembers.filter(
      (member) => utils.hasSocialMediaMonitoring(member.plan.productFeatures) && member.relationship === 'Guardian'
    );
  }

  /**
   * Checks if given feature is available for associated members
   * @param {Array} associatedMembers - Array of household members object
   * @param {string} featureName - feature name string like 'childFreezes'
   * @return {boolean}
   */
  static associatedMemberFeatureAvailable(associatedMembers: Array<any>, featureName: string) {
    const associatedMemberFeatureEnabled = utils.getFeatureEnabledAssociatedMember(associatedMembers, featureName);
    return !!(Array.isArray(associatedMemberFeatureEnabled) && associatedMemberFeatureEnabled.length > 0);
  }

  /**
   * Function to get feature enabled associated member product features
   * @param {Array} associatedMembers - Array of household members object
   * @param {string} featureName - feature name string like 'childFreezes'
   * @return {Array} - feature name enabled associated members object
   */
  static getFeatureEnabledAssociatedMember(associatedMembers: Array<any>, featureName: string) {
    return associatedMembers.filter(
      (member) => utils.isFeaturePresent(member.plan.productFeatures, featureName) && member.relationship === 'Guardian'
    );
  }

  /** General function for Transactions and TransactionsOverview
   * for evaluating success msg and dispatch DeleteInstitutionModal
   * @param institutionId - institutionId
   * @param memSiteAccountId - memSiteAccountId
   * @param e - the triggering event
   * @param {function} localizedStringBundle
   * @param {function} dispatch
   * **/
  static dispatchTransactionsDeleteAccountModal(
    // @ts-expect-error TS(7006) FIXME: Parameter 'institutionId' implicitly has an 'any' ... Remove this comment to see the full error message
    institutionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'memSiteAccountId' implicitly has an 'an... Remove this comment to see the full error message
    memSiteAccountId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    e,
    localizedStringBundle: StringMap,
    dispatch: $TSFixMeFunction
  ) {
    e.preventDefault();
    utils.trackEventForManageAccount('TXM_Monitor', localizedStringBundle.MANAGE_ACCOUNT_EDIT, true);
    dispatch({
      type: 'SHOW_MODAL',
      modalType: 'DELETE_INSTITUTION_MODAL',
      modalProps: {
        data: {
          institutionId,
          memSiteAccountId,
        },
        static: {
          header: localizedStringBundle.MANAGE_MODAL_TRANSACTION_MONITORING_HEADER,
          icon: localizedStringBundle.MANAGE_MODAL_TRANSACTION_MONITORING_ICON,
          title: localizedStringBundle.MANAGE_MODAL_TRANSACTION_MONITORING_HEADER_REMOVE,
          feature: '',
          infoTitle: localizedStringBundle.MANAGE_MODAL_TRANSACTION_MONITORING_INFO_TITLE,
          text1: localizedStringBundle.MANAGE_MODAL_TRANSACTION_MONITORING_INFO_TEXT1,
        },
      },
    });
  }

  /** General function to check valid url
   * @param {string} url - Url to be tested
   * @return {boolean} - returns true/false if url is valid
   */
  static isValidUrl(url: string) {
    const matchPattern =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
    return matchPattern.test(url);
  }

  /** General function to open credit kba window
   */
  static openTuKba() {
    utils.sendMessage(
      {type: 'CHANGE_WINDOW_LOCATION', payload: window.REACT_APP_ACTIVATE_CREDIT_KBA},
      window.REACT_APP_PARENT_HOST
    );
  }

  /** function to open credit activation window - pending credit
   */
  static openCreditActivationPendingCredit() {
    utils.sendMessage(
      {
        type: 'CHANGE_WINDOW_LOCATION',
        payload: window.REACT_APP_ACTIVATE_CREDIT_KBA + '?exiturl=' + window.REACT_APP_NORTON_DSP_LINK,
      },
      window.REACT_APP_PARENT_HOST
    );
  }

  /** function to open credit activation window - pending pii
   */
  static openCreditActivationPendingPii() {
    utils.sendMessage(
      {
        type: 'CHANGE_WINDOW_LOCATION',
        payload: window.REACT_APP_ACTIVATE_CREDIT + '?exiturl=' + window.REACT_APP_NORTON_DSP_LINK,
      },
      window.REACT_APP_PARENT_HOST
    );
  }

  /**
   * General function to scroll component to specific top
   * @param {string} section - Id of the section to be scrolled
   * @param {string[]} availableSections - Group of section that can be scrolled to
   */
  static scrollToSection(section = '', availableSections: string[] = []) {
    if (section && availableSections.indexOf(section) !== -1) {
      const el = document.getElementById(section);
      const elementSpec = el?.getBoundingClientRect();
      if (elementSpec && elementSpec?.top) {
        window.parent.postMessage(
          {type: 'SCROLL_PARENT', payload: _get(elementSpec, 'top', 0)},
          window.REACT_APP_PARENT_HOST
        );
      }
    }
  }

  /**
   * Returns true when plan contains credit uk feature
   * @param {ProductFeatures} productFeatures - contains all the features of a plan
   * @returns {boolean} true when plan contains credit uk feature, false otherwise
   */
  static isCreditUk(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'creditView_1B_Gb');
  }

  /**
   * Send GA event
   * @param {string} category - denotes the category
   * @param {string} action - denotes the action(click,...)
   * @param {string} label - denotes the label(it can be page name,....)
   * @param {number} value - denotes the value
   */
  static triggerGaEvent(category: string, action: string, label: string, value = 1) {
    const option = {
      category,
      action,
      label,
      value,
    };
    utils.trackEvent(option);
  }

  /**
   * Renders footer for the credit uk feature
   * @param {object} localizedStringBundle contains the strings corresponding to user locale
   * @returns {JSX.Element} JSX for the credit uk feature
   */
  static renderCreditUkFooter(localizedStringBundle: StringMap) {
    return (
      <div
        className="m-4 flex-grow basis-full px-4 text-center text-sm text-secondary lg:px-0"
        data-testid="page-footer"
      >
        {localizedStringBundle.CREDIT_UK_FOOTER}
      </div>
    );
  }

  /**
   * Gets currency code by country code
   * @param {string} countryCode - countryCode string of a given member
   * @return {string} currencyCode - Currency code (e.g. USD, JPY, CAD) for use in formatting currency amounts.
   */
  static getCurrencyCode(countryCode: string) {
    switch (countryCode) {
      case 'AU':
        return 'AUD';
      case 'CA':
        return 'CAD';
      case 'JP':
        return 'JPY';
      case 'GB':
        return 'GBP';
      case 'NZ':
        return 'NZD';
      case 'US':
      default:
        return 'USD';
    }
  }

  /**
   * Gets page title by location pathname
   * @param {string} pathname - Location pathname
   * @param {StringMap} localizedStringBundle - string bundle
   * @return {string} title (Dashboard, Alerts, etc.)
   */
  static getPageTitle(pathname: string, localizedStringBundle: StringMap) {
    // GUTS-3080: Adds non translated default title incase localizedStringBundle is not initialized.
    let pageTitle: string = localizedStringBundle.LEFT_NAV_DASHBOARD || 'Dashboard';
    switch (pathname) {
      case 'dashboard':
        pageTitle = localizedStringBundle.LEFT_NAV_DASHBOARD;
        break;
      case 'alerts':
        pageTitle = localizedStringBundle.LEFT_NAV_ALERTS;
        break;
      case 'credit':
        pageTitle = localizedStringBundle.LEFT_NAV_CREDIT;
        break;
      case 'locks':
        pageTitle = localizedStringBundle.LEFT_NAV_IDENTITY_LOCK;
        break;
      case 'hometitle':
        pageTitle = localizedStringBundle.LEFT_NAV_HOMETITLE;
        break;
      case 'transactions':
        pageTitle = localizedStringBundle.LEFT_NAV_TRANSACTIONS;
        break;
      case 'restoration':
        pageTitle = localizedStringBundle.LEFT_NAV_ID_RESTORATION;
        break;
      case 'monitoring':
        pageTitle = localizedStringBundle.LEFT_NAV_MONITORED_INFO;
        break;
      case 'plandetails':
        pageTitle = localizedStringBundle.LEFT_NAV_PLAN_DETAILS;
        break;
      case 'privacyadvisor':
        pageTitle = localizedStringBundle.LEFT_NAV_PRIVACY_ADVISOR;
        break;
    }
    return pageTitle;
  }

  static isTxmUkAvailable(productFeatures: ProductFeatures) {
    return _get(productFeatures, 'financialMonitoringUk.featureStatus', 'NOT_AVAILABLE') === 'AVAILABLE';
  }

  /**
   * show financial monitoring UK UI for US and Canada
   * @param {ProductFeatures} productFeatures - object specifying product features for current user's plan
   * @return {boolean} - returns true if user has transactionMonitoring enabled
   */
  static showFmUiUsCa(productFeatures: ProductFeatures) {
    return utils.isFeaturePresent(productFeatures, 'transactionMonitoring');
  }

  /**
   * Gets transaction item key for navigation menu by coountryCode
   * @param {ProductFeatures} productFeatures - object specifying product features for current user's plan
   * @return {string} transaction item key
   */
  static getTxmNameKey(productFeatures: ProductFeatures) {
    return this.isTxmUkAvailable(productFeatures) || this.showFmUiUsCa(productFeatures)
      ? 'LEFT_NAV_FINANCIAL_MONITORING'
      : 'LEFT_NAV_TRANSACTIONS';
  }

  /**
   * Converts a rem value to pixels based on the current font size of the document
   * @param {number} rem - the rem number to convert to pixels
   * @param {HTMLElement | null} documentElement - the element who's font size to use
   * @returns {number} - pixel value of the input rem, defaults to 0
   */
  static convertRemToPixels(rem: number, documentElement: HTMLElement | null) {
    return documentElement ? rem * parseFloat(getComputedStyle(documentElement).fontSize) : 0;
  }

  /**
   * Tests a candidate URL against a list of white-listed URL domain values
   * @param {string} url - The candidate URL to be checked against the white-listed domains
   * @param {string[]} whiteListedDomains - list of pure domain strings (eg. api.equifax.com) that are white-listed
   *
   * @returns {boolean} True if the candidate URL has a domain value that is white-listed
   */
  static isUrlWhiteListed(url: string, whiteListedDomains: string[]) {
    // Use the JavaScript URL class to get the hostname, which is scheme prefix ("www.") + full domain
    try {
      const urlObj = new URL(url);
      const urlDomain = urlObj.hostname;
      return whiteListedDomains.includes(urlDomain);
    } catch {
      // This is mainly to catch "TypeError" exceptions thrown by the URL constructor for invalid URL string input
      return false;
    }
  }

  /**
   * Returns true if product features have deferred fulfillment eligible and deferred state is pending pii
   * @param {ProductFeatures} productFeatures - contains all the features of a plan
   * @returns {boolean} Returns true if product features have deferred fulfillment eligible and deferred state is pending pii
   */
  static isPendingPIICollectionState(productFeatures: ProductFeatures): boolean {
    const deferredEnrollmentEligible = _get(productFeatures, 'deferredServicesSummary.deferredEligible', false);
    const deferredState = _get(productFeatures, 'deferredServicesSummary.deferredState', '');
    return deferredEnrollmentEligible && deferredState === 'PENDING_PII';
  }

  /**
   * Returns true if product features have deferred fulfillment eligible and deferred state is pending credit
   * @param {ProductFeatures} productFeatures - contains all the features of a plan
   * @returns {boolean} Returns true if product features have deferred fulfillment eligible and deferred state is pending credit
   */
  static isPendingCreditCollectionState(productFeatures: ProductFeatures): boolean {
    const deferredEnrollmentEligible = _get(productFeatures, 'deferredServicesSummary.deferredEligible', false);
    const deferredState = _get(productFeatures, 'deferredServicesSummary.deferredState', '');
    return deferredEnrollmentEligible && deferredState === 'PENDING_CREDIT';
  }

  /**
   * Helper method to add spaces around back slashes in a string for enhanced line breaking
   * @param {string} str - string to be modified
   * @returns {string} Returns modified string with spaces around back slashes
   */
  static addSpacesAroundSlash(str: string) {
    return str.replace(/\//g, ' / ');
  }

  /**
   * Helper method to get displayLang, countryCode, partnerId, puId to be used in site director support urls
   * @param {AuthState} auth
   */
  static getSupportUrlParams(auth: AuthState) {
    const displayLang = (getBrowserLanguage() || 'en').substring(0, 2);
    const countryCode = _get(auth, 'user.countryCode', 'US');
    const partnerId = (_get(auth, 'user.primaryMember.plan.partnerDetails.partnerId', '') || '').toString();
    const puId = (_get(auth, 'user.primaryMember.plan.partnerDetails.partnerUnitId', '') || '').toString();
    return {displayLang, countryCode, partnerId, puId};
  }

  /**
   * Returns a site director support url
   * @param {string} helpId - value for helpid param
   * @param {string} displayLang - value for displayLang param
   * @param {string} countryCode - value for countryCode param
   * @param {string} partnerId - value for partnerid param
   * @param {string} puid - value for puid param
   * @param {string} ssdCat - value for ssdCat param; default value is '243'
   * @returns {string} Returns a site director support url
   */
  static getSupportUrl(
    helpId: string,
    displayLang: string,
    countryCode: string,
    partnerId: string,
    puid: string,
    ssdCat = LL_SSDCAT
  ) {
    let url = interpolate(window.REACT_APP_SITEDIRECTOR_URL, {
      helpid: helpId,
      env: window.REACT_APP_LABEL === 'production' ? 'prod' : 'int',
      displayLang,
      localeParam: _isEmpty(countryCode) ? 'US' : countryCode,
      partnerid: partnerId || '',
      puid: puid || '',
    });
    if (ssdCat !== LL_SSDCAT) {
      url = url.replace(`ssdcat=${LL_SSDCAT}`, `ssdcat=${ssdCat}`);
    }
    if (partnerId && ![LL_SITE_DIRECORY_PARTNER_ID].includes(partnerId)) {
      url += '&layouttype=sos';
    }
    return url;
  }

  /**
   * Override specific values in config.js using site director url.
   * These keys are overridden for partners in src/helper/partnerUtils.js.
   * @param {string} displayLang - value for displayLang param
   * @param {string} countryCode - value for countryCode param
   * @param {string} partnerId - value for partnerid param
   * @param {string} puid - value for puid param
   * @param {string} ssdCat - value for ssdCat param; default value is '243'
   * @returns {string} Returns a site director support url
   */
  static overrideLocksAndFreezeSupportUrl(
    displayLang: string,
    countryCode: string,
    partnerId: string,
    puId: string,
    LL_SSDCAT: string
  ) {
    window.REACT_APP_CREDIT_FREEZE_IRS_LEARN_MORE = utils.getSupportUrl(
      'Freeze_IRS',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_BANK_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'TXM_Bank_Freeze',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_CHILD_CREDIT_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'Child_Credit',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_CREDIT_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'Credit_Freeze_learnmore',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_CREDIT_FREEZE_EXPERIAN_KB_LINK = utils.getSupportUrl(
      'Credit_Freeze_Experian',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_EQUIFAX_LEARN_MORE = utils.getSupportUrl(
      'Data_Freeze_Equifax',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_EVERIFY_LEARN_MORE = utils.getSupportUrl(
      'Everify_learnmore',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_IDENITY_LOCK_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'IDTheft_Learnmore',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_PAYDAY_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'Payday_Loan',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_UTILITIES_LEARN_MORE_ARTICLE = utils.getSupportUrl(
      'Freeze_Utilities',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
    window.REACT_APP_FREEZE_AND_LOCK_DIFFERENT_LINK = utils.getSupportUrl(
      'Lock_Freeze_Diff',
      displayLang,
      countryCode,
      partnerId,
      puId,
      LL_SSDCAT
    );
  }
}

utils.resizeIframe = _debounce(utils.resizeIframe, 150);
