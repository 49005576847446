import utils from '../../helpers/utils';
import _get from 'lodash/get';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../reducers/rootReducer';
import Image from '../../components/common/VectorImage';

const AlertToggle = ({toggleData, value, option}: AlertToggleProps) => {
  const whiteLabelClient = useSelector((store: RootState) => _get(store, 'auth.whiteLabelClient', '') || '');
  const toggleOnIcon = _get(toggleData, 'onIcon', '') || '';
  const toggleOffIcon = _get(toggleData, 'offIcon', '') || '';
  const toggleValue = _get(toggleData, 'value', '') || '';
  const toggleState = _get(toggleData, 'state', true);
  const [isToggleOn, setIsToggleOn] = useState(toggleState);
  const iconName = isToggleOn ? toggleOffIcon : toggleOnIcon;
  const iconUrl = utils.getCdnImagesPath(`/dsp-northstar/${iconName}`, whiteLabelClient);

  const handleToggle = () => {
    setIsToggleOn(!isToggleOn);
    if (option) {
      utils.trackEvent(option);
    }
  };

  return (
    <div className="flex">
      {isToggleOn ? toggleValue : value}
      <div onClick={handleToggle}>
        <Image classes="ml-4 h-6 w-6 cursor-pointer" alt="toggle-icon" url={iconUrl} dataTestId={'alert-toggle-icon'} />
      </div>
    </div>
  );
};

export default AlertToggle;
