import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const EMAIL_OTP_GENERATE_REQUEST = 'EMAIL_OTP_GENERATE_REQUEST';
export const EMAIL_OTP_GENERATE_SUCCESS = 'EMAIL_OTP_GENERATE_SUCCESS';
export const EMAIL_OTP_GENERATE_FAILURE = 'EMAIL_OTP_GENERATE_FAILURE';
export const EMAIL_OTP_VERIFY_REQUEST = 'EMAIL_OTP_VERIFY_REQUEST';
export const EMAIL_OTP_VERIFY_SUCCESS = 'EMAIL_OTP_VERIFY_SUCCESS';
export const EMAIL_OTP_VERIFY_FAILURE = 'EMAIL_OTP_VERIFY_FAILURE';
export const RESET_EMAIL_OTP_STATE = 'RESET_EMAIL_OTP_STATE';

export function generateEmailOtpRequest() {
  return {
    type: EMAIL_OTP_GENERATE_REQUEST,
  };
}

export function generateEmailOtpSuccess(payload: $TSFixMe) {
  return {
    type: EMAIL_OTP_GENERATE_SUCCESS,
    payload,
  };
}

export function generateEmailOtpFailure(error: $TSFixMe) {
  const options = {
    category: 'Modal - Password Combo',
    action: 'click',
    label: 'Error getting code',
  };
  utils.trackEvent(options);
  return {
    type: EMAIL_OTP_GENERATE_FAILURE,
    error,
  };
}

export function generateEmailOtp(emailAddress: $TSFixMe, alertId: $TSFixMe) {
  const url = utils.getEmailOtpGeneratePath();
  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    body: JSON.stringify({emailAddress, alertId}),
    credentials: 'include',
  };
  return api(url, config, generateEmailOtpRequest(), generateEmailOtpSuccess, generateEmailOtpFailure);
}

export function verifyEmailOtpRequest() {
  return {
    type: EMAIL_OTP_VERIFY_REQUEST,
  };
}

export function verifyEmailOtpSuccess(payload: $TSFixMe) {
  const options = {
    category: 'Modal - Password Combo',
    action: 'click',
    label: 'Email Verified',
  };
  utils.trackEvent(options);
  return {
    type: EMAIL_OTP_VERIFY_SUCCESS,
    payload,
  };
}

export function verifyEmailOtpFailure(error: $TSFixMe) {
  const options = {
    category: 'Modal - Password Combo',
    action: 'click',
    label: 'Error validating code',
  };
  utils.trackEvent(options);
  return {
    type: EMAIL_OTP_VERIFY_FAILURE,
    error,
  };
}

export function verifyEmailOtp(emailAddress: $TSFixMe, otp: $TSFixMe) {
  const url = utils.getEmailOtpVerifyPath();
  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    body: JSON.stringify({emailAddress, otp}),
    credentials: 'include',
  };
  return api(url, config, verifyEmailOtpRequest(), verifyEmailOtpSuccess, verifyEmailOtpFailure);
}

export function resetEmailOtpState() {
  return {
    type: RESET_EMAIL_OTP_STATE,
  };
}
