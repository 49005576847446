import {api} from '../helpers/api';
import utils from '../helpers/utils';

export const TRANSUNION_UK_REGISTER_GET_REQUEST_INITIATE = 'TRANSUNION_UK_REGISTER_GET_REQUEST_INITIATE';
export const TRANSUNION_UK_REGISTER_GET_REQUEST_SUCCESS = 'TRANSUNION_UK_REGISTER_GET_REQUEST_SUCCESS';
export const TRANSUNION_UK_REGISTER_GET_REQUEST_FAILURE = 'TRANSUNION_UK_REGISTER_GET_REQUEST_FAILURE';

type transunionUkRegisterGetRequestSuccessResponse = {
  ctaUrl: string;
};
export function transunionUkRegisterGetRequestInitiate(): {type: string} {
  return {
    type: TRANSUNION_UK_REGISTER_GET_REQUEST_INITIATE,
  };
}

export function transunionUkRegisterGetRequestSuccess(payload: transunionUkRegisterGetRequestSuccessResponse): {
  type: string;
  ctaUrl: string;
} {
  return {
    type: TRANSUNION_UK_REGISTER_GET_REQUEST_SUCCESS,
    ctaUrl: payload.ctaUrl,
  };
}

export function transunionUkRegisterGetRequestFailure(): {type: string} {
  return {
    type: TRANSUNION_UK_REGISTER_GET_REQUEST_FAILURE,
  };
}

export function getTransunionUkRegister() {
  const url = utils.getTransunionUkRegisterPath();
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(
    url,
    config,
    transunionUkRegisterGetRequestInitiate(),
    transunionUkRegisterGetRequestSuccess,
    transunionUkRegisterGetRequestFailure
  );
}
