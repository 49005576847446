import {
  ALERTS_DETAIL_DISPOSITION_FAILURE,
  ALERTS_DETAIL_DISPOSITION_SUCCESS,
  ALERT_DETAIL_FAILURE,
  ALERT_DETAIL_MOBILE,
  ALERT_DETAIL_REQUEST,
  ALERT_DETAIL_RESET,
  ALERT_DETAIL_SET_DETAIL_VIEW_INDEX,
  ALERT_DETAIL_SUCCESS,
} from '../actions/alertDetail';

const initialState = {
  alertDetail: {},
  hasDetailError: null,
  hasDispositionError: null,
  status: null,
  currentDetailViewIndex: 0,
};

export default function alertDetail(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case ALERT_DETAIL_REQUEST:
      return Object.assign({}, state, {
        alertDetail: {},
        hasDetailError: null,
        status: 'loading',
        hasDispositionError: null,
        currentDetailViewIndex: 0,
      });
    case ALERT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertDetail: action.alertDetail,
        status: 'completed',
        hasDetailError: null,
        hasDispositionError: null,
        currentDetailViewIndex: 0,
      });
    case ALERT_DETAIL_FAILURE:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {hasDetailError: action.error, status: 'error'});
    //case ALERTS_DETAIL_DISPOSITION_REQUEST:
    //	return Object.assign({}, state, {alertDetail: {},hasError:''});
    case ALERTS_DETAIL_DISPOSITION_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertDetail: action.alertDetail,
        status: 'completed',
        hasDetailError: null,
        hasDispositionError: null,
        currentDetailViewIndex: 0,
      });
    case ALERTS_DETAIL_DISPOSITION_FAILURE:
      return Object.assign({}, state, {
        hasDetailError: null,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        hasDispositionError: action.error,
        status: 'disposition_error',
      });
    case ALERT_DETAIL_RESET:
      return initialState;
    case ALERT_DETAIL_SET_DETAIL_VIEW_INDEX:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        currentDetailViewIndex: action.index,
      });
    case ALERT_DETAIL_MOBILE:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        alertDetail: action.alertDetail,
      });
    default:
      return state;
  }
}
