import utils from '../../helpers/utils';
import {useContext} from 'react';
import {IntlContext} from '../../containers/IntlProvider';
import Image from '../common/VectorImage';

type HeaderDwaProps = {
  productFeatures: ProductFeatures;
  whiteLabelClient: string; // Identifies white label client associated with the current user. Used to select partner-specific image.
  marketingDetails: MarketingDetails;
};

function HeaderDwa({productFeatures, whiteLabelClient, marketingDetails}: HeaderDwaProps) {
  const localizedStringBundle: StringMap = useContext(IntlContext);

  function renderHeader() {
    // Partner images will be hosted in nortonLifelock CDN repo
    const partnerCdnPath = utils.getCdnImagesPath('/logo', whiteLabelClient);
    const nortonLifelockCdnPath = utils.getCdnImagesPath('/dsp-northstar/LifelockLogo', whiteLabelClient);
    const titleString = utils.getHeaderTitleString(
      productFeatures,
      marketingDetails,
      localizedStringBundle,
      whiteLabelClient
    );

    return (
      <div className="bg-background">
        <div
          data-testid="dwa-banner"
          className="m-auto flex max-w-3xl flex-col justify-between px-4 pb-4 pt-6 md:flex-row lg:px-12"
        >
          <div className="flex flex-row items-center">
            <div className="mr-2 h-8 w-8 min-w-8">
              <Image alt="logo" url={whiteLabelClient ? partnerCdnPath : nortonLifelockCdnPath} />
            </div>
            <h2 className="break-all text-xl font-bold tracking-normal text-primary">{titleString}</h2>
          </div>
          <div className="my-3 flex">
            <span className="h-4 w-4 rounded-full bg-safe">
              <span className="material-icons mb-0.5 ml-0.5 text-xs font-extrabold text-surface">check</span>
            </span>
            <span className="ml-1 mt-0.5 block align-middle text-xs font-extrabold text-safe">
              {localizedStringBundle.DARK_WEB_ACTIVE_MONITORING}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return renderHeader();
}

export default HeaderDwa;
