import {useMemo} from 'react';

type Props = {
  alt: string;
  url: string;
  classes?: string;
  dataTestId?: string;
  useCache?: boolean; // If set to true it makes use of the useMemo method which reuses existing image instead of reloading
};

// Returns an <img> element that uses a .svg vector as the image's src
// Vector images scale with higher resolutions, allowing quicker renders on higher quality screens without any quality loss
const Image = ({alt, url, classes, dataTestId, useCache = true}: Props): JSX.Element => {
  const imgComponent = <img alt={alt} className={classes} src={`${url}.svg`} data-testid={dataTestId} />;
  const memoizedComponent = useMemo(() => imgComponent, [url]);
  if (useCache) {
    return memoizedComponent;
  }
  return imgComponent;
};

export default Image;
