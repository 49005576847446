import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import {StrictMode, Suspense, lazy, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';

// Components
import Loader from '../components/common/Loader';
import HeaderDsp from '../components/header/HeaderDsp';
import HeaderDwa from '../components/header/HeaderDwa';
import {LoggedOut} from '../components/LoggedOut';
import ErrorBoundary from './ErrorBoundary';
import FcraModal from './fcra/FcraModal';
import {IntlProvider} from './IntlProvider';
import RestrictedRoute from './RestrictedRoute';

// Actions
import {alertDetailMobileMemberChat} from '../actions/alertDetail';
import {alertsSetInboxFilter, getAlertListDarkWebInbox, getAlertListInbox} from '../actions/alertList';
import {isMobileApp, loginRefresh, refreshToken, whiteLabelClient} from '../actions/auth';
import {
  equifaxClient,
  getCreditPullInfo,
  getTuCreditReportHistory,
  setCreditOnDemand,
  setProviderBureauData,
} from '../actions/credit';
import {setModalQuery, setModalSizeSpec} from '../actions/modal';
import {setWindowScrolledEvent} from '../actions/scroll';
import {getAccessToken, getAccountTransactions, getInstitutionAccounts} from '../actions/transactionActions';

// Reducers
import {RootState} from '../reducers/rootReducer';
import {InstitutionAccountsResponse} from '../reducers/transactionReducers';

// Hooks
import usePrevious from '../customHooks/usePrevious';

// Helpers
import {authWithNslToken, checkUser, startNslHandshake, startSso, startTokenExchange} from '../helpers/apiCalls';
import dateUtils from '../helpers/dateUtils';
import errorCodes from '../helpers/errorCodes';
import {setHeaderAccessToken} from '../helpers/fetchRest';
import {shouldUseDspUi, shouldUseDwmUi} from '../helpers/layout';
import log from '../helpers/log';
import {loadCssUrls, loadPartnerTheme, overrideConfigs} from '../helpers/partnerUtils';
import {initTrackingValues} from '../helpers/tracking';
import {hasAvailableInstitution} from '../helpers/transactionHelper';
import utils from '../helpers/utils';

// Constants
import {TXM_DEFAULT_LOOK_BACK, TXM_DEFAULT_LOOK_BACK_JP} from '../constants/durations';
import * as globalConstants from '../constants/screenConstants';
import {getCountryCode, getLangCode, getPlan, stringBundle} from '../stringBundle';
import useJWT from '../customHooks/useJWT';

const AdobeBannerList = lazy(
  () => import('../containers/banner/AdobeBannerList' /* webpackChunkName: "abtestbanner" */)
);
const Dashboard = lazy(() => import('../components/dashboard/Dashboard' /* webpackChunkName: "dashboard" */));
const BureauRedirect = lazy(() => import('../components/credit/BureauRedirect' /* webpackChunkName: "bureau" */));
const ModalRoot = lazy(() => import('../containers/common/ModalRoot' /* webpackChunkName: "modal" */));
const AlertList = lazy(() => import('./alerts/AlertList' /* webpackChunkName: "alertlist" */));
const Credit = lazy(() => import('../containers/credit/Credit' /* webpackChunkName: "credit" */));
const MonitoringInfo = lazy(() => import('./monitoring/MonitoringInfo' /* webpackChunkName: "monitoring" */));
const DarkWebLanding = lazy(() => import('./alerts/DarkWebLanding' /* webpackChunkName: "darkweblanding" */));
const AlertPreferences = lazy(
  () => import('../containers/monitoring/AlertPreferences' /* webpackChunkName: "alertpref" */)
);
const AlertsLanding = lazy(() => import('../components/alerts/AlertsLanding' /* webpackChunkName: "alertsland" */));
const CaseDetails = lazy(() => import('./restoration/CaseDetails' /* webpackChunkName: "casedetail" */));
const RestorationCases = lazy(() => import('./restoration/CaseList' /* webpackChunkName: "caselist" */));
const Transactions = lazy(() => import('../containers/Transactions' /* webpackChunkName: "transactions" */));
const ErrorPage = lazy(() => import('../components/ErrorPage' /* webpackChunkName: "errorpage" */));
const TuSummaryOfRights = lazy(() => import('../components/TuSummaryOfRights' /* webpackChunkName: "turights" */));
const ItpsLocksAndFreeze = lazy(() => import('./locks/ItpsLocksAndFreeze' /* webpackChunkName: "locks" */));
const SocialMediaMonitoring = lazy(
  () => import('../containers/monitoring/socialMediaMonitoring/SocialMediaMonitoring' /* webpackChunkName: "smm" */)
);
const PrivacyAdvisor = lazy(() => import('./privacyAdvisor/PrivacyAdvisor' /* webpackChunkName: "privacyadvisor" */));
const HomeTitleMonitoring = lazy(() => import('./homeTitle/HomeTitleMonitoring' /* webpackChunkName: "hometitle" */));
const MemberChatShell = lazy(
  () => import('../containers/memberChat/MemberChatShell' /* webpackChunkName: "memberchat" */)
);
const PlanDetails = lazy(() => import('./planDetails/PlanDetails' /* webpackChunkName: "plandetails" */));
const RecurringTransactionDetailView = lazy(() => import('./transactions/RecurringTransactionDetailView'));
const AppObserver = lazy(() => import('../components/appObserver/AppObserver' /* webpackChunkName: "AppObserver" */));
const TransactionsSearch = lazy(
  () => import('../containers/transactions/TransactionsSearch' /* webpackChunkName: "transactionssearch" */)
);
const CreditOffersRedirect = lazy(() => import('../components/redirects/CreditOffersRedirect'));
const ScamValidation = lazy(() => import('./scamValidation/ScamValidation'));
const ScamValidationCaseDetails = lazy(() => import('./scamValidation/ScamValidationCaseDetails'));

// Object passed to sendMessage for relaying to parent page via window.postMessage
type Message = {
  type: string;
  payload: any;
};

// Object containing partner token and client identifier.  Used for establishing a Member API session for partner's user.
type PartnerData = {
  nslToken?: string; // NSL token used to establish a session for partner's user.  Prefer to use partner token if available.
  partnerToken?: string; // partner token used to establish a session for partner's user
  clientId?: string; // client identifier, used to identify the client calling Member API
  cssUrls?: string[]; // array of URLs for CSS files to be loaded for the partner corresponding to clientId
  partnerConfig?: PartnerConfig; // object containing string values to override support URLs and phone numbers
  theme?: string; // value to set in the data-theme attribute of 'html' element
};

export const App = () => {
  const [state, setState] = useState({
    loggedOut: false,
    getMode: false,
    showLifeLockWelcome: false,
    needLifeLockTermsConditions: false,
    needsDSPOnboarding: false,
    showQuebecReimbursementOptIn: false,
    showFcraSuccessModal: false,
    fetchUserData: false,
  });
  const [hasDispatched, setHasDispatched] = useState(false);

  const localizedStringBundle: StringMap = stringBundle(getLangCode(), null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((store: RootState) => store.auth);
  const propModal = useSelector((store: RootState) => store.modal);
  const alertCategoryFilter = useSelector((store: RootState) => store.alertCategoryFilter);
  const alertList = useSelector((store: RootState) => store.alertList);
  const alertDetail = useSelector((store: RootState) => store.alertDetail);
  const quebecOptIn = useSelector((store: RootState) => store.quebecOptIn);
  const fcra = useSelector((store: RootState) => store.fcra);
  const errorStatus = _get(auth, 'loginError.result.status', null) || null;
  const showFcraColp = _get(auth, 'user.primaryMember.plan.showFcraColp', false) || false;
  const prevShowLifeLockWelcome = usePrevious(state.showLifeLockWelcome);
  const prevNeedLifeLockTermsConditions = usePrevious(state.needLifeLockTermsConditions);
  const prevShowQuebecReimbursementOptIn = usePrevious(state.showQuebecReimbursementOptIn);
  const prevQuebecOptIn = usePrevious(quebecOptIn);
  const prevPathname = usePrevious(_get(location, 'pathname', '') || '');
  const prevModalType = usePrevious(_get(propModal, 'modalType', null) || null);
  const prevShowFcraSuccessModal = usePrevious(state.showFcraSuccessModal);
  const prevUser = usePrevious(_get(auth, 'user', {} || {}));
  const useDspUi = shouldUseDspUi(auth);
  const useDwmUi = shouldUseDwmUi(auth);
  const accountId = _get(auth, 'user.primaryMember.accountId', '') || '';
  const [jwtEnabled, setJWTEnabled] = useState(false);
  const {
    isSuccess: isJWTSuccess,
    isError: isJWTError,
    data: jwt,
    error: jwtError,
  } = useJWT(accountId, jwtEnabled, 'app');

  let ngpAccessToken = '';
  let xNlokTraceId = '';
  let ngpTenantName = 'norton';
  const isAgent = _get(auth, 'user.isAgent', false) || false;
  const appContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const expiryTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const appContainerClasses = classNames({
    'flex-row justify-between min-h-104 sm:min-h-0 bg-background': useDspUi,
    'lg:flex': !window.REACT_APP_COLLAPSE_LEFT_NAV && useDspUi,
  });
  const isPublicRoute =
    [
      '/bureauredirect',
      '/tuSummaryOfRights',
      '/appObserver',
      '/advanceDispositionFragment',
      '/disposition/alertConfirmedValidFragment',
      '/disposition/alertConfirmedInvalidFragment',
      '/disposition/custodianAlertConfirmedValidFragment',
      '/disposition/custodianAlertConfirmedInvalidFragment',
      '/disposition/alertDispositionedFragment',
      '/disposition/error',
      '/pageNotAvailable',
      '/externalredirect/creditoffers',
    ].indexOf(_get(location, 'pathname')) !== -1;
  const isInterstitialPage = _get(location, 'pathname', '').startsWith('/externalredirect');
  const appClasses = classNames(
    'flex flex-col w-full',
    isInterstitialPage ? 'h-screen' : 'h-full',
    useDwmUi ? 'max-w-3xl m-auto pb-12 overflow-auto' : 'overflow-auto'
  );
  const appBackgroundClasses = classNames(
    'flex-1 m-auto 3xl:max-w-3xl min-h-96 app',
    _get(location, 'pathname', '') === '/error' ? '' : 'w-full bg-background'
  );

  let lastActive: number = Date.now(); // initialize lastActive

  /**
   * Sends an app loaded message to the parent window.
   */
  const sendAppLoadedMessage = () => {
    sendMessage(
      {
        type: 'APP_LOADED',
        payload: {
          message: 'app was loaded',
          tokenExchangeEnabled: window.REACT_APP_ENABLE_OIDC_TOKEN_EXCHANGE === true,
        },
      },
      window.REACT_APP_PARENT_HOST
    );
  };

  /**
   * Handles the redirect issue for FB / Instagram account linking.
   * If the current pathname is '/_%3D_' or '/_', it clears the pathname and replaces it with the search pathname.
   */
  const handleRedirectIssue = useCallback(() => {
    if (history?.location?.pathname === '/_%3D_' || history?.location?.pathname === '/_') {
      history.location.pathname = '';
      history?.replace({pathname: history?.location?.search});
    }
  }, [history]);

  useEffect(() => {
    handleRedirectIssue();
  }, [handleRedirectIssue]);

  /**
   * Gets the specifications of the parent window.
   */
  const getParentWindowSpec = () => {
    sendMessage({type: 'GET_PARENT_WINDOW_SPEC', payload: {}}, window.REACT_APP_PARENT_HOST);
  };

  /**
   * Sets up event listeners for the app.
   */
  const setupEventListeners = () => {
    window.addEventListener('beforeunload', handleAppUnload);
    window.addEventListener('message', receiveMessage, false);
  };

  /**
   * Cleans up event listeners for the app.
   */
  const cleanupEventListeners = () => {
    window.removeEventListener('message', receiveMessage, false);
    window.removeEventListener('beforeunload', handleAppUnload);
  };

  /**
   * Handles first load
   */
  useEffect(() => {
    utils.polyfillsForIe(); // IE 11 polyfill methods
    sendAppLoadedMessage();
    getParentWindowSpec();
    setupEventListeners();
    utils.resizeIframe();

    const ngpKeepAlive = setupNgpKeepAlive();
    const unsubscribeFromHistory = history?.listen((location: $TSFixMe) => {
      if (location.state === null || location.state === undefined || location.state.updateParent) {
        updateParentLocation(location);
      }
    });

    return () => {
      teardownNgpKeepAlive(ngpKeepAlive);
      clearTimeout(expiryTimerRef.current);
      cleanupEventListeners();
      if (unsubscribeFromHistory) {
        unsubscribeFromHistory();
      }
    };
  }, []);

  /**
   * Redirects to NGP dashboard if the user is not DSP, not DWM, and not viewing this SPA from the mobile app.
   */
  useEffect(() => {
    if (!_isEmpty(auth.user) && !useDspUi && !useDwmUi && !auth.isMobileApp) {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      window.top.location.replace(window.REACT_APP_NORTON_NGP_LINK);
    }
  }, [auth, useDspUi, useDwmUi]);

  /**
   * Handles situations where Member API returns an error code.
   * Either redirects to an external URL, or uses react router to change the path to this SPA's error page.
   * @param errorCode
   * @param trackId
   */
  const handleErrorPage = useCallback(
    (errorCode: number, trackId: string) => {
      if (errorCode === errorCodes.AUTH_NO_ACCOUNT_FOUND_APPLE_RELAY_EMAIL) {
        utils.sendMessage(
          {type: 'CHANGE_WINDOW_LOCATION', payload: window.REACT_IDENTITY_ENROLLMENT_LINK},
          window.REACT_APP_PARENT_HOST
        );
      } else {
        const searchStr = 'errorCode=' + errorCode + '&trackId=' + trackId;
        setState((prevState) => ({
          ...prevState,
          getMode: true,
        }));
        history?.replace({
          pathname: '/error',
          search: searchStr,
        });
      }
    },
    [history]
  );

  /**
   * Handles rate limiting error status.
   */
  useEffect(() => {
    if (errorStatus === 429) {
      handleErrorPage(errorCodes.API_RATE_LIMIT_HIT, ''); // no trackId is available when limit is hit
    }
  }, [errorStatus, handleErrorPage]);

  /**
   * Handles various modals and redirects
   */
  useEffect(() => {
    const quebecOptInErr = _get(quebecOptIn, 'quebecOptInErr', {}) || {};
    if (
      // Handle LifeLock welcome
      window.REACT_APP_ENABLE_LIFELOCK_WELCOME === true &&
      prevShowLifeLockWelcome !== state.showLifeLockWelcome &&
      state.showLifeLockWelcome === true
    ) {
      setTimeout(() => {
        dispatch({
          type: 'SHOW_MODAL',
          modalType: 'WELCOME_MODAL',
        });
      }, 0);
    } else if (
      // Handle LifeLock terms and conditions
      window.REACT_APP_ENABLE_LIFELOCK_WELCOME === true &&
      prevNeedLifeLockTermsConditions !== state.needLifeLockTermsConditions &&
      state.needLifeLockTermsConditions === true
    ) {
      utils.sendMessage(
        {type: 'CHANGE_WINDOW_LOCATION', payload: window.REACT_APP_LSA_LINK},
        window.REACT_APP_PARENT_HOST
      );
    } else if (
      state.showQuebecReimbursementOptIn &&
      state.showQuebecReimbursementOptIn !== prevShowQuebecReimbursementOptIn
    ) {
      // Handle Quebec reimbursement opt-in
      setTimeout(() => {
        dispatch({
          type: 'SHOW_MODAL',
          modalType: 'QUEBEC_INSURANCE_OPT_IN_MODAL',
          modalProps: {},
        });
      }, 0);
    } else if (!_isEmpty(quebecOptInErr) && !_isEqual(prevQuebecOptIn, quebecOptIn)) {
      // Handle Quebec opt-in error
      setTimeout(() => {
        dispatch({
          type: 'SHOW_MODAL',
          modalType: 'CONSENT_ERROR_MODAL',
          modalProps: {},
        });
      }, 0);
    }
  }, [
    state.showLifeLockWelcome,
    prevShowLifeLockWelcome,
    state.needLifeLockTermsConditions,
    prevNeedLifeLockTermsConditions,
    state.showQuebecReimbursementOptIn,
    prevShowQuebecReimbursementOptIn,
    quebecOptIn,
    prevQuebecOptIn,
    dispatch,
  ]);

  /**
   * Fetches Equifax credit information.
   * @param is3BUser - Indicates if the user is a 3B user.
   * @param creditPullInfo - The credit pull information.
   */
  const equifaxCredit = useCallback(
    (is3BUser: boolean, creditPullInfo: $TSFixMe, accountId: string) => {
      if ((isJWTSuccess && !window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) || window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
        if (!hasDispatched) {
          dispatch(equifaxClient(jwt, localizedStringBundle, getLangCode(), is3BUser, creditPullInfo, accountId));
          setHasDispatched(true);
        }
      } else if (isJWTError) {
        dispatch({
          type: 'EQUIFAX_STATUS',
          equifax_status: 'error',
          rawError: jwtError,
        });
      }
    },
    [localizedStringBundle, dispatch, isJWTSuccess, isJWTError, jwtError, jwt, hasDispatched]
  );

  /**
   * fetches Equifax credit data
   */
  const initCreditDataForEquifax = useCallback(async() => {
    const is3BScoreFeatureAvailable =
      _get(auth, 'user.primaryMember.plan.productFeatures.creditScore_3B.featureStatus') === 'AVAILABLE';
    const is3BScoreFulfillmentEnrolled =
      _get(auth, 'user.primaryMember.plan.productFeatures.creditScore_3B.fulfillmentStatus') === 'ENROLLED';
    const is3BUser = is3BScoreFeatureAvailable && is3BScoreFulfillmentEnrolled;
    const accountId = _get(auth, 'user.primaryMember.accountId', '') || '';
    dispatch(setCreditOnDemand());

    try {
      const res: $TSFixMe = await dispatch(getCreditPullInfo(accountId));
      const isFulfilled1BReportAvailable = _get(res, 'pullInfo.isFulfilled1BReportAvailable', false);
      const isFulfilled3BReportAvailable = _get(res, 'pullInfo.isFulfilled3BReportAvailable', false);
      const creditPullInfo = _get(res, 'pullInfo', {});
      if (isFulfilled1BReportAvailable || isFulfilled3BReportAvailable || window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
        equifaxCredit(is3BUser, creditPullInfo, accountId);
      }
    } catch (error) {
      console.error('Error fetching credit pull info:', error);
    }
  }, [auth, dispatch, equifaxCredit]);

  /**
   * Initializes the credit data.
   */
  const initCreditData = useCallback(() => {
    const isLoading = _get(auth, 'isLoading');
    if (!isLoading) {
      const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', '');
      const creditScore1BFeatureStatus = _get(productFeatures, 'creditScore_1B.featureStatus', '');
      const creditScore1BFulfillmentStatus = _get(productFeatures, 'creditScore_1B.fulfillmentStatus', '');
      const creditScore3BFeatureStatus = _get(productFeatures, 'creditScore_3B.featureStatus', '');
      const creditScore3BFullfilmentStatus = _get(productFeatures, 'creditScore_3B.fulfillmentStatus', '');
      const creditScore1bCaFeatureStatus = _get(productFeatures, 'creditScore1bCa.featureStatus', '');
      const creditScore1bCaFullfilmentStatus = _get(productFeatures, 'creditScore1bCa.fulfillmentStatus', '');
      const hasEquifaxCredit =
        creditScore1bCaFeatureStatus !== 'AVAILABLE' &&
        ((creditScore1BFeatureStatus === 'AVAILABLE' && creditScore1BFulfillmentStatus === 'ENROLLED') ||
          (creditScore3BFeatureStatus === 'AVAILABLE' && creditScore3BFullfilmentStatus === 'ENROLLED'));
      if (hasEquifaxCredit && accountId) {
        setJWTEnabled(true);
      } else if (creditScore1bCaFeatureStatus !== 'NOT_AVAILABLE') {
        dispatch(setProviderBureauData('TU', '900'));
        if (creditScore1bCaFeatureStatus === 'AVAILABLE' && creditScore1bCaFullfilmentStatus === 'ENROLLED') {
          dispatch(getTuCreditReportHistory('transunion', localizedStringBundle, getLangCode(), accountId));
        }
      }
    }
  }, [auth, localizedStringBundle, dispatch, accountId]);

  /**
   * Updates the user session 1 minute before the lifelock token expiry (20 min) for active user.
   * NGP will logout the user who is inactive for 15 min.
   */
  useEffect(() => {
    const whiteLabelClient = _get(auth, 'whiteLabelClient', '') || '';
    const primaryMember = _get(auth, 'user.primaryMember', null);

    if (window.REACT_APP_ENABLE_REFRESH_TOKEN === true && !_isEmpty(primaryMember) && !whiteLabelClient) {
      const expiry = _get(auth, 'user.expiry', null) || null;
      const timeout = expiry && expiry * 1000 - 60 * 1000 - Date.now();
      const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', {});

      if (expiryTimerRef.current) {
        clearTimeout(expiryTimerRef.current);
      }

      if (timeout && timeout > 0) {
        expiryTimerRef.current = setTimeout(() => {
          dispatch(refreshToken()).then((res: $TSFixMe) => {
            if (res.type === 'REFRESH_TOKEN_SUCCESS') {
              if (utils.txmVisible(productFeatures) && !utils.isTxmJpAvailable(productFeatures)) {
                dispatch(getAccessToken({useCache: false}));
              }
            }
          });
        }, timeout);
      }
    }
  }, [auth, dispatch]);

  /**
   * Retrieves the SPA experience for analytics.
   * @returns {void}
   */
  const getSpaExperienceForAnalytics = useCallback(() => {
    const marketingDetails = _get(auth, 'user.primaryMember.plan.marketingDetails', {});
    const isDwm = _get(auth, 'user.primaryMember.plan.isDwm', false);
    const isDsp = _get(auth, 'user.primaryMember.plan.isDsp', false);
    const isHometitleStandalone = utils.isHomeTitleStandalone(marketingDetails);
    const isIdAdvisorStandalone = utils.isIdAdvisorStandalone(marketingDetails);

    if (isHometitleStandalone) {
      return 'home title';
    } else if (isIdAdvisorStandalone) {
      return 'id advisor';
    } else if (isDwm) {
      return 'dark web';
    } else if (isDsp) {
      return 'itps';
    } else {
      return '';
    }
  }, [auth]);

  /**
   * Sets up analytics. It will be called once after user is authenticated.
   */
  const setupAnalytics = useCallback(() => {
    if (!window.nortonAnalytics) {
      window.nortonAnalytics = {};
    }
    window.nortonAnalytics.site_country = (_get(auth, 'user.countryCode', 'US') || 'US').toLowerCase();
    window.nortonAnalytics.lifelock_product = _get(auth, 'user.primaryMember.plan.packageCode', null);

    const spaExperience = getSpaExperienceForAnalytics();
    const pageNameForAnalytics = _get(location, 'pathname', '') || '';
    if (spaExperience) utils.setPageNameForAnalytics(pageNameForAnalytics, spaExperience);
  }, [auth, location, getSpaExperienceForAnalytics]);

  /**
   * Handles analytics calls on location changes and modal opening.
   */
  useEffect(() => {
    const pathname = _get(location, 'pathname', '') || '';
    const modalType = _get(propModal, 'modalType', null) || null;
    const plan = _get(auth, 'user.primaryMember.plan');

    if (
      !_isEmpty(plan) &&
      ((pathname !== '' && prevPathname !== '/' && pathname !== prevPathname) ||
        (modalType !== null && modalType !== prevModalType))
    ) {
      const spaExperience = getSpaExperienceForAnalytics();
      const pageNameForAnalytics = pathname !== prevPathname ? pathname : modalType;
      if (spaExperience) utils.setPageNameForAnalytics(pageNameForAnalytics, spaExperience);
    }
  }, [auth, location, propModal, prevModalType, prevPathname, getSpaExperienceForAnalytics]);

  /**
   * Handles the FCRA success modal.
   */
  useEffect(() => {
    const {isFcraAccepted = false, isFcraSuccessModalShown = false} = fcra;
    const showFcraColp = _get(auth, 'user.primaryMember.plan.showFcraColp', false);

    setState((prevState) => {
      const updatedState = {
        ...prevState,
        showFcraSuccessModal: showFcraColp && isFcraAccepted && !isFcraSuccessModalShown,
      };

      if (prevShowFcraSuccessModal !== updatedState.showFcraSuccessModal && updatedState.showFcraSuccessModal) {
        dispatch({
          type: 'SHOW_MODAL',
          modalType: 'FCRA_SUCCESS_MODAL',
          modalProps: {},
        });
      }

      return updatedState;
    });
  }, [fcra, auth, prevShowFcraSuccessModal, dispatch, setState]);

  /**
   * Handles user changes.
   */
  useEffect(() => {
    const user = _get(auth, 'user', '');
    if (!_isEmpty(user) && !_isEqual(user, prevUser)) {
      const planName = _get(auth, 'user.primaryMember.plan.name');
      const itpsDataBag = {
        planName,
      };
      sendMessage({type: 'SET_ITPS_DATA_BAG', payload: itpsDataBag}, window.REACT_APP_PARENT_HOST);
    }
  }, [auth, prevUser]);

  /**
   * Handles path changes and alert changes and sets window analytics object to use in Qualtrics feedback data
   */
  useEffect(() => {
    const pathname = _get(location, 'pathname', '') || '';
    const {level2AlertCategory, alertTypeName} = _get(alertDetail, 'alertDetail', {}) || {};
    const itpsDataBag: $TSFixMe = {pathName: '', alertType: '', alertSubtype: ''};

    if ((pathname !== '/' && pathname !== prevPathname) || level2AlertCategory) {
      itpsDataBag.pathName = pathname;

      if (level2AlertCategory) {
        itpsDataBag.alertType = level2AlertCategory;
      }
      if (alertTypeName) {
        itpsDataBag.alertSubtype = alertTypeName;
      }
      sendMessage({type: 'SET_ITPS_DATA_BAG', payload: itpsDataBag}, window.REACT_APP_PARENT_HOST);
    }
  }, [location, alertDetail, prevPathname]);

  /**
   * Handle setting up window.analytics.
   */
  useEffect(() => {
    const primaryMember = _get(auth, 'user.primaryMember', null);
    // GUTS-3965 Check accountGuid/nortonGuid (not sure which is reliably present), since accountGuid is gone as of April 2024
    const primaryMemberNortonGuid = _get(auth, 'user.primaryMember.nortonGuid');
    const primaryMemberAccountId = _get(auth, 'user.primaryMember.accountId');
    if (!_isEmpty(primaryMemberNortonGuid) || !_isEmpty(primaryMemberAccountId)) {
      const analytics = {
        sku: _get(primaryMember, 'plan.packageCode'),
        planName: _get(primaryMember, 'plan.name'),
        hashedAccountId: _get(primaryMember, 'accountId'),
        promoCode: _get(primaryMember, 'plan.promoCode'),
        isPrimary: true,
        memberGuid: _get(primaryMember, 'accountGuid'),
        primaryMemberGuid: _get(primaryMember, 'accountGuid'),
        userAgent: navigator.userAgent,
      };
      window.analytics = window.analytics || {};
      Object.assign(window.analytics, analytics);
    }
  }, [auth]);

  /**
   * This function will get the alerts category list for a particular alert bucket, if the category passed in browser
   * url params exist in the alert category list. It will filter the alerts list of the bucket based on the category.
   * @param {string} alertTypeNameKeyObject - containing the key of the alert type name key object
   * @param {string} alertBucket - containing the alert bucket name
   */
  const filterAlert = useCallback(
    (alertTypeNameKeyObject: string, alertBucket: string) => {
      const inboxDateFilter = _get(alertList, 'inboxDateFilter');
      const allMembersFilter = {name: localizedStringBundle.ALL_MEMBERS, value: 'All'};
      const defaultFilter = {name: localizedStringBundle.ALL_CATEGORIES, value: 'All'};
      const alertTypeObjectList = _get(alertList, alertTypeNameKeyObject, []);
      const alertFilter =
        alertTypeObjectList.find(({name}: $TSFixMe) => name === alertCategoryFilter.alertCategory) || defaultFilter;
      switch (alertBucket) {
        case 'inbox':
          dispatch(alertsSetInboxFilter(alertFilter, allMembersFilter, 'alerttypeDropdown', inboxDateFilter));
          break;
        default:
          break;
      }
    },
    [alertList, alertCategoryFilter, localizedStringBundle, dispatch]
  );

  /**
   * Fetches inbox alerts.
   */
  const fetchInboxAlerts = useCallback(() => {
    dispatch(getAlertListInbox()).then(() => {
      const {alertCategory, alertBucket} = alertCategoryFilter || {};
      if (alertCategory && alertBucket === 'inbox') {
        filterAlert('inboxAlertTypeNameKeysObject', alertCategoryFilter.alertBucket);
      }
    });
  }, [alertCategoryFilter, dispatch, filterAlert]);

  /**
   * Fetches the Txm data.
   */
  const fetchTxmData = useCallback(() => {
    const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', null);
    const whiteLabelClient = _get(auth, 'whiteLabelClient', '');
    const showTxmUpgrade = utils.isTxmUpgradeScenario(productFeatures, whiteLabelClient);
    const primaryMember = _get(auth, 'user.primaryMember', null);

    if (!showTxmUpgrade && utils.showTransactions(primaryMember)) {
      const defaultLookBack = utils.isTxmJpAvailable(productFeatures)
        ? TXM_DEFAULT_LOOK_BACK_JP
        : TXM_DEFAULT_LOOK_BACK;
      const transactionArgs = {
        startDate: dateUtils.getStartDate(defaultLookBack),
        endDate: dateUtils.getEndDate(),
        sortField: 'postedDate',
        institutionId: '',
        accountId: '',
        offset: '',
        sortOrder: '',
        memberId: '',
        rowsToRetrieve: '',
      };

      dispatch(getInstitutionAccounts({}, accountId)).then((response: InstitutionAccountsResponse) => {
        if (hasAvailableInstitution(response)) {
          dispatch(getAccountTransactions(transactionArgs, accountId));
        }
      });
    }
  }, [auth, dispatch, accountId]);

  /**
   * Handles fetching user's alert, credit and txm data.
   */
  useEffect(() => {
    // GUTS-3965 Check accountGuid/nortonGuid (not sure which is reliably present), since accountGuid is gone as of April 2024
    const useDwmUi = shouldUseDwmUi(auth);
    const primaryMemberNortonGuid = _get(auth, 'user.primaryMember.nortonGuid');
    const primaryMemberAccountId = _get(auth, 'user.primaryMember.accountId');
    // Fetch user data if the user is authenticated and the data has not been fetched yet, do not fetch this data if user is using DWM UI as they only need dwm alert fetch
    if (
      (!_isEmpty(primaryMemberNortonGuid) || !_isEmpty(primaryMemberAccountId)) &&
      state.fetchUserData === true &&
      !useDwmUi
    ) {
      setupAnalytics();
      fetchInboxAlerts();
      fetchTxmData();
      initCreditData();
      setState((prevState) => ({
        ...prevState,
        fetchUserData: false,
      }));
    }
  }, [auth, state.fetchUserData, fetchInboxAlerts, fetchTxmData, initCreditData, setupAnalytics]);

  /**
   * Handles fetching user credit data for Equifax when mock tool is off
   */
  useEffect(() => {
    const authCheck = auth?.user;
    const jwtCheck =
      !isJWTError && jwt && !_has(jwt, 'jwt.jwtToken') && jwtEnabled && !window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL;

    if (authCheck && jwtCheck) {
      initCreditDataForEquifax();
    }
  }, [jwtEnabled, jwt, isJWTError, auth]);

  /**
   * Handles fetching user credit data for Equifax when mock tool is on
   */
  useEffect(() => {
    const authCheck = auth?.user;
    const mockToolCheck = window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL === true;

    const isMockUser = true;

    if (authCheck && mockToolCheck && (isMockUser || (!isMockUser && !_has(jwt, 'jwt.jwtToken') && jwtEnabled))) {
      initCreditDataForEquifax();
    }
  }, [auth]);

  /**
   * Updates the height of the iframe on every render if needed.
   */
  useEffect(() => {
    utils.resizeIframe();
  });

  /**
   * Handles user activity by updating the last active timestamp.
   */
  const handleUserActivity = () => {
    lastActive = Date.now();
  };

  /**
   * Sends a message to the parent host when the app is unloaded.
   */
  const handleAppUnload = () => {
    sendMessage({type: 'APP_UNLOADED', payload: {}}, window.REACT_APP_PARENT_HOST);
  };

  /**
   * Handles Yodlee iframe activity by checking if the function to call is 'renewClientSession'
   * and updating the last active timestamp.
   * @param msg - The message received from the iframe.
   */
  const handleYodleeIframeActivity = (msg: $TSFixMe) => {
    if (_get(msg.data, 'fnToCall', null) === 'renewClientSession') {
      handleUserActivity();
    }
  };

  /**
   * Sets up the NGP keep-alive mechanism by adding event listeners for user activity and Yodlee iframe activity.
   * @returns The timer used for the keep-alive mechanism.
   */
  const setupNgpKeepAlive = (): NodeJS.Timer => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('message', handleYodleeIframeActivity);

    // Checks every 60s for new activity. Notifies NGP if new activity found.
    return setInterval(() => {
      if (Date.now() - lastActive < 60000) {
        // last activity was less than 60 seconds ago
        sendMessage({type: 'KEEP_NGP_ALIVE', payload: ''}, window.REACT_APP_PARENT_HOST);
      }
    }, 60000);
  };

  /**
   * Tears down the NGP keep-alive mechanism by removing event listeners and clearing the timer.
   * @param ngpKeepAlive - The timer used for the keep-alive mechanism.
   */
  const teardownNgpKeepAlive = (ngpKeepAlive: NodeJS.Timer) => {
    window.removeEventListener('click', handleUserActivity);
    window.removeEventListener('keypress', handleUserActivity);
    window.removeEventListener('message', handleYodleeIframeActivity);
    clearInterval(ngpKeepAlive);
  };

  /**
   * Starts oidc authentication via token exchange or iframe flow
   * @param {NgpData} ngpData
   */
  const startAuthentication = (ngpData: NgpData) => {
    if (window.REACT_APP_ENABLE_OIDC_TOKEN_EXCHANGE === true && ngpData.ngpAccessToken) {
      startTokenExchange(ngpData.ngpAccessToken).then((data) => {
        const errorCode = data.payload && data.payload.errorCode;
        const subscriptionErrorcode = data.payload && data.payload.subscriptionErrorcode;
        const trackId = data.payload && data.payload.trackId;

        if (data.type === 'LOGGED_IN') {
          loadSpaData(data.payload);
        } else if (
          data.type === 'LOGIN_ERROR' &&
          (errorCode === errorCodes.OIDC_EXCHANGE_TOKEN_ERROR ||
            errorCode === errorCodes.INVALID_NGP_ACCESS_TOKEN ||
            errorCode === errorCodes.MISSING_MAY_ACT_CLAIM_ERROR)
        ) {
          sendMessage({type: 'LOGIN_ERROR', payload: ngpData.ngpAccessToken}, window.REACT_APP_PARENT_HOST);
        } else if (data.type === 'LOGIN_ERROR' && errorCodes.AUTH_LSA_REQUIRED_NOT_ACCEPTED === subscriptionErrorcode) {
          utils.sendMessage(
            {type: 'CHANGE_WINDOW_LOCATION', payload: window.REACT_APP_LSA_LINK_ON_20008_ERROR},
            window.REACT_APP_PARENT_HOST
          );
        } else {
          handleErrorPage(errorCode, trackId);
        }
      });
    } else {
      return startNslHandshake();
    }
  };

  /**
   * Checks messages posted to this window and takes action as necessary.
   * type window.postMessage in console to simulate a message from NGP, such as "INIT"
   * @param event The message event.
   */
  const receiveMessage = (event: MessageEvent) => {
    if (
      window.REACT_APP_PARENT_HOST !== '*' &&
      event.origin.indexOf(window.REACT_APP_PARENT_HOST) === -1 &&
      event.origin !== window.location.origin &&
      event.origin + ':3000' !== window.location.origin
    ) {
      // TODO: remove the :3000 after nsl redirect to local includes :3000 in url
      return;
    }
    let ngpRedirect = '';
    // data object sent via window.postMessage from NGP page to the SPA iframe

    let ngpData: NgpData = {}; // stores data from NGP, including anonymous tracking values the SPA includes in Member API calls
    let partnerData: PartnerData = {};
    const paspaIframe = document.getElementById('paspa') as HTMLIFrameElement;

    switch (event.data.type) {
      case 'APP_GET_METADATA':
        if (paspaIframe && paspaIframe.contentWindow) {
          paspaIframe.contentWindow.postMessage(
            {
              type: 'APP_METADATA',
              payload: {
                culture: getLangCode(),
                initFrom: 'll-web',
                countryCode: getCountryCode(),
                accessToken: ngpAccessToken,
                xNlokTraceId,
                flow: 'privacyadvisor',
                tenant: ngpTenantName,
                plan: getPlan(),
              },
            },
            window.REACT_APP_PRIVACY_ADVISOR_HOST
          );
        }
        break;
      case 'APP_ERROR':
        history?.replace({
          pathname: '/error',
          search: 'errorCode=' + event.data.payload.errorCode,
        });
        break;
      case 'PRIVACY_ADVISOR_RESIZED':
        // resize the Privacy Advisor SPA iframe based on height received from Privacy Advisor SPA
        paspaIframe.style.minHeight = `${event.data.payload}px`;
        utils.resizeIframe();
        break;
      case 'PRIVACY_ADVISOR_SCROLL_PARENT':
        // Scroll the parent window to focus on the Privacy Advisor SPA iframe top based
        // on event received from Privacy Advisor SPA
        utils.scrollToSection('paspa', ['paspa']);
        break;
      case 'LOGGED_OUT':
        ngpRedirect = window.location.href.replace(window.location.protocol + '//' + window.location.hostname, '');
        ngpRedirect = ngpRedirect.replace(':' + window.location.port, '');
        sendMessage({type: 'LOGGED_OUT', payload: ngpRedirect}, window.REACT_APP_PARENT_HOST);
        setState((prevState) => ({
          ...prevState,
          loggedOut: true,
        }));
        break;
      case 'PARTNER_LOGIN':
        // Starting point for partner pages using NSL token to log their user into the SPA.
        // Initialize anything else necessary for partner user.
        if (event.data.payload && typeof event.data.payload === 'object') {
          try {
            partnerData = event.data.payload;
          } catch (error) {
            partnerData = {};
          }
        }
        if (!partnerData.nslToken || !partnerData.clientId) {
          sendMessage(
            {type: 'PARTNER_LOGIN_ERROR', payload: {message: 'missing nslToken or clientId'}},
            window.REACT_APP_PARENT_HOST
          );
          break;
        }
        authWithNslToken(partnerData.nslToken, partnerData.clientId).then((result) => {
          // notify parent container of success
          sendMessage(result, window.REACT_APP_PARENT_HOST);
          loadSpaData({
            whiteLabelClient: partnerData.clientId,
            accessToken: _get(result, 'payload.accessToken', ''), // accessToken is only returned by Member API for clients that do not use session cookie
          });
          // loadSpaData will try to load the predefined theme that corresponds to the whiteLabelClient value.
          // The parent container of this SPA also has the option of listening for the result from sendMessage, and then
          // sending a LOAD_CSS_URLS message to the SPA to theme it, followed by a OVERRIDE_CONFIGS message to the SPA
          // to customize support URLs and phone numbers.
        });
        break;
      case 'PARTNER_EXTEND_ACCESS':
        // Starting point for partner pages using NSL token to extend the user's SPA access time.
        // We avoid calling this "session" time because there is no session cookie and no corresponding Member API session.
        // The underlying process involves refreshing the Member API encrypted access token.
        if (event.data.payload && typeof event.data.payload === 'object') {
          partnerData = event.data.payload;
        }
        if (!partnerData.nslToken || !partnerData.clientId) {
          sendMessage(
            {type: 'PARTNER_EXTEND_ACCESS_ERROR', payload: {message: 'missing nslToken or clientId'}},
            window.REACT_APP_PARENT_HOST
          );
          break;
        }
        authWithNslToken(partnerData.nslToken, partnerData.clientId).then((result) => {
          // notify parent container of success
          if (typeof result === 'object') {
            // modify type to indicate the message was a result of the PARTNER_EXTEND_ACCESS message
            switch (result.type) {
              case 'PARTNER_LOGIN_SUCCESS':
                result.type = 'PARTNER_EXTEND_ACCESS_SUCCESS';
                // accessToken is only returned by Member API for clients that do not use session cookie
                setHeaderAccessToken(_get(result, 'payload.accessToken', '')); // used by fetchRest.ts and api.ts to call Member API auth-required endpoints
                break;
              case 'PARTNER_LOGIN_ERROR':
                result.type = 'PARTNER_EXTEND_ACCESS_ERROR';
                break;
              default:
                // prefix any unexpected types to indicate PARTNER_EXTEND_ACCESS origination
                result.type = 'PARTNER_EXTEND_ACCESS_' + result.type;
                break;
            }
            sendMessage(result, window.REACT_APP_PARENT_HOST);
          }
        });
        break;
      case 'LOAD_CSS_URLS':
        // process message sent by partner page to load CSS files, and set data-theme attribute to partnerData.theme value
        if (event.data.payload && typeof event.data.payload === 'object') {
          partnerData = event.data.payload;
          if (partnerData.cssUrls) {
            loadCssUrls(partnerData.cssUrls);
            window.htmlEl = document.querySelector('html');
            if (window.htmlEl) {
              window.htmlEl.setAttribute('data-theme', partnerData.theme);
            }
          }
        }
        break;
      case 'OVERRIDE_CONFIGS':
        // process message sent by partner page to override support URLs and phone numbers specified in config.js
        if (event.data.payload && typeof event.data.payload === 'object') {
          partnerData = event.data.payload;
          if (partnerData.partnerConfig) {
            overrideConfigs(partnerData.partnerConfig);
          }
        }
        break;
      case 'INIT':
        // Parse data from NGP, which includes ngpUserHash, ngpSessionHash, NLokTraceId and ngp access token.
        // See sendInit method in iframeListeners.js
        if (event.data.payload) {
          try {
            ngpData = JSON.parse(event.data.payload);
            ngpAccessToken = ngpData.ngpAccessToken || '';
            xNlokTraceId = ngpData.NLokTraceId || '';
            ngpTenantName = ngpData.tenantName || 'norton';
            initTrackingValues(
              ngpData.ngpUserHash || '',
              ngpData.ngpSessionHash || '',
              ngpData.NLokTraceId || '',
              ngpData.tenantId || '',
              ngpData.tenantName || '',
              ngpData.pCode || ''
            );
          } catch (error) {
            ngpData = {};
          }
        }

        if (ngpData.recreateSession === true) {
          setState((prevState) => ({
            ...prevState,
            getMode: false,
          }));

          startAuthentication(ngpData);
        } else {
          // Note, comment out checkUser if you are developing locally and always want to go through OIDC flow
          // checkUser should pass on refresh for new flow...local has issue related to cors
          checkUser()
            .then(() => {
              // NGP hashes match what is stored in user's session. Proceed to loading member data using existing session.
              // Get whiteLabelClient, if any, and send to loadSpaData to activate theme.
              // Clients using this flow utilize NSL, NGP and session cookies, and do not use an access token to call Member API auth-required endpoints
              const whiteLabelClient = sessionStorage.getItem('whiteLabelClient');
              return loadSpaData({whiteLabelClient});
            })
            .then((result) => {
              // If loadSpaData failed with a login failure, throw the result so the catch block initiates NSL handshake
              // This could occur if the Member API session's tokens for data-api are invalid
              if (result.type === 'LOGIN_FAILURE') {
                throw result;
              }
            })
            .catch((error) => {
              // Exception could be due to
              // 1) User first time logging in
              // 2) NGP hashes DO NOT match what is stored in user's session. Create a new session via OIDC flow.
              // 3) loadSpaData failed with a login failure
              // 4) rate limited
              const errorCode = error && error.errorCode;

              if (errorCode === errorCodes.API_RATE_LIMIT_HIT) {
                // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
                handleErrorPage(errorCode); // no trackId is available when limit is hit
              } else {
                startAuthentication(ngpData);
              }
            });
        }
        /*
        Alternative is to always go through OIDC flow to ensure Norton member
        establishes their own member-api session.  Checking NGP hashes or always
        establishing a session via OIDC, are two routes that handle the edge case where Norton
        user 1 logs into DSP SPA, logs out using Norton logout link, Norton logout flow
        fails to trigger logout on member-api so user 1's member-api session is still active,
        Norton user 2 logs into DSP SPA on the same computer and reuses user 1's session.
        */
        //startNslHandshake(ngpData); // try OIDC flow to establish a new member-api session
        break;
      case 'LOCATION_HASH_CHANGE':
        updateLocationHash(event.data.payload.locationHash, event.data.payload.queryString);
        break;
      case 'GET_WINDOW_SPEC':
        setAppContainerHeight(event.data.payload);
        updateModalSize(event.data.payload);
        repositionBody(event.data.payload);
        setAppSizeScrollData(event.data.payload);
        utils.resizeIframe(event.data.payload);
        break;
      case 'LOGIN_ERROR': {
        // due to child iframe encountering an error while obtaining a single-use access code from identity provider
        const errorCode = event.data.payload && event.data.payload.errorCode;
        const trackId = event.data.payload && event.data.payload.trackId;
        handleErrorPage(errorCode, trackId);
        break;
      }
      case 'LOGGED_IN':
        // Process message sent by SPA's iframe that loaded accessCode.html, as part of the NSL OIDC flow when no NGP token is usable.
        // typically due to calling startNslHandshake leading to child iframe sending the single-use access code from identity provider
        // This flow does not apply to NortonLifeLock partner users, so there is no whiteLabelClient in the payload.
        loadSpaData(event.data.payload);
        break;
      case 'SSO_INIT':
        // directs this SPA to initialize a session with member-api using the provided SSO token
        // type SSO_INIT comes from post message from mobile apps
        dispatch(isMobileApp(true));
        startSso(event.data.payload)
          .then(() => {
            if (event.data.isMobileApp) {
              dispatch(alertDetailMobileMemberChat(event.data.alertDetail));
            }
            // check window.MODE and init user data as necessary. (similar to case LOGGED_IN)
            loadSpaData(null);
            // GUTS-1757 TODO verify with mobile team that white labeling is not required in this flow
          })
          .catch((error) => {
            log.error('receiveMessage SSO_INIT, error=', JSON.stringify(error));
          });
        break;
      case 'WINDOW_SCROLLED': {
        debouncedScrollHandler(event);
        break;
      }
      default:
        // dont do anything for unrecognized message
        break;
    }
  };

  /**
   * Debounce the scroll handler to minimize the number of times handleUserActivity and setScrollData are called.
   * Wrapped inside useCallback to ensure that the debounced function is not recreated on every render.
   */
  const debouncedScrollHandler = useCallback(
    _debounce((event) => {
      handleUserActivity();
      setScrollData(event.data.payload);
    }, 25),
    [event]
  );

  /**
   * Object containing post-auth response from Member API to this SPA
   * @typedef {Object} PostAuthApiResponse
   * @property {boolean} showLifeLockWelcome - whether to show welcome screen or not
   * @property {boolean} needLifeLockTermsConditions - whether to show t&c screen or not
   * @property {string} whiteLabelClient - Identifies the white label client associated with the current user. Used to determine which theme to activate.
   * @property {string} accessToken - encrypted LL JWT, used by clients to access Member API auth-required endpoints
   */

  /**
   * Starting point for loading member data for this SPA.  Should be called after receiveMessage detects
   * a LOGGED_IN event.
   * If the payload indicates the user is a NortonLifeLock partner's customer, the corresponding partner theme should be loaded by the SPA
   * and window.clientId should be set to the whiteLabelClient value for use with subsequent calls to Member API
   * @param {PostAuthApiResponse} payload - Contains extra information such as whether to show the welcome screen, and the business partner identifier
   */
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'PostAuthApiResponse'.
  const loadSpaData = (payload: PostAuthApiResponse) => {
    // load partner theme if necessary
    if (payload && payload.whiteLabelClient) {
      // set overriding client id based on payload.whiteLabelClient.
      // see api.ts api() which depends on window.clientId and window.isDwm
      window.clientId = payload.whiteLabelClient;
      loadPartnerTheme(payload.whiteLabelClient);
      dispatch(whiteLabelClient(payload.whiteLabelClient));
    }

    if (payload && payload.accessToken) {
      setHeaderAccessToken(payload.accessToken); // used by fetchRest.ts and api.ts to call Member API auth-required endpoints
    }

    return dispatch(loginRefresh()).then((result: $TSFixMe) => {
      window.isDwm = _get(result, 'user.primaryMember.plan.isDwm', false);
      if (result.type === 'LOGIN_FAILURE') {
        return result; // return immediately and let the caller decide how to handle login failure
      }

      setState((prevState) => ({
        ...prevState,
        getMode: true,
        showLifeLockWelcome: payload && payload.showLifeLockWelcome,
        needLifeLockTermsConditions: payload && payload.needLifeLockTermsConditions,
        whiteLabelClient: payload && payload.whiteLabelClient,
        needsDSPOnboarding: _get(result, 'user.primaryMember.needsDSPOnboarding', false),
        showQuebecReimbursementOptIn: _get(result, 'user.primaryMember.plan.showQuebecReimbursementOptIn', false),
        fetchUserData: true,
      }));

      // login was successful, continue loading data
      if (window.isDwm) {
        // init alerts data for dark web
        const userCountryCode = _get(result, 'user.countryCode', '');
        dispatch(getAlertListDarkWebInbox(userCountryCode));
      } else {
        // currently, mobile apps only load the SPA in a webview to display the alert detail page.
        // the API calls in this method are not necessary in that scenario.
        if (result.isMobileApp) {
          return null;
        }
      }
      return result;
    });
  };

  /**
   * Sends message to window parent.  If this window is an iframe, the message is sent to the parent of the iframe.
   * @param {Message} message - The message to send.
   * @param {string} host - The host to send the message to.
   */
  const sendMessage = (message: Message, host: string) => {
    if (!message || typeof message !== 'object') {
      throw new Error('expected message to be a JSON object');
    }
    try {
      window.parent.postMessage(message, host);
    } catch (error) {
      log.error(error);
    }
  };

  /**
   * Updates the hash portion of this window's location.
   * Usually done in response to receiving a message from window parent due to user
   * navigating with back and forward buttons on browser.
   * @param {string} locationHash - the part of the URL after the "#" character
   * @param {string} queryString - query string portion of URL path e.g. "?x=1&y=2"
   */
  const updateLocationHash = (locationHash: string, queryString: string) => {
    if (history?.location?.pathname !== locationHash) {
      history?.push({pathname: locationHash + queryString, state: {updateParent: false}});
    }
  };

  /**
   * Sends a message to window parent indicating the new location.
   * Usually done in response to user navigating inside iframe and the need to keep the
   * window parent's location in sync to support bookmarking.
   * @param location
   */
  const updateParentLocation = (location: Location) => {
    log.info('updateParentLocation location=', location.pathname);
    const pathArr = location.pathname.split('/');
    // Keep modal open when pagination in alerts model
    // eg- /alerts/123/page/1
    const keepModalOpen = pathArr[1] === 'alerts' && pathArr[3] === 'page';

    // Close modal on browser location changes
    if (!_isEmpty(_get(propModal, 'modalType', null)) && !keepModalOpen) {
      utils.closeModal({propModal, dispatch});
    }
    const title = utils.getPageTitle(pathArr[1], localizedStringBundle);

    sendMessage({type: 'LOCATION_CHANGED', payload: {hash: location.pathname, title}}, window.REACT_APP_PARENT_HOST);
    utils.resizeIframe();
  };

  /**
   * Updates the modal size.
   * @param data - The payload containing the window specifications.
   */
  const updateModalSize = (data: GetWindowSpecPayload) => {
    const xSpacing = 135;
    const ySpacing = 20;
    let top;
    let bottom;
    let left;
    let right;
    let maxHeight;

    let calculatedTop = data?.scrollTop - data?.offsetTop || 0;
    calculatedTop = Math.max(0, calculatedTop);

    if (
      data.windowHeight < globalConstants.SMALL_SCREEN_WIDTH ||
      data.windowWidth < globalConstants.MEDIUM_SCREEN_WIDTH
    ) {
      // Occupy full width/height
      bottom = '0px';
      left = '0px';
      right = '0px';
      top = calculatedTop + 'px';
      maxHeight = data.windowHeight - data.headerHeight + 'px';
    } else {
      top = calculatedTop + ySpacing + 'px';
      bottom = 'auto';
      left = xSpacing + 'px';
      right = xSpacing + 'px';
      if (data.scrollTop + data.windowHeight + data.footerHeight < data.scrollHeight) {
        // When norton footer is not visible
        maxHeight = data.windowHeight - data.headerHeight - 2 * ySpacing + 'px';
      } else {
        // When norton footer is visible
        maxHeight = data.windowHeight - data.headerHeight - data.footerHeight - 2 * ySpacing + 'px';
      }
    }

    dispatch(
      setModalSizeSpec({
        content: {
          top,
          bottom,
          left,
          right,
          maxHeight,
          scrollTop: data.scrollTop,
        },
      })
    );
  };

  /**
   * Repositions the body based on the provided data.
   * @param data - The payload containing window specifications.
   */
  const repositionBody = (data: GetWindowSpecPayload) => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      document.getElementById('csp-app-container')?.scrollTo(0, data.scrollTop);
    }
  };

  /**
   * Set iframe size and position data from the window size specifications
   * To be used when calculating "absolute" positioned elements based on user's initial window prior to any scroll event being fired
   * @param payload
   */
  const setAppSizeScrollData = (payload: GetWindowSpecPayload) => {
    if (payload && !_isEmpty(payload)) {
      const {scrollTop, windowHeight, offsetTop, headerHeight} = payload;
      dispatch(
        setWindowScrolledEvent({
          scrollTop,
          windowHeight,
          offsetTop,
          headerHeight,
        })
      );
    }
  };

  /**
   * Sets the redux store scroll object using the data from input payload from a scroll event
   * @param payload
   */
  const setScrollData = (payload: ScrollEventPayload) => {
    dispatch(setWindowScrolledEvent(payload));
  };

  /**
   * Sets the height of the app container.
   * @param payload - The payload containing the window specifications.
   */
  const setAppContainerHeight = (payload: GetWindowSpecPayload) => {
    if (payload) {
      let headerHeight = payload.headerHeight || 0;
      let footerHeight = payload.footerHeight || 0;
      const windowHeight = payload.windowHeight || 0;
      // below condition is added to get the best height when the browser is in/not a full screen mode
      const totalHeight = screen.availHeight < windowHeight ? windowHeight : screen.availHeight;
      // Adobe banner addition/removal is dynamic and this is needed to calculate the right height for the app
      // Any future dynamic banner/element addition to the app on any page, logic needs to be added to calculate the height
      const adobeElement = document.getElementById('adobeBanner') as HTMLElement;
      let adobeBannerHeight = adobeElement ? adobeElement.offsetHeight : 0;

      // when banner is turned on in full screen vs not full screen browser
      if (windowHeight === screen.availHeight) {
        adobeBannerHeight = 0;
      }

      // when height of the browser is less than iframe max-height
      if (windowHeight < globalConstants.IFRAME_MAX_HEIGHT) {
        headerHeight = 0;
        footerHeight = 0;
      }

      // when footer is longer(incase of partners), zooming out of csp app is causing display issues
      if (footerHeight > globalConstants.IFRAME_MAX_FOOTER_HEIGHT) {
        footerHeight = globalConstants.IFRAME_MAX_FOOTER_HEIGHT;
      }
      const appHeight = totalHeight - (headerHeight + footerHeight + adobeBannerHeight);
      if (appContainerRef.current) {
        appContainerRef.current.style.setProperty('min-height', `${appHeight}px`, 'important');
        utils.resizeIframe();
      }
    }
  };

  /**
   * Returns header based on window mode
   * @returns {JSX.Element} HeaderDwa if primary member has a DWM plan.  HeaderDsp if DSP plan. Null otherwise.
   */
  const getHeader = () => {
    const useDspUi = shouldUseDspUi(auth);
    const useDwmUi = shouldUseDwmUi(auth);
    const errorStatus = _get(auth, 'loginError.result.status', null);
    const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures');
    const marketingDetails = _get(auth, 'user.primaryMember.plan.marketingDetails');
    const whiteLabelClient = _get(auth, 'whiteLabelClient', '');

    if (errorStatus === 429) {
      return null;
    }

    if (useDwmUi) {
      return (
        <HeaderDwa
          productFeatures={productFeatures}
          whiteLabelClient={whiteLabelClient}
          marketingDetails={marketingDetails}
        />
      );
    } else if (useDspUi) {
      return (
        <HeaderDsp
          isMobileApp={auth.isMobileApp}
          productFeatures={productFeatures}
          marketingDetails={marketingDetails}
        />
      );
    }
    return null;
  };

  /**
   * Shows the loader.
   */
  const showLoader = () => {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-background p-20">
        <Loader />
      </div>
    );
  };

  /**
   * Handles the redirect.
   * @param {any} props - The props for the redirect.
   */
  const handleRedirect = (props: $TSFixMe) => {
    const search = _get(props, 'location.search', '') || '';
    const params = new URLSearchParams(search);
    const redirectUrl = params.get('redirectUrl');
    if (redirectUrl) {
      const urlQueryString = utils.parseQueryString(search);
      !_isEmpty(urlQueryString) && dispatch(setModalQuery(urlQueryString));
      return <Redirect to={redirectUrl} />;
    } else {
      return <Redirect to={'/dashboard'} />;
    }
  };

  /**
   * Returns DWM or DSP specific routes
   * @return {*}
   */
  const getMode = () => {
    const useDwmUi = shouldUseDwmUi(auth);
    const productFeatures = _get(auth, 'user.primaryMember.plan.productFeatures', {});
    const whiteLabelClient = _get(auth, 'whiteLabelClient', '');
    const marketingDetails = _get(auth, 'user.primaryMember.plan.marketingDetails', {});
    const associatedMembers = _get(auth, 'user.associatedMembers', []);

    if (useDwmUi) {
      return (
        <Switch>
          <Route path="/memberchat" exact={true} component={MemberChatShell} />
          <Route path="/" component={DarkWebLanding} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" exact={true} render={handleRedirect} />
          <Route path="/alerts/:id" exact={true} component={AlertList} />
          <Route path="/alerts" exact={true} component={AlertList} />
          <Route path="/alerts/inbox" exact={true} component={AlertList} />
          <Route path="/alerts/disputed" exact={true} component={AlertList} />
          <Route path="/alerts/archived" exact={true} component={AlertList} />
          <Route path="/alerts/inbox/page/:pagenum" exact={true} component={AlertList} />
          <Route path="/alerts/disputed/page/:pagenum" exact={true} component={AlertList} />
          <Route path="/alerts/archived/page/:pagenum" exact={true} component={AlertList} />
          <Route
            path="/alertPreferences"
            exact={true}
            render={(props) => <AlertPreferences {...props} hideLabel={true} />}
          />
          <Route path="/bureauredirect" component={BureauRedirect} />
          <Route path="/externalredirect/creditoffers" component={CreditOffersRedirect} />
          <RestrictedRoute
            path="/credit"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Credit}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/credit/:tab"
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Credit}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <Route path="/advanceDispositionFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/alertConfirmedValidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/alertConfirmedInvalidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/alertConfirmedGrayInvalidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/alertConfirmedRegularInvalidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/alertDispositionedFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/custodianAlertConfirmedValidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/custodianAlertConfirmedInvalidFragment" exact={true} component={AlertsLanding} />
          <Route path="/disposition/error" exact={true} component={AlertsLanding} />
          <RestrictedRoute
            path="/dashboard"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Dashboard}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <Route path="/error" exact={true} component={ErrorPage} />
          <RestrictedRoute
            path="/hometitle"
            exact={true}
            productFeatures={productFeatures}
            marketingDetails={marketingDetails}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={HomeTitleMonitoring}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/privacyadvisor"
            exact={true}
            productFeatures={productFeatures}
            marketingDetails={marketingDetails}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={PrivacyAdvisor}
            whiteLabelClient={whiteLabelClient}
          />
          <Route path="/plandetails" exact={true} component={PlanDetails} />
          <RestrictedRoute
            path="/locks"
            productFeatures={productFeatures}
            marketingDetails={marketingDetails}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={ItpsLocksAndFreeze}
            whiteLabelClient={whiteLabelClient}
          />
          <Route path="/logout" exact={true} component={LoggedOut} />
          <Route path="/memberchat" exact={true} component={MemberChatShell} />
          <RestrictedRoute
            path="/monitoring/:section?"
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={MonitoringInfo}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <Route path="/pageNotAvailable" exact={true} component={AlertsLanding} />
          <Route path="/restoration" exact={true} component={RestorationCases} />
          <Route path="/restoration/:id" component={CaseDetails} />
          <RestrictedRoute
            path="/social-media-monitoring/add-account-success"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={SocialMediaMonitoring}
            associatedMembers={associatedMembers}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/social-media-monitoring/add-account-error"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={SocialMediaMonitoring}
            associatedMembers={associatedMembers}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/transactions"
            productFeatures={productFeatures}
            exact={true}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Transactions}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/transactions/all"
            productFeatures={productFeatures}
            exact={true}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Transactions}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/transactions/all/search"
            productFeatures={productFeatures}
            exact={true}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={TransactionsSearch}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/transactions/recurring/all"
            productFeatures={productFeatures}
            exact={true}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Transactions}
            marketingDetails={marketingDetails}
            whiteLabelClient={whiteLabelClient}
          />
          <Route
            path="/transactions/recurring/details/:recurringTagId/:returnPath?"
            component={RecurringTransactionDetailView}
          />
          <RestrictedRoute
            path="/txm-transactions/txmJp-add-account-error"
            productFeatures={productFeatures}
            exact={true}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={Transactions}
            marketingDetails={marketingDetails}
          />
          <RestrictedRoute
            path="/monitoring/add-account-error"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={MonitoringInfo}
            whiteLabelClient={whiteLabelClient}
          />
          <RestrictedRoute
            path="/txm-monitoring/txmJp-add-account-error"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={MonitoringInfo}
          />
          <Route path="/tuSummaryOfRights" component={TuSummaryOfRights} />
          <RestrictedRoute
            path="/scam-validation"
            exact={true}
            productFeatures={productFeatures}
            // @ts-expect-error TS(2322) FIXME: Type 'typeof LazyloadComponent' is not assignable ... Remove this comment to see the full error message
            component={ScamValidation}
          />
          <Route path="/scam-validation/:id" component={ScamValidationCaseDetails} />
          <Route path="/appObserver" component={AppObserver} />
          <Route component={ErrorPage} />
        </Switch>
      );
    }
  };

  if (showFcraColp && !fcra.isFcraModalShown) {
    return (
      <FcraModal
        isAgent={isAgent}
        showErrorMsg={!_isEmpty(fcra.fcraAcceptErr)}
        localizedStringBundle={localizedStringBundle}
        showFcraColp={showFcraColp}
      />
    );
  } else if (state.loggedOut) {
    return <LoggedOut />;
  } else {
    return (
      <IntlProvider>
        <Suspense fallback={<></>}>
          <ModalRoot />
        </Suspense>
        <StrictMode>
          <ErrorBoundary errorComponent={<ErrorPage />}>
            <div className={appContainerClasses} data-testid="app-container" ref={appContainerRef}>
              {auth?.user && getHeader()}
              <div className="w-full bg-background">
                <div className={appBackgroundClasses}>
                  <div className={appClasses}>
                    <div className="hidden">{process.env.REACT_APP_LABEL}</div>
                    <iframe title="authIframeTitle" id="authIframe" src="" className="hidden" />
                    <div className="mb-4 overflow-auto" id="csp-app-container">
                      <Suspense fallback={showLoader()}>
                        <AdobeBannerList />
                        {state.getMode || isPublicRoute ? getMode() : showLoader()}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </StrictMode>
      </IntlProvider>
    );
  }
};

export default App;
