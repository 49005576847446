import _get from 'lodash/get';

/**
 * Returns ranking for a score name.  If score name is not recognized, returns the ranking for 'default' score name.
 * @param {string} scoreName The name of the credit score, used to determine score ranking.
 * @returns {number} The score ranking.
 */
export function getScoreRanking(scoreName: string) {
  const scoreRankings = {
    Poor: 0,
    Fair: 1,
    Good: 2,
    'Very Good': 3,
    Excellent: 4,
    NA: 0,
    default: 0,
  };
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return scoreRankings.hasOwnProperty(scoreName) ? scoreRankings[scoreName] : scoreRankings['default'];
}

/**
 * Returns css style for a score name.  If score name is not recognized, returns css style for
 * 'default' score name.
 * @param {string} scoreName The name of the credit score
 * @returns {string} CSS style
 */
export function getScoreCssColor(scoreName: string) {
  const cssStyles = {
    Poor: 'danger',
    Fair: 'warning',
    Good: 'good',
    'Very Good': 'very-good',
    Excellent: 'excellent',
    NA: 'grey',
    default: 'grey',
  };
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return cssStyles.hasOwnProperty(scoreName) ? cssStyles[scoreName] : cssStyles['default'];
}

/**
 * Returns true if one-bureau credit score (Equifax for US) is available for this plan.
 * Any feature status value except NOT_AVAILABLE will cause this method to return true.
 * @param {ProductFeatures} productFeatures Product features response from member-api
 * @return {boolean}
 */
export function availableCreditScore1b(productFeatures: ProductFeatures) {
  return _get(productFeatures, 'creditScore_1B.featureStatus', 'NOT_AVAILABLE') !== 'NOT_AVAILABLE';
}

/**
 * Returns true if three-bureau credit score (Equifax, Transunion, Experian, for US) is available for this plan.
 * Any feature status value except NOT_AVAILABLE will cause this method to return true.
 * @param {ProductFeatures} productFeatures Product features response from member-api
 * @return {boolean}
 */
export function availableCreditScore3b(productFeatures: ProductFeatures) {
  return _get(productFeatures, 'creditScore_3B.featureStatus', 'NOT_AVAILABLE') !== 'NOT_AVAILABLE';
}

/**
 * Returns true if one-bureau credit score (Transunion, for Canada) is available for this plan.
 * Any feature status value except NOT_AVAILABLE will cause this method to return true.
 * @param {ProductFeatures} productFeatures Product features response from member-api
 * @return {boolean}
 */
export function availableCreditScore1bCa(productFeatures: ProductFeatures) {
  return _get(productFeatures, 'creditScore1bCa.featureStatus', 'NOT_AVAILABLE') !== 'NOT_AVAILABLE';
}
