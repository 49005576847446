import {
  CURRENT_ACCOUNT_SELECTION,
  CURRENT_DATE_SELECTION,
  DELETE_INSTITUTION_LOGIN_FAILURE,
  DELETE_INSTITUTION_LOGIN_REQUEST,
  DELETE_INSTITUTION_LOGIN_SUCCESS,
  FILTERED_TRANSACTIONS,
  GET_ACCESS_TOKEN_ERROR,
  GET_ACCESS_TOKEN_REQUEST,
  GET_ACCESS_TOKEN_SUCCESS,
  MEMBER_ACCOUNTS_FAILURE,
  MEMBER_ACCOUNTS_REQUEST,
  MEMBER_ACCOUNTS_SUCCESS,
  PAGINATE_TRANSACTIONS,
  TRANSACTION_LIST_FAILURE,
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_OVERVIEW_SUCCESS,
  TXM_SET_ALERT_PREF_FAILURE,
  TXM_SET_ALERT_PREF_REQUEST,
  TXM_SET_ALERT_PREF_SUCCESS,
  TXM_SET_RECURRING_ALERT_OPT_SUCCESS,
  TXM_SET_RECURRING_ALERT_OPT_REQUEST,
} from '../actions/transactionActions';

export type Transaction = {
  accountDisplay: string;
  accountId: string;
  accountNumber: string;
  amount: string;
  category: string;
  categoryId: string;
  currencyCode: string;
  desc: string;
  id: string;
  merchantName: string;
  postedDate: string;
  siteName: string;
  status: string;
  transactionBaseType: string;
  transactionDate: string;
  transactionType: string;
  absoluteAmount: string;
};

export type CardAccount = {
  accountId: string;
  accountType: string;
  displayName: string;
  institutionId: string;
  lastRefreshDate: string;
  memSiteAccountId: string;
  state: string;
  accountName: string;
  accountNumber: string;
  balance: number;
  currencyCode: string;
  accountHolder: string;
  availableCredit: number;
  dueDate: string;
  minPayment: number;
  totalCreditLine: number;
  runningBalance: number;
};

export type InvestmentAccount = {
  accountId: string;
  accountType: string;
  displayName: string;
  institutionId: string;
  lastRefreshDate: string;
  memSiteAccountId: string;
  state: string;
  accountName: string;
  accountNumber: string;
  balance: number;
  currencyCode: string;
  cash: number;
  marginBalance: number;
  totalBalance: number;
};

export type BankAccount = {
  accountId: string;
  accountType: string;
  displayName: string;
  institutionId: string;
  lastRefreshDate: string;
  memSiteAccountId: string;
  state: string;
  accountName: string;
  accountNumber: string;
  balance: number;
  currencyCode: string;
  accountHolder: string;
  availableBalance: number;
  currentBalance: number;
};

export type LoanAccount = {
  accountId: string;
  accountType: string;
  displayName: string;
  institutionId: string;
  lastRefreshDate: string;
  memSiteAccountId: string;
  state: string;
  accountName: string;
  accountNumber: string;
  balance: number;
  currencyCode: string;
  accountHolder: string;
  amountDue: number;
  availableCredit: number;
  dueDate: string;
  interestPaidLastYear: number;
  interestPaidYtd: number;
  loanInterestRateType: string;
  minPayment: number;
  originalLoanAmount: number;
  originationDate: string;
  principalBalance: number;
};

export type Institution = {
  institutionDisplayName: string;
  institutionId: string;
  noResults: string;
  institutionCreateDate: string;
  institutionRefreshDate: string;
  institutionState: string;
  institutionActions: string | string[];
  memSiteAccountId: string;
  messageCode: string;
  autoRefreshState: string;
  bankingAccounts: BankAccount[];
  cardAccounts: CardAccount[];
  investmentAccounts: InvestmentAccount[];
  loanAccounts: LoanAccount[];
  favicon: string;
  logo: string;
  institutionUrl: string;
  name: string;
  institutionCta: string;
  accountGroup: string;
};

export type AlertPreference = {
  type: string;
  value: string;
};

//FL4 upgrade start
export type ContainerAccounts = {
  container: string;
  currencyCode: string;
  totalBalance: number;
  institutions: Institutions[];
};
export type Institutions = {
  institutionId: string;
  institutionDisplayName: string;
  institutionState: string;
  institutionActions: string | string[];
  autoRefreshState: string;
  institutionCta: string;
  favicon: string;
  logo: string;
  accountsBalance: string;
  currencyCode: string;
  accountsList: Accounts[];
};
export type Accounts = {
  accountId: string;
  accountType: string;
  institutionId: string;
  institutionActions: string | string[];
  autoRefreshState: string;
  accountNumber: string;
  balance: number;
  accountName: string;
};
//FL4 upgrade end

//NOTE: We can remove the empty object {} once we review the usage
export type TXMState = {
  transactionRequestStatus: null | string;
  transactionList: {} | [] | Transaction[];
  transactionsCount: number;
  lastUpdated: string;
  enrollmentState: {} | null | string;
  institutionsList: {} | [] | Institution[];
  alertPreferences: {} | [] | AlertPreference[];
  accounts: {} | [] | ContainerAccounts[];
  paginatedTransactions: {} | [] | Transaction[];
  filteredTransactionList: {} | [] | Transaction[];
  deleteInstitutionLoginStatus: {} | string;
  dateOption: string;
  accountName: string;
  txmJpUrlRedirectStatus: null | string;
  redirectUrl: null | string;
};

export type InstitutionAccountsResponse = {
  alertPreferences?: [
    {
      type: string;
      value: string;
    },
  ];
  institutionsList?: Institution[] | NoResult;
  accounts?: [];
  type: string;
};

export type NoResult = {
  noResults?: string;
};

const initialState = {
  transactionRequestStatus: null,
  transactionList: {},
  transactionsCount: 0,
  lastUpdated: '',
  enrollmentState: {},
  institutionsList: {},
  alertPreferences: {},
  accounts: {},
  paginatedTransactions: {},
  filteredTransactionList: {},
  deleteInstitutionLoginStatus: {},
  dateOption: 'Date',
  accountName: 'Accounts',
  txmJpUrlRedirectStatus: null,
  redirectUrl: null,
  last30DaysTransactionsCount: 0,
  last30DaysRecurringTransactionsCount: 0,
  last30DaysRecurringTransactionsTotal: 0,
  recurringTransactions: [],
  recurringTransactionsStatus: null,
  recurringBillsAlertRequestStatus: null,
};

export default function transactions(state = initialState, action = null): TXMState {
  if (!action) return state;
  switch ((action as $TSFixMe).type) {
    case TRANSACTION_LIST_REQUEST:
      return Object.assign({}, state, {
        transactionRequestStatus: 'loading',
        transactionList: {},
        transactionsCount: 0,
        lastUpdated: '',
        last30DaysTransactionsCount: 0,
        last30DaysRecurringTransactionsCount: 0,
        last30DaysRecurringTransactionsTotal: 0,
        recurringTransactions: [],
      });
    case TRANSACTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        transactionRequestStatus: 'success',
        transactionList: (action as $TSFixMe).transactionList,
        transactionsCount: (action as $TSFixMe).transactionsCount,
        last30DaysTransactionsCount: (action as $TSFixMe).last30DaysTransactionsCount,
        lastUpdated: (action as $TSFixMe).lastUpdated,
        filteredTransactionList: {},
        last30DaysRecurringTransactionsCount: (action as $TSFixMe).last30DaysRecurringTransactionsCount,
        last30DaysRecurringTransactionsTotal: (action as $TSFixMe).last30DaysRecurringTransactionsTotal,
        recurringTransactions: (action as $TSFixMe).recurringTransactions,
        recurringTransactionsStatus: (action as $TSFixMe).recurringTransactionsStatus,
      });
    case TRANSACTION_OVERVIEW_SUCCESS:
      return Object.assign({}, state, {
        transactionList4Overview: (action as $TSFixMe).transactionList4Overview,
      });
    case TRANSACTION_LIST_FAILURE:
      return Object.assign({}, state, {
        transactionRequestStatus: 'error',
        transactionList: {},
        transactionsCount: 0,
        lastUpdated: {},
        last30DaysTransactionsCount: 0,
        last30DaysRecurringTransactionsCount: 0,
        last30DaysRecurringTransactionsTotal: 0,
        recurringTransactions: [],
        recurringTransactionsStatus: 'error',
      });
    case MEMBER_ACCOUNTS_REQUEST:
      return Object.assign({}, state, {
        accountsRequestStatus: 'loading',
        institutionsList: [],
      });
    case MEMBER_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        accountsRequestStatus: 'success',
        institutionsList: (action as $TSFixMe).institutionsList,
        alertPreferences: (action as $TSFixMe).alertPreferences,
        accounts: (action as $TSFixMe).accounts,
      });
    case MEMBER_ACCOUNTS_FAILURE:
      return Object.assign({}, state, {
        accountsRequestStatus: 'error',
        institutionsList: [],
      });
    case PAGINATE_TRANSACTIONS:
      return Object.assign({}, state, {
        paginatedTransactions: (action as $TSFixMe).paginatedTransactions,
      });
    case FILTERED_TRANSACTIONS:
      return Object.assign({}, state, {
        filteredTransactionList: (action as $TSFixMe).filteredTransactionList,
      });
    case CURRENT_DATE_SELECTION:
      return Object.assign({}, state, {
        dateOption: (action as $TSFixMe).dateOption,
      });
    case CURRENT_ACCOUNT_SELECTION:
      return Object.assign({}, state, {
        accountName: (action as $TSFixMe).accountName,
      });
    case TXM_SET_ALERT_PREF_REQUEST:
      return Object.assign({}, state, {
        alertPreferencesRequestStatus: 'loading',
        alertPreferences: [],
      });
    case TXM_SET_ALERT_PREF_SUCCESS:
      return Object.assign({}, state, {
        alertPreferencesRequestStatus: 'success',
        alertPreferences: (action as $TSFixMe).alertPreference,
      });
    case TXM_SET_RECURRING_ALERT_OPT_REQUEST:
      return Object.assign({}, state, {
        recurringBillsAlertRequestStatus: 'request',
      });
    case TXM_SET_RECURRING_ALERT_OPT_SUCCESS:
      return Object.assign({}, state, {
        recurringBillsAlertRequestStatus: 'success',
      });
    case TXM_SET_ALERT_PREF_FAILURE:
      return Object.assign({}, state, {
        alertPreferencesRequestStatus: 'error',
      });
    case DELETE_INSTITUTION_LOGIN_REQUEST:
      return Object.assign({}, state, {
        deleteInstitutionLoginStatus: 'loading',
      });
    case DELETE_INSTITUTION_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        delete_institution_login_response: (action as $TSFixMe).payload,
        deleteInstitutionLoginStatus: 'success',
      });
    case DELETE_INSTITUTION_LOGIN_FAILURE:
      return Object.assign({}, state, {
        deleteInstitutionLoginStatus: 'error',
      });
    case GET_ACCESS_TOKEN_REQUEST:
      return Object.assign({}, state, {
        getAccessTokenStatus: 'request',
        getAccessTokenResult: {},
      });
    case GET_ACCESS_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        getAccessTokenStatus: 'success',
        getAccessTokenResult: (action as $TSFixMe).getAccessTokenResult,
      });
    case GET_ACCESS_TOKEN_ERROR:
      return Object.assign({}, state, {
        getAccessTokenStatus: 'error',
        getAccessTokenResult: {},
      });
    default:
      return state;
  }
}
