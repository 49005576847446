import {WINDOWS_SCROLLED_EVENT_STORE} from '../actions/scroll';

const initialState = {
  scrollTop: 0,
  offsetTop: 0,
  windowHeight: 0,
  headerHeight: 0,
};

export default function scroll(state: ScrollEventPayload = initialState, action: ScrollAction) {
  switch (action.type) {
    case WINDOWS_SCROLLED_EVENT_STORE:
      return Object.assign({}, state, action.scrollEvent);
    default:
      return state;
  }
}
