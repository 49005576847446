/**
 * Helpers for determining UI and theme.
 */
import _get from 'lodash/get';

interface Auth {
  user: {
    primaryMember: {
      plan: {
        isDsp: boolean;
        isDwm: boolean;
      };
    };
    associatedMembers: Object[];
  };
}

/**
 * Returns true if app should use DSP UI.
 * DSP UI should be used if plan is DSP, or plan is DWM and there are one or more associated members.
 */
export function shouldUseDspUi(auth: Auth): boolean {
  //console.log(JSON.stringify(_get(auth, 'user.associatedMembers', [])));
  return (
    _get(auth, 'user.primaryMember.plan.isDsp', false) ||
    (_get(auth, 'user.primaryMember.plan.isDwm', false) && _get(auth, 'user.associatedMembers', []).length > 0)
  );
}

/**
 * Returns true if app should use DWM UI.
 * DWM UI should be used if plan is DWM and there are no associated members.
 */
export function shouldUseDwmUi(auth: Auth): boolean {
  return _get(auth, 'user.primaryMember.plan.isDwm', false) && _get(auth, 'user.associatedMembers', []).length === 0;
}
