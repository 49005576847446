import React, {Component} from 'react';
import utils from '../helpers/utils';

type Props = {
  children: JSX.Element;
  errorComponent: JSX.Element;
};

type State = {
  isError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  state = {
    isError: false,
  };

  static getDerivedStateFromError() {
    return {isError: true};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.logErrorToBackend(error, errorInfo);
  }

  logErrorToBackend(error: Error, errorInfo: React.ErrorInfo) {
    try {
      const payload = {
        source: 'ErrorBoundary',
        clientId: window.isDwm ? 'dwm' : 'dsp',
        message: error.toString(),
        error: errorInfo.componentStack,
      };
      utils.log(payload);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const {isError} = this.state;
    const {children, errorComponent} = this.props;
    return isError ? errorComponent : children;
  }
}
