import {ACTIVE_ALERTS_TAB, ACTIVE_REPORT} from '../actions/tabs';

const initialState = {
  report: 'EFX',
  activeAlertTab: 'Inbox',
};

export default function tabs(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case ACTIVE_REPORT:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {report: action.report});
    case ACTIVE_ALERTS_TAB:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {activeAlertTab: action.activeAlertTab});
    default:
      return state;
  }
}
