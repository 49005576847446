/* global EfxConsumerApiConstants EfxConsumerApiClient */

import _get from 'lodash/get';
import {getLangCode} from '../stringBundle';
import {api} from '../helpers/api';
import utils from '../helpers/utils';
import {
  updateAlertInAlertList,
  //getAlertListInbox,
  //getAlertListDisputed,
  //getAlertListArchived
} from '../actions/alertList';

export const ALERT_DETAIL_REQUEST = 'ALERT_DETAIL_REQUEST';
export const ALERT_DETAIL_RESET = 'ALERT_DETAIL_RESET';
export const ALERT_DETAIL_SUCCESS = 'ALERT_DETAIL_SUCCESS';
export const ALERT_DETAIL_FAILURE = 'ALERT_DETAIL_FAILURE';

export const ALERTS_DETAIL_DISPOSITION_REQUEST = 'ALERTS_DETAIL_DISPOSITION_REQUEST';
export const ALERTS_DETAIL_DISPOSITION_SUCCESS = 'ALERTS_DETAIL_DISPOSITION_SUCCESS';
export const ALERTS_DETAIL_DISPOSITION_FAILURE = 'ALERTS_DETAIL_DISPOSITION_FAILURE';
export const ALERT_DETAIL_SET_DETAIL_VIEW_INDEX = 'ALERT_DETAIL_SET_DETAIL_VIEW_INDEX';

export const ALERT_DETAIL_MOBILE = 'ALERT_DETAIL_MOBILE';

function alertDetailRequest() {
  return {
    type: ALERT_DETAIL_REQUEST,
  };
}

export function alertDetailReset() {
  return {
    type: ALERT_DETAIL_RESET,
  };
}

function alertDetailSuccess(payload: $TSFixMe, options: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    const payloadUpdated = Object.assign({}, payload);
    const updatedAlertDetail = _get(payloadUpdated, 'alertDetail');
    const alertId = _get(updatedAlertDetail, 'id');
    if (updatedAlertDetail) {
      const extAlertId = _get(updatedAlertDetail, 'extAlertId', '');
      const alertType = _get(updatedAlertDetail, 'alertType', '');
      const isEquifaxAlertType = !!(alertType && alertType.indexOf('equifax') >= 0);

      if (extAlertId && isEquifaxAlertType && window.REACT_APP_ENABLE_EFX_ALERTS === true) {
        // fetch jwt token if alert type is equifax and has extAlertId
        return (
          options.refetch &&
          options
            .refetch()
            .then((jwtResponse: JWT) => {
              const jwt = _get(jwtResponse, 'data', {}) || {};
              if (!jwt) {
                return dispatch({
                  type: ALERT_DETAIL_FAILURE,
                  error: 'jwt error',
                });
              }
              const jwtVendorInfoScope = _get(jwt, 'vendorInfo.scope');
              const apiKey = _get(jwt, 'vendorInfo.equifaxApiKey');
              const server = _get(jwt, 'vendorInfo.equifaxEndPoint');
              const clientAssertion = _get(jwt, 'jwtToken');
              const scope =
                jwtVendorInfoScope === 'delivery'
                  ? // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
                    EfxConsumerApiConstants.ApiChannel.DELIVERY
                  : // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
                    EfxConsumerApiConstants.ApiChannel.PROXY_DELIVERY;

              // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiClient'.
              return EfxConsumerApiClient({
                scope,
                apiKey,
                server,
                clientAssertion,
              }).then((clientLibraryInstance: $TSFixMe) => {
                return clientLibraryInstance.getCreditAlert(extAlertId).then(
                  (response: $TSFixMe) => {
                    payloadUpdated.alertDetail.efxDetail = response;

                    dispatch({
                      type: ALERT_DETAIL_SUCCESS,
                      alertDetail: payloadUpdated.alertDetail,
                    });

                    dispatch(updateAlertInAlertList(alertId, updatedAlertDetail));
                  },
                  () => {
                    dispatch({
                      type: ALERT_DETAIL_FAILURE,
                      error: 'equifax call error',
                    });
                  }
                );
              });
            })
            .catch(() => {
              return dispatch({
                type: ALERT_DETAIL_FAILURE,
                error: 'jwt error',
              });
            })
        );
      }

      dispatch(updateAlertInAlertList(alertId, updatedAlertDetail));
      dispatch({
        type: ALERT_DETAIL_SUCCESS,
        alertDetail: updatedAlertDetail,
      });
    } else {
      dispatch({
        type: ALERT_DETAIL_FAILURE,
        error: `Unable to load alertId: ${options.alertId}`,
      });
    }
  };
}

function alertDetailFailure(error: $TSFixMe) {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: ALERT_DETAIL_FAILURE,
      error,
    });
  };
}

export function getAlertDetail(
  alertId: $TSFixMe,
  accountId: $TSFixMe,
  isMobileLogin: $TSFixMe,
  refetch?: $TSFixMeFunction
) {
  const url = utils.getAlertDetailViewPath(alertId);

  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      account_id: accountId,
      mobileLogin: isMobileLogin,
      lang_code: getLangCode(),
    },
    credentials: 'include',
  };

  const options = {
    alertId,
    refetch,
  };

  return api(url, config, alertDetailRequest(), alertDetailSuccess, alertDetailFailure, options);
}

function alertDetailDispositionRequest() {
  return {
    type: ALERTS_DETAIL_DISPOSITION_REQUEST,
  };
}
function alertDetailDispositionSuccess(payload: $TSFixMe) {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const previousAlertDetail = getState().alertDetail;
    const previousBucket = _get(previousAlertDetail, 'alertDetail.bucket', '');
    const newAlertDetail = _get(payload, 'alertDetail');
    newAlertDetail.previousBucket = previousBucket;

    if (!newAlertDetail) {
      dispatch({
        type: ALERTS_DETAIL_DISPOSITION_FAILURE,
        error: 'Missing newAlertDetail',
      });
      return;
    }

    const currentAlertDetail = getState().alertDetail;
    const currentAlertEFXDetail = _get(currentAlertDetail, 'alertDetail.efxDetail');
    const dispositionQuestions = _get(currentAlertDetail, 'alertDetail.dispositionQuestions', []);

    if (dispositionQuestions) {
      newAlertDetail.dispositionQuestions = dispositionQuestions;
      newAlertDetail.efxDetail = currentAlertEFXDetail;
    }

    dispatch({
      type: ALERTS_DETAIL_DISPOSITION_SUCCESS,
      alertDetail: newAlertDetail,
    });
    dispatch(updateAlertInAlertList(newAlertDetail.id, newAlertDetail));
  };
}

function alertDetailDispositionFailure(error: $TSFixMe) {
  return {
    type: ALERTS_DETAIL_DISPOSITION_FAILURE,
    error,
  };
}

export function setAlertDetailDisposition(options: $TSFixMe, accountId: $TSFixMe, isMobileLogin: $TSFixMe) {
  let dispositionValue = options.disposition;
  if (typeof dispositionValue === 'string') {
    dispositionValue = options.disposition === 'true';
  }
  const url = utils.getAlertDetailPath(options.id);

  const config: FetchConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
      account_id: accountId,
      mobileLogin: isMobileLogin,
      lang_code: getLangCode(),
    },
    credentials: 'include',
    body: JSON.stringify({
      disposition: dispositionValue,
    }),
  };

  return api(
    url,
    config,
    alertDetailDispositionRequest(),
    alertDetailDispositionSuccess,
    alertDetailDispositionFailure,
    options
  );
}

export function setAlertDetailViewIndex(index: number): {type: string; index: number} {
  return {
    type: ALERT_DETAIL_SET_DETAIL_VIEW_INDEX,
    index,
  };
}

export function alertDetailMobileMemberChat(alertDetail: $TSFixMe): {type: string; alertDetail: $TSFixMe} {
  return {
    type: ALERT_DETAIL_MOBILE,
    alertDetail,
  };
}
