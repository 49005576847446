import {
  CLEAR_FULFILLMENT_INFO,
  CREDIT_FULFILLMENT_FAILURE,
  CREDIT_FULFILLMENT_REQUEST,
  CREDIT_FULFILLMENT_SUCCESS,
  CREDIT_ON_DEMAND,
  CREDIT_PULLINFO_FAILURE,
  CREDIT_PULLINFO_REQUEST,
  CREDIT_PULLINFO_SUCCESS,
  CREDIT_REPORT,
  LATEST_3B_CREDIT_REPORT,
  LATEST_3B_CREDIT_REPORT_STATUS,
  PREVIOUS_EFX_CREDIT_REPORT,
  PREVIOUS_EFX_CREDIT_REPORT_STATUS,
  PREVIOUS_3B_CREDIT_REPORT,
  PREVIOUS_3B_CREDIT_REPORT_STATUS,
  CREDIT_REPORT_ABSTRACT,
  CREDIT_REPORT_ABSTRACT_STATUS,
  CREDIT_REPORT_STATUS,
  CREDIT_SCORE,
  CREDIT_SCORE_HISTORY,
  CREDIT_SCORE_HISTORY_STATUS,
  CREDIT_SCORE_STATUS,
  DOWNLOAD_REPORT_STATUS,
  EQUIFAX_STATUS,
  SET_PROVIDER_BUREAU_DATA,
  TU_REPORT_FAILURE,
  TU_REPORT_HISTORY_FAILURE,
  TU_REPORT_HISTORY_REQUEST,
  TU_REPORT_HISTORY_SUCCESS,
  TU_REPORT_REQUEST,
  TU_REPORT_SUCCESS,
} from '../actions/credit';
import {buildCreditScoreData} from '../helpers/buildCreditScoreData';
import {buildCreditReportData} from '../helpers/buildCreditReportData';

const initialState = {
  credit_score: {},
  credit_score_status: '',
  status_text: '',
  credit_score_history: [],
  credit_score_history_status: '',
  credit_report: {},
  credit_report_status: '',
  credit_report_error_code: '',
  credit_report_abstract_status: '',
  download_report_status: '',
  primaryBureau: 'EFX',
  creditRangeMax: '850',
  caTu: {
    newReportAllowed: false,
    nextAvailablePullDate: '',
    nextAvailablePullDateAfterToday: '',
    previousScore: 0,
    previousScoreDate: '',
  },
  creditOnDemand: false,
  creditPullInfo: {},
  creditPullInfoStatus: '',
  creditFulfillment: {},
  creditReportTypeRequest: '',
  creditFulfillmentStatus: '',
  latest_3B_credit_report: {},
  latest_3B_credit_report_status: '',
  previous_EFX_credit_report: {},
  previous_EFX_credit_report_status: '',
  previous_3B_credit_report: {},
  previous_3B_credit_report_status: '',
};

export default function credit(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    //Equifax
    case CREDIT_SCORE:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {credit_score: action.credit_score, credit_score_status: 'success'});
    case CREDIT_SCORE_STATUS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_score_status: action.credit_score_status,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        status_text: action.status_text,
      });
    case CREDIT_SCORE_HISTORY:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_score_history: action.credit_score_history,
        credit_score_history_status: 'success',
      });
    case CREDIT_SCORE_HISTORY_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {credit_score_history_status: action.credit_score_history_status});
    case CREDIT_REPORT:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_report: action.credit_report,
        credit_report_status: 'success',
      });
    case CREDIT_REPORT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {credit_report_status: action.credit_report_status});
    case LATEST_3B_CREDIT_REPORT:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {latest_3B_credit_report: action.latest_3B_credit_report});
    case LATEST_3B_CREDIT_REPORT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {latest_3B_credit_report_status: action.latest_3B_credit_report_status});
    case PREVIOUS_EFX_CREDIT_REPORT:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {previous_EFX_credit_report: action.previous_EFX_credit_report});
    case PREVIOUS_EFX_CREDIT_REPORT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {previous_EFX_credit_report_status: action.previous_EFX_credit_report_status});
    case PREVIOUS_3B_CREDIT_REPORT:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {previous_3B_credit_report: action.previous_3B_credit_report});
    case PREVIOUS_3B_CREDIT_REPORT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {previous_3B_credit_report_status: action.previous_3B_credit_report_status});
    case CREDIT_REPORT_ABSTRACT:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {credit_report_abstract: action.credit_report_abstract});
    case CREDIT_REPORT_ABSTRACT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {credit_report_abstract_status: action.credit_report_abstract_status});
    case CREDIT_ON_DEMAND:
      return Object.assign({}, state, {creditOnDemand: true});
    case CREDIT_FULFILLMENT_REQUEST:
      return Object.assign(
        {},
        state,
        {creditFulfillmentStatus: 'loading'},
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        {creditReportTypeRequest: action.reportType}
      );
    case CREDIT_FULFILLMENT_SUCCESS:
      return Object.assign(
        {},
        state,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        {creditFulfillment: action.fulfillmentInfo},
        {creditFulfillmentStatus: 'success'}
      );
    case CREDIT_FULFILLMENT_FAILURE:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {creditFulfillment: action.error}, {creditFulfillmentStatus: 'error'});
    case CREDIT_PULLINFO_REQUEST:
      return Object.assign({}, state, {creditPullInfoStatus: 'loading'});
    case CREDIT_PULLINFO_SUCCESS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {creditPullInfo: action.pullInfo}, {creditPullInfoStatus: 'success'});
    case CREDIT_PULLINFO_FAILURE:
      return Object.assign({}, state, {creditPullInfoStatus: 'error'});
    case EQUIFAX_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {equifax_status: action.equifax_status});
    case DOWNLOAD_REPORT_STATUS:
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      return Object.assign({}, state, {download_report_status: action.download_report_status});
    //TransUnion
    case TU_REPORT_HISTORY_REQUEST:
      return Object.assign({}, state, {
        credit_score_history_status: 'loading',
      });
    case TU_REPORT_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_score_history: action.reportHistoryScoreTrend,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_score: action.reportHistory[0]
          ? // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
            buildCreditScoreData(action.reportHistory[0], action.localizedStringBundle, action.langCode, 'CATU')
          : {},
        credit_score_history_status: 'success',
        credit_score_status: 'success',
        caTu: Object.assign({}, state.caTu, {
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          newReportAllowed: action.newReportAllowed,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          nextAvailablePullDate: action.nextAvailablePullDate,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          nextAvailablePullDateAfterToday: action.nextAvailablePullDateAfterToday,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          previousScore: action.previousScore,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          previousScoreDate: action.previousScoreDate,
        }),
      });
    case TU_REPORT_HISTORY_FAILURE:
      return Object.assign({}, state, {
        credit_score_history_status: 'error',
        credit_score_status: 'error',
      });
    case TU_REPORT_REQUEST:
      return Object.assign({}, state, {
        credit_report_status: 'loading',
      });
    case TU_REPORT_SUCCESS:
      return Object.assign({}, state, {
        credit_report: buildCreditReportData(
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          action.report,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          action.localizedStringBundle,
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          action.langCode,
          'CATU',
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          action.accountId
        ),
        credit_report_status: 'success',
      });
    case TU_REPORT_FAILURE:
      return Object.assign({}, state, {
        credit_report_status: 'error',
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        credit_report_error_code: action.errorCode,
      });
    case SET_PROVIDER_BUREAU_DATA:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        primaryBureau: action.bureau,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        creditRangeMax: action.creditMax,
      });
    case CLEAR_FULFILLMENT_INFO:
      return Object.assign({}, state, {creditReportTypeRequest: '', creditFulfillmentStatus: ''});
    default:
      return state;
  }
}
