import {useQuery} from '@tanstack/react-query';
import {fetchRest} from '../helpers/fetchRest';
import utils from '../helpers/utils';

/**
 * get JWT for user
 * @param {string} accountId - user account id to get JWT
 * @param {boolean} enableJWT - enable jwt api call
 * @param {string} key - identifier for a React Query, to manage and cache query results
 * @return {Object} returns JWT
 */
const useJWT = (accountId: string, enableJWT = true, key = '') => {
  const url = utils.getJWTPath();

  return useQuery(
    ['jwt', key, accountId],
    () =>
      fetchRest(url, {
        method: 'GET',
        headers: {Accept: 'application/json', account_id: accountId},
      }),
    {
      staleTime: 0, // don't cache the jwt results
      enabled: !!accountId && enableJWT,
    }
  );
};

export default useJWT;
