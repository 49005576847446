import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import alertListReducer from './alertListReducer';
import auth from './auth';
import credit from './credit';
import tabs from './tabs';
import modal from './modal';
import alertList from './alertList';
import alertDetail from './alertDetail';
import parentWindow from './parentWindow';
import logout from './logout';
import onboarding from './onboardingDashboard';
import alertCategoryFilter from './alertCategoryFilter';
import locks from './locks';
import otp from './otp';
import socialMediaMonitoring from './socialMediaMonitoring';
import fcra from './fcra';
import transunionUk from './transunionUk';
import transactions from './transactionReducers';
import quebecOptIn from './quebecOptIn';
import scroll from './scroll';

let rootReducer: any;

/**
 * Returns result of combining default reducers with async reducers
 * @param asyncReducers hash of name=>reducer pairs
 * @returns {Reducer<any>}
 */
export default function createReducer(asyncReducers: $TSFixMe) {
  rootReducer = combineReducers({
    alertListReducer,
    auth,
    credit,
    tabs,
    modal,
    alertList,
    alertDetail,
    parentWindow,
    logout,
    onboarding,
    alertCategoryFilter,
    locks,
    otp,
    socialMediaMonitoring,
    fcra,
    transunionUk,
    transactions,
    quebecOptIn,
    scroll,
    form: formReducer,
    ...asyncReducers,
  });
  return rootReducer;
}
export type RootState = ReturnType<typeof rootReducer>;
