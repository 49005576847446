import classNames from 'classnames';
import utils from '../../helpers/utils';
import * as menuUtils from '../../helpers/menuUtils';
import {useHistory} from 'react-router-dom';

type Props = {
  href: string;
  label: string;
  selected?: string;
  option?: $TSFixMe;
  iconName: string;
  whiteLabelClient: string; // Identifies white label client associated with the current user. Used to select partner-specific image.
  supportComponent: string | JSX.Element;
};

function MenuItem({option, selected, href, label, iconName, whiteLabelClient, supportComponent}: Props) {
  const history = useHistory();
  const handleMenuItemClick = () => {
    utils.scrollTo(0); // scrolls to top of the opened page if user had scrolled earlier
    if (option) {
      utils.trackEvent(option);
    }
    // go to the selected page
    history.push(href);
  };

  const menuItemClasses = classNames(
    'text-center sm:text-left min-w-52 w-full block text-base no-underline lg:mt-0 py-1 px-0 text-primary rounded-xl hidden lg:flex',
    selected && selected === href ? 'lg:bg-brand-primary font-extrabold' : 'font-medium bg-background'
  );

  const icon = iconName && menuUtils.renderNavIcon(iconName, whiteLabelClient);

  return (
    <button
      className="focus:ring-blue-500 mt-1 py-0 font-extrabold focus:outline-none focus:ring-2"
      onClick={handleMenuItemClick}
      data-testid={iconName}
    >
      <div className={menuItemClasses}>
        <span className="mx-2 h-8">{icon}</span>
        <span className="my-auto mr-4">{label}</span>
        {supportComponent && <span className="my-auto ml-auto mr-2">{supportComponent}</span>}
      </div>
    </button>
  );
}

export default MenuItem;
