import {WINDOW_SPEC_THREAT_LIST} from '../actions/parentWindow';

const initialState = {
  windowSpecThreatList: {
    scrollTop: 0,
  },
};

export default function parentWindow(state = initialState, action = null) {
  // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  switch (action.type) {
    case WINDOW_SPEC_THREAT_LIST:
      return Object.assign({}, state, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        windowSpecThreatList: action.windowSpecThreatList,
      });
    default:
      return state;
  }
}
