import {useQuery} from '@tanstack/react-query';
import {fetchRest} from '../helpers/fetchRest';
import utils from '../helpers/utils';

/**
 * Fetch user scam protect cases through member-api.
 * @return {Object} scam protect cases data, various response status(s),
 * and customize object for handling scam protect cases and case details screen(s).
 */

export const useScamValidationCases = () => {
  const url = utils.getScamValidationCasesPath();
  return useQuery(['scamProtect'], () =>
    fetchRest(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
  );
};
