import {
  TRANSUNION_UK_REGISTER_GET_REQUEST_FAILURE,
  TRANSUNION_UK_REGISTER_GET_REQUEST_INITIATE,
  TRANSUNION_UK_REGISTER_GET_REQUEST_SUCCESS,
} from '../actions/transunionUk';

const initialState = {
  ctaUrl: '',
  status: '',
};

type Action = {
  type: string;
  ctaUrl: string;
};

export type transunionUkRegisterStateType = {
  ctaUrl: string; // token url
  status: string; // status denotes success/error/loading
};

export default function transunionUk(
  state: transunionUkRegisterStateType = initialState,
  action: Action
): {ctaUrl: string; status: string} {
  switch (action.type) {
    case TRANSUNION_UK_REGISTER_GET_REQUEST_INITIATE:
      return Object.assign({}, state, {ctaUrl: '', status: 'loading'});
    case TRANSUNION_UK_REGISTER_GET_REQUEST_SUCCESS:
      return Object.assign({}, state, {ctaUrl: action.ctaUrl, status: 'success'});
    case TRANSUNION_UK_REGISTER_GET_REQUEST_FAILURE:
      return Object.assign({}, state, {ctaUrl: '', status: 'error'});
    default:
      return state;
  }
}
