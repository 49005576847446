const adobePageNameMap = {
  '/dashboard': 'dashboard',
  '/hometitle': 'home title',
  '/alerts': 'alerts inbox',
  '/alerts/archived': 'alerts archived',
  '/alerts/disputed': 'alerts disputed',
  '/alerts/inbox': 'alerts inbox',
  '/alerts/detail': 'alerts',
  '/alertPreferences': 'alert preferences',
  '/credit': 'credit',
  '/credit/efx': 'credit efx',
  '/credit/tu': 'credit tu',
  '/credit/exp': 'credit exp',
  '/locks': 'locks',
  '/privacyadvisor': 'privacy advisor',
  '/transactions': 'transactions',
  '/transactions/all': 'transactions',
  '/restoration': 'id restoration',
  '/monitoring': 'monitored info',
  '/error': 'error',
  YODLEE_DISCLAIMER_MODAL: 'add account to monitor',
  ALERT_DETAILS_MODAL: 'alert detail',
  ALERT_PREF_MODAL: 'alert preferences',
  CREDIT_FREEZE_MODAL: 'credit freeze',
  CREDIT_HISTORY_MODAL: 'credit score history',
  CREDIT_INQUIRIES_MODAL: 'credit inquiries',
  CREDIT_PULL_MODAL: 'credit pull',
  CREDIT_REPORT_DOWNLOAD_MODAL: 'download credit report',
  CREDIT_REPORT_INACCURACY_MODAL: 'dispute an error',
  DELETE_INSTITUTION_MODAL: 'delete institution',
  EDIT_ADDRESS_MODAL: 'monitor your address',
  EDIT_BANK_ACCOUNT_MODAL: 'monitor your bank',
  EDIT_CREDIT_DEBIT_CARD_MODAL: 'monitor your credit debit card',
  EDIT_DRIVERS_LICENSE_MODAL: 'monitor your driver license',
  EDIT_E_MAIL_ADDR_MODAL: 'monitor your email',
  EDIT_GAMER_TAG_MODAL: 'monitor your gamer tag',
  EDIT_INSURANCE_CARD_MODAL: 'monitor your insurance',
  EDIT_MOTHERS_MAIDEN_NAME_MODAL: 'monitor your mothers maiden name',
  EDIT_PHONE_NUMBER_MODAL: 'monitor your phone number',
  LOCKS_ERROR_MODAL: 'locks error',
  MA_PERSONAL_INFO_STATUS_MODAL: 'personal info add status',
  MA_ACCOUNTS_STATUS_MODAL: 'add account status',
  MA_ALERT_PREF_STATUS_MODAL: 'alert preferences status',
  PAYMENT_HISTORY_MODAL: 'payment history',
  PRIMARY_PHONE_PROMPT_MODAL: 'primary phone prompt',
  REMOVE_SUPPLEMENTAL_MODAL: 'remove supplemental',
  SMM_ADD_ACCOUNT_SUCCESS_ERROR_MODAL: 'smm add account success error',
  SMM_CONNECT_ACCOUNT_MODAL: 'smm add account',
  SMM_DELETE_ACCOUNT_MODAL: 'smm delete account',
  SMS_MODAL: 'sms',
  SUCCESS_MODAL: 'success',
  SUMMARY_OF_RIGHTS_MODAL: 'summary of rights',
  TRANSACTIONS_ALERT_PREF_MODAL: 'transaction alert preferences',
  VANTAGE_SCORE_MODAL: 'vantage score info',
  VERBAL_PASSCODE_MODAL: 'verbal passcode',
  VERIFY_EMAIL_MODAL: 'verify email',
  VIEW_BALANCES_MODAL: 'view balances',
  WELCOME_MODAL: 'welcome',
};
export default {
  adobePageNameMap,
};
