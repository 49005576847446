import {reduxForm} from 'redux-form';
import Button from '../../components/common/Button';

type Props = {
  onSubmit?: $TSFixMeFunction;
  checkBoxSelected?: boolean;
  localizedStringBundle?: $TSFixMe;
};

export const FcraForm = ({onSubmit, checkBoxSelected, localizedStringBundle}: Props) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="m-auto flex h-10 w-fit flex-row justify-center">
        <Button size="medium" variant="primary" disabled={!checkBoxSelected} id="btn-accept-fcra">
          {localizedStringBundle.CONTINUE}
        </Button>
      </div>
    </form>
  );
};
export default reduxForm({
  form: 'fcraForm',
  touchOnChange: true,
  // @ts-expect-error TS(2345) FIXME: Argument of type '({ onSubmit, checkBoxSelected, l... Remove this comment to see the full error message
})(FcraForm);
